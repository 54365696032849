import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import Modal from '../../components/global/ui/Modal';
import { IUserNotifications } from '../../interfaces/notification';
import { useEffect } from 'react';
import { useNotifications } from '../../pages/main/Notifications/useNotifications';
import { routes } from '../../constants/routes';

export default function ViewNotification({
  notification,
  closeModal
}: {
  notification: IUserNotifications;
  closeModal: () => void;
}) {
  const { markNotificationAsRead } = useNotifications();

  useEffect(() => {
    if (notification.status == 'pending') {
      // mark notification as read
      markNotificationAsRead(notification.id).then((res) => console.log('read'));
    }
  }, [notification.status === 'pending']);

  return (
    <Modal
      closeModal={closeModal}
      title={notification.event_type.replace(/[._]/g, ' ').toUpperCase()}
      width={'w-full md:w-4/5 lg:w-1/3'}>
      <div>
        <div className={'flex flex-col lg:flex-row justify-between items-start gap-2'}>
          <span className={'text-gray-600 text-sm'}>
            {moment(notification.time_added).format('lll')}
          </span>
        </div>
        <div
          className={`text-sm mt-2`}
          dangerouslySetInnerHTML={{
            __html: notification.message as string
          }}></div>
        <div className={'mt-2 text-sm'}>
          {notification.body.payment_method_details && notification.body.paid ? (
            <div>
              <p>
                Your {notification.body.payment_method_details.card.brand.toUpperCase()} card with
                number ending {notification.body.payment_method_details.card.last4} was charged{' '}
                {notification.body.currency.toUpperCase()}{' '}
                {(notification.body.amount / 100).toFixed(2)} successfully.
              </p>{' '}
              <div className={'mt-2 flex'}>
                <a
                  target={'_blank'}
                  href={notification.body.receipt_url}
                  className={
                    'rounded px-3 py-2 gap-2 bg-gray-200 hover:bg-gray-300 text-gray-700 text-sm flex'
                  }
                  rel="noreferrer">
                  Download receipt
                </a>
              </div>
            </div>
          ) : notification.body.payment_method_details && notification.body.failure_message ? (
            <div>
              <p>
                We attempted charging your{' '}
                {notification.body.payment_method_details.card.brand.toUpperCase()} card with number
                ending {notification.body.payment_method_details.card.last4} was charged{' '}
                {notification.body.currency.toUpperCase()}{' '}
                {(notification.body.amount / 100).toFixed(2)}
              </p>
              <p>{notification.body.outcome.seller_message}</p>
              <div className={'mt-2 flex'}>
                <Link
                  to={routes.ACCOUNT_PAYMENTS}
                  className={'rounded-md px-3 py-2 gap-2 bg-[#008160] text-white text-sm flex'}>
                  Update payment method
                </Link>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </Modal>
  );
}
