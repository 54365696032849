import React, { useState, useEffect } from 'react';

export default function Tab2Content() {
 
  return (
        <>
          <div className="tabs inside here">
          tab 2 content
          </div>
        </>
  );
}
