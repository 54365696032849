import { useState } from 'react';
import Modal from '../../components/global/ui/Modal';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import WarningPrompt from '../../components/global/ui/alerts/WarningPrompt';
import Error from '../../components/global/ui/alerts/Error';
import { IAIDocument } from '../../interfaces/analytic';
import { useAnalytics } from '../../pages/main/Dashboard/useAnalytics';

export default function DeleteAIPrompt({
  data,
  closeModal,
  refetch
}: {
  data: IAIDocument;
  closeModal: () => void;
  refetch: () => void;
}) {
  const { deleteAiInsight, loading, errorMessage } = useAnalytics();
  const [message, setMessage] = useState<string | null>(null);

  const handleDeleteDocument = async () => {
    setMessage(null);
    const response = await deleteAiInsight({
      id: data.id
    });
    if (response?.status == 200) {
      setMessage('Document has been successfully deleted!');
      refetch();
      setTimeout(() => {
        closeModal();
      }, 1000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Delete document'} width={'w-full md:w-1/2 lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Deleting document...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <div>
              <div>
                <WarningPrompt>
                  <>
                    <p className={'font-medium'}>You are opting to delete this OpenAI document</p>
                    <p className={'text-xs'}>
                      Proceeding to delete will remove this OpenAI document completely from the
                      platform.
                    </p>
                  </>
                </WarningPrompt>
              </div>
              <div className={'grid gap-2 mt-5'}>
                <div className={'mt-2 flex flex-col-reverse lg:flex-row justify-end gap-2'}>
                  <button
                    type={'button'}
                    onClick={closeModal}
                    className={`px-4 py-2 rounded text-gray-700 bg-gray-200 hover:bg-gray-300 text-xs`}>
                    No, do not delete
                  </button>
                  <button
                    type={'button'}
                    onClick={handleDeleteDocument}
                    disabled={loading}
                    className={`rounded font-medium px-4 py-2 text-xs bg-red-500 hover:bg-red-600 text-white`}>
                    {loading ? 'Deleting document..' : 'Yes, proceed to delete'}
                  </button>
                </div>
                {errorMessage && <Error value={`${errorMessage}`} />}
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}
