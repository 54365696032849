import { useState } from 'react';
import moment from 'moment';
import ViewNotification from './ViewNotification';
import { IUserNotifications } from '../../interfaces/notification';

export default function NotificationListCard({ data }: { data: IUserNotifications }) {
  const [selected, setSelected] = useState<IUserNotifications | null>(null);

  const [viewOpen, setViewOpen] = useState<boolean>(false);

  return (
    <>
      {viewOpen && selected && (
        <ViewNotification notification={selected} closeModal={() => setViewOpen(false)} />
      )}
      <div
        onClick={() => {
          setSelected(data);
          setViewOpen(true);
        }}
        className={
          'cursor-pointer flex gap-2 w-full items-center border rounded-md border-gray-200 bg-white hover:border-gray-400 p-3 md:p-3'
        }>
        {data.status !== 'seen' && (
          <div className={'flex items-start'}>
            <span className={'w-2 h-2 rounded-full bg-emerald-400'}></span>
          </div>
        )}
        <div className={'w-full'}>
          <div className={'flex justify-between items-start gap-2'}>
            <span className={`bg-gray-100 text-gray-600 px-2 py-1 text-xs`}>
              {data.event_type.replace(/[._]/g, ' ').toUpperCase()}
            </span>
            <span>
              <i className="fi fi-rr-angle-small-right text-lg text-gray-400"></i>
            </span>
          </div>
          <div
            className={`text-sm mt-1 ${data.status !== 'seen' ? 'font-medium' : 'font-normal'}`}
            dangerouslySetInnerHTML={{
              __html: data.message as string
            }}></div>
          <h6 className={'mt-1 text-xs text-gray-600'}>{moment(data.time_added).format('lll')}</h6>
        </div>
      </div>
    </>
  );
}
