import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface IDataObject {
  active: number;
  active_not_cancelled: number;
  paused: number;
  cancelled: number;
  pending_cancellation: number;
  incomplete: number;
}

export default function StatusChart({ data }: { data: any }) {
  const modifyValues = (data: IDataObject): string[] => {
    return Object.keys(data).map((value) => {
      // Remove underscores and capitalize words
      return value.replace(/_/g, ' ').replace(/\b\w/g, (char: any) => char.toUpperCase());
    });
  };
  const modifiedValues: string[] = modifyValues(data);

  const dataStats = {
    labels: modifiedValues,
    datasets: [
      {
        label: 'No. of Subscriptions',
        data: Object.values(data),
        backgroundColor: [
          'rgba(255, 206, 86, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(8, 145, 178, 0.2)',
          'rgba(108, 43, 145, 0.2)',
          'rgba(37, 99, 235, 0.2)'
        ],
        borderColor: [
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(8, 145, 178, 1)',
          'rgba(108, 43, 145, 1)',
          'rgba(37, 99, 235, 1)'
        ],
        borderWidth: 1
      }
    ]
  };
  return (
    <Doughnut
      options={{
        plugins: {
          legend: {
            position: 'bottom' as const
          },
          title: {
            text: 'Subscriptions Status',
            display: true
          }
        }
      }}
      data={dataStats}
    />
  );
}
