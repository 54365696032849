import { IOrganization } from '../../interfaces/organization';
import { useContext, useState } from 'react';
import LeaveOrganization from './LeaveOrganization';
import { OrganizationContext } from '../../contexts/OrganizationContext';

export default function OrganizationListCardItem({ data }: { data: IOrganization }) {
  const [leaveOpen, setLeaveOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<IOrganization | null>(null);
  const { profile } = useContext(OrganizationContext);

  return (
    <>
      {leaveOpen && selected && (
        <LeaveOrganization data={selected} closeModal={() => setLeaveOpen(false)} />
      )}
      <div
        className={
          'grid lg:grid-cols-10 border rounded-md cursor-default border-gray-200 text-sm hover:border-gray-400 bg-white hover:shadow p-3 md:p-3'
        }>
        <div className={'lg:col-span-4'}>
          <h6>{data.name}</h6>
        </div>
        <div className={'lg:col-span-2 flex gap-1 items-start'}>
          <span className={''}>
            {data.permission == 'read' ? (
              <i className="fi fi-rr-book-alt"></i>
            ) : data.permission == 'write' ? (
              <i className="fi fi-rr-pencil"></i>
            ) : (
              <i className="fi fi-rr-lock"></i>
            )}
          </span>
          <span className={''}>{data.access_label}</span>
        </div>
        <div className={'lg:col-span-2 flex lg:justify-end'}>
          {/*<span>{data.account_id}</span>*/}
        </div>
        <div className={'lg:col-span-2 flex justify-end'}>
          {profile && profile.profile_details?.account_id == data.account_id && (
            <div className={'mt-1'}>
              <span
                className={
                  'px-2 py-1 text-xs border border-emerald-500 text-emerald-600 rounded-full leading-none'
                }>
                Your account
              </span>
            </div>
          )}
          {profile && profile.profile_details?.account_id != data.account_id && (
            <button
              type={'button'}
              onClick={() => {
                setSelected(data);
                setLeaveOpen(true);
              }}
              className={
                'rounded font-medium px-3 lg:px-4 py-1 lg:py-2 text-xs bg-red-500 hover:bg-red-600 text-white'
              }>
              Leave
            </button>
          )}
        </div>
      </div>
    </>
  );
}
