import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useState } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import Modal from '../../components/global/ui/Modal';
import { useClients } from '../../pages/main/Clients/useClients';
import { IClient } from '../../interfaces/client';

export default function DeleteClient({
  client,
  closeModal
}: {
  client: IClient;
  closeModal: () => void;
}) {
  const { deleteClient, loading, errorMessage } = useClients();
  const [message, setMessage] = useState<string | null>(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Enter the account name')
  });

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema,
    onSubmit: () => handleDeleteClient()
  });

  const handleDeleteClient = async () => {
    setMessage(null);
    const response = await deleteClient({ userId: client.user_id as number });
    if (response?.result == 'success') {
      setMessage(response?.msg);
      setTimeout(() => {
        closeModal();
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Delete Client'} width={'lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Deleting client...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <div>
              <div>
                <div className={'grid gap-2 text-sm'}>
                  <p>
                    You are opting to delete this account from{' '}
                    <span className={'text-[#008160]'}>Heatmap.com</span>.
                  </p>
                </div>
                <div className={'mt-5'}>
                  <p className={'text-sm'}>
                    Type <strong>{client.full_name}</strong> below to proceed
                  </p>
                  <div className={'my-1'}>
                    <input
                      type={'text'}
                      name={'name'}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      className="w-full bg-transparent outline-0 px-3 py-2 rounded border border-gray-300 mt-1 flex items-center"
                    />

                    <p className={'text-xs text-red-500'}>
                      {formik.touched.name && formik.errors.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className={'grid gap-2'}>
                <div className={'mt-2 flex flex-col-reverse lg:flex-row justify-end gap-2'}>
                  <button
                    type={'button'}
                    onClick={closeModal}
                    className={`px-4 py-2 rounded text-gray-700 bg-gray-200 hover:bg-gray-300 text-xs`}>
                    No, do not cancel
                  </button>
                  <button
                    type={'button'}
                    onClick={handleDeleteClient}
                    disabled={formik.values.name !== client.full_name}
                    className={`rounded bg-white font-medium px-4 py-2 text-xs  ${
                      formik.values.name === '' || formik.values.name !== client.full_name
                        ? 'border border-red-500 text-red-500 hover:bg-transparent'
                        : 'bg-red-500 hover:bg-red-600 text-white'
                    }`}>
                    {loading ? 'Deleting a client...' : 'Yes, proceed to delete'}
                  </button>
                </div>
                {errorMessage && <Error value={`${errorMessage}`} />}
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}
