import { Link } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import Layout from '../../../layouts/global/Layout';
import SearchCoupons from '../../../components/form/SearchCoupons';
import { IpManagementInterface } from '../../../interfaces/coupon';
import PrimaryButtonWithIcon from '../../../components/global/ui/buttons/PrimaryButtonWithIcon';
import { routes } from '../../../constants/routes';
import PageTitle from '../../../components/global/PageTitle';
import { useIP } from './useIP';
import AddNewIPs from './AddNewIPs';
import IpList from './IpList';
import { OrganizationContext } from '../../../contexts/OrganizationContext';

export default function IpManagement() {
  const [createIPsOpen, setCreateIPsOpen] = useState<boolean>(false);

  const [ips, setIPs] = useState<IpManagementInterface[] | []>([]);
  const [ipsSearchResults, setIPsearchResults] = useState<IpManagementInterface[] | []>([]);

  const [updatedIps, setUpdatedIps] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { profile } = useContext(OrganizationContext);

  const allWebsites = useMemo(
    () =>
      profile.subscription_details?.subscription
        .map((subscription) => subscription.website)
        .filter(Boolean),
    [profile.subscription_details?.subscription]
  );

  const { editIP, retrieveIpList, couponLoading, deleteIP } = useIP();

  const toggleIPStatus = (data: IpManagementInterface) => {
    setUpdatedIps(null);
    const { id, ipaddress, status, title = '' } = data;

    editIP({
      status,
      title: title || 'No name specified',
      ip: ipaddress,
      value: id
    }).then((res) => {
      if (res.result == 'success') {
        setUpdatedIps(true);
      }
    });
  };

  useEffect(() => {
    setLoading(true);

    retrieveIpList().then((res) => {
      setIPs(res);
      setIPsearchResults(res);
    });
    setLoading(false);
  }, [updatedIps]);

  return (
    <Layout>
      <PageTitle title={'Blocked IP Address'} />
      <div>
        {createIPsOpen && (
          <AddNewIPs
            closeModal={() => setCreateIPsOpen(false)}
            setIPs={setIPs}
            setIPsearchResults={setIPsearchResults}
          />
        )}
        <div className={'flex justify-between gap-5'}>
          <SearchCoupons
            coupons={ips}
            setSearchResults={setIPsearchResults}
            placeholder={'Search Ip'}
          />

          <div className={'hidden lg:flex gap-2'}>
            <div className="flex items-center gap-2">
              <div className="relative inline-block w-full">
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">

                </div>
              </div>
            </div>
            {(
              <PrimaryButtonWithIcon
                handler={() => setCreateIPsOpen(true)}
                title={'Block New IP'}
                icon={<i className=""></i>}
              />
            )}
          </div>
        </div>
        <div className={'my-3 grid gap-2'}>
          {couponLoading ? (
            <div className={'p-5 h-[70vh] flex flex-col justify-center items-center text-center'}>
              <LoadingSpinner color={'text-[#008160]'} />
              <p>Loading IPs</p>
            </div>
          ) : ipsSearchResults && ipsSearchResults.length > 0 ? (
            <table className="w-full mb-8 border-collapse border border-gray-200">
              <thead>
                <tr className="bg-gray-100">
                  <th className="text-left py-3 px-4 align-middle w-1/4 text-[#5C6670]">Name</th>
                  <th className="py-3 px-4 text-left align-middle w-1/4 text-[#5C6670]">IP Address</th>
                  <th className="py-3 px-4 text-left align-middle w-1/4 text-[#5C6670]">Status</th>
                  <th className="py-3 px-4 text-right align-middle w-1/4 text-[#5C6670] pr-10">Action</th>
                </tr>
              </thead>
              <tbody>
                {ipsSearchResults
                  .sort()
                  .map((item, index) => (
                    <IpList
                      key={index}
                      toggle={toggleIPStatus}
                      data={item as IpManagementInterface}
                      deleteIP={deleteIP}
                      setIPs={setIPs}
                      setIPsearchResults={setIPsearchResults}
                    />
                  ))}
              </tbody>
            </table>
          ) : (
            <div className={'h-[70vh] flex flex-col justify-center items-center text-center'}>
              <h4 className={'font-medium mt-5'}>You don’t have any blocked IPs</h4>
              <div className={'mt-3 flex'}>
                <button
                  type={'button'}
                  onClick={() => setCreateIPsOpen(true)}
                  className={
                    'rounded px-4 py-2 flex items-start gap-2 bg-[#008160] hover:bg-emerald-600 text-white font-medium'
                  }>
                  <span className={'text-sm capitalize'}>Block New IP</span>
                </button>
              </div>
            </div>
          )}
        </div>
        <Link
          to={routes.PRICING}
          className={
            'fixed lg:hidden bottom-5 pt-1 right-5 w-14 h-14 shadow-md rounded-full bg-[#008160] hover:bg-emerald-600 flex justify-center items-center text-white'
          }>
          <i className="fi fi-rr-plus text-xl"></i>
        </Link>
      </div>
    </Layout>
  );
}

