import LoadingSpinner from '../../../components/global/LoadingSpinner';
import { useNotifications } from './useNotifications';
import NotificationListCard from '../../../feature/Notifications/NotificationListCard';
import Layout from '../../../layouts/global/Layout';
import PrimaryButtonWithIcon from '../../../components/global/ui/buttons/PrimaryButtonWithIcon';
import PageTitle from '../../../components/global/PageTitle';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export default function Notifications() {
  const { loadUserNotifications, markAllNoticesAsRead, markAllNotificationsReadLoading } =
    useNotifications();

  const queryClient = useQueryClient();

  // Fetching user notifications using React Query
  const {
    data: notifications,
    isLoading: notificationsLoading,
    error
  } = useQuery('user-notifications', loadUserNotifications, {
    select: (data) => data?.msg.notification
  });

  // Mutation for marking all notifications as read
  const markAllNotificationsMutation = useMutation(markAllNoticesAsRead, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries('user-notifications');
    }
  });

  const markAllAsComplete = async () => {
    await markAllNotificationsMutation.mutateAsync();
  };

  return (
    <Layout>
      <div className={'flex justify-between items-center gap-5'}>
        <PageTitle title={'Notifications'} />
        <div className={'flex'}>
          {notifications &&
            notifications.filter((item: any) => item.status !== 'seen').length > 0 && (
              <PrimaryButtonWithIcon
                handler={() => markAllAsComplete()}
                title={'Mark all as read'}
                icon={<i className="fi fi-rr-check text-xs"></i>}
              />
            )}
        </div>
      </div>

      <div className={'my-3 grid gap-2'}>
        {notificationsLoading || markAllNotificationsReadLoading ? (
          <div className={'p-5 h-[80vh] flex flex-col justify-center items-center text-center'}>
            <LoadingSpinner color={'text-[#008160]'} />
            <p>Loading notifications</p>
          </div>
        ) : notifications && notifications.length > 0 ? (
          notifications.map((item: any, index: number) => (
            <NotificationListCard data={item} key={index} />
          ))
        ) : (
          <div className={'flex h-[80vh] flex-col justify-center items-center text-center'}>
            <div className={'flex justify-center mb-2'}>
              <span
                className={
                  'w-16 h-16 rounded-full pt-2 flex justify-center items-center text-gray-400 bg-gray-100'
                }>
                <i className="fi fi-sr-bell-ring text-3xl"></i>
              </span>
            </div>
            <p>No notification</p>
          </div>
        )}
      </div>
    </Layout>
  );
}
