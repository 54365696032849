import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useState } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import SuccessMessage from '../../../components/global/ui/SuccessMessage';
import Error from '../../../components/global/ui/alerts/Error';
import Modal from '../../../components/global/ui/Modal';
import { useCoupon } from '../../../pages/main/Coupons/useCoupon';
import { ICoupon } from '../../../interfaces/coupon';

export default function CreatePromo({
  closeModal,
  coupons
}: {
  closeModal: () => void;
  coupons: ICoupon[];
}) {
  const validationSchema = Yup.object().shape({
    promoCode: Yup.string()
      .max(255, 'Promo code cannot exceed 255 characters')
      .required('Title is required'),
    couponId: Yup.string().required('Coupon is required'),
    expiry: Yup.string().required('Expiry date is required')
  });

  const { createPromo, loading, errorMessage } = useCoupon();

  const [message, setMessage] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      promoCode: '',
      couponId: '',
      expiry: ''
    },
    validationSchema,
    onSubmit: async (values) => handleCreatePromo(values)
  });

  const handleCreatePromo = async (values: FormikValues) => {
    setMessage(null);

    const response = await createPromo({
      promoCode: values.promoCode,
      couponId: values.couponId,
      expiry: values.expiry
    });

    if (response?.result === 'success') {
      setMessage(response.msg);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Create promo'} width={'lg:w-1/4'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Creating a coupon...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}>
              <div>
                <div className={'grid gap-2'}>
                  <div className={''}>
                    <label className={'text-sm'}>
                      Promo Code <span className={'text-red-500'}>*</span>
                    </label>
                    <input
                      type={'text'}
                      name={'promoCode'}
                      value={formik.values.promoCode}
                      onChange={formik.handleChange}
                      autoFocus={true}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1"
                    />
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.promoCode && formik.errors.promoCode}
                    </p>
                  </div>
                  <div>
                    <label className={'text-sm'}>
                      Select Coupon <span className={'text-red-500'}>*</span>
                    </label>
                    <select
                      name={'couponId'}
                      value={formik.values.couponId}
                      onChange={formik.handleChange}
                      className="w-full bg-transparent outline-0 p-2 text-sm rounded border border-gray-300 mt-1">
                      <option>--select--</option>
                      {coupons &&
                        coupons.map((item, index) => (
                          <option value={item.uuid} key={index}>
                            {item.title}
                            {' - '}
                            {item.is_percentage
                              ? `${parseInt(item.amount)}% OFF`
                              : `USD${item.amount}`}
                          </option>
                        ))}
                    </select>
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.couponId && formik.errors.couponId}
                    </p>
                  </div>
                  <div>
                    <label className={'text-sm'}>
                      Expiry <span className={'text-red-500'}>*</span>
                    </label>
                    <input
                      type={'date'}
                      name={'expiry'}
                      value={formik.values.expiry}
                      onChange={formik.handleChange}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1"
                    />
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.expiry && formik.errors.expiry}
                    </p>
                  </div>
                </div>
                <div className={'mt-2'}>
                  <PrimaryButton
                    disabled={loading}
                    title={loading ? 'Creating promo code...' : 'Create Promo Code'}
                    handler={() => formik.handleSubmit()}
                  />
                </div>
                {errorMessage && <Error value={errorMessage} />}
              </div>
            </form>
          )}
        </div>
      )}
    </Modal>
  );
}
