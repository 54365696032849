import Snippet from '../Snippet';
import React from 'react';
import { ITracking } from '../../../interfaces/global';

export default function BigCommerce({ trackingDetails }: { trackingDetails: ITracking }) {
  return (
    <div className={'lg:max-h-[55vh] overflow-auto slim-scroll pr-2'}>
      <div>
        <h6 className={'font-medium text-base mb-2 text-gray-700'}>
          To add the snippet to your BigCommerce website
        </h6>
      </div>
      <div>
        <h6 className={'font-medium text-sm'}>Copy the Header snippet after adding your site</h6>
        <Snippet snippet={trackingDetails.script as string} />
      </div>

      <div className={'mt-4'}>
        <h6 className={'font-medium text-sm'}>Add your conversion snippet (Second Snippet)</h6>
      </div>
      <div>
        <Snippet snippet={trackingDetails.bigcommerce as string} />
      </div>
    </div>
  );
}
