import { useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import Layout from '../../../layouts/global/Layout';
import { useFeedback } from './useFeedback';
import { IFeedback } from '../../../interfaces/support-ticket';
import FeedbackListCard from '../../../feature/Clients/FeedbackListCard';
import SearchFeedback from '../../../components/form/SearchFeedback';
import PageTitle from '../../../components/global/PageTitle';

export default function Feedback() {
  const [feedback, setFeedback] = useState<IFeedback[] | []>([]);
  const [searchResults, setSearchResults] = useState<IFeedback[] | []>([]);

  const { getFeedbacks, loading, errorMessage } = useFeedback();

  const fetchFeedback = async () => {
    const response = await getFeedbacks();

    if (response) {
      setFeedback(response.msg);
      setSearchResults(response.msg);
    }
  };

  useEffect(() => {
    fetchFeedback();
  }, []);

  return (
    <Layout>
      <PageTitle title={'Feedback'} />
      <div>
        <div className={'flex justify-between gap-5'}>
          <SearchFeedback feedback={feedback} setSearchResults={setSearchResults} />
        </div>
        <div className={'my-3 flex flex-col gap-2 h-[70vh] overflow-auto slim-scroll pr-2'}>
          {loading ? (
            <div className={'p-5 h-[70vh] flex flex-col justify-center items-center text-center'}>
              <LoadingSpinner color={'text-[#008160]'} />
              <p>Loading feedback...</p>
            </div>
          ) : searchResults.length > 0 ? (
            searchResults.map((item, index) => <FeedbackListCard data={item} key={index} />)
          ) : feedback.length == 0 ? (
            <div className={'h-[60vh] flex flex-col justify-center items-center text-center'}>
              <h4 className={'font-medium mt-5'}>No feedback found</h4>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  );
}
