import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import { useEffect, useState } from 'react';
import { useSignOut } from 'react-auth-kit';
import { routes } from '../../constants/routes';

export default function Logout() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const signOut = useSignOut();

  useEffect(() => {
    document.title = 'Heatmap Login';
    setLoading(true);
    signOut();
    navigate(routes.LOGIN);
    setLoading(false);
  }, []);
  return (
    <main className={'p-10 flex justify-center items-center h-screen text-center'}>
      <div className={'flex flex-col justify-center items-center'}>
        {loading && <LoadingSpinner color={'text-[#008160]'} />}
      </div>
    </main>
  );
}
