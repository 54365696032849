import { useContext, useState, useEffect } from 'react';
import { IPlan, IPlanInterval, IPackage } from '../../../interfaces/components';
import Layout from '../../../layouts/global/Layout';
import SubscriptionCheckoutForm from '../../../feature/AccountSubscription/Checkout/SubscriptionCheckoutForm';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import { ISubscription } from '../../../interfaces/subscription';
import AddPaymentCard from '../../../feature/AccountSubscription/Payments/AddPaymentCard';
import AddAndPaymentCard from '../../../feature/AccountSubscription/Payments/addSub';
import { ORGANIZATION_ID, SUBSCRIPTION_ID_KEY } from '../../../constants';
import { AGENCY_BOOKING_URL, pro, agency } from '../../../constants';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { AccountHolderSetup } from '../../../feature/AccountProfile/AccountHolderSetup';

// packages interface
interface Package {
  key: number;
  value: number;
  name: string;
  pricing: {
    month: number;
    year: number;
    total:number;
    limit: number;
    annual: number;
    range?: {
      start: number;
      end: number;
    };
  };
}

interface PlanPrices {
  monthly: number;
  yearly: number;
  total:number
}

export const plans = [
  { name: 'Starter', value: 1, monthlyPrice: 89, yearlyPrice: 37 },
  { name: 'Grow', value: 2, monthlyPrice: 129, yearlyPrice: 79 },
  { name: 'Scale', value: 3, monthlyPrice: 249, yearlyPrice: 79 },
  { name: 'Scale Plus', value: 4, monthlyPrice: 449, yearlyPrice: 79 },
  { name: 'Scale Plus 1', value: 5, monthlyPrice: 750, yearlyPrice: 79 },
  { name: 'Scale Plus 2', value: 6, monthlyPrice: 1250, yearlyPrice: 79 },
  { name: 'Scale Plus 3', value: 7, monthlyPrice: 3000, yearlyPrice: 79 }
];

export const packages = [
  // { "key": 0, value: 0, "name": "Pro", "pricing": { "month": 47, "year": 37, "limit": 50000 } },
  // { "key": 2, value: 2, "name": "Grow", "pricing": { "month": 129, "year": 129, "limit": 250000 } },
  // { "key": 3, value: 3, "name": "Pro", "pricing": { "month": 249, "year": 249, "limit": 500000 } },
  // { "key": 4, value: 4, "name": "Pro", "pricing": { "month": 449, "year": 5388, "limit": 1000000 } },
  // { "key": 5, value: 5, "name": "Pro", "pricing": { "month": 750, "year": 9000, "limit": 2000000 } },
  // { "key": 6, value: 6, "name": "Pro", "pricing": { "month": 1250, "year": 15000, "limit": 5000000 } },
  // { "key": 7, value: 7, "name": "Pro", "pricing": { "month": 3000, "year": 36000, "limit": 10000000 } },
  // { "key": 8, value: 8, "name": "Pro", "pricing": { "month": 1500, "year": 18000, "limit": 100000000 } },
  // { "key": 1, value: 1, "name": "Pro", "pricing": { "month": 89, "year": 69, "limit": 50000 } },
  // { "key": 9, value: 9, "name": "Freemium", "pricing": { "month": 0, "year": 0,  "annual": 0, "limit": 20000, "range": { "start": 0, "end": 1000000 } } },
  { "key": 1, value: 1, "name": "Pro", "pricing": { "month": 89, "year": 649, "total": 69, "annual": 649, "limit": 999999, "range": { "start": 100000, "end": 999999 } } },
  { "key": 10, value: 10, "name": "Pro", "pricing": { "month": 119, "year": 999, "total": 99, "annual": 999, "limit": 2500000, "range": { "start": 1000000, "end": 2500000 } } },
  { "key": 11, value: 11, "name": "Pro", "pricing": { "month": 159, "year": 1299, "total": 129, "annual": 1299, "limit": 5000000, "range": { "start": 2500000, "end": 5000000 } } },
  { "key": 12, value: 12, "name": "Pro", "pricing": { "month": 219, "year": 1699, "total": 169, "annual": 1699, "limit": 7500000, "range": { "start": 5000000, "end": 7500000 } } },
  { "key": 13, value: 13, "name": "Pro", "pricing": { "month": 299, "year": 2399, "total": 239, "annual": 2399, "limit": 10000000, "range": { "start": 7500000, "end": 10000000 } } },
  { "key": 14, value: 14, "name": "Pro", "pricing": { "month": 449, "year": 3599, "total": 359, "annual": 3599, "limit": 15000000, "range": { "start": 10000000, "end": 15000000 } } },
  { "key": 15, value: 15, "name": "Pro", "pricing": { "month": 699, "year": 5499, "total": 549, "annual": 5499, "limit": 25000000, "range": { "start": 15000000, "end": 25000000 } } },
  { "key": 16, value: 16, "name": "Pro", "pricing": { "month": 999, "year": 7999, "total": 799, "annual": 7999, "limit": 30000000, "range": { "start": 25000000, "end": 30000000 } } },
  { "key": 17, value: 17, "name": "Pro", "pricing": { "month": 1399, "year": 11199, "total": 1119, "annual": 11199, "limit": 40000000, "range": { "start": 30000000, "end": 40000000 } } },
  { "key": 18, value: 18, "name": "Pro", "pricing": { "month": 1749, "year": 13999, "total": 1399, "annual": 13999, "limit": 50000000, "range": { "start": 40000000, "end": 50000000 } } },
  { "key": 19, value: 19, "name": "Pro", "pricing": { "month": 1999, "year": 15999, "total": 1599, "annual": 15999, "limit": 60000000, "range": { "start": 50000000, "end": 60000000 } } },
  { "key": 20, value: 20, "name": "Pro", "pricing": { "month": 2499, "year": 19999, "total": 1999, "annual": 19999, "limit": 75000000, "range": { "start": 60000000, "end": 75000000 } } },
  { "key": 21, value: 21, "name": "Pro", "pricing": { "month": 2999, "year": 23999, "total": 2399,"annual": 23999, "limit": 100000000, "range": { "start": 75000000, "end": 100000000 } } }
];

export const intervals = [
  { name: 'Monthly', value: 'month' },
  { name: 'Yearly', value: 'year' }
];

export interface IPackageInterval {
  name: string;
  value: string;
}

export default function Pricing() {
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
  const [intervalSelected, setIntervalSelected] = useState<IPackageInterval>(intervals[0]);
  const [isNewCardAdded, setIsNewCardAdded] = useState(false);
  const [selectedPlanKey, setSelectedPlanKey] = useState<number | null>(null);


const { fetchOrganizationData, loading: subscriptionLoading } = useContext(OrganizationContext);

  const { profile, loading, accountHolderSetupOpen, setAccountHolderSetupOpen } =
    useContext(OrganizationContext);
  const [addCardOpen, setAddCardOpen] = useState<boolean>(false);
  const [revenue, setRevenue] = useState(0);
  const [isManuallySelected, setIsManuallySelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hideModal = () => setIsOpen(false); useState(false);

  // pro plan state
  const [proPlanPrice, setProPlanPrice] = useState<PlanPrices>({ monthly: 89, yearly: 649, total : 69 });
  const [selectedPlan, setSelectedPlan] = useState<string>('');
  const [isYearly, setIsYearly] = useState(false);
  const [currentPackage, setCurrentPackage] = useState<IPackage | null>(null);

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const [formattedRevenue, setFormattedRevenue] = useState(formatCurrency(100000));

  const handleToggle = () => {
    const newIsYearly = !isYearly;
    setIsYearly(newIsYearly);
    const newInterval = newIsYearly ? intervals[1] : intervals[0];
    setIntervalSelected(newInterval);
    localStorage.setItem('selectedInterval', newInterval.value);
  };

  useEffect(() => {
    setIntervalSelected(isYearly ? intervals[1] : intervals[0]);
  }, [isYearly]);

  useEffect(() => {
    const storedInterval = localStorage.getItem('selectedInterval');
    if (storedInterval) {
      setIntervalSelected(intervals.find(interval => interval.value === storedInterval) || intervals[0]);
      setIsYearly(storedInterval === 'year');
    }
  }, []);

  // Function to handle changes in the input field
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    const newRevenue = value === '' ? 0 : parseInt(value);

    setRevenue(newRevenue);
    setFormattedRevenue(formatCurrency(newRevenue));

    const newPackage = getPackageByRevenue(newRevenue);
    if (newPackage) {
      if (newPackage.key === 9) {
        setSelectedPlan('freemium');
      } else if (newPackage.key >= 10 || newPackage.key == 1) {
        setSelectedPlan('pro');
      }
      setCurrentPackage(newPackage);
      setSelectedPackage(newPackage);
      updateProPlanPrice(newRevenue);
    }
  };

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRevenue = parseInt(e.target.value);
    setRevenue(newRevenue);
    setFormattedRevenue(formatCurrency(newRevenue));
  
    const newPackage = getPackageByRevenue(newRevenue);
    if (newPackage) {
      setCurrentPackage(newPackage);
      setSelectedPackage(newPackage);
  
      if (newPackage.key !== selectedPlanKey) {
        setSelectedPlanKey(newPackage.key);
        localStorage.setItem('plan', newPackage.key.toString());
      }
  
      // Update pro plan price
      setProPlanPrice({
        monthly: newPackage.pricing.month,
        yearly: newPackage.pricing.annual,
        total: newPackage.pricing.total,
      });
  
      // Update selected plan
      setSelectedPlan(newPackage.name.toLowerCase().includes('pro') ? 'pro' : 'freemium');
    }
  
    updateProPlanPrice(newRevenue);
    setIsManuallySelected(false);
  };

  const updateProPlanPrice = (newRevenue: number) => {
    const newPackage = getPackageByRevenue(newRevenue);
    const minPrice = getMinProPlanPrice();
    if (newPackage) {
      setProPlanPrice({
        monthly: Math.max(newPackage.pricing.month, minPrice.monthly),
        yearly: Math.max(newPackage.pricing.annual, minPrice.yearly),
        total: Math.max(newPackage.pricing.total, minPrice.total)
      });
    } else {
      setProPlanPrice(minPrice);
    }
  };

  const getPackageByRevenue = (revenue: number): Package | null => {
    return packages.find(pkg => {
      if (pkg.pricing.range) {
        return revenue >= pkg.pricing.range.start && revenue <= pkg.pricing.range.end;
      }
      return false;
    }) || null;
  };

  const handlePlanSelection = (plan: string) => {
    let adjustedRevenue = revenue;
    if (plan === 'pro') {
      adjustedRevenue = Math.max(revenue, 100000);
    }

    setRevenue(adjustedRevenue);

    const selectedPackage = getPackageByRevenue(adjustedRevenue);
    if (selectedPackage) {
      setSelectedPackage(selectedPackage);
      setCurrentPackage(selectedPackage);
      setSelectedPlan(plan);
      if (plan === 'pro') {
        setProPlanPrice(getMinProPlanPrice());
      } else {
        updateProPlanPrice(adjustedRevenue);
      }
    }
    setIsManuallySelected(true);
    setIsOpen(true);
  };

  const handleAddCard = () => {
    if (profile.profile_details?.account_holder == 0 && profile.profile_details?.permission &&
      !['admin', 'write'].includes(profile.profile_details.permission)) {
      setAccountHolderSetupOpen(true);
    } else {
      setAddCardOpen(true);
      setIsNewCardAdded(true);
    }
  };

  const getMinProPlanPrice = () => {
    return { monthly: 89, yearly: 649, total: 69 };
  };

  useEffect(() => {
    const storedPlanKey = localStorage.getItem('selectedPlanKey');
    if (storedPlanKey) {
      setSelectedPlanKey(parseInt(storedPlanKey));
    }
  }, []);


  useEffect(() => {
    const storedPlanKey = localStorage.getItem('plan');
    if (storedPlanKey) {
      const planKey = parseInt(storedPlanKey);
      const selectedPackage = packages.find(pkg => pkg.key === planKey);
      if (selectedPackage) {
        // console.log("Found package:", selectedPackage);
      }
    }
  
    // Check for period
    const storedInterval = localStorage.getItem('period');
    if (storedInterval) {
      const selectedInterval = intervals.find(interval => interval.value === storedInterval);
      if (selectedInterval) {
        // console.log("Found interval:", selectedInterval);
      }
    }
  }, []);

  useEffect(() => {
    const storedPlanKey = localStorage.getItem('plan');
    const storedInterval = localStorage.getItem('period');
  
    if (storedPlanKey) {
      const planKey = parseInt(storedPlanKey);
      const storedPackage = packages.find(pkg => pkg.key === planKey);
      
      if (storedPackage) {
        setSelectedPackage(storedPackage);
        setCurrentPackage(storedPackage);
        setProPlanPrice({
          monthly: storedPackage.pricing.month,
          yearly: storedPackage.pricing.annual,
          total: storedPackage.pricing.total,
        });
  
        // Set the revenue and range based on the stored package
        const initialRevenue = storedPackage.pricing.range?.start || 100000;
        setRevenue(initialRevenue);
        setFormattedRevenue(formatCurrency(initialRevenue));
      }
    }
  
    if (storedInterval) {
      const interval = intervals.find(interval => interval.value === storedInterval);
      if (interval) {
        setIntervalSelected(interval);
        setIsYearly(storedInterval === 'year');
      }
    }
  }, []);

  useEffect(() => {
    const storedInterval = localStorage.getItem('selectedInterval');
    if (storedInterval) {
      const interval = intervals.find(interval => interval.value === storedInterval);
      if (interval) {
        setIntervalSelected(interval);
        setIsYearly(storedInterval === 'year');
      }
    } else {
      // If no stored interval, set it to 'month' and save to localStorage
      setIntervalSelected(intervals[0]);
      setIsYearly(false);
      localStorage.setItem('selectedInterval', 'month');
    }
  }, []);

  return (
    <Layout>
      <>
        {addCardOpen && profile.subscription_details && (
           <AddAndPaymentCard
           customerId={profile.subscription_details.stripe_customer_id}
           closeModal={() => setAddCardOpen(false)}
           onCardAdded={() => {
             setIsNewCardAdded(true);
             fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
           }}
           plan={selectedPackage as IPackage}
           interval={intervalSelected}
           subscription={profile.subscription_details}
           isNewCardAdded={isNewCardAdded}
           onCancel={() => setAddCardOpen(false)}
         />
        )}
        {accountHolderSetupOpen && (
          <AccountHolderSetup closeModal={() => setAccountHolderSetupOpen(false)} />
        )}
        <main className={'flex flex-col justify-center'}>
          <div>

            {/* new plan packages start */}
            <div className="mt-[3vh]">
              <h2 className="text-gray-900 text-center text-3xl font-bold leading-tight mb-2">Purchase new subscription</h2>
              <h6 className="text-gray-900 text-center text-lg font-normal leading-6 text-[15px]">Choose a plan for your website needs</h6>

              <div className="flex items-center justify-center mt-16">
                <p className="text-gray-900 text-center text-lg font-medium leading-6">What’s your annual revenue?: </p>

                <div className="flex w-[200px] ml-8">
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 rounded-l-lg border-gray-300 border-e-0 rounded-s-md"> $ </span>
                  <input
                    type="text"
                    id="website-admin"
                    className="rounded-none rounded-e-lg rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-emerald-500 focus:border-emerald-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 text-end "
                    value={revenue.toLocaleString()}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="flex items-center justify-center mt-2 mb-16">
                <span className="mr-3 text-gray-900"> 100K </span>
                <input
                  type="range"
                  min={100000}
                  max={100000000}
                  step="10000"
                  value={revenue}
                  onChange={handleRangeChange}
                  className="w-[700px] bg-gray-200 h-4 rounded-full outline-none appearance-none transition-colors duration-300 ease-in-out"
                  style={{
                    background: `linear-gradient(to right, #08916F 0%, #08916F ${(revenue - 100000) / (100000000 - 100000) * 100}%, #D1D5DB ${(revenue - 100000) / (100000000 - 100000) * 100}%, #D1D5DB 100%)`
                  }}
                />

                <span className="ml-4 text-gray-900">$100m+</span>
              </div>
              <div className='text-center'>
                <div className='flex justify-center'>
                  <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isYearly}
                    onChange={handleToggle}
                  />
                    <span className={`ms-3 text-sm font-medium ${isYearly ? 'text-gray-500' : 'text-gray-900'} mr-3`}>Month-to-Month</span>
                    <div className="relative w-11 h-[1.6rem] bg-white border border-[#08916F] rounded-full peer">
                      <div
                        className={`absolute top-[2px] ${isYearly ? 'right-[2px]' : 'left-[2px]'} bg-[#08916F] rounded-full h-5 w-5 transition-all duration-300 ease-in-out`}
                      ></div>
                    </div>
                    <span className={`ms-3 text-sm font-medium ${isYearly ? 'text-gray-900' : 'text-gray-500'} ml-3`}>12-Month Agreement</span>
                  </label>
                </div>
                <small className='text-[11px] text-[#08916F] font-medium'>Save 20% with 12-months agreement</small>
              </div>

              <div>
                <div className='mt-10'>
                  <div className="flex justify-center items-end container xl:px-[6rem] mx-auto">
                    {/* <div className="w-1/3 p-4 border mr-3 h-[auto] xl:h-[595px] rounded-lg">
                      <div className='flex justify-between'>
                        <h2 className="text-xl font-bold">Freemium</h2>
                        <div>
                          <span className='text-[30px] text-[#00c291] font-bold  text-end flex justify-end'>
                            {formatCurrency(0)}
                          </span>
                          <p className='text-end text-[12px]'>
                            {isYearly ? '/per month' : '/per month'}
                          </p>
                        </div>
                      </div>

                      {profile.subscription_details?.card.length === 0 ? (
                        // User doesn't have a card, show "Add payment card" button

                        <button
                          onClick={handleAddCard}
                          className={`border-[#008160] border hover:bg-[#008160] text-[#008160] hover:text-white py-3 w-full mt-5 rounded-lg text-sm font-medium ${isManuallySelected && selectedPlan === 'freemium' && currentPackage?.name === 'Freemium'
                              ? 'bg-[#008160] text-white border-[#008160]'
                              : 'bg-transparent text-[#008160] border-[#008160]'
                            }`}
                        >
                          {isManuallySelected && selectedPlan === 'freemium' && currentPackage?.name === 'Freemium' ? 'Selected Plan' : 'Select Plan'}
                        </button>
                      ) : (
                        // User has a card, show "Select Plan" button
                        <button
                          onClick={() => handlePlanSelection('freemium')}
                          className={`border-[#008160] border hover:bg-[#008160] text-[#008160] hover:text-white py-3 w-full mt-5 rounded-lg text-sm font-medium ${isManuallySelected && selectedPlan === 'freemium' && currentPackage?.name === 'Freemium'
                              ? 'bg-[#008160] text-white border-[#008160]'
                              : 'bg-transparent text-[#008160] border-[#008160]'
                            }`}
                        >
                          {isManuallySelected && selectedPlan === 'freemium' && currentPackage?.name === 'Freemium' ? 'Selected Plan' : 'Select Plan'}
                        </button>
                      )}
                      <div className='mt-8'>
                        <h6 className='text-[14px] mb-4 font-semibold'>What’s included:</h6>

                        <li className=" flex items-center mb-2.5">
                          <span className=" top-0 left-0 pr-3">
                            <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                            </svg>
                          </span>
                          <small>Revenue-based heatmap & scrollmap for <span className='font-bold'>one page</span></small>
                        </li>
                        <li className=" flex items-center mb-2.5">
                          <span className=" top-0 left-0 pr-3">
                            <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                            </svg>
                          </span>
                          <small>15 standard filters</small>
                        </li>
                        <li className=" flex items-center mb-2.5">
                          <span className=" top-0 left-0 pr-3">
                            <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                            </svg>
                          </span>
                          <small>60 day data storage</small>
                        </li>
                        <li className=" flex items-center mb-2.5">
                          <span className=" top-0 left-0 pr-3">
                            <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                            </svg>
                          </span>
                          <small>Custom filters</small>
                        </li>
                        <li className=" flex items-center mb-2.5">
                          <span className=" top-0 left-0 pr-3">
                            <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                            </svg>
                          </span>
                          <small>Interactive mode</small>
                        </li>
                        <li className=" flex items-center mb-2.5">
                          <span className=" top-0 left-0 pr-3">
                            <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                            </svg>
                          </span>
                          <small>Comparison mode</small>
                        </li>
                        <li className=" flex items-center mb-2.5">
                          <span className=" top-0 left-0 pr-3">
                            <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                            </svg>
                          </span>
                          <small>Site speed tracking</small>
                        </li>
                        <li className=" flex items-center mb-2.5">
                          <span className=" top-0 left-0 pr-3">
                            <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                            </svg>
                          </span>
                          <small>Email support</small>
                        </li>
                        <li className=" flex items-center mb-2.5">
                          <span className=" top-0 left-0 pr-3">
                            <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                            </svg>
                          </span>
                          <small className='font-bold'>Works with any CMS</small>
                        </li>
                      </div>
                    </div> */}

                    <div className='lg:w-[40%]'>
                      <div className='bg-[#DAFBED] mr-3 rounded-t-lg text-center text-[11px] font-bold text-[#53C768] py-3'>🔥 MOST POPULAR</div>
                      <div className="p-4 border border-[#30E8AB] h-[595px] mr-3 rounded-b-lg">
                        <div className='flex justify-between'>
                          <h2 className="text-xl font-bold">Pro</h2>
                          <div>
                            <div className='flex items-center'>

                            <p className='mr-2'>
                          {isYearly && (
                              <span className='text-[12px] text-gray-500 line-through ml-2'>
                                {formatCurrency(proPlanPrice.total * 12)}
                              </span>
                            )}
                            </p>
                            <span className='text-[30px] text-[#00c291] font-bold text-right'>
                              {formatCurrency(
                                Math.max(
                                  isYearly ? proPlanPrice.yearly : proPlanPrice.monthly,
                                  isYearly ? getMinProPlanPrice().yearly : getMinProPlanPrice().monthly
                                )
                              )}
                            </span>
                            </div>
                            <p className='text-end text-[12px]'>
                              {isYearly ? '/per year' : '/per month'}
                            </p>
                          </div>
                        </div>
                        {profile.subscription_details?.card.length === 0 ? (
                          <button
                            onClick={handleAddCard}
                            className={`border-[#008160] border hover:bg-[#008160] text-[#008160] hover:text-white py-3 w-full mt-5 rounded-lg text-sm font-medium ${isManuallySelected && selectedPlan === 'pro' && currentPackage?.name.startsWith('Pro Package')
                                ? 'bg-[#008160] text-white border-[#008160]'
                                : 'bg-transparent text-[#008160] border-[#008160]'
                              }`}
                          >
                            {isManuallySelected && selectedPlan === 'pro' && currentPackage?.name.startsWith('Pro Package') ? 'Selected Plan' : 'Select Plan'}
                          </button>
                        ) : (
                          <button
                            onClick={() => handlePlanSelection('pro')}
                            className={`border-[#008160] border hover:bg-[#008160] text-[#008160] hover:text-white py-3 w-full mt-5 rounded-lg text-sm font-medium ${isManuallySelected && selectedPlan === 'pro' && currentPackage?.name.startsWith('Pro Package')
                                ? 'bg-[#008160] text-white border-[#008160]'
                                : 'bg-transparent text-[#008160] border-[#008160]'
                              }`}
                          >
                            {isManuallySelected && selectedPlan === 'pro' && currentPackage?.name.startsWith('Pro Package') ? 'Selected Plan' : 'Select Plan'}
                          </button>
                        )}
                        <div className='mt-8'>
                          <h6 className='text-[14px] mb-4 font-semibold'>What&lsquo;s included:</h6>
                          {pro.map((item, index) => (
                            <li key={index} className="flex items-center mb-4">
                              <span className="top-0 left-0 pr-3">
                                <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
                                  <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                                </svg>
                              </span>
                              <small>{item}</small>
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>


                    <div className="lg:w-[40%] p-4 border h-[auto] xl:h-[593px] rounded-lg">
                      <div className='flex justify-between mb-12 '>
                        <h2 className="text-xl font-bold">Enterprise</h2>
                        <div>

                          <p className='text-[20px] text-[#00c291] font-bold  text-end p-0 m-0'>Book a call</p>
                        </div>
                      </div>
                      <a href={AGENCY_BOOKING_URL} target='blank' className='border-[#008160] border hover:bg-[#008160] text-[#008160] hover:text-white py-3 w-full block text-center cursor-pointer mt-5 rounded-lg text-sm font-medium'>Book a call</a>

                      <div className='mt-8'>
                        <h6 className='text-[14px] mb-4 font-semibold'>What’s included:</h6>

                        {agency.map((item, index) => (
                            <li key={index} className="flex items-center mb-4">
                              <span className="top-0 left-0 pr-3">
                                <svg className="w-3 h-3 text-[#008160]" fill="currentColor" viewBox="0 0 20 20">
                                  <path fillRule="evenodd" d="M18.293 4.293a1 1 0 0 1 1.414 1.414l-12 12a1 1 0 0 1-1.414 0l-7-7a1 1 0 1 1 1.414-1.414L7 14.086l10.293-10.293a1 1 0 0 1 1.414 0z" clipRule="evenodd" />
                                </svg>
                              </span>
                              <small>{item}</small>
                            </li>
                          ))}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <style>{`
    input[type=range] {
      -webkit-appearance: none;
      appearance: none;
      height: 4px;
      background: transparent;
      cursor: pointer;
    }

    
    input[type=range]::-moz-range-thumb {
      background-color: #08916F;
      border: none;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      cursor: pointer;
    }

   
    input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: #08916F;
      cursor: pointer;
    }

  
    input[type=range]::-ms-thumb {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: #08916F;
      cursor: pointer;
    }
    `}</style>

            {/* new plan packages end */}
            <div className={' gap-10 container xl:px-[6rem] mx-auto'}>

              <div className={'lg:col-span-2'}>
                <div className={'mt-3'}>
                  {loading ? (
                    <div className={'h-[40vh] flex flex-col justify-center items-center p-5'}>
                      <LoadingSpinner color={'text-[#008160]'} />
                    </div>
                  ) : profile.subscription_details && (
                    <>

                      <div>
                        {isOpen && (
                          <div className="fixed inset-0 z-50 overflow-hidden bg-gray-800 bg-opacity-50 flex flex-col items-center justify-center">

                            <div className=' border-gray-200 border-b-2 bg-white w-full lg:w-[700px] rounded-md border py-8'>
                              <div className='flex justify-between items-center px-4 mb-4'>
                                <h2 className="text-lg font-bold">Subscription Checkout</h2>
                                <i className="fi fi-ss-cross text-xs hover:cursor-pointer" onClick={hideModal}></i>
                              </div>

                              <SubscriptionCheckoutForm
                                plan={selectedPackage as IPackage}
                                interval={intervalSelected as IPlanInterval}
                                subscription={profile.subscription_details as ISubscription}
                                isNewCardAdded={isNewCardAdded}
                                onCancel={hideModal}
                              />
                            </div>

                          </div>
                        )}
                      </div>
                      <div className={'flex justify-center mt-6'}>
                        <img src={'/assets/stripe.png'} alt={''} className={'w-1/2 md:w-1/3'} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
        {/*)}*/}
      </>
    </Layout>
  );
}
