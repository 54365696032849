import { useContext, useState } from 'react';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { usePayment } from '../../pages/main/Pricing/usePayment';
import { ORGANIZATION_ID } from '../../constants';
import Modal from '../../components/global/ui/Modal';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import WarningPrompt from '../../components/global/ui/alerts/WarningPrompt';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import Error from '../../components/global/ui/alerts/Error';
import { useSetupTwoFA } from '../../pages/main/AccountProfile/useSetupTwoFA';

export default function DisableTwoFA({
  closeModal,
  userId
}: {
  closeModal: () => void;
  userId?: number;
}) {
  const { disable2FA, disableClient2FA, loading, errorMessage } = useSetupTwoFA();
  const [message, setMessage] = useState<string | null>(null);
  const { fetchOrganizationData } = useContext(OrganizationContext);

  const handleDeactivateTwoFA = async () => {
    setMessage(null);
    let response;

    if (userId) {
      response = await disableClient2FA({ user_id: userId });
    } else {
      response = await disable2FA();
    }

    if (response?.result === 'success') {
      setMessage(response.msg);
      // refetch organization data
      fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);

      setTimeout(() => {
        closeModal();
      }, 3000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Disable 2FA'} width={'lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Disabling Two Factor authentication...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <div>
              <div>
                <WarningPrompt>
                  <>
                    <p className={'text-sm'}>
                      You are disabling Two Factor authentication on this account on Heatmap.com
                    </p>
                    <p className={'text-sm'}></p>
                  </>
                </WarningPrompt>
              </div>
              <div className={'grid gap-2'}>
                <div className={'mt-2 flex flex-col-reverse lg:flex-row justify-end gap-2'}>
                  <button
                    type={'button'}
                    disabled={loading}
                    onClick={closeModal}
                    className={`px-4 py-2 rounded text-sm text-gray-700 bg-gray-200 hover:bg-gray-300`}>
                    Cancel
                  </button>
                  <button
                    type={'button'}
                    onClick={handleDeactivateTwoFA}
                    disabled={loading}
                    className={`rounded hover:bg-red-600 font-medium px-4 py-2 text-sm bg-red-500 text-white`}>
                    {loading ? 'disabling...' : 'Yes, disable'}
                  </button>
                </div>
              </div>
            </div>
          )}
          {errorMessage && <Error value={errorMessage} />}
        </div>
      )}
    </Modal>
  );
}
