import { CancellationChartType, IAnalytic } from '../../../interfaces/analytic';
import { useEffect, useState } from 'react';
import { allMonths, allWeekDays, cancellationTypes, periods } from '../../../constants';
import CancellationReasonsChart from './Charts/CancellationReasonsChart';
import CancellationPlatformsChart from './Charts/CancellationPlatformsChart';
import CancellationPackagesChart from './Charts/CancellationPackagesChart';
import SubscriptionCancellationReasons from '../Subscription/SubscriptionCancellationReasons';

export default function CancellatationSummary({ data }: { data: IAnalytic }) {
  const thisMonth = new Date().toLocaleString('default', { month: 'long' });
  const today = new Date().toLocaleString('default', { weekday: 'long' });

  const [cancelledOtherReasonsOpen, setCancelledOtherReasonsOpen] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(2);
  const [cancellationTypeTab, setCancellationTypeTab] = useState<number>(1);
  const [filterPeriod, setFilterPeriod] = useState<string>(today);
  const [annualCancellations, setAnnualCancellations] = useState([]);
  const [weeklyCancellations, setWeeklyCancellations] = useState([]);

  useEffect(() => {
    if (tab === 2) {
      setFilterPeriod(today);
    } else if (tab === 3) {
      setFilterPeriod(thisMonth);
    }
  }, [tab]);

  useEffect(() => {
    if (data.chart && allMonths.includes(filterPeriod)) {
      setAnnualCancellations(data.chart.annual.cancelled_reason[filterPeriod]);
    } else if (data.chart && allWeekDays.includes(filterPeriod)) {
      setWeeklyCancellations(data.chart.weekly.cancelled_reason[filterPeriod]);
    } else {
      console.log('filterPeriod is not in months or week array');
    }
  }, [filterPeriod]);

  return (
    <div className={'flex flex-col gap-5'}>
      {cancelledOtherReasonsOpen && data.chart && (
        <SubscriptionCancellationReasons
          data={data.chart}
          closeModal={() => setCancelledOtherReasonsOpen(false)}
        />
      )}
      <div
        className={
          'lg:col-span-1 rounded-md border border-gray-200 p-3 md:p-5 bg-white text-gray-500'
        }>
        <div className={'flex gap-2 justify-between items-start'}>
          <span className={'text-sm'}>Subscription Cancellation Analytics</span>
          <i className="fi fi-sr-box-open text-xl lg:text-xl text-gray-400"></i>
        </div>
        <div className={'grid grid-cols-3 gap-2'}>
          <div className={'flex flex-col justify-between'}>
            <span className={'text-sm'}></span>
            <div className={'mt-2 flex flex-col justify-center items-center text-center'}>
              <span className={'text-2xl lg:text-3xl font-medium'}>
                {data && data.subscriptions.statuses.attempted_cancellation}
              </span>
              <span className={'text-xs lg:text-sm'}>Attempted Cancellations</span>
            </div>
          </div>
          <div className={'flex flex-col justify-between'}>
            <span className={'text-sm'}></span>
            <div className={'mt-2 flex flex-col justify-center items-center text-center'}>
              <span className={'text-2xl lg:text-3xl font-medium'}>
                {data && data.subscriptions.statuses.resolved_cancellation}
              </span>
              <span className={'text-xs lg:text-sm'}>Recovered Cancellations</span>
            </div>
          </div>
          <div className={'flex flex-col justify-between'}>
            <span className={'text-sm'}></span>
            <div className={'mt-2 flex flex-col justify-center items-center text-center'}>
              <span className={'text-2xl lg:text-3xl font-medium'}>
                {data && data.subscriptions.statuses.cancelled}
              </span>
              <span className={'text-xs lg:text-sm'}>Lost Cancellations</span>
            </div>
          </div>
        </div>
      </div>
      <div className={'flex items-end gap-10 mb-5'}>
        <div className={'flex gap-4'}>
          {periods &&
            periods.map((item, index) => (
              <button
                type={'button'}
                onClick={() => setTab(item.id)}
                key={index}
                className={`${
                  tab === item.id
                    ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                    : 'border-transparent'
                } py-2 border-b-2 hover:border-gray-300 font-medium text-sm flex gap-3`}>
                <span className={'text-sm'}>{item.name}</span>
              </button>
            ))}
        </div>
        <div>
          <label className={'text-sm'}>Select Period</label>
          <select
            className={`px-2 py-2 w-full rounded border text-sm border-gray-300 capitalize`}
            name={'platform'}
            value={filterPeriod}
            onChange={(event) => {
              setFilterPeriod(event.target.value);
            }}>
            {tab === 2
              ? data.chart &&
                Object.keys(data.chart.weekly.cancelled_subscriptions).map((item, index) => (
                  <option key={index}>{item}</option>
                ))
              : data.chart &&
                Object.keys(data.chart.annual.cancelled_subscriptions).map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
          </select>
        </div>
      </div>
      <div>
        {tab == 3 ? (
          <div className={''}>
            <div className={'rounded-md border border-gray-200 p-3 md:p-5'}>
              {data && data.chart && annualCancellations.length !== 0 ? (
                <div>
                  <CancellationReasonsChart period={filterPeriod} data={annualCancellations} />
                  <div className={'flex justify-end'}>
                    <button
                      type={'button'}
                      onClick={() => setCancelledOtherReasonsOpen(!cancelledOtherReasonsOpen)}
                      className={`rounded px-3 py-2 flex items-center gap-2 border border-[#008160] ${
                        cancelledOtherReasonsOpen ? 'bg-emerald-100' : ''
                      } hover:bg-emerald-100 text-[#008160]`}>
                      <span>
                        <i className="fi fi-rr-comment-dots text-xs"></i>
                      </span>
                      <span className={'text-xs'}>See all other reasons</span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className={'flex justify-center items-center'}>
                  <p>No cancellation reason record found</p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={''}>
            <div className={'rounded-md border border-gray-200 p-3 md:p-5'}>
              {data && data.chart && weeklyCancellations.length !== 0 ? (
                <div>
                  <CancellationReasonsChart period={filterPeriod} data={weeklyCancellations} />
                  <div className={'flex justify-end'}>
                    <button
                      type={'button'}
                      onClick={() => setCancelledOtherReasonsOpen(!cancelledOtherReasonsOpen)}
                      className={`rounded px-3 py-2 flex items-center gap-2 border border-[#008160] ${
                        cancelledOtherReasonsOpen ? 'bg-emerald-100' : ''
                      } hover:bg-emerald-100 text-[#008160]`}>
                      <span>
                        <i className="fi fi-rr-comment-dots text-xs"></i>
                      </span>
                      <span className={'text-xs'}>See all other reasons</span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className={'flex justify-center items-center'}>
                  <p>No cancellation reason record found</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div>
        <div className={'flex gap-4'}>
          {cancellationTypes &&
            cancellationTypes.map((item, index) => (
              <button
                type={'button'}
                onClick={() => setCancellationTypeTab(item.id)}
                key={index}
                className={`${
                  cancellationTypeTab === item.id
                    ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                    : 'border-transparent'
                } py-2 border-b-2 hover:border-gray-300 font-medium text-sm flex gap-3`}>
                <span className={'text-sm'}>{item.name}</span>
              </button>
            ))}
        </div>
        <div className={'grid lg:grid-cols-2 gap-5 mt-5'}>
          <div className={''}>
            {data && data.subscriptions && data.subscriptions.platforms && (
              <div className={'rounded-md border border-gray-200 p-3 md:p-5'}>
                {cancellationTypeTab === 1 ? (
                  <CancellationPlatformsChart
                    data={data.subscriptions.platforms}
                    type={CancellationChartType.attempted}
                  />
                ) : cancellationTypeTab === 2 ? (
                  <CancellationPlatformsChart
                    data={data.subscriptions.platforms}
                    type={CancellationChartType.recovered}
                  />
                ) : cancellationTypeTab === 3 ? (
                  <CancellationPlatformsChart
                    data={data.subscriptions.platforms}
                    type={CancellationChartType.cancelled}
                  />
                ) : null}
              </div>
            )}
          </div>
          <div className={''}>
            {data && data.subscriptions && (
              <div className={'rounded-md border border-gray-200 p-3 md:p-5'}>
                {cancellationTypeTab === 1 ? (
                  <CancellationPackagesChart
                    data={data.subscriptions}
                    type={CancellationChartType.attempted}
                  />
                ) : cancellationTypeTab === 2 ? (
                  <CancellationPackagesChart
                    data={data.subscriptions}
                    type={CancellationChartType.recovered}
                  />
                ) : cancellationTypeTab === 3 ? (
                  <CancellationPackagesChart
                    data={data.subscriptions}
                    type={CancellationChartType.cancelled}
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
