import logo from '../../assets/logo.svg';
export default function RightSectionPlaceholder() {
  return (
    <section className="right-side-container py-10 px-20 hidden lg:flex flex-col justify-center">
      <div className="relative">
        <img className="mb-4" src={logo} alt="" />
        <p className="font-bold text-[42px] leading-tight text-white mb-4">
        The only heatmap that tells  you how to make more  money
        </p>
        <p className=" text-white mb-8">
        See revenue insights for every pixel on every page and optimize your e-commerce site based on buyer behavior
        </p>
        <div className="insight-container">
          <div className="flex items-center relative">
            <img className="image-rounded-full " src="/assets/Photo.jpg" alt="" />
            <img
              className="image-rounded-full absolute"
              src="/assets/Photo-1.jpg"
              alt=""
              style={{ left: '30px' }}
            />
            <img
              className="image-rounded-full absolute"
              src="/assets/Photo-2.jpg"
              alt=""
              style={{ left: '60px', zIndex: 5 }}
            />
            <img
              className="image-rounded-full absolute"
              src="/assets/Photo-3.jpg"
              alt=""
              style={{ left: '90px', zIndex: 6 }}
            />
          </div>
          <p className="align-self-center text-2">And 10,000 more satisfied customers worldwide</p>
        </div>
      </div>
    </section>
    
  );
}

{/* <section className="right-side-container p-10 hidden lg:flex flex-col justify-center">
  <div className="relative">
    <img className="mb-3" src={logo} alt="" />
    <p className="font-bold text-3xl text-white mb-2">
      Let’s make some magic <br /> with Heatmap.com
    </p>
    <p className=" text-white mb-4">
      The greatest heatmap solution on the planet. No big deal or anything.
    </p>
    <div className="insight-container">
      <div className="flex items-center relative">
        <img className="image-rounded-full " src="/assets/Photo.jpg" alt="" />
        <img
          className="image-rounded-full absolute"
          src="/assets/Photo-1.jpg"
          alt=""
          style={{ left: '30px' }}
        />
        <img
          className="image-rounded-full absolute"
          src="/assets/Photo-2.jpg"
          alt=""
          style={{ left: '60px', zIndex: 5 }}
        />
        <img
          className="image-rounded-full absolute"
          src="/assets/Photo-3.jpg"
          alt=""
          style={{ left: '90px', zIndex: 6 }}
        />
      </div>
      <p className="align-self-center text-2">And 10,000 more satisfied customers worldwide</p>
    </div>
  </div>
</section> */}