export default function PackageItem({ value, color }: { value: string; color: string }) {
  return (
    <li className={'flex gap-2 items-start'}>
      <span>
        <i className={`fi fi-rr-check ${color}`}></i>
      </span>
      <span
        style={{ fontFamily: "'CircularStd-Medium', sans-serif" }}
        className={'text-[0.875rem]'}>
        {value}
      </span>
    </li>
  );
}
