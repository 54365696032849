import { useState } from 'react';
import { getRequest } from '../../../requests';
import { useAuthHeader } from 'react-auth-kit';

type EDIT_IP = {
  status: string;
  ip: string;
  title: string;
  value: string;
};

export const useIP = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [promoLoading, setPromoLoading] = useState(true);
  const [couponLoading, setCouponLoading] = useState(true);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const retrieveIpList = async () => {
    setCouponLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.manageIpAddresses',
          token: token,
          format: 'json',
          request: 'list'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setCouponLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setCouponLoading(false);
        return response.data?.msg;
      }
    } catch (error) {
      setErrorMessage('Failed retrieving coupons');
      setCouponLoading(false);
    }
  };

  const deleteIP = async (ip: string) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.manageIpAddresses',
          value: ip,
          request: 'delete',
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed deleting a coupon');
      setLoading(false);
    }
  };

  const createIPs = async ({
    data
  }: {
    data: { title: string; value: string }[];
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.manageIpAddresses',
          value: JSON.stringify(data),
          token: token,
          format: 'json',
          request: 'insert'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed creating a coupon');
      setLoading(false);
    }
  };

  const editIP = async ({ status, ip, title, value }: EDIT_IP) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: { title: title, value: ip },
        params: {
          module: 'API',
          method: 'PaymentIntegration.manageIpAddresses',
          status: status === 'active' ? 'inactive' : 'active',
          value,
          request: 'update',
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed updating a coupon');
      setLoading(false);
    }
  };

  return {
    deleteIP,
    retrieveIpList,
    createIPs,
    editIP,
    errorMessage,
    loading,
    promoLoading,
    couponLoading
  };
};

