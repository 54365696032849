import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useContext, useState, useEffect, useRef } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import Modal from '../../components/global/ui/Modal';
import { IWebsiteDeleteModal } from '../../interfaces/website';
import { ORGANIZATION_ID } from '../../constants';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { useEditWebsite } from '../../pages/main/Websites/useEditWebsite';
import { useCoupon } from '../../pages/main/Coupons/useCoupon';
import { ICoupon } from '../../interfaces/coupon';

const trialOptions = [0, 7, 10, 14, 21, 30];

// This will store our coupons outside of the component
let cachedCoupons: ICoupon[] | null = null;
export default function UpdateSubscription({ website, subscriptionId, closeModal }: { website: any, subscriptionId: string, closeModal: any }) {
  const { updateSubscription, loading, errorMessage } = useEditWebsite();
  const { retrieveCoupons } = useCoupon();
  const [message, setMessage] = useState<string | null>(null);
  const [coupons, setCoupons] = useState<ICoupon[]>([]);
  const [couponError, setCouponError] = useState<string | null>(null);
  const [couponLoading, setCouponLoading] = useState(false);
  const fetchedRef = useRef(false);

  const { fetchOrganizationData } = useContext(OrganizationContext);

  useEffect(() => {
    const fetchCoupons = async () => {
      if (cachedCoupons) {
        setCoupons(cachedCoupons);
        return;
      }

      if (fetchedRef.current) return;
      fetchedRef.current = true;

      setCouponLoading(true);
      try {
        const response = await retrieveCoupons();
        if (Array.isArray(response) && response.length > 0) {
          setCoupons(response);
          cachedCoupons = response;
        } 
      } finally {
        setCouponLoading(false);
      }
    };

    fetchCoupons();
  }, []);

  const validationSchema = Yup.object().shape({
    // trial: Yup.number().required('Trial is required').oneOf(trialOptions, 'Invalid trial option'),
    // coupon: Yup.string().required('Coupon is required')
  });

  const formik = useFormik({
    initialValues: {
      trial: '',
      coupon: '',
      subscriptionId: subscriptionId
    },
    validationSchema,
    onSubmit: async (values) => handleUpdateSubscription(values)
  });

  const handleUpdateSubscription = async (values: FormikValues) => {
    console.log('Formik Values:', values);
    setMessage(null);
    const response = await updateSubscription({
      subscriptionId: values.subscriptionId,
      trial: values.trial,
      coupon: values.coupon
    });
    if (response?.result == 'success') {
      setMessage(response?.msg);
      fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
      setTimeout(() => {
        closeModal();
      }, 4000);
    }
  };
  
  return (
    <Modal closeModal={closeModal} title={'Update Subscription'} width={'w-full md:w-1/2 lg:w-1/3'}>
      {loading || couponLoading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Processing...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className={'mb-4'}>
                <label htmlFor={'trial'} className={'text-sm'}>
                  Trial Days
                </label>
                <select
                  name={'trial'}
                  value={formik.values.trial}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                >
                  <option value="" disabled>Select trial days</option>
                  {trialOptions.map((days) => (
                    <option key={days} value={days}>
                      {days} days
                    </option>
                  ))}
                </select>
              </div>
              <div className={'mb-4'}>
                <label htmlFor={'coupon'} className={'text-sm'}>
                  Coupon
                </label>
                {couponError ? (
                  <p className={'text-xs text-red-500'}>{couponError}</p>
                ) : coupons.length === 0 ? (
                  <p className={'text-xs text-gray-500'}>No coupons available</p>
                ) : (
                  <select
                    name={'coupon'}
                    value={formik.values.coupon}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                  >
                    <option value="" disabled>Select a coupon</option>
                    {coupons.map((coupon) => (
                      <option key={coupon.uuid} value={coupon.coupon_id}>
                        {coupon.title} - {coupon.amount}{coupon.is_percentage === 'Yes' ? '%' : '$'} off
                        (Min plan: {coupon.min_plan})
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className={'mt-2 flex flex-col-reverse lg:flex-row justify-between gap-2'}>
                <button
                  type={'button'}
                  onClick={closeModal}
                  className={`px-4 py-3 rounded text-gray-700 bg-gray-200 hover:bg-gray-300 text-xs`}>
                  Cancel
                </button>
                <button
                  type={'submit'}
                  className={`rounded bg-[#008160] font-medium px-4 py-3 text-xs text-white hover:bg-emerald-600`}>
                  {loading ? 'Updating subscription...' : 'Update Subscription'}
                </button>
              </div>
              {errorMessage && <Error value={`${errorMessage}`} />}
            </form>
          )}
        </div>
      )}
    </Modal>
  );
}