import { useState } from 'react';
import { getRequest } from '../../../requests';
import { useAuthHeader } from 'react-auth-kit';

export const useEditUser = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');
  const editUser = async ({
    name,
    email,
    billing_address,
    phone,
    nationality
  }: {
    name: string;
    email: string;
    billing_address?: string;
    phone?: string;
    nationality?: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.updateUser',
          name,
          email,
          billing_address,
          phone,
          nationality,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response;
      }
    } catch (e) {
      setErrorMessage('Error adding website');
      setLoading(false);
    }
  };

  return { editUser, loading, errorMessage };
};
