import React, { useState, useEffect } from 'react';
import Layout from '../../../layouts/global/Layout';
import PageTitle from '../../../components/global/PageTitle';
import RulesComponent from './RulesComponent';
import Tab2Content from './Tab2Component';
import Tab3Content from './Tab3Component';
import OpenAIDocuments from './OpenAIDocuments';

export default function OpenAISettings() {
    const [activeTab, setActiveTab] = useState(() => {
        // Check if the active tab is stored in localStorage
        const storedTab = localStorage.getItem('activeTab');
        return storedTab ? storedTab : 'tab1';
    });

    const renderTabContent = () => {
        switch (activeTab) {
            case 'tab1':
                return <RulesComponent />;
            case 'tab2':
                return <Tab2Content />;
            case 'tab3':
                return <Tab3Content />;
            case 'tab4':
                return <OpenAIDocuments />;
            default:
                return <RulesComponent />;
        }
    };

    // Update localStorage when activeTab changes
    useEffect(() => {
        localStorage.setItem('activeTab', activeTab);
    }, [activeTab]);

    return (
        <Layout>
            <PageTitle title={'AI Settings'} />
            <div className="flex mb-4">
                <div
                    className={` py-2 cursor-pointer mr-5 font-[500] text-[14px] text-center ${activeTab === 'tab1' ? 'border-b-2 border-[#059669] text-[#008160]' : 'hover:border-b-2 hover:border-gray-300'}`}
                    onClick={() => setActiveTab('tab1')}>
                    AI Rules
                </div>
                {/* <div
                    className={` py-2 cursor-pointer mr-5 font-[500] text-[14px] text-center ${activeTab === 'tab2' ? 'border-b-2 border-[#059669] text-[#008160]' : 'hover:border-b-2 hover:border-gray-300'}`}
                    onClick={() => setActiveTab('tab2')}>
                    Tab 2
                </div>
                <div
                    className={` py-2 cursor-pointer mr-5 font-[500] text-[14px] text-center ${activeTab === 'tab3' ? 'border-b-2 border-[#059669] text-[#008160]' : 'hover:border-b-2 hover:border-gray-300'}`}
                    onClick={() => setActiveTab('tab3')}>
                    Tab 3
                </div> */}
                <div
                    className={` py-2 cursor-pointer mr-5 font-[500] text-[14px] text-center ${activeTab === 'tab4' ? 'border-b-2 border-[#059669] text-[#008160]' : 'hover:border-b-2 hover:border-gray-300'}`}
                    onClick={() => setActiveTab('tab4')}>
                    OpenAI Documents
                </div>
            </div>
            <div className=" py-4">
                {renderTabContent()}
            </div>
        </Layout>
    );
}
