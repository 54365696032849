import { useContext, useState } from 'react';
import AddPaymentCard from './AddPaymentCard';
import { usePayment } from '../../../pages/main/Pricing/usePayment';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import PaymentCardItem from './PaymentCardItem';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { AccountHolderSetup } from '../../AccountProfile/AccountHolderSetup';

export default function PaymentMethods() {
  const [addCardOpen, setAddCardOpen] = useState<boolean>(false);

  const { setCardAsDefault, loading } = usePayment();
  const { profile, accountHolderSetupOpen, setAccountHolderSetupOpen } =
    useContext(OrganizationContext);

  const handleAddCard = () => {
    if (profile.profile_details?.account_holder == 0 &&  profile.profile_details?.permission &&
      !['admin', 'write'].includes(profile.profile_details.permission)) {
      setAccountHolderSetupOpen(true);
    } else {
      setAddCardOpen(true);
    }
  };

  return (
    <>
      {addCardOpen && profile.subscription_details && (
        <AddPaymentCard
          customerId={profile.subscription_details.stripe_customer_id}
          closeModal={() => setAddCardOpen(false)}
        />
      )}

      {accountHolderSetupOpen && (
        <AccountHolderSetup closeModal={() => setAccountHolderSetupOpen(false)} />
      )}
      <div
        className={
          'sticky top-0 rounded-md border-t border-gray-200 p-3 md:p-5 bg-white text-gray-500'
        }>
        <div>
          <div className={'flex justify-between items-center'}>
            <h6 className={'font-semibold md:font-medium text-sm lg:text-base'}>Payment Method</h6>
            {profile.subscription_details &&
            profile.subscription_details.subscription &&
            profile.profile_details?.permission &&
            ['admin', 'write'].includes(profile.profile_details.permission) && (
              <button
                type="button"
                onClick={handleAddCard}
                className="rounded px-2 md:px-5 py-2 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white"
              >
                <span>
                  <i className="fi fi-rr-plus text-xs"></i>
                </span>
                <span className="text-xs md:text-sm">Add card</span>
              </button>
            )
          }
          </div>
          <hr className={'my-2'} />
          <div className={''}>
            <div className={'w-full md:w-1/2 lg:w-full'}>
              {profile.subscription_details &&
                profile.subscription_details.card.find((card: any) => card.is_default_source) && (
                  <PaymentCardItem
                    data={
                      profile.subscription_details.card.find(
                        (card: any) => card.is_default_source
                      ) as any
                    }
                    setCardAsDefault={setCardAsDefault}
                  />
                )}
            </div>
            <div className={'max-h-[50vh] overflow-y-auto slim-scroll pb-2 pr-1 mt-3'}>
              {loading ? (
                <div className={'flex flex-col justify-center items-center p-5'}>
                  <LoadingSpinner color={'text-[#008160]'} />
                  <h5 className={'mt-2 text-sm'}>Switching default payment method</h5>
                </div>
              ) : (
                <div>
                  {profile.subscription_details && !profile.subscription_details.card ? (
                    <div className={'p-5 flex flex-col justify-center items-center text-center'}>
                      <p className={'font-medium text-sm text-amber-500'}>No payment card added</p>
                    </div>
                  ) : (
                    <div className={'grid md:grid-cols-2 lg:grid-cols-1 gap-3'}>
                      {profile.subscription_details &&
                      profile.subscription_details?.card.length > 0 ? (
                        profile.subscription_details.card
                          .filter((card: any) => !card.is_default_source)
                          .map((item: any, index: number) => (
                            <PaymentCardItem
                              key={index}
                              data={item}
                              setCardAsDefault={setCardAsDefault}
                            />
                          ))
                      ) : (
                        <div
                          className={'p-5 flex flex-col justify-center items-center text-center'}>
                          <div>
                            <img
                              src={'/assets/icons/credit-card.png'}
                              className={'w-[4vw]'}
                              alt={''}
                            />
                          </div>
                          <p className={'font-medium text-sm text-amber-500'}>
                            No payment card added
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
