export default function Error({ value }: { value: string }) {
  return (
    <div
      className={
        'relative bg-red-100 border-l-2 border-red-500 text-red-500 p-3 w-full mt-5 rounded-md'
      }>
      <div>
        <p className={'text-sm'}>{value}</p>
      </div>
      <span
        className={
          'absolute pt-2 -top-2 -left-3 bg-white rounded-full w-6 h-6 flex justify-center items-center shadow-md'
        }>
        <i className="fi fi-rr-cross-circle text-lg"></i>
      </span>
    </div>
  );
}
