import { useContext, useState } from 'react';
import { getRequest } from '../../../requests';
import { useAuthHeader } from 'react-auth-kit';
import { useLogoutUser } from '../../../util/global';
import { OrganizationContext } from '../../../contexts/OrganizationContext';

export const useLogs = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const { logoutUser } = useLogoutUser();

  const readUserLogHistory = async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.readUserLogHistory',
          token: token && token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error' && response.data.reason == 'token_expired') {
          await logoutUser();
          setErrorMessage(response.data.msg);
          setLoading(false);
          return;
        } else if (response.data.result === 'error') {
          setErrorMessage(response.data.msg);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error loading system logs');
      setLoading(false);
    }
  };

  return {
    readUserLogHistory,
    loading,
    errorMessage
  };
};
