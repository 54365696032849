import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { clientDetailsTabs } from '../../constants';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import DisableTwoFA from '../AccountProfile/DisableTwoFA';
import ClientsWebsiteListCard from './ClientsWebsiteListCard';
import { ITeam } from '../../interfaces/user';
import { IAccount, IClient } from '../../interfaces/client';
import { useTeamInvites } from '../../pages/main/TeamManagement/useTeamInvites';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import InviteUser from '../TeamManagement/InviteUser';
import RemoveUserFromTeam from '../TeamManagement/RemoveUserFromTeam';
import EditTeamMember from '../TeamManagement/EditTeamMember';
import { useClients } from '../../pages/main/Clients/useClients';
import UserAccountListCard from '../Accounts/UserAccountListCard';
import { IAccountWebsite, IUserWebsite } from '../../interfaces/website';
import AccountWebsiteListCard from './AccountWebsiteListCard';
import { IWebsiteSubscription } from '../../interfaces/subscription';

export default function ClientAccount({
  closeModal,
  data
}: {
  data: IClient;
  closeModal: () => void;
}) {
  const { retrieveAccountMembers, loading: teamLoading } = useTeamInvites();
  const { listClientAccounts, loading: clientAccountsLoading } = useClients();
  const { profile } = useContext(OrganizationContext);

  const [tab, setTab] = useState<number>(1);
  const [removeOpen, setRemoveOpen] = useState<boolean>(false);
  const [inviteOpen, setInviteOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<ITeam | null>(null);
  const [accountTeam, setAccountTeam] = useState<ITeam[] | null>(null);
  const [userOrganizations, setUserOrganizations] = useState<IAccount[] | null>(null);
  const [confirmDisableTwoFAOpen, setConfirmDisableTwoFAOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);

  const fetchTeamMembers = () => {
    retrieveAccountMembers({ account_id: data.account_id }).then((res) => {
      setAccountTeam(res.msg);
    });
  };
  const fetchClientAccounts = () => {
    listClientAccounts({ user_id: data.user_id }).then((res) => {
      setUserOrganizations(res.msg);
    });
  };

  useEffect(() => {
    fetchTeamMembers();
    fetchClientAccounts();
  }, []);

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      {confirmDisableTwoFAOpen && (
        <DisableTwoFA userId={data.user_id} closeModal={() => setConfirmDisableTwoFAOpen(false)} />
      )}

      {inviteOpen && profile && (
        <InviteUser
          account_id={data.account_id}
          fetchData={fetchTeamMembers}
          websites={profile.subscription_details?.subscription as IWebsiteSubscription[]}
          closeModal={() => setInviteOpen(false)}
        />
      )}
      {editOpen && selected && (
        <EditTeamMember
          fetchData={fetchTeamMembers}
          user_id={selected.user_id}
          currentAccess={selected.access}
          account_id={data.account_id}
          websites={profile.subscription_details?.subscription as IWebsiteSubscription[]}
          invited_sites={selected.invited_sites}
          closeModal={() => setEditOpen(false)}
        />
      )}
      {removeOpen && selected && (
        <RemoveUserFromTeam
          fetchData={fetchTeamMembers}
          user_id={selected.user_id}
          account_id={data.account_id}
          closeModal={() => setRemoveOpen(false)}
        />
      )}
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div className="pointer-events-auto relative w-[80vw]">
              <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                <button
                  type="button"
                  onClick={closeModal}
                  className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="sr-only">Close panel</span>
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <h2
                    className="text-base font-semibold leading-6 text-gray-900"
                    id="slide-over-title">
                    <span>{data.full_name}</span> - <span>#{data.account_id}</span>
                  </h2>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <div className={'flex flex-col gap-5'}>
                    <div className={'flex justify-between gap-2'}>
                      <span className={'flex items-center gap-3'}>
                        <span
                          className={`rounded-full px-2 py-1 leading-none text-xs font-medium ${
                            data.status == 'active'
                              ? 'bg-emerald-100 text-[#008160]'
                              : data.status == 'inactive'
                              ? 'bg-red-100 text-red-500'
                              : ''
                          } uppercase`}>
                          {data.status}
                        </span>
                        <span
                          className={
                            'flex items-center px-2 py-1 rounded gap-2 border border-emerald-500'
                          }>
                          <span className={'text-xs text-emerald-500'}>
                            {data.account_holder == 1 ? (
                              <span title={'Account holder'} className={''}>
                                Account holder
                              </span>
                            ) : data.account_holder == 0 ? (
                              <span title={'Guest user'} className={''}>
                                Guest User
                              </span>
                            ) : null}
                          </span>
                        </span>
                      </span>
                      <span className={'text-sm'}>
                        Joined: {moment(data.date_registered).format('lll')}
                      </span>
                    </div>
                    <div className={'flex gap-4'}>
                      {clientDetailsTabs &&
                        clientDetailsTabs.map((item, index) => (
                          <button
                            type={'button'}
                            onClick={() => setTab(item.id)}
                            key={index}
                            className={`${
                              tab === item.id
                                ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                                : 'border-transparent'
                            } py-2 border-b-2 hover:border-gray-300 font-medium text-sm flex gap-3`}>
                            <span className={'text-sm'}>{item.name}</span>
                          </button>
                        ))}
                    </div>
                    <div>
                      {tab == 1 && (
                        <div>
                          <div className={'mb-2'}>
                            <h6 className={'font-semibold md:font-medium text-sm lg:text-base'}>
                              Account Profile
                            </h6>
                            <p className={'text-xs'}>This account user profile details</p>
                          </div>
                          <div className={'border border-gray-200'}>
                            <div className={'grid grid-cols-2 gap-3 p-5'}>
                              <div className={'col-span-2'}>
                                <div
                                  className={
                                    'w-12 lg:w-20 h-12 lg:h-20 bg-gray-200 text-gray-700 font-medium rounded-full flex justify-center items-center'
                                  }>
                                  <span className={'font-medium text-lg md:text-3xl uppercase'}>
                                    {data.full_name.charAt(0)}
                                  </span>
                                </div>
                              </div>
                              <div className={'flex flex-col gap-1 col-span-2'}>
                                <span className={'text-sm'}>Account name</span>
                                <span className={'font-medium'}>{data.full_name}</span>
                              </div>
                              <div className={'flex flex-col gap-1'}>
                                <span className={'text-sm'}>Email address</span>
                                <span className={'font-medium'}>{data.email}</span>
                              </div>
                              <div className={'flex flex-col gap-1'}>
                                <span className={'text-sm'}>Phone number</span>
                                <span className={'font-medium'}>
                                  {data.phone ? data.phone : 'N/A'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {tab == 2 && (
                        <div>
                          <div>
                            <h6 className={'font-medium'}>Two-factor Authentication</h6>
                            <p className={'text-sm'}>
                              {data && data.two_fa
                                ? 'This account has Multi-factor authentication enabled'
                                : 'This account does not have Multi-factor authentication enabled'}
                            </p>
                          </div>
                          <div className={'flex mt-3'}>
                            {data && data.two_fa && (
                              <button
                                type={'button'}
                                onClick={() => setConfirmDisableTwoFAOpen(true)}
                                className={
                                  'rounded border border-red-500 hover:bg-red-100 text-red-500 font-medium px-3 md:px-4 py-2 md:py-2 text-xs md:text-sm'
                                }>
                                Disable 2FA
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      {tab == 3 && (
                        <div className={'mt-2 flex flex-col gap-2'}>
                          {profile.subscription_details?.subscription &&
                          profile.subscription_details?.subscription.filter(
                            (item) => item.account_id == data.account_id && item.website
                          ).length > 0 ? (
                            profile.subscription_details?.subscription
                              .filter((item) => item.account_id == data.account_id && item.website)
                              .map((item) => (
                                <ClientsWebsiteListCard
                                  data={item as IWebsiteSubscription}
                                  key={item.idsite}
                                />
                              ))
                          ) : (
                            <div className={'flex justify-center items-center h-[70vh]'}>
                              <p>No website added</p>
                            </div>
                          )}
                        </div>
                      )}
                      {tab == 4 && (
                        <div>
                          <div className={'flex justify-between items-center gap-5'}>
                            <h6 className={'font-semibold md:font-medium text-base lg:text-base'}>
                              Manage users
                            </h6>
                            <div className={'flex gap-3'}>
                              <button
                                type={'button'}
                                onClick={() => setInviteOpen(true)}
                                className={
                                  'rounded px-3 py-2 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                                }>
                                <span>
                                  <i className="fi fi-rr-user-add text-xs"></i>
                                </span>
                                <span className={'text-xs'}>Invite members</span>
                              </button>
                            </div>
                          </div>

                          <div className={'my-2 w-full'}>
                            {teamLoading ? (
                              <div
                                className={
                                  'flex h-[80vh] gap-2 flex-col justify-center items-center text-center'
                                }>
                                <LoadingSpinner color={'text-[#008160]'} />
                                <p>Loading team members</p>
                              </div>
                            ) : accountTeam && accountTeam.length > 0 ? (
                              <div className={'border border-gray-200'}>
                                <div
                                  className={
                                    'hidden md:grid grid-cols-12 gap-2 font-medium text-sm mb-2 p-2 bg-gray-100'
                                  }>
                                  <div className={'col-span-3'}>
                                    <div>
                                      <h5>Name</h5>
                                    </div>
                                  </div>
                                  <div className={'col-span-4'}>
                                    <h5>Email address</h5>
                                  </div>
                                  <div className={'col-span-3'}>
                                    <h5>Websites</h5>
                                  </div>
                                  <div className={'col-span-2'}>
                                    <h5>Access level</h5>
                                  </div>
                                  <div className={'col-span-1'}></div>
                                </div>
                                <div>
                                  {accountTeam &&
                                    accountTeam.map((item, index) => (
                                      <div
                                        key={index}
                                        className={
                                          'grid md:grid-cols-12 text-sm gap-2 px-2 py-3 border-b border-gray-200'
                                        }>
                                        <div className={'md:col-span-3'}>
                                          <div>
                                            <h5 className={'font-medium'}>{item.full_name}</h5>
                                          </div>
                                        </div>
                                        <div className={'md:col-span-4 flex flex-col gap-1'}>
                                          <h5>{item.email}</h5>
                                          <div>
                                            <span
                                              className={`${
                                                item.status === 'active'
                                                  ? 'bg-emerald-100 text-emerald-600'
                                                  : 'bg-yellow-100 text-yellow-600'
                                              }  px-2 py-1 text-xs rounded-full uppercase`}>
                                              {item.status}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          className={
                                            'md:col-span-3 flex items-start flex-wrap gap-2'
                                          }>
                                          {item.invited_sites &&
                                          item.invited_sites.length ==
                                            profile.subscription_details?.subscription.filter(
                                              (item) => item.account_id == data.account_id
                                            ).length ? (
                                            <span>All websites</span>
                                          ) : item.invited_sites.length == 0 ? (
                                            <span className={'italic'}>
                                              Not assigned any website
                                            </span>
                                          ) : (
                                            item.invited_sites.map((site, index: number) => (
                                              <span
                                                key={index}
                                                className={
                                                  'rounded bg-gray-100 hover:bg-gray-200 flex items-center gap-2 p-1'
                                                }>
                                                <span>
                                                  <img
                                                    className={
                                                      'rounded-sm w-6 h-6 object-contain drop-shadow-sm'
                                                    }
                                                    src={`https://www.google.com/s2/favicons?domain=${site?.main_url}&sz=128`}
                                                    alt={''}
                                                  />
                                                </span>
                                                <span className={'text-xs font-medium'}>
                                                  {site?.main_url}
                                                </span>
                                              </span>
                                            ))
                                          )}
                                        </div>
                                        <div className={'md:col-span-1'}>
                                          <span
                                            className={
                                              'capitalize border border-emerald-500 bg-emerald-50 text-emerald-500 px-2 py-1 rounded text-xs'
                                            }>
                                            {item.access}
                                          </span>
                                        </div>
                                        <div className={'col-span-1 flex md:justify-center'}>
                                          <div className={'flex items-start gap-2'}>
                                            <button
                                              title={'Update'}
                                              type={'button'}
                                              onClick={() => {
                                                setSelected(item);
                                                setEditOpen(true);
                                              }}
                                              className={
                                                'edit rounded px-2 py-1 flex items-start gap-2 border border-transparent hover:border-[#008160] bg-emerald-50 text-[#008160] text-sm'
                                              }>
                                              <span>
                                                <i className="edit fi fi-rr-pencil text-sm"></i>
                                              </span>
                                            </button>
                                            <button
                                              type={'button'}
                                              onClick={() => {
                                                setSelected(item);
                                                setRemoveOpen(true);
                                              }}
                                              className={
                                                'rounded px-2 pt-1 items-start border border-transparent hover:border-red-500 bg-red-50 text-red-500 text-sm flex gap-2'
                                              }>
                                              <span>
                                                <i className="fi fi-rr-trash text-base text-red-500"></i>
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            ) : (
                              <div className={'p-5'}>
                                <p className={'text-sm italic text-center'}>No team members</p>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {tab === 5 && (
                        <div>
                          <div className={'mb-2'}>
                            <h6 className={'font-semibold md:font-medium text-sm lg:text-base'}>
                              Accounts
                            </h6>
                            <p className={'text-xs'}>
                              All accounts the client belongs to and can manage based on permissions
                            </p>
                          </div>
                          <div className={'grid gap-2'}>
                            {clientAccountsLoading ? (
                              <div
                                className={
                                  'p-5 h-[70vh] flex flex-col justify-center items-center text-center'
                                }>
                                <LoadingSpinner color={'text-[#008160]'} />
                                <p>Loading user accounts...</p>
                              </div>
                            ) : userOrganizations?.length === 0 ? (
                              <div className={'flex justify-center items-center h-[70vh]'}>
                                <p>User does not have access to any other account</p>
                              </div>
                            ) : (
                              userOrganizations &&
                              userOrganizations.map((item, index) => (
                                <AccordionItem
                                  key={index}
                                  account={item}
                                  items={item.websites}
                                  isOpen={openIndex === index}
                                  toggleAccordion={() => toggleAccordion(index)}
                                />
                              ))
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface AccordionItemProps {
  account: IAccount;
  items: IAccountWebsite[];
  isOpen: boolean;
  toggleAccordion: () => void;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  account,
  items,
  isOpen,
  toggleAccordion
}) => {
  return (
    <div
      className={`cursor-pointer border border-gray-300 rounded mb-2 ${
        isOpen ? 'bg-gray-50' : ''
      }`}>
      <div
        className="p-4 w-full bg-gray-100 flex justify-between items-start focus:outline-none"
        onClick={toggleAccordion}>
        <UserAccountListCard data={account} />
        <span className={'text-sm'}>
          {isOpen ? <i className="fi fi-rr-minus"></i> : <i className="fi fi-rr-plus"></i>}
        </span>
      </div>
      {isOpen && (
        <div
          className={`p-4 overflow-auto slim-scroll transition-max-h ${
            isOpen ? 'max-h-[500px]' : 'max-h-0'
          }`}>
          {items.length > 0 ? (
            <ul className={'flex flex-col gap-2 mt-2'}>
              {items.map((item, index) => (
                <AccountWebsiteListCard data={item} key={index} />
              ))}
            </ul>
          ) : (
            <span className={'mt-2 italic text-sm'}>No website</span>
          )}
        </div>
      )}
    </div>
  );
};
