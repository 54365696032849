import { IAIDocument } from '../../interfaces/analytic';

interface SearchFormProps {
  data: any;
  setSearchResults: any;
}

export default function SearchAIPrompt({ data, setSearchResults }: SearchFormProps) {
  const handleSearch = (e: any) => e.preventDefault();

  const handleSearchChange = (e: any) => {
    if (!e.target.value) return setSearchResults(data);
    const results = data.filter(
      (item: IAIDocument) =>
        item.title?.toLowerCase().includes(e.target?.value) ||
        item.context?.toLowerCase().includes(e.target?.value)
    );
    setSearchResults(results);
  };

  return (
    <form
      onSubmit={handleSearch}
      className={
        'border border-gray-300 bg-gray-100 rounded px-4 py-2 flex gap-3 items-start w-full lg:w-[20vw]'
      }>
      <span>
        <i className="fi fi-rr-search"></i>
      </span>
      <input
        type={'search'}
        id={'search'}
        placeholder={'Search AI Document'}
        className={'bg-transparent w-full text-sm'}
        onChange={handleSearchChange}
      />
    </form>
  );
}
