import { useState } from 'react';
import { periods } from '../../../constants';
import { ICancellationReasonItem } from '../../../interfaces/analytic';
import CancellationReasonListCard from './CancellationReasonListCard';

export default function SubscriptionCancellationReasons({
  closeModal,
  data
}: {
  data: any;
  closeModal: () => void;
}) {
  const [tab, setTab] = useState<number>(2);
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  console.log(data && Object.entries(data.weekly.cancelled_reason_other));

  return (
    <div
      className="relative z-10"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div className="pointer-events-auto relative w-[40vw]">
              <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                <button
                  type="button"
                  onClick={closeModal}
                  className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="sr-only">Close panel</span>
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <h2
                    className="text-base font-semibold leading-6 text-gray-900"
                    id="slide-over-title">
                    <span>Other Cancellation Reasons</span>
                  </h2>
                  <p className={'text-sm'}>
                    The list of other reasons for clients&apos; cancellation
                  </p>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <div className={'flex flex-col gap-5'}>
                    <div className={'flex gap-4'}>
                      {periods &&
                        periods.map((item, index) => (
                          <button
                            type={'button'}
                            onClick={() => setTab(item.id)}
                            key={index}
                            className={`${
                              tab === item.id
                                ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                                : 'border-transparent'
                            } py-2 border-b-2 hover:border-gray-300 font-medium text-sm flex gap-3`}>
                            <span className={'text-sm'}>{item.name}</span>
                          </button>
                        ))}
                    </div>
                    <div>
                      {/*{tab == 1 && (*/}
                      {/*  <div className={'flex flex-col gap-2'}>*/}
                      {/*    {data && data.today.length > 0 ? (*/}
                      {/*      data.today.map((item: I, index: number) => (*/}
                      {/*        <CancellationReasonListCard key={index} data={item} />*/}
                      {/*      ))*/}
                      {/*    ) : (*/}
                      {/*      <span className={'mt-2 italic text-sm'}>No cancelled subscription</span>*/}
                      {/*    )}*/}
                      {/*  </div>*/}
                      {/*)}*/}
                      {tab == 2 && (
                        <div className={'flex flex-col gap-2'}>
                          {data &&
                            Object.entries(data.weekly.cancelled_reason_other).map(
                              ([day, items]: any, index: number) => (
                                <AccordionItem
                                  key={index}
                                  title={day}
                                  items={items}
                                  isOpen={openIndex === index}
                                  toggleAccordion={() => toggleAccordion(index)}
                                />
                              )
                            )}
                        </div>
                      )}
                      {tab == 3 && (
                        <div className={'flex flex-col gap-2'}>
                          {data &&
                            Object.entries(data.annual.cancelled_reason_other).map(
                              ([day, items]: any, index: number) => (
                                <AccordionItem
                                  key={index}
                                  title={day}
                                  items={items}
                                  isOpen={openIndex === index}
                                  toggleAccordion={() => toggleAccordion(index)}
                                />
                              )
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface AccordionItemProps {
  title: string;
  items: ICancellationReasonItem[];
  isOpen: boolean;
  toggleAccordion: () => void;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ title, items, isOpen, toggleAccordion }) => {
  return (
    <div className={`border border-gray-300 rounded mb-2 ${isOpen ? 'bg-gray-50' : ''}`}>
      <button
        className="p-4 w-full bg-gray-100 flex justify-between items-center focus:outline-none"
        onClick={toggleAccordion}>
        <span className={'text-sm font-medium'}>
          {title} - {items.length}
        </span>
        <span className={'text-sm'}>
          {isOpen ? <i className="fi fi-rr-minus"></i> : <i className="fi fi-rr-plus"></i>}
        </span>
      </button>
      {isOpen && (
        <div
          className={`p-4 overflow-auto slim-scroll transition-max-h ${
            isOpen ? 'max-h-[500px]' : 'max-h-0'
          }`}>
          {items.length > 0 ? (
            <ul className={'flex flex-col gap-2 mt-2'}>
              {items.map((item, index) => (
                <CancellationReasonListCard key={index} data={item} />
              ))}
            </ul>
          ) : (
            <span className={'mt-2 italic text-sm'}>No cancelled subscription</span>
          )}
        </div>
      )}
    </div>
  );
};
