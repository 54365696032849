import React from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { cancellationReasons } from '../../../../constants';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function CancellationReasonsChart({ data, period }: { data: any; period: string }) {
  const chartData =
    data.length !== 0 &&
    cancellationReasons.map((reasonObj) => ({
      reason: reasonObj.reason,
      value: data[reasonObj.id] || 0
    }));

  const options = {
    elements: {
      bar: {
        borderWidth: 1.5
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: true,
        text: 'Cancellation Reasons'
      }
    }
  };

  const dataStats = {
    labels: chartData && chartData.map((item, index) => item.reason),
    datasets: [
      {
        label: 'Cancellation Reasons',
        data: chartData && chartData.map((item, index) => item.value),
        backgroundColor: 'rgba(0, 129, 96, 0.3)',
        borderColor: 'rgb(0, 129, 96)'
      }
    ]
  };
  return <Bar options={options} data={dataStats as any} />;
}
