import { ICoupon } from '../../interfaces/coupon';

interface SearchFormProps {
  coupons: any;
  setSearchResults: any;
  placeholder: string;
}

export default function SearchCoupons({ coupons, setSearchResults, placeholder }: SearchFormProps) {
  const handleSearch = (e: any) => e.preventDefault();

  const handleSearchChange = (e: any) => {
    if (!e.target.value) return setSearchResults(coupons);
    const results = coupons.filter(
      (item: ICoupon) =>
        item.title?.toLowerCase().includes(e.target?.value) ||
        item.description?.toLowerCase().includes(e.target?.value)
    );
    setSearchResults(results);
  };

  return (
    <form
      onSubmit={handleSearch}
      className={
        'border border-gray-300 bg-gray-100 rounded px-4 py-2 flex gap-3 items-start w-full lg:w-[20vw]'
      }>
      <span>
        <i className="fi fi-rr-search"></i>
      </span>
      <input
        type={'search'}
        id={'search'}
        placeholder={placeholder}
        className={'bg-transparent w-full text-sm'}
        onChange={handleSearchChange}
      />
    </form>
  );
}
