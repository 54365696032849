import { getRequest } from '../requests';
import { useState } from 'react';
import { useAuthHeader } from 'react-auth-kit';

export const useLogout = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const logout = async () => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.logout',
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
        }
        setLoading(false);
        return response.data;
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMessage('Error logging out user');
    }
  };

  return { logout, loading, errorMessage };
};
