import {
  ChartPeriod,
  ChartType,
  IAnalytic,
  IChart,
  IWebsitePlatformChart
} from '../../../interfaces/analytic';
import MonthlySubscription from './Charts/MonthlySubscription';
import { useState } from 'react';
import { periods } from '../../../constants';
import WebsitePlatforms from './Charts/WebsitePlatforms';

export default function OnboardingSummary({ data }: { data: IAnalytic }) {
  const [tab, setTab] = useState<number>(2);
  // console.log(Object.values(data.websites?.platforms as IWebsitePlatformChart));

  return (
    <div className={'grid gap-5'}>
      <div className={'rounded-md border border-gray-200 p-3 md:p-5 bg-white text-gray-500'}>
        <div className={'flex gap-2 justify-between items-start'}>
          <span className={'text-sm'}>Today&apos;s Summary</span>
          <i className="fi fi-sr-sensor-fire text-xl lg:text-xl text-gray-400"></i>
        </div>
        <div className={'grid grid-cols-2 lg:grid-cols-5 gap-2'}>
          <div className={'flex flex-col justify-between'}>
            <span className={'text-sm'}></span>
            <div className={'mt-2 flex flex-col justify-center items-center text-center'}>
              <span className={'text-3xl font-medium'}>{data && data.today.subscriptions}</span>
              <span className={'text-xs'}>Subscriptions</span>
            </div>
          </div>
          <div className={'flex flex-col justify-between'}>
            <span className={'text-sm'}></span>
            <div className={'mt-2 flex flex-col justify-center items-center text-center'}>
              <span className={'text-3xl font-medium'}>{data && data.today.registrations}</span>
              <span className={'text-xs'}>Registrations</span>
            </div>
          </div>
          <div className={'flex flex-col justify-between'}>
            <span className={'text-sm'}></span>
            <div className={'mt-2 flex flex-col justify-center items-center text-center'}>
              <span className={'text-3xl font-medium'}>{data && data.today.invitations}</span>
              <span className={'text-xs'}>Invitations</span>
            </div>
          </div>
          <div className={'flex flex-col justify-between'}>
            <span className={'text-sm'}></span>
            <div className={'mt-2 flex flex-col justify-center items-center text-center'}>
              <span className={'text-3xl font-medium'}>
                {data && data.today.cancelled_subscriptions}
              </span>
              <span className={'text-xs'}>Cancelled Subscriptions</span>
            </div>
          </div>
          <div className={'flex flex-col justify-between'}>
            <span className={'text-sm'}></span>
            <div className={'mt-2 flex flex-col justify-center items-center text-center'}>
              <span className={'text-3xl font-medium'}>
                {data && data.today.unpaid_registrations}
              </span>
              <span className={'text-xs'}>Unpaid Registrations</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={'flex gap-4 mb-5'}>
          {periods &&
            periods.map((item, index) => (
              <button
                type={'button'}
                onClick={() => setTab(item.id)}
                key={index}
                className={`${
                  tab === item.id
                    ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                    : 'border-transparent'
                } py-2 border-b-2 hover:border-gray-300 font-medium text-sm flex gap-3`}>
                <span className={'text-sm'}>{item.name}</span>
              </button>
            ))}
        </div>
        {tab == 2 ? (
          <div className={'grid lg:grid-cols-2 gap-5'}>
            {/*Users Accounts this Week*/}
            <div className={'rounded-md border border-gray-200 p-3 md:p-5'}>
              {data && (
                <MonthlySubscription
                  period={ChartPeriod.week}
                  type={ChartType.user}
                  data={data.chart as IChart}
                />
              )}
            </div>
            {/*Waitlist Accounts this Week*/}
            <div className={'rounded-md border border-gray-200 p-3 md:p-5'}>
              {data && (
                <MonthlySubscription
                  period={ChartPeriod.week}
                  type={ChartType.waitlist}
                  data={data.chart as IChart}
                />
              )}
            </div>
          </div>
        ) : (
          <div className={'grid lg:grid-cols-2 gap-5'}>
            {/*User Accounts this Week*/}
            <div className={'rounded-md border border-gray-200 p-3 md:p-5'}>
              {data && (
                <MonthlySubscription
                  period={ChartPeriod.annual}
                  type={ChartType.user}
                  data={data.chart as IChart}
                />
              )}
            </div>
            {/*Waitlist Accounts this Year*/}
            <div className={'rounded-md border border-gray-200 p-3 md:p-5'}>
              {data && (
                <MonthlySubscription
                  period={ChartPeriod.annual}
                  type={ChartType.waitlist}
                  data={data.chart as IChart}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className={''}>
        <div className={'grid lg:grid-cols-10 gap-5'}>
          <div
            className={
              'lg:col-span-4 rounded-md border border-gray-200 p-3 md:p-5 bg-white text-gray-500'
            }>
            <div className={'flex gap-2 justify-between items-start'}>
              <span className={'text-sm'}>Clients Onboarding</span>
              <i className="fi fi-sr-users text-xl lg:text-xl text-gray-400"></i>
            </div>
            <div className={'grid grid-cols-2 lg:grid-cols-1 gap-3 mt-2'}>
              <div
                className={
                  'flex flex-col justify-between border-l-2 border-yellow-500 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}></span>
                <div className={'flex flex-col'}>
                  <span className={'text-sm'}>Pending</span>
                  <span className={'text-2xl lg:text-4xl font-medium'}>
                    {data && data.onboarding?.pending}
                  </span>
                </div>
              </div>
              <div
                className={
                  'flex flex-col justify-between border-l-2 border-emerald-600 shadow text-black p-3 rounded'
                }>
                <span className={'text-sm'}></span>
                <div className={'flex flex-col'}>
                  <span className={'text-sm'}>Completed</span>
                  <span className={'text-2xl lg:text-4xl font-medium'}>
                    {data && data.onboarding?.completed}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              'lg:col-span-6 rounded-md border border-gray-200 p-3 md:p-5 bg-white text-gray-500'
            }>
            <div className={'flex gap-2 justify-between items-start'}>
              <span className={'text-sm'}>Users Status</span>
              <i className="fi fi-sr-users text-xl lg:text-xl text-gray-400"></i>
            </div>
            <div className={'mt-2'}>
              <div className={'grid grid-cols-2 gap-3'}>
                <div
                  className={
                    'flex flex-col justify-between border-l-2 border-emerald-600 shadow text-black p-3 rounded'
                  }>
                  <span className={'text-sm'}>Active</span>
                  <div className={'mt-1'}>
                    <span className={'text-2xl lg:text-3xl font-medium'}>
                      {data && data.onboarding?.statuses.active}
                    </span>
                  </div>
                </div>
                <div
                  className={
                    'flex flex-col justify-between border-l-2 border-gray-600 shadow text-black p-3 rounded'
                  }>
                  <span className={'text-sm'}>Inactive</span>
                  <div className={'mt-0'}>
                    <span className={'text-2xl lg:text-3xl font-medium'}>
                      {data && data.onboarding?.statuses.inactive}
                    </span>
                  </div>
                </div>
                <div
                  className={
                    'flex flex-col justify-between border-l-2 border-yellow-500 shadow text-black p-3 rounded'
                  }>
                  <span className={'text-sm'}>Pending</span>
                  <div className={'mt-1'}>
                    <span className={'text-2xl lg:text-3xl font-medium'}>
                      {data && data.onboarding?.statuses.pending}
                    </span>
                  </div>
                </div>
                <div
                  className={
                    'flex flex-col justify-between border-l-2 border-red-600 shadow text-black p-3 rounded'
                  }>
                  <span className={'text-sm'}>Deleted</span>
                  <div className={'mt-1'}>
                    <span className={'text-2xl lg:text-3xl font-medium'}>
                      {data && data.onboarding?.statuses.deleted}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'p-5'}>
        <div className={'grid grid-cols-4 gap-3'}>
        </div>
        <div>
          {data && <WebsitePlatforms data={data.websites?.platforms as IWebsitePlatformChart} />}
        </div>
      </div>
    </div>
  );
}
