export default function PrimaryButtonWithIcon({
  handler,
  title,
  icon
}: {
  handler: any;
  title: string;
  icon: any;
}) {
  return (
    <button
      type={'button'}
      onClick={handler}
      className={
        'rounded px-3 py-1 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
      }>
      <span>{icon}</span>
      <span className={'text-sm'}>{title}</span>
    </button>
  );
}
