import { useState } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import { useTeamInvites } from '../../pages/main/TeamManagement/useTeamInvites';
import Modal from '../../components/global/ui/Modal';

export default function RemoveUserFromTeam({
  user_id,
  account_id,
  closeModal,
  fetchData
}: {
  user_id: string;
  account_id?: number;
  closeModal: () => void;
  fetchData: () => void;
}) {
  const { removeUser, loading, errorMessage } = useTeamInvites();
  const [message, setMessage] = useState<string | null>(null);

  const handleRemoveUser = async () => {
    setMessage(null);

    const response = await removeUser({ user_id, account_id });
    if (response.result == 'success') {
      setMessage(response.msg);

      // refetch team members
      fetchData();

      setTimeout(() => {
        closeModal();
      }, 3000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Remove user'} width={'w-full md:w-1/2 lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Removing user from team...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <div>
              <div className={'flex gap-2 text-sm bg-amber-50 p-3 rounded-md'}>
                <div className={'w-1/6 flex justify-center'}>
                  <span>
                    <i className="fi fi-ss-triangle-warning text-2xl text-amber-500"></i>
                  </span>
                </div>
                <div className={'grid gap-2 text-sm'}>
                  <p className={'font-medium'}>
                    You are opting to remove this user from your account.
                  </p>
                  <p className={'text-xs'}>
                    Proceeding to delete will remove this user&apos;s access your websites.
                  </p>
                </div>
              </div>
              <div className={'grid gap-2'}>
                <div className={'mt-2 flex flex-col-reverse lg:flex-row justify-end gap-2'}>
                  <button
                    type={'button'}
                    onClick={closeModal}
                    className={`px-4 py-2 rounded text-gray-700 bg-gray-200 hover:bg-gray-300 text-xs`}>
                    Cancel
                  </button>
                  <button
                    type={'button'}
                    onClick={() => handleRemoveUser()}
                    disabled={loading}
                    className={`rounded hover:bg-red-600 font-medium px-4 py-2 text-xs bg-red-500 text-white`}>
                    {loading ? 'removing user..' : 'Yes,  remove'}
                  </button>
                </div>
                {errorMessage && <Error value={`${errorMessage}`} />}
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}
