import React from 'react';
import ReactPlayer from 'react-player';
import { WORDPRESS_INSTALLATION_VIDEO_URL } from '../../constants';

export default function WordpressWoocommerceInstruction() {
  return (
    <div>
      <div>
        <div className={'mb-3'}>
          <h3 className={'font-medium text-xl text-center'}>
            Video: How to Install the WordPress/WooCommerce Plugin
          </h3>
        </div>
        <div className={'flex flex-col justify-center items-center'}>
          <div className={'my-5 w-full h-[55vh]'}>
            <ReactPlayer
              width={'lg:100%'}
              height={'100%'}
              controls={true}
              url={WORDPRESS_INSTALLATION_VIDEO_URL}
            />
          </div>
        </div>
      </div>
      <div>
        <div className={'mb-3'}>
          <h3 className={'font-medium text-xl text-center'}>Installation Guide</h3>
        </div>
        <div className={'bg-white px-5 py-5 text-black text-left text-sm'}>
          <div className={'lg:h-[45vh] overflow-auto slim-scroll pr-2'}>
            <div>
              <h6 className={'text-sm my-3'}>
                These are the instructions on how to download the Heatmap.com WooCommerce plugin on
                your website:
              </h6>
            </div>
            <div className={'flex flex-col gap-2'}>
              <h6 className={'font-semibold text-sm mb-2'}>To add the snippet to your website:</h6>
              <h6 className={'text-sm'}>
                1. Download the Heatmap Woocommerce/WordPress Plugin as a .zip file.
              </h6>
              <h6 className={'text-sm italic font-medium'}>
                - You do not need to open the zip file, you can upload it directly to your store.
              </h6>
              <h6 className={'text-sm'}>
                2. From your WordPress dashboard, choose Plugins {'>'} Add New.
              </h6>
              <h6 className={'text-sm'}>3. Click Upload Plugin at the top of the page.</h6>
              <img
                width={300}
                src={'/assets/img/wp/wp1.png'}
                alt={''}
                className={'my-3 w-4/5 lg:w-full object-contain'}
              />
            </div>
            <div>
              <h6 className={'text-sm'}>
                4. Click Choose File, locate the plugin .zip file, and then click Install Now.
              </h6>
              <img
                width={300}
                src={'/assets/img/wp/wp2.png'}
                alt={''}
                className={'my-3 w-4/5 lg:w-full object-contain'}
              />
            </div>
            <div>
              <h6 className={'text-sm'}>
                5. After the installation is complete, click Activate Plugin.
              </h6>
              <img
                width={300}
                src={'/assets/img/wp/wp3.png'}
                alt={''}
                className={'my-3 w-4/5 lg:w-full object-contain'}
              />
            </div>
            <div>
              <h6 className={'text-sm'}>
                6. After a successful installation of the Heatmap-tracker plugin, it will be listed
                as part of the installed plugins in your plugin dashboard.
              </h6>
              <img
                width={300}
                src={'/assets/img/wp/wp4.png'}
                alt={''}
                className={'my-3 w-4/5 lg:w-full object-contain'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
