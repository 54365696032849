import React from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { CancellationChartType } from '../../../../interfaces/analytic';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function CancellationPlatformsChart({
  data,
  type
}: {
  data: any;
  type: CancellationChartType;
}) {
  let chartData;
  const cancelType =
    type === 1 ? 'attempted_cancellation' : type === 2 ? 'resolved_cancellation' : 'cancelled';

  if (data && cancelType) {
    chartData = [
      { name: 'Big Commerce', value: data.bigcommerce[cancelType] },
      { name: 'Shopify', value: data.shopify[cancelType] },
      { name: 'WooCommerce', value: data.woocommerce[cancelType] },
      { name: 'WebFlow', value: data.webflow[cancelType] },
      { name: 'Squarespace', value: data.squarespace[cancelType] },
      { name: 'Other', value: data.other[cancelType] }
    ];
  }

  const options = {
    elements: {
      bar: {
        borderWidth: 1.5
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: true,
        text: 'Platforms of cancelled subscriptions'
      }
    }
  };

  const dataStats = {
    labels: chartData && chartData.map((item, index) => item.name),
    datasets: [
      {
        label: 'Platform count',
        data: chartData && chartData.map((item, index) => item.value),
        backgroundColor: 'rgba(0, 129, 96, 0.3)',
        borderColor: 'rgb(0, 129, 96)'
      }
    ]
  };
  return <Bar options={options} data={dataStats as any} />;
}
