import React from 'react';
import { ITracking } from '../../../interfaces/global';

export default function WordpressWoocommerce({ trackingDetails }: { trackingDetails: ITracking }) {
  return (
    <div className={'flex justify-center'}>
      <div className={'w-4/5 bg-gray-100 p-10 flex flex-col gap-3'}>
        <div className={'flex gap-3'}>
          <div>
            <span>
              <i className="fi fi-rs-file text-xl"></i>
            </span>
          </div>
          <div>
            <p className={'font-medium text-sm'}>
              Heatmap plugin for{' '}
              {trackingDetails.platform == 'wordpress' ? 'WordPress' : 'WooCommerce'}
            </p>
            <p className={'text-sm text-gray-500 mt-1'}>19kb</p>
          </div>
        </div>
        <div className={'flex'}>
          <a
            href={'/heatmap-tracker.zip'}
            download={true}
            className={`w-full px-4 py-2 hover:bg-emerald-600 rounded text-white bg-[#008160] text-sm flex gap-2 justify-center`}>
            <span>
              <i className="fi fi-rr-download text-sm"></i>
            </span>
            <span>Download</span>
          </a>
        </div>
      </div>
    </div>
  );
}
