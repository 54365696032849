import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useSignOut } from 'react-auth-kit';
import { useLogout } from '../hooks/useLogout';
import { CLIENT_URL, ORGANIZATION_ID } from '../constants';
import { routes } from '../constants/routes';

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useLogoutUser() {
  const signOut = useSignOut();
  const { logout } = useLogout();

  const logoutUser = async () => {
    const response = await logout();
    if (response.result === 'success') {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
      localStorage.removeItem(ORGANIZATION_ID);
      localStorage.removeItem(CLIENT_URL);

      signOut();
      window.location.replace(routes.LOGIN);
    }
  };
  return { logoutUser };
}
