import { IAccountWebsite } from '../../interfaces/website';

export default function AccountWebsiteListCard({ data }: { data: IAccountWebsite }) {
  return (
    <div
      className={
        'border rounded-md border-gray-200 cursor-default hover:border-gray-400 bg-white hover:shadow p-3 md:p-3'
      }>
      <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-9 gap-2 lg:gap-5'}>
        <div className={'lg:col-span-5 flex gap-2'}>
          <div>
            <img
              className={'rounded w-6 h-6 object-contain'}
              src={`https://www.google.com/s2/favicons?domain=${data.main_url}&sz=128`}
              alt={''}
            />
          </div>
          <div className={'flex justify-between'}>
            <div className={'w-full'}>
              <div className={'flex gap-2 items-center flex-wrap'}>
                <h5 className={'font-medium text-base lg:text-sm capitalize truncate'}>
                  {data.name}
                </h5>
                {data.ecommerce === 1 && (
                  <>
                    <span className={'w-1 h-1 rounded-full bg-gray-400'}></span>
                    <span className={'font-normal text-sm lg:text-sm text-gray-500'}>
                      e-commerce
                    </span>
                  </>
                )}
              </div>
              <div className={'flex gap-1 my-1'}>
                <span>
                  <i className="fi fi-rr-link text-xs"></i>
                </span>
                <span className={'text-sm md:text-sm truncate'}>{data.main_url}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={'col-span-2'}>
          <div className={'flex flex-col gap-2 items-start md:justify-end lg:justify-start'}>
            <span
              className={
                'rounded-full px-2 py-1 leading-none text-xs bg-white text-[#008160] border border-[#008160] capitalize'
              }>
              {data.platform}
            </span>
          </div>
        </div>
        <div>
          <p>Site ID: {data.idsite}</p>
        </div>
        <div className={'flex justify-center'}>
          {data.verified == 1 ? (
            <span className={'text-[#008160] hidden md:flex'}>
              <i className="fi fi-ss-badge-check text-xl md:text-xl"></i>
            </span>
          ) : (
            <span className={'text-gray-500 hidden md:flex'}>
              <i className="fi fi-rr-badge-check text-gray-400 text-xl md:text-xl"></i>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
