import { useState } from 'react';
import { useAuthHeader } from 'react-auth-kit';
import { getRequest } from '../../../requests';
import axios from 'axios';
import { OPEN_AI_PROMPT_API_URL } from '../../../constants';

export const useAnalytics = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const loadAnalytics = async () => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.clientDashboardAnalytics',
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed loading analytics');
      setLoading(false);
    }
  };

  const loadAiInsight = async () => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await axios.get(OPEN_AI_PROMPT_API_URL);

      if (response.status === 200) {
        setLoading(false);
        return response;
      }
    } catch (error) {
      setErrorMessage('Failed processing data');
      setLoading(false);
    }
  };

  const loadAllAiInsightDocuments = async () => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await axios.get(OPEN_AI_PROMPT_API_URL);

      if (response.status === 200) {
        setLoading(false);
        return response;
      }
    } catch (error) {
      setErrorMessage('Failed loading data');
      setLoading(false);
    }
  };

  const updateAiInsight = async (data: any) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await axios.put(OPEN_AI_PROMPT_API_URL, data);

      if (response.status === 200) {
        setLoading(false);
        return response;
      }
    } catch (error) {
      setErrorMessage('Failed processing data');
      setLoading(false);
    }
  };

  const addAiInsight = async (data: any) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await axios.post(OPEN_AI_PROMPT_API_URL, data);

      if (response.status === 200) {
        setLoading(false);
        return response;
      }
    } catch (error) {
      setErrorMessage('Failed processing data');
      setLoading(false);
    }
  };

  const deleteAiInsight = async (data: any) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await axios.delete(OPEN_AI_PROMPT_API_URL, { data });

      if (response.status === 200) {
        setLoading(false);
        return response;
      }
    } catch (error) {
      setErrorMessage('Failed deleting document');
      setLoading(false);
    }
  };

  const activateAiInsight = async (data: any) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await axios.patch(OPEN_AI_PROMPT_API_URL, data);

      if (response.status === 200) {
        setLoading(false);
        return response;
      }
    } catch (error) {
      setErrorMessage('Failed activating document');
      setLoading(false);
    }
  };

  return {
    loadAllAiInsightDocuments,
    loadAnalytics,
    loadAiInsight,
    addAiInsight,
    activateAiInsight,
    updateAiInsight,
    deleteAiInsight,
    loading,
    errorMessage
  };
};
