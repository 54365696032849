import { useState } from 'react';
import { getRequest } from '../../../requests';

export const useVerifyEmail = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const verifyEmailAccount = async (code: string) => {
    setLoading(true);
    setErrorMessage(null);

    if (code === '' || !code) {
      setErrorMessage('Enter the OTP code sent to your email');
      setLoading(false);
    }

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.verify',
          ver_code: code,
          format: 'json'
        }
      });
      if (response.data.value) {
        setErrorMessage(response.data.value);
        setLoading(false);
      } else if (response.data.result === 'error' && (response.data.msg || response.data.message)) {
        setErrorMessage(response.data.msg || response.data.message);
        setLoading(false);
      } else if (response.data.result === 'error') {
        setErrorMessage('Enter the OTP code sent to your email');
        setLoading(false);
      } else if (response.data.result === 'success') {
        // localStorage.removeItem('email');
        setLoading(false);
        return response.data;
      }
      return response.data;
    } catch (e) {
      localStorage.removeItem('email');
      setErrorMessage('Error verifying email');
      setLoading(false);
    }
  };

  const verifyReset = async (code: string) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      if (code === '' || !code) {
        setLoading(false);
        setErrorMessage('Enter the OTP code sent to your email');
      }

      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.verifyReset',
          ver_code: code,
          email: localStorage.getItem('email'),
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result == 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error verifying email');
      setLoading(false);
    }
  };

  return { verifyEmailAccount, verifyReset, loading, errorMessage };
};
