import { useContext, useEffect, useState } from 'react';
import { useUserSites } from '../../../hooks/useUserSites';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import InviteUser from '../../../feature/TeamManagement/InviteUser';
import RemoveUserFromTeam from '../../../feature/TeamManagement/RemoveUserFromTeam';
import Layout from '../../../layouts/global/Layout';
import { ITeam, IUserProfile } from '../../../interfaces/user';
import { Link } from 'react-router-dom';
import { routes } from '../../../constants/routes';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import EditTeamMember from '../../../feature/TeamManagement/EditTeamMember';
import { AccountHolderSetup } from '../../../feature/AccountProfile/AccountHolderSetup';
import { IWebsiteSubscription } from '../../../interfaces/subscription';

export default function TeamManagement() {
  const { loadUserProfile, loading } = useUserSites();
  const {
    selectedOrganization,
    setSelectedOrganization,
    profile,
    accountHolderSetupOpen,
    setAccountHolderSetupOpen
  } = useContext(OrganizationContext);

  const [userProfile, setUserProfile] = useState<IUserProfile | null>(null);
  const [team, setTeam] = useState<ITeam[] | null>(null);
  const [inviteOpen, setInviteOpen] = useState<boolean>(false);

  const [removeOpen, setRemoveOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<ITeam | null>(null);

  const fetchTeamMembers = () => {
    loadUserProfile({
      team: true,
      account_id: selectedOrganization && selectedOrganization.account_id
    }).then((res) => {
      if (res?.profile_details) {
        setUserProfile(res.profile_details);
        setTeam(res.team);
      }
    });
  };

  useEffect(() => {
    if (selectedOrganization) {
      fetchTeamMembers();
    } else {
      setSelectedOrganization(profile.organizations[0]);
    }
  }, [selectedOrganization && selectedOrganization.account_id]);

  const handleInitiateInvitation = () => {
    if (profile.profile_details?.account_holder == 0) {
      setAccountHolderSetupOpen(true);
    } else {
      setInviteOpen(true);
    }
  };

  return (
    <Layout>
      <>
        {inviteOpen && profile && (
          <InviteUser
            account_id={selectedOrganization.account_id}
            fetchData={fetchTeamMembers}
            websites={
              profile.subscription_details?.subscription.filter(
                (web) => web.website
              ) as IWebsiteSubscription[]
            }
            closeModal={() => setInviteOpen(false)}
          />
        )}
        {removeOpen && selected && (
          <RemoveUserFromTeam
            fetchData={fetchTeamMembers}
            user_id={selected.user_id}
            closeModal={() => setRemoveOpen(false)}
          />
        )}
        {editOpen && selected && (
          <EditTeamMember
            fetchData={fetchTeamMembers}
            user_id={selected.user_id}
            currentAccess={selected.access}
            websites={
              profile.subscription_details?.subscription.filter(
                (web) => web.website
              ) as IWebsiteSubscription[]
            }
            invited_sites={selected.invited_sites}
            closeModal={() => setEditOpen(false)}
          />
        )}
        {accountHolderSetupOpen && (
          <AccountHolderSetup closeModal={() => setAccountHolderSetupOpen(false)} />
        )}
        <div className={'lg:col-span-4'}>
          {loading ? (
            <div className={'flex h-[80vh] gap-2 flex-col justify-center items-center text-center'}>
              <LoadingSpinner color={'text-[#008160]'} />
              <p>Loading team members</p>
            </div>
          ) : (
            <div className={'rounded-md shadow-sm bg-white text-gray-500'}>
              <div className={'flex justify-between items-center gap-5'}>
                <h6 className={'font-semibold md:font-medium text-base lg:text-base'}>
                  Manage users
                </h6>
                {/*{(userProfile?.account_holder == 1 ||*/}
                {/*  profile.profile_details?.permission == 'write') && (*/}
                <div className={'flex gap-3'}>
                  <button
                    type={'button'}
                    onClick={() => setInviteOpen(true)}
                    className={
                      'rounded px-3 py-2 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                    }>
                    <span>
                      <i className="fi fi-rr-user-add text-xs"></i>
                    </span>
                    <span className={'text-xs'}>Invite members</span>
                  </button>
                  <Link
                    to={routes.ACCOUNTS}
                    className={
                      'rounded px-3 py-2 flex items-center gap-2 border border-[#008160] hover:bg-emerald-100 text-[#008160]'
                    }>
                    <span>
                      <i className="fi fi-rr-lock text-xs"></i>
                    </span>
                    <span className={'text-xs'}>Manage Accounts</span>
                  </Link>
                </div>
                {/*)}*/}
              </div>
              <hr className={'my-2 md:my-4'} />
              <div className={'my-2 w-full border border-gray-200'}>
                <div
                  className={
                    'hidden md:grid grid-cols-12 gap-2 font-medium text-sm mb-2 p-2 bg-gray-100'
                  }>
                  <div className={'col-span-3'}>
                    <div>
                      <h5>Name</h5>
                    </div>
                  </div>
                  <div className={'col-span-3'}>
                    <h5>Email address</h5>
                  </div>
                  <div className={'col-span-4'}>
                    <h5>Websites</h5>
                  </div>
                  <div className={'col-span-1'}>
                    <h5>Access level</h5>
                  </div>
                  <div className={'col-span-1'}></div>
                </div>
                {team &&
                  team.length > 0 &&
                  team.map((item, index) => (
                    <div
                      key={index}
                      className={
                        'grid md:grid-cols-12 text-sm gap-2 px-2 py-3 border-b border-gray-200'
                      }>
                      <div className={'md:col-span-3'}>
                        <div>
                          <h5 className={'font-medium'}>{item.full_name}</h5>
                        </div>
                      </div>
                      <div className={'md:col-span-3 flex flex-col gap-1'}>
                        <h5>{item.email}</h5>
                        <div>
                          <span
                            className={`${
                              item.status === 'active'
                                ? 'bg-emerald-100 text-emerald-600'
                                : 'bg-yellow-100 text-yellow-600'
                            }  px-2 py-1 text-xs rounded-full uppercase`}>
                            {item.status}
                          </span>
                        </div>
                      </div>
                      <div className={'md:col-span-4 flex items-start flex-wrap gap-2'}>
                        {item.invited_sites &&
                        item.invited_sites.length ==
                          profile.subscription_details?.subscription.length ? (
                          <span>All websites</span>
                        ) : item.invited_sites.length == 0 ? (
                          <span className={'italic'}>Not assigned any website</span>
                        ) : (
                          item.invited_sites.map((site, index: number) => (
                            <a
                              href={site?.main_url}
                              target={'_blank'}
                              key={index}
                              className={
                                'rounded bg-gray-100 hover:bg-gray-200 flex items-center gap-2 p-1'
                              }
                              rel="noreferrer">
                              <span>
                                <img
                                  className={'rounded-sm w-6 h-6 object-contain drop-shadow-sm'}
                                  src={`https://www.google.com/s2/favicons?domain=${site?.main_url}&sz=128`}
                                  alt={''}
                                />
                              </span>
                              <span className={'text-xs font-medium'}>{site?.main_url}</span>
                            </a>
                          ))
                        )}
                      </div>
                      <div className={'md:col-span-1'}>
                        {profile && profile.profile_details?.permission == 'admin' ? (
                          <span
                            className={
                              'capitalize border border-emerald-500 bg-emerald-50 text-emerald-500 px-2 py-1 rounded text-xs'
                            }>
                            {item.access}
                          </span>
                        ) : (
                          <span>Restricted</span>
                        )}
                      </div>
                      <div className={'col-span-1 flex md:justify-center'}>
                        {profile && profile.profile_details?.permission == 'admin' && (
                          <div className={'flex items-start gap-2'}>
                            <button
                              title={'Update'}
                              type={'button'}
                              onClick={() => {
                                setSelected(item);
                                setEditOpen(true);
                              }}
                              className={
                                'edit rounded px-2 py-1 flex items-start gap-2 border border-transparent hover:border-[#008160] bg-emerald-50 text-[#008160] text-sm'
                              }>
                              <span>
                                <i className="edit fi fi-rr-pencil text-sm"></i>
                              </span>
                            </button>
                            <button
                              type={'button'}
                              onClick={() => {
                                setSelected(item);
                                setRemoveOpen(true);
                              }}
                              className={
                                'rounded px-2 pt-1 items-start border border-transparent hover:border-red-500 bg-red-50 text-red-500 text-sm flex gap-2'
                              }>
                              <span>
                                <i className="fi fi-rr-trash text-base text-red-500"></i>
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                {profile &&
                  selectedOrganization &&
                  profile.profile_details?.account_id === selectedOrganization.account_id && (
                    <div className={'grid md:grid-cols-12 text-sm gap-2 p-2'}>
                      <div className={'md:col-span-3'}>
                        <div>
                          <h5>{userProfile?.full_name}</h5>
                        </div>
                      </div>
                      <div className={'md:col-span-3'}>
                        <h5>{userProfile?.email}</h5>
                      </div>
                      <div className={'md:col-span-6'}>
                        {userProfile?.account_holder == 1 ? (
                          <h5 className={'text-sm font-medium'}>Account holder</h5>
                        ) : (
                          <h5 className={'text-sm font-medium'}>Collaborator</h5>
                        )}
                      </div>
                      <div className={'col-span-1'}></div>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </>
    </Layout>
  );
}
