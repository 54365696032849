import { useContext, useState } from 'react';
import { ILimitReportItem } from '../../interfaces/subscription';
import ExceededLimitWebsiteListCard from './ExceededLimitWebsiteListCard';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { IUserWebsite } from '../../interfaces/website';

export default function SessionLimitReports({
  closeModal,
  data
}: {
  data: ILimitReportItem[];
  closeModal: () => void;
}) {
  const [tab, setTab] = useState<number>(1);

  const { profile } = useContext(OrganizationContext);

  const limitReportTabs = [
    {
      id: 1,
      name: 'Sessions'
    }
    // {
    //   id: 2,
    //   name: 'Revenues'
    // },
    // {
    //   id: 3,
    //   name: 'Teams'
    // }
  ];

  const WEBSITES_WITH_EXCEEDED_SESSION_LIMIT = profile.subscription_details?.subscription.filter(
    (item) => {
      return data.some(
        (exceededItem) =>
          exceededItem.session.limit_hit == 'yes' && exceededItem.idSite === item.idsite
      );
    }
  );

  const WEBSITES_WITH_EXCEEDED_REVENUE_LIMIT = profile.subscription_details?.subscription.filter(
    (item) => {
      return data.some(
        (exceededItem) =>
          exceededItem.revenue.limit_hit == 'yes' && exceededItem.idSite === item.idsite
      );
    }
  );

  const WEBSITES_WITH_EXCEEDED_TEAMS_LIMIT = profile.subscription_details?.subscription.filter(
    (item) => {
      return data.some(
        (exceededItem) =>
          exceededItem.teams.limit_hit == 'yes' && exceededItem.idSite === item.idsite
      );
    }
  );

  return (
    <div
      className="relative z-10"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div className="pointer-events-auto relative w-[60vw]">
              <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                <button
                  type="button"
                  onClick={closeModal}
                  className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="sr-only">Close panel</span>
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <h2
                    className="text-base font-semibold leading-6 text-gray-900"
                    id="slide-over-title">
                    <span>Exceeded Limits Report</span>
                  </h2>
                  <p className={'text-sm'}>
                    The list of websites that have exceed their sessions limit
                  </p>
                </div>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <div className={'flex flex-col gap-5'}>
                    <div className={'flex gap-4'}>
                      {limitReportTabs &&
                        limitReportTabs.map((item, index) => (
                          <button
                            type={'button'}
                            onClick={() => setTab(item.id)}
                            key={index}
                            className={`${
                              tab === item.id
                                ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                                : 'border-transparent'
                            } py-2 border-b-2 hover:border-gray-300 font-medium text-sm flex gap-3`}>
                            <span className={'text-sm'}>{item.name}</span>
                          </button>
                        ))}
                    </div>
                    <div>
                      {tab == 1 && (
                        <div className={'flex flex-col gap-2'}>
                          {profile &&
                          WEBSITES_WITH_EXCEEDED_SESSION_LIMIT &&
                          WEBSITES_WITH_EXCEEDED_SESSION_LIMIT.length > 0 ? (
                            WEBSITES_WITH_EXCEEDED_SESSION_LIMIT.map((item, index) => (
                              <ExceededLimitWebsiteListCard key={index} data={item} />
                            ))
                          ) : (
                            <span className={'mt-2 italic text-sm'}>
                              No website exceeded session limit
                            </span>
                          )}
                        </div>
                      )}
                      {tab == 2 && (
                        <div className={'flex flex-col gap-2'}>
                          {profile &&
                          WEBSITES_WITH_EXCEEDED_REVENUE_LIMIT &&
                          WEBSITES_WITH_EXCEEDED_REVENUE_LIMIT.filter((web) => web.website).length >
                            0 ? (
                            WEBSITES_WITH_EXCEEDED_REVENUE_LIMIT.filter((web) => web.website).map(
                              (item, index) => (
                                <ExceededLimitWebsiteListCard key={index} data={item} />
                              )
                            )
                          ) : (
                            <span className={'mt-2 italic text-sm'}>
                              No website exceeded revenue limit
                            </span>
                          )}
                        </div>
                      )}
                      {tab == 3 && (
                        <div className={'flex flex-col gap-2'}>
                          {profile &&
                          WEBSITES_WITH_EXCEEDED_TEAMS_LIMIT &&
                          WEBSITES_WITH_EXCEEDED_TEAMS_LIMIT.length > 0 ? (
                            WEBSITES_WITH_EXCEEDED_TEAMS_LIMIT.map((item, index) => (
                              <ExceededLimitWebsiteListCard key={index} data={item} />
                            ))
                          ) : (
                            <span className={'mt-2 italic text-sm'}>
                              No website exceeded teams limit
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
