import React from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ChartPeriod, ChartType, IChart } from '../../../../interfaces/analytic';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function MonthlySubscription({
  data,
  type,
  period
}: {
  data: IChart;
  type: ChartType;
  period: ChartPeriod;
}) {
  const options = {
    elements: {
      bar: {
        borderWidth: 1.5
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: true,
        text:
          type == ChartType.subscription && period == ChartPeriod.annual
            ? 'Monthly User Subscriptions'
            : type == ChartType.subscription && period == ChartPeriod.week
            ? 'Daily User Subscriptions'
            : type == ChartType.user && period == ChartPeriod.annual
            ? 'Monthly User Accounts Created'
            : type == ChartType.user && period == ChartPeriod.week
            ? 'Daily User Accounts Created'
            : type == ChartType.cancelled_subscriptions && period == ChartPeriod.annual
            ? 'Monthly Subscriptions Cancelled'
            : type == ChartType.cancelled_subscriptions && period == ChartPeriod.week
            ? 'Daily Subscriptions Cancelled'
            : type == ChartType.waitlist && period == ChartPeriod.annual
            ? 'Monthly Waitlist Accounts'
            : 'Daily Waitlist Accounts'
      }
    }
  };

  const dataStats = {
    labels:
      type == ChartType.subscription && period == ChartPeriod.annual
        ? Object.keys(data.annual.subscriptions)
        : type == ChartType.subscription && period == ChartPeriod.week
        ? Object.keys(data.weekly.subscriptions)
        : type == ChartType.user && period == ChartPeriod.annual
        ? Object.keys(data.annual.users)
        : type == ChartType.user && period == ChartPeriod.week
        ? Object.keys(data.weekly.users)
        : type == ChartType.cancelled_subscriptions && period == ChartPeriod.annual
        ? Object.keys(data.annual.cancelled_subscriptions)
        : type == ChartType.cancelled_subscriptions && period == ChartPeriod.week
        ? Object.keys(data.weekly.cancelled_subscriptions)
        : type == ChartType.waitlist && period == ChartPeriod.annual
        ? Object.keys(data.annual.waitlist)
        : Object.keys(data.weekly.waitlist),
    datasets: [
      {
        label:
          type == ChartType.subscription
            ? 'Website Subscriptions'
            : type == ChartType.waitlist
            ? 'Waitlist Accounts'
            : type == ChartType.cancelled_subscriptions
            ? 'Cancelled Subscriptions'
            : 'User accounts',
        data:
          type == ChartType.subscription && period == ChartPeriod.annual
            ? Object.values(data.annual.subscriptions)
            : type == ChartType.subscription && period == ChartPeriod.week
            ? Object.values(data.weekly.subscriptions)
            : type == ChartType.user && period == ChartPeriod.annual
            ? Object.values(data.annual.users)
            : type == ChartType.user && period == ChartPeriod.week
            ? Object.values(data.weekly.users)
            : type == ChartType.cancelled_subscriptions && period == ChartPeriod.annual
            ? Object.values(data.annual.cancelled_subscriptions)
            : type == ChartType.cancelled_subscriptions && period == ChartPeriod.week
            ? Object.values(data.weekly.cancelled_subscriptions)
            : type == ChartType.waitlist && period == ChartPeriod.annual
            ? Object.values(data.annual.waitlist)
            : Object.values(data.weekly.waitlist),
        backgroundColor: 'rgba(0, 129, 96, 0.3)',
        borderColor: 'rgb(0, 129, 96)'
      }
    ]
  };
  return <Bar options={options} data={dataStats} />;
}
