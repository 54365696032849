import { useState } from 'react';

export default function Snippet({ snippet }: { snippet: string }) {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(snippet as string)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="rounded-md md:rounded-lg p-4 my-2 bg-gray-200 w-full lg:w-full max-h-[15vh] overflow-y-auto slim-scroll">
      <div className={'float-right sticky-component flex justify-end gap-2 mb-2'}>
      <button
  type={'button'}
  onClick={handleCopyClick}
  className={
    'text-sm px-2 py-1 rounded text-white bg-[#008160] hover:bg-emerald-500 flex items-start gap-1'
  }
>
  <span className={'text-sm'}>{isCopied ? 'Copied!' : 'Copy'}</span>
  {!isCopied && (
    <span>
      <i className="fi fi-rr-duplicate text-xs"></i>
    </span>
  )}
</button>
      </div>
      <code className={'text-sm text-gray-600 overflow-y-auto'}>{snippet}</code>
    </div>
  );
}
