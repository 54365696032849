import { useRef } from 'react';
import useClickOutside from '../../../../hooks/useClickOutside';
import { IUserType } from '../../../../interfaces/user';

export default function UserTypesPopover({
  onClickOutside,
  show,
  hideUserTypes,
  setSelectedUserType,
  options
}: {
  onClickOutside?: any;
  hideUserTypes: () => void;
  show: boolean;
  setSelectedUserType: (item: IUserType) => void;
  options: IUserType[];
}) {
  if (!show) return null;

  const ref: any = useRef(null);
  useClickOutside(ref, onClickOutside);

  return (
    <div ref={ref} className={'absolute top-0 left-0 bg-white w-full drop-shadow-lg'}>
      <p className={'font-medium text-sm px-3 pt-3 pb-2'}>Select user type</p>
      <hr className={'m-1'} />
      <div>
        {options &&
          options.map((item, index) => (
            <div
              onClick={() => {
                setSelectedUserType(item);
                hideUserTypes();
              }}
              key={index}
              className={
                'px-4 py-3 flex items-start gap-3 cursor-pointer hover:bg-emerald-50 border-b border-gray-100'
              }>
              <div>
                <h6 className={'text-sm font-medium'}>{item.name}</h6>
                <p className={'text-xs'}>{item.description}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
