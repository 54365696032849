import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import Plans from '../pages/main/Plans';

export const ProtectedRoute = ({ children, loginPath }: any) => {
  const authHeader = useAuthHeader();
  const auth = useAuthUser();

  const location = useLocation();

  const [tokenType, token] = authHeader().split(' ');

  /*
  Redirects new users to the index page to buy first Subscription
   */
  if (token && auth()?.newUser) {
    return <Plans />;
  } else if (token) {
    return children;
  }

  return <Navigate to={loginPath} state={{ from: location }} replace />;
};

export default ProtectedRoute;
