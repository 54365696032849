import { Link, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState, useRef } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import WebsiteListCard from '../../../feature/Websites/WebsiteListCard';
import Layout from '../../../layouts/global/Layout';
import SearchWebsites from '../../../components/form/SearchWebsites';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { routes } from '../../../constants/routes';
import PageTitle from '../../../components/global/PageTitle';
import { AccountHolderSetup } from '../../../feature/AccountProfile/AccountHolderSetup';
import { IWebsiteSubscription } from '../../../interfaces/subscription';

export default function Websites() {
  const [searchResults, setSearchResults] = useState<IWebsiteSubscription[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;

  const {
    profile,
    loading: profileLoading,
    setAccountHolderSetupOpen,
    accountHolderSetupOpen
  } = useContext(OrganizationContext);

  const navigate = useNavigate();

  const currentUrl = useRef(window.location.href);
  const getItemFromUrl = (item: string) => {
    const parsedUrl = new URL(currentUrl.current);
    const searchParams = new URLSearchParams(parsedUrl.search);
    const hashParams = new URLSearchParams(parsedUrl.hash.slice(1));
    return searchParams.get(item) || hashParams.get(item) || "";
  };

  const currentUserWebsite = searchResults?.find(data => 
    data.idsite === +getItemFromUrl('siteId') && 
    data.plan === +getItemFromUrl('planId') && 
    'can_upgrade' in data && 
    data.can_upgrade === true && 
    getItemFromUrl('is_upgrade') === '1'
  );

  useEffect(() => {
    setLoading(true);
    if (profile && profile.subscription_details?.subscription) {
      setSearchResults(profile.subscription_details.subscription);
      setLoading(false);
    }
  }, [profile]);

  const handleInitiateAddWebsite = () => {
    if (profile.profile_details?.account_holder === 0) {
      setAccountHolderSetupOpen(true);
    } else {
      navigate(
        profile.subscription_details &&
        profile.subscription_details.subscription.some((item) => item.idsite === 0)
          ? routes.SETUP
          : routes.PRICING
      );
    }
  };

  const filteredWebsites = searchResults.filter((web) => web.website);
  const totalPages = Math.ceil(filteredWebsites.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredWebsites.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginationDisplay = () => {
    if (totalPages <= 1) return null;

    const renderPageNumbers = () => {
      const pageNumbers = [];
      let startPage = Math.max(1, currentPage - 1);
      const endPage = Math.min(totalPages, startPage + 2);

      if (endPage - startPage < 2) {
        startPage = Math.max(1, endPage - 2);
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (startPage > 1) {
        pageNumbers.unshift('...');
        pageNumbers.unshift(1);
      }

      if (endPage < totalPages) {
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }

      return pageNumbers.map((pageNumber, index) => {
        if (pageNumber === '...') {
          return (
            <li key={index}>
              <span className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border-t border-b border-gray-300">
                {pageNumber}
              </span>
            </li>
          );
        }

        return (
          <li key={index}>
            <button
              onClick={() => handlePageChange(Number(pageNumber))}
              className={`flex items-center justify-center px-4 h-10 leading-tight ${
                currentPage === pageNumber
                  ? 'text-emerald-600 border border-r-2 border-emerald-300 bg-emerald-50 hover:bg-emerald-100 hover:text-emerald-700'
                  : 'text-gray-500 bg-white border-t border-b border-gray-300 hover:bg-gray-100 hover:text-gray-700'
              }`}
            >
              {pageNumber}
            </button>
          </li>
        );
      });
    };

    return (
      <div className="flex justify-end -bottom-5 sticky bg-white py-3">
        <nav aria-label="Page navigation">
          <ul className="flex items-center -space-x-px h-10 text-base">
            <li>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className={`flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white rounded-l border border-r-2 border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-s-lg ${
                  currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''
                }`}
                disabled={currentPage === 1}
              >
                <svg
                  className="w-3 h-3 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 1 1 5l4 4"
                  />
                </svg>
                <span className="ml-2">Previous</span>
              </button>
            </li>
            {renderPageNumbers()}
            <li>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white rounded-r border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 ${
                  currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''
                }`}
                disabled={currentPage === totalPages}
              >
                <span className="mr-2">Next</span>
                <svg
                  className="w-3 h-3 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  };

  return (
    <Layout>
      {accountHolderSetupOpen && (
        <AccountHolderSetup closeModal={() => setAccountHolderSetupOpen(false)} />
      )}
      <PageTitle title={'Websites'} />
      <div>
        <div className={'flex items-start justify-between gap-5'}>
          <SearchWebsites
            websites={profile?.subscription_details?.subscription || []}
            setSearchResults={setSearchResults}
          />

          {profile && profile.profile_details?.super_user !== 1 && (
            <div>
              {profile.subscription_details?.subscription && profile.subscription_details.subscription.length > 0 && (
                <div className={'hidden lg:flex gap-3'}>
                  <button
                    onClick={handleInitiateAddWebsite}
                    className={'rounded px-4 py-2 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'}
                  >
                    <span>
                      <i className="fi fi-rr-plus text-xs"></i>
                    </span>
                    <span className={'text-xs'}>Add a website</span>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={'my-3 flex flex-col gap-2 h-[70vh] overflow-auto slim-scroll pr-2'}>
          {loading ? (
            <div className={'p-5 h-[70vh] flex flex-col justify-center items-center text-center'}>
              <LoadingSpinner color={'text-[#008160]'} />
              <p>Loading websites...</p>
            </div>
          ) : currentItems.length > 0 ? (
            currentItems.map((item, index) => (
              <WebsiteListCard
                data={item}
                key={index}
                currentUserWebsite={currentUserWebsite}
                shouldUpgrade={item === currentUserWebsite}
              />
            ))
          ) : (
            <div className={'h-[60vh] flex flex-col justify-center items-center text-center'}>
              <h4 className={'font-medium mt-5'}>No website found</h4>
              <div className={'mt-3 flex'}>
                <button
                  type={'button'}
                  onClick={handleInitiateAddWebsite}
                  className={'rounded px-4 py-2 flex items-center gap-2 bg-[#008160] text-white font-medium'}
                >
                  <span>
                    <i className="fi fi-rr-plus text-xs"></i>
                  </span>
                  <span className={'text-xs'}>Add a website</span>
                </button>
              </div>
            </div>
          )}
        </div>
        {paginationDisplay()}
        <Link
          to={routes.PRICING}
          className={'fixed lg:hidden bottom-5 pt-1 right-5 w-14 h-14 shadow-md rounded-full bg-[#008160] hover:bg-emerald-600 flex justify-center items-center text-white'}
        >
          <i className="fi fi-rr-plus text-xl"></i>
        </Link>
      </div>
    </Layout>
  );
}