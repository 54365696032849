import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useUserSites } from '../../../hooks/useUserSites';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import ActiveSubscriptionListCard from '../../../feature/Dashboard/Subscription/ActiveSubscriptionListCard';
import PendingUnusedSubscription from '../../../layouts/dashboard/PendingUnusedSubscription';
import Layout from '../../../layouts/global/Layout';
import { useAuthUser } from 'react-auth-kit';
import { IUserWebsite } from '../../../interfaces/website';
import { ISubscription, IWebsiteSubscription } from '../../../interfaces/subscription';
import { routes } from '../../../constants/routes';
import PageTitle from '../../../components/global/PageTitle';
import SearchWebsites from '../../../components/form/SearchWebsites';

export default function UserSubscriptions() {
  const [tab, setTab] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<IWebsiteSubscription[] | []>([]);
  const [menuOptionOpen, setMenuOptionOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;

  const { loadUserProfile, errorMessage } = useUserSites();

  const navigate = useNavigate();
  const auth = useAuthUser();

  const [userWebsites, setUserWebsites] = useState<IWebsiteSubscription[] | []>([]);
  const [subscription, setSubscription] = useState<ISubscription>();

  useEffect(() => {
    setLoading(true);
    loadUserProfile({}).then((res) => {
      setUserWebsites(res.subscription_details?.subscription);
      setSearchResults(res.subscription_details?.subscription);
      setSubscription(res.subscription_details);
      setLoading(false);
      if (
        auth()?.super_user == 0 &&
        res.profile_details.days_left == 0 &&
        res.subscription_details?.subscription.length == 0
      )
        navigate(routes.PRICING);
    });
  }, []);

  const subscriptionTabs = [
    {
      name: 'Subscriptions with Websites',
      description: 'All Subscriptions with websites attached',
      id: 1,
      total: subscription?.subscription
        ? subscription?.subscription.filter((item) => item.idsite != 0).length
        : 0,
      active: subscription?.subscription
        ? subscription?.subscription.filter((item) => item.website && item.status == 'active')
          .length
        : 0,
      paused: subscription?.subscription
        ? subscription?.subscription.filter((item) => item.website && item.status == 'paused')
          .length
        : 0,
      cancelled: subscription?.subscription
        ? subscription?.subscription.filter((item) => item.website && item.is_cancelled == 1).length
        : 0
    },
    {
      name: 'Subscriptions without Websites',
      description: 'All Subscriptions without websites attached',
      id: 2,
      total:
        (auth()?.account_holder == 1 || auth()?.permission == 'write') &&
          subscription?.subscription.filter((item) => !item.website).length
          ? subscription?.subscription.filter((item) => !item.website).length
          : 0,
      active:
        (auth()?.account_holder == 1 || auth()?.permission == 'write') &&
          subscription?.subscription.filter((item) => !item.website && item.status == 'active').length
          ? subscription?.subscription.filter((item) => !item.website && item.status == 'active')
            .length
          : 0,
      paused:
        (auth()?.account_holder == 1 || auth()?.permission == 'write') &&
          subscription?.subscription.filter((item) => !item.website && item.status == 'paused').length
          ? subscription?.subscription.filter((item) => !item.website && item.status == 'paused')
            .length
          : 0,
      cancelled:
        (auth()?.account_holder == 1 || auth()?.permission == 'write') &&
          subscription?.subscription.filter((item) => !item.website && item.is_cancelled == 1).length
          ? subscription?.subscription.filter((item) => !item.website && item.is_cancelled == 1)
            .length
          : 0
    }
  ];

  const filteredWebsites = searchResults.filter((web) => web.website);
  const totalPages = Math.ceil(filteredWebsites.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredWebsites.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginationDisplay = () => {
    if (totalPages <= 1) return null;

    const renderPageNumbers = () => {
      const pageNumbers = [];
      let startPage = Math.max(1, currentPage - 1);
      const endPage = Math.min(totalPages, startPage + 2);

      if (endPage - startPage < 2) {
        startPage = Math.max(1, endPage - 2);
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (startPage > 1) {
        pageNumbers.unshift('...');
        pageNumbers.unshift(1);
      }

      if (endPage < totalPages) {
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }

      return pageNumbers.map((pageNumber, index) => {
        if (pageNumber === '...') {
          return (
            <li key={index}>
              <span className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border-t border-b border-gray-300">
                {pageNumber}
              </span>
            </li>
          );
        }

        return (
          <li key={index}>
            <button
              onClick={() => handlePageChange(Number(pageNumber))}
              className={`flex items-center justify-center px-4 h-10 leading-tight ${currentPage === pageNumber
                  ? 'text-emerald-600 border border-r-2 border-emerald-300 bg-emerald-50 hover:bg-emerald-100 hover:text-emerald-700'
                  : 'text-gray-500 bg-white border-t border-b border-gray-300 hover:bg-gray-100 hover:text-gray-700'
                }`}
            >
              {pageNumber}
            </button>
          </li>
        );
      });
    };

    return (
      <div className="flex justify-end -bottom-5 sticky bg-white py-3">
        <nav aria-label="Page navigation">
          <ul className="flex items-center -space-x-px h-10 text-base">
            <li>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className={`flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white rounded-l border border-r-2 border-gray-300 hover:bg-gray-100 hover:text-gray-700 rounded-s-lg ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''
                  }`}
                disabled={currentPage === 1}
              >
                <svg
                  className="w-3 h-3 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 1 1 5l4 4"
                  />
                </svg>
                <span className="ml-2">Previous</span>
              </button>
            </li>
            {renderPageNumbers()}
            <li>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white rounded-r border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''
                  }`}
                disabled={currentPage === totalPages}
              >
                <span className="mr-2">Next</span>
                <svg
                  className="w-3 h-3 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  };

  return (
    <>
      <Layout>
        <PageTitle title={'Subscriptions'} />

        <div>
          <div>
            <div className={'w-full grid grid-cols-2 gap-3 mb-5'}>
              {subscriptionTabs &&
                subscriptionTabs.map((item, index) => (
                  <div
                    onClick={() => setTab(item.id)}
                    key={index}
                    className={`${tab === item.id
                        ? 'border-emerald-500 bg-emerald-50 text-[#008160] hover:border-emerald-500'
                        : 'border-transparent hover:border-gray-300 drop-shadow'
                      } cursor-pointer p-3 border-2 rounded-md bg-white font-medium text-sm w-full flex flex-col justify-between gap-3`}>
                    <span className={'flex flex-col'}>
                      <span className={'text-xs md:text-sm font-medium'}>{item.name}</span>
                      <span className={'text-xs md:text-xs font-light'}>{item.description}</span>
                    </span>
                    <span className={'w-full hidden lg:grid lg:grid-cols-4 gap-2'}>
                      <span
                        className={`rounded-md bg-white p-2 flex flex-col gap-1 border-l-2 border-emerald-500 shadow`}>
                        <span className={'text-xs'}>Total</span>
                        <span className={'text-2xl lg:text-xl font-medium'}>{item.total}</span>
                      </span>
                      <span
                        className={`rounded-md bg-white p-2 flex flex-col gap-1 border-l-2 border-emerald-500 shadow`}>
                        <span className={'text-xs'}>Active</span>
                        <span className={'text-2xl lg:text-xl font-medium'}>{item.active}</span>
                      </span>
                      <span
                        className={`rounded-md bg-white p-2 flex flex-col gap-1 border-l-2 border-yellow-500 shadow`}>
                        <span className={'text-xs'}>Paused</span>
                        <span className={'text-2xl lg:text-xl font-medium'}>{item.paused}</span>
                      </span>
                      <span
                        className={`rounded-md bg-white p-2 flex flex-col gap-1 border-l-2 border-red-500 shadow`}>
                        <span className={'text-xs'}>PNDG. Cancelled</span>
                        <span className={'text-2xl lg:text-xl font-medium'}>{item.cancelled}</span>
                      </span>
                    </span>
                  </div>
                ))}
            </div>

            <div>
              {loading ? (
                <div className={'h-[60vh] flex flex-col justify-center items-center mb-5'}>
                  <LoadingSpinner color={'text-[#008160]'} />
                  <p>Loading subscriptions...</p>
                </div>
              ) : subscription && subscription.subscription?.length > 0 ? (
                <div>
                  {tab === 1 && userWebsites.length > 0 ? (
                    <div>
                      <div className={'flex items-start justify-between gap-5 mb-3'}>
                        <SearchWebsites
                          websites={userWebsites && userWebsites}
                          setSearchResults={setSearchResults}
                        />
                      </div>
                      <div className={'w-full flex flex-col gap-2'}>
                        {currentItems.map((item: any, index: number) => (
                          <ActiveSubscriptionListCard key={index} item={item} />
                        ))}
                      </div>
                      {paginationDisplay()}
                    </div>
                  ) : tab === 1 && userWebsites.length == 0 ? (
                    <div
                      className={'w-full p-5 h-[50vh] flex flex-col justify-center items-center'}>
                      <img
                        src={'/assets/analytics.png'}
                        width={250}
                        height={250}
                        className={'w-[12vw]'}
                        alt={''}
                      />
                      <h4 className={'font-normal mt-5'}>
                        You currently do not have any website added.
                      </h4>
                      <div className={'mt-3 flex'}>
                        <Link
                          to={
                            subscription &&
                              subscription?.subscription.filter((item) => !item.website).length > 0
                              ? routes.SETUP
                              : routes.PRICING
                          }
                          className={
                            'rounded px-4 py-2 flex items-start gap-2 bg-[#008160] text-white font-medium'
                          }>
                          <span>
                            <i className="fi fi-rr-plus text-sm"></i>
                          </span>
                          <span className={'text-sm'}>Add a website</span>
                        </Link>
                      </div>
                    </div>
                  ) : null}

                  {tab === 2 &&
                    (auth()?.account_holder == 1 || auth()?.permission == 'write') &&
                    subscription.subscription.filter((item) => !item.website).length > 0 ? (
                    <PendingUnusedSubscription userSubscription={subscription as ISubscription} />
                  ) : tab === 2 &&
                    (auth()?.account_holder == 1 || auth()?.permission == 'write') &&
                    subscription.subscription.filter((item) => item.idsite == 0).length == 0 ? (
                    <div>
                      <p>No pending subscriptions</p>
                    </div>
                  ) : null}

                  {tab === 2 && auth()?.permission == 'read' && (
                    <div
                      className={
                        'h-[60vh] p-10 flex gap-2 flex-col justify-center items-center text-center'
                      }>
                      <p>You do not have access to pending subscriptions.</p>
                      <p className={'text-sm text-amber-600'}>
                        Contact your account owner to give you read and write access to manage
                        pending unused subscriptions
                      </p>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}