import { useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import Layout from '../../../layouts/global/Layout';
import { useLogs } from './useLogs';
import { ILog } from '../../../interfaces/analytic';
import LogListCard from '../../../feature/Accounts/LogListCard';
import PageTitle from '../../../components/global/PageTitle';

export default function Logs() {
  const [logs, setLogs] = useState<ILog[] | []>([]);
  const [searchResults, setSearchResults] = useState<ILog[] | []>([]);

  const { readUserLogHistory, loading, errorMessage } = useLogs();

  const fetchSystemLogs = async () => {
    const response = await readUserLogHistory();

    if (response) {
      setLogs(response.msg.logs);
      setSearchResults(response.msg.logs);
    }
  };

  useEffect(() => {
    fetchSystemLogs();
  }, []);

  return (
    <Layout>
      <PageTitle title={'System Logs'} />
      <div>
        <div className={'flex justify-between gap-5'}>
          {/*<SearchFeedback feedback={logs} setSearchResults={setSearchResults} />*/}
        </div>
        <div className={'my-3 flex flex-col gap-2 h-[70vh] overflow-auto slim-scroll pr-2'}>
          {loading ? (
            <div className={'p-5 h-[70vh] flex flex-col justify-center items-center text-center'}>
              <LoadingSpinner color={'text-[#008160]'} />
              <p>Loading feedback...</p>
            </div>
          ) : searchResults.length > 0 ? (
            searchResults.map((item, index) => <LogListCard data={item} key={index} />)
          ) : logs.length == 0 ? (
            <div className={'h-[60vh] flex flex-col justify-center items-center text-center'}>
              <h4 className={'font-medium mt-5'}>No logs found</h4>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  );
}
