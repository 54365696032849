export default function CancellationReasonListCard({ data }: { data: any }) {
  return (
    <>
      <div
        className={
          'border border-gray-200 bg-white hover:border-gray-400 cursor-default w-full rounded-md p-4'
        }>
        <div className={'flex gap-3 md:gap-5'}>
          <div className={'w-full flex flex-col justify-between'}>
            <div>
              <div>
                <div>
                  <span
                    className={
                      'rounded-full px-2 py-1 leading-none text-xs bg-white text-[#008160] border border-[#008160] capitalize'
                    }>
                    {data.plan} Plan
                  </span>
                </div>
                <div className={'bg-gray-50 rounded-sm p-3 mt-3'}>
                  <p className={'text-sm font-medium'}>Reason</p>
                  <p>{data.reason}</p>
                  <p className={'italic'}>
                    - {data.name}(
                    <a className={'text-emerald-600 underline'} href={`mailto:${data.email}`}>
                      {data.email}
                    </a>
                    )
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
