import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import Modal from '../../components/global/ui/Modal';
import { IUserEditModal } from '../../interfaces/user';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { ORGANIZATION_ID, RE_DIGIT } from '../../constants';
import { useSetupTwoFA } from '../../pages/main/AccountProfile/useSetupTwoFA';
import LoadingSpinnerMini from '../../components/global/LoadingSpinnerMini';

interface ISetup {
  qrcode: string | null;
  secret: string | null;
}

export default function EnableTwoFASetup({ user, closeModal }: IUserEditModal) {
  const { fetchOrganizationData } = useContext(OrganizationContext);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const [message, setMessage] = useState<string | null>(null);
  const [setup, setSetup] = useState<ISetup | null>({
    qrcode: null,
    secret: null
  });
  const { setup2FA, verify2FA, verifyLoading, errorMessage, infoMessage, loading } =
    useSetupTwoFA();
  const [step, setStep] = useState<number>(1);

  const [otp, setOtp] = useState('');

  const otpDigits = useMemo(() => {
    const valueArray = otp.split('');
    const items: Array<string> = [];

    for (let i = 0; i < 6; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [otp, otp.length]);

  const inputOnChange = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    const target = e.target;
    let targetValue = target.value;
    const isTargetValueDigit = RE_DIGIT.test(targetValue);
    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const newValue = otp.substring(0, idx) + targetValue + otp.substring(idx + 1);
    setOtp(newValue);

    if (!isTargetValueDigit) {
      return;
    }

    const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const inputOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (e.key !== 'Backspace' || target.value !== '') {
      return;
    }

    const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    target.setSelectionRange(0, target.value.length);
  };

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard('snippet' as string)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setup2FA()
      .then((response) => {
        setSetup({
          qrcode: response.msg.qrcode,
          secret: response.msg.secret
        });
      })
      .catch();
  }, []);

  const handleVerify2FA = async (values: string) => {
    try {
      const response = await verify2FA({ code: values, secret: setup?.secret as string });

      if (response?.result === 'success') {
        setMessage(response.msg);
        // refetch organization data
        fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);

        setTimeout(() => {
          closeModal();
        }, 5000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      closeModal={closeModal}
      title={'Enable 2FA Setup'}
      width={message ? 'w-full lg:w-2/5' : 'w-full lg:w-3/5'}>
      {loading ? (
        <div className={'flex flex-col gap-2 justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Loading...</h5>
        </div>
      ) : message ? (
        <SuccessMessage message={message} />
      ) : infoMessage ? (
        <SuccessMessage message={infoMessage} />
      ) : (
        <>
          <div className={'grid md:grid-cols-2 gap-3'}>
            <div className={''}>
              <h6 className={'font-semibold'}>Set up Two Factor Authentication</h6>
              <div className={'mt-5'}>
                <ul className={'flex flex-col gap-4'}>
                  <li className={'flex items-start gap-2'}>
                    <div>
                      <span
                        className={
                          'w-6 h-6 flex justify-center items-center rounded-full bg-gradient-to-tr from-emerald-600 to-emerald-400 text-white'
                        }>
                        1
                      </span>
                    </div>
                    <div className={'text-sm'}>
                      <span>
                        Install <strong>Google</strong>, <strong>Salesforce</strong> or{' '}
                        <strong>Microsoft Authenticator</strong> on your mobile device
                      </span>
                    </div>
                  </li>
                  <li className={'flex items-start gap-2'}>
                    <div>
                      <span
                        className={
                          'w-6 h-6 flex justify-center items-center rounded-full bg-gradient-to-tr from-emerald-600 to-emerald-400 text-white'
                        }>
                        2
                      </span>
                    </div>
                    <div className={'text-sm'}>
                      <span>
                        Open the <strong>Authenticator</strong> and scan the{' '}
                        <strong>QR Code</strong> or manually input the <strong>Secret code</strong>
                        below to add this application
                      </span>
                    </div>
                  </li>
                  <li className={'flex items-start gap-2'}>
                    <div>
                      <span
                        className={
                          'w-6 h-6 flex justify-center items-center rounded-full bg-gradient-to-tr from-emerald-600 to-emerald-400 text-white'
                        }>
                        3
                      </span>
                    </div>
                    <div className={'text-sm'}>
                      <span>
                        Click to proceed and input the <strong>6-digit</strong> code generated by
                        the <strong>Authenticator</strong> into the form provided
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className={'flex flex-col items-center gap-3 text-center'}>
              {step === 1 && (
                <div>
                  <h6 className={'text-sm font-medium'}>
                    Scan this QR Code with your Authenticator
                  </h6>
                  <div className={'flex justify-center items-center'}>
                    <img
                      className={'w-3/5 lg:w-4/5 bg-gray-100'}
                      src={setup?.qrcode as string}
                      alt={'Scan QR'}
                    />
                  </div>
                  <div className={'flex justify-center items-center'}>
                    <button
                      type={'button'}
                      onClick={() => setStep(2)}
                      className={`w-3/5 rounded bg-[#008160] hover:bg-emerald-600 text-white font-medium px-3 md:px-4 py-2 md:py-2 text-xs md:text-sm`}>
                      {'Click to proceed'}
                    </button>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className={'lg:w-4/5'}>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      handleVerify2FA(otp);
                    }}>
                    <div>
                      <div className={'flex justify-start w-full'}>
                        <button
                          className={
                            'w-9 h-9 rounded-full flex justify-center items-center border border-[#008160] hover:bg-[#008160] hover:text-white text-[#008160]'
                          }
                          type={'button'}
                          onClick={() => setStep(1)}>
                          <span className={'pt-1'}>
                            <i className="fi fi-rr-arrow-left text-lg"></i>
                          </span>
                        </button>
                      </div>
                      <div className={'mb-5'}>
                        <p className={'text-base font-semibold'}>Verification Code</p>
                        <p className={'text-xs'}>
                          Enter the 6-pin verification code from your Authenticator app after you
                          scanned the QR code
                        </p>
                      </div>
                      <div className={'grid grid-cols-6 gap-2 lg:gap-2 my-5'}>
                        {otpDigits &&
                          otpDigits.map((digit, index) => (
                            <input
                              key={index}
                              className="bg-gray-100 w-full h-[5vh] lg:h-[8vh] rounded border border-[#E6E7E8] text-xl text-center"
                              type="text"
                              inputMode={'numeric'}
                              autoComplete={'one-time-code'}
                              pattern="\d{1}"
                              maxLength={6}
                              value={digit}
                              onKeyDown={inputOnKeyDown}
                              onFocus={inputOnFocus}
                              onChange={(e) => inputOnChange(e, index)}
                              required={true}
                            />
                          ))}
                      </div>
                    </div>
                    <div className={'flex justify-center items-center mt-3'}>
                      <button
                        type={'button'}
                        disabled={verifyLoading}
                        onClick={() => handleVerify2FA(otp)}
                        className={`w-3/5 rounded ${
                          verifyLoading
                            ? 'bg-emerald-200 hover:bg-emerald-200'
                            : 'bg-[#008160] hover:bg-emerald-600'
                        } text-white font-medium px-3 md:px-4 py-2 md:py-2 text-xs md:text-sm flex justify-center`}>
                        {verifyLoading ? (
                          <LoadingSpinnerMini color={'text-white'} />
                        ) : (
                          'Verify account'
                        )}
                      </button>
                    </div>
                    {errorMessage && <Error value={errorMessage as string} />}
                  </form>
                </div>
              )}
            </div>
          </div>

          <hr className={'border-b border-gray-200 my-3'} />
          <div className={'text-center flex gap-1 flex-col justify-center items-center'}>
            <div>
              <h6 className={'font-semibold'}>Your Secret Code</h6>
            </div>
            <div className={'p-3 rounded-md bg-gray-100 border border-gray-200 flex gap-3'}>
              <span className={'text-lg font-medium text-gray-700'}>{setup?.secret}</span>
              <button
                type={'button'}
                onClick={handleCopyClick}
                className={
                  'text-sm px-2 py-1 rounded text-white bg-[#008160] hover:bg-emerald-500 flex items-start gap-1'
                }>
                <span className={'text-sm'}>{isCopied ? 'Copied!' : 'Copy'}</span>
                <span>
                  <i className="fi fi-rr-duplicate text-xs"></i>
                </span>
              </button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}
