import { useContext, useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import Layout from '../../../layouts/global/Layout';
import { useTicketIssues } from './useTicketIssues';
import Error from '../../../components/global/ui/alerts/Error';
import CreateTicketIssue from '../../../feature/SupportTickets/CreateTicketIssue';
import SearchTicketIssues from '../../../components/form/SearchTicketIssues';
import SupportTicketIssueListCard from '../../../feature/SupportTickets/SupportTicketIssueListCard';
import SelectedTicketIssue from '../../../feature/SupportTickets/SelectedTicketIssue';
import Modal from '../../../components/global/ui/Modal';
import { IUserTicketIssueWithComment } from '../../../interfaces/support-ticket';
import { useAuthUser } from 'react-auth-kit';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import PageTitle from '../../../components/global/PageTitle';

export default function SupportTickets() {
  const [tab, setTab] = useState<number>(1);
  const [tickets, setTickets] = useState<IUserTicketIssueWithComment[]>([]);
  const [searchResults, setSearchResults] = useState<IUserTicketIssueWithComment[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<IUserTicketIssueWithComment>(
    tickets && tickets[0]
  );
  const [newTicketOpen, setNewTicketOpen] = useState<boolean>(false);
  const [ticketModalOpen, setTicketModalOpen] = useState<boolean>(false);

  const auth = useAuthUser();
  const { selectedOrganization, setSelectedOrganization, profile } =
    useContext(OrganizationContext);

  const { loadUserTicketIssues, ticketsLoading, errorMessage } = useTicketIssues();

  const fetchTickets = () => {
    loadUserTicketIssues({ account_id: selectedOrganization.account_id })
      .then((response) => {
        setTickets(response?.msg);
        setSearchResults(response?.msg);
        setSelectedTicket(response.msg[0]);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (selectedOrganization) {
      fetchTickets();
    } else {
      setSelectedOrganization(profile.organizations[0]);
    }
  }, [selectedOrganization && selectedOrganization.account_id]);

  const tabs = [
    {
      name: 'All Issues',
      id: 1,
      icon: <i className="fi fi-rr-comment-alt text-base text-gray-600"></i>,
      total: tickets ? tickets?.length : 0
    },
    {
      name: 'Open Issues',
      id: 2,
      icon: <i className="fi fi-rr-hourglass-end text-base text-gray-600"></i>,
      total: tickets ? tickets?.filter((item) => item.ticket.status == 'active').length : 0
    },
    {
      name: 'Closed Issues',
      id: 3,
      icon: <i className="fi fi-sr-comment-check text-base text-gray-600"></i>,
      total: tickets ? tickets?.filter((item) => item.ticket.status == 'closed').length : 0
    }
  ];

  return (
    <Layout>
      {newTicketOpen && (
        <CreateTicketIssue fetchTickets={fetchTickets} closeModal={() => setNewTicketOpen(false)} />
      )}

      <PageTitle title={'Help & Support'} />
      <div className={'lg:hidden'}>
        {tickets.length > 0 && selectedTicket && ticketModalOpen && (
          <Modal
            closeModal={() => setTicketModalOpen(false)}
            title={''}
            width={'w-full md:w-4/5 lg:w-2/3'}>
            <SelectedTicketIssue
              fetchTickets={fetchTickets}
              setSelectedTicket={setSelectedTicket}
              ticket={selectedTicket}
            />
          </Modal>
        )}
      </div>
      <div>
        <div className={'flex flex-col lg:flex-row justify-between items-start gap-5'}>
          <div className={'w-full lg:w-auto flex flex-col lg:flex-row gap-3 items-start'}>
            <SearchTicketIssues tickets={tickets} setSearchResults={setSearchResults} />
            <div className={'flex gap-4'}>
              {tabs &&
                tabs.map((item, index) => (
                  <button
                    type={'button'}
                    onClick={() => setTab(item.id)}
                    key={index}
                    className={`${
                      tab === item.id
                        ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                        : 'border-transparent'
                    } py-2 border-b-2 hover:border-gray-300 font-medium text-sm flex gap-3`}>
                    <span className={'flex gap-2'}>
                      <span className={'hidden lg:flex'}>{item.icon}</span>
                      <span className={'text-sm text-left'}>{item.name}</span>
                    </span>
                    <span
                      className={
                        'hidden lg:flex bg-emerald-100 text-emerald-500 font-medium px-2 rounded'
                      }>
                      {item.total}
                    </span>
                  </button>
                ))}
            </div>
          </div>
          {auth()?.super_user == 0 && (
            <div className={'hidden lg:flex'}>
              <button
                type={'button'}
                onClick={() => setNewTicketOpen(true)}
                className={
                  'rounded px-4 py-2 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                }>
                <span>
                  <i className="fi fi-rr-plus text-xs"></i>
                </span>
                <span className={'text-xs'}>Create Support Ticket</span>
              </button>
            </div>
          )}
        </div>
        <div className={'my-3 grid gap-2'}>
          {ticketsLoading ? (
            <div className={'p-5 h-[80vh] flex flex-col justify-center items-center text-center'}>
              <LoadingSpinner color={'text-[#008160]'} />
              <p>Loading ticket issues</p>
            </div>
          ) : searchResults && searchResults.length > 0 ? (
            <div className={'grid lg:grid-cols-2 gap-5'}>
              <div
                className={
                  'flex flex-col gap-3 lg:max-h-[70vh] lg:overflow-y-auto slim-scroll lg:pr-1 lg:pb-1'
                }>
                {tab == 1
                  ? searchResults.sort().map((item, index) => (
                      <div key={index}>
                        <SupportTicketIssueListCard
                          openModal={() => setTicketModalOpen(true)}
                          selectedTicket={selectedTicket}
                          setSelectedTicket={setSelectedTicket}
                          data={item}
                        />
                      </div>
                    ))
                  : tab == 2
                  ? searchResults
                      .filter((item) => item.ticket.status == 'active')
                      .map((item, index) => (
                        <div key={index}>
                          <SupportTicketIssueListCard
                            openModal={() => setTicketModalOpen(true)}
                            selectedTicket={selectedTicket}
                            setSelectedTicket={setSelectedTicket}
                            data={item}
                          />
                        </div>
                      ))
                  : tab == 3
                  ? searchResults
                      .filter((item) => item.ticket.status == 'closed')
                      .map((item, index) => (
                        <div key={index}>
                          <SupportTicketIssueListCard
                            openModal={() => setTicketModalOpen(true)}
                            selectedTicket={selectedTicket}
                            setSelectedTicket={setSelectedTicket}
                            data={item}
                          />
                        </div>
                      ))
                  : searchResults.map((item, index) => (
                      <div key={index}>
                        <SupportTicketIssueListCard
                          openModal={() => setTicketModalOpen(true)}
                          selectedTicket={selectedTicket}
                          setSelectedTicket={setSelectedTicket}
                          data={item}
                        />
                      </div>
                    ))}
              </div>

              <div
                className={
                  'h-[70vh] overflow-y-auto slim-scroll p-5 rounded border border-gray-200 hidden lg:flex lg:flex-col w-full'
                }>
                {tickets.length > 0 && selectedTicket && (
                  <SelectedTicketIssue
                    fetchTickets={fetchTickets}
                    setSelectedTicket={setSelectedTicket}
                    ticket={selectedTicket}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className={'flex h-[80vh] flex-col justify-center items-center text-center'}>
              <div className={'flex justify-center mb-2'}>
                <span
                  className={
                    'w-16 h-16 rounded-full pt-2 flex justify-center items-center text-gray-400 bg-gray-100'
                  }>
                  <i className="fi fi-sr-interrogation text-3xl"></i>
                </span>
              </div>
              <p>No Support Tickets Created</p>
            </div>
          )}
        </div>
        {auth()?.super_user == 0 && (
          <button
            type={'button'}
            onClick={() => setNewTicketOpen(true)}
            className={
              'fixed lg:hidden bottom-5 pt-1 right-5 w-14 h-14 shadow-md rounded-full bg-[#008160] hover:bg-emerald-600 flex justify-center items-center text-white'
            }>
            <i className="fi fi-rr-plus text-xl"></i>
          </button>
        )}
        {errorMessage && (
          <div className={'fixed right-5 bottom-5 w-[20vw]'}>
            <Error value={errorMessage} />
          </div>
        )}
      </div>
    </Layout>
  );
}
