import React, { useContext, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { usePayment } from '../../../pages/main/Pricing/usePayment';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { useAuthUser } from 'react-auth-kit';
import { ORGANIZATION_ID, SUBSCRIPTION_ID_KEY } from '../../../constants';
import { routes } from '../../../constants/routes';
import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import Error from '../../../components/global/ui/alerts/Error';
import SuccessMessage from '../../../components/global/ui/SuccessMessage';
import DetailsModal from './DetailsModal';
import { IPackage, IPlan, IPlanInterval } from '../../../interfaces/components';
import { IPaymentCard, ISubscription } from '../../../interfaces/subscription';
import SubscriberCardPopover from '../../../components/global/ui/popovers/SubscriberCardPopover';
import LoadingSpinner from '../../../components/global/LoadingSpinner';

export default function SubscriptionCheckoutForm({
  plan,
  interval,
  subscription,
  isNewCardAdded,
  onCancel
}: {
  plan: IPackage;
  interval: IPlanInterval;
  subscription: ISubscription;
  isNewCardAdded: boolean;
  onCancel: () => void; 
}) {
  const { makePayment, confirmPayment, addCardToSubscription, loading, errorMessage } = usePayment();
  const { profile, selectedOrganization, fetchOrganizationData, loading: subscriptionLoading } = useContext(OrganizationContext);

  const [error, setError] = useState<string | null | undefined>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<IPaymentCard | null | undefined>(
    profile && profile.subscription_details?.card.find((item) => item.is_default_source == 1)
  );
  const [cardOptionsShow, setCardOptionsShow] = useState<boolean>(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); 

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    plan: Yup.string().required('Select your plan'),
    email:
      subscription && subscription.card?.length > 0
        ? Yup.string().email('Enter a valid email')
        : Yup.string().email('Enter a valid email').required('Enter your email'),
    name:
      subscription.card?.length > 0
        ? Yup.string()
        : Yup.string().required('Enter the name on card'),
    interval: Yup.string().required('Specify the interval'),
    coupon: Yup.string()
  });

  const auth = useAuthUser();

  const isAdminOrWriteAccess = selectedOrganization && 
    (selectedOrganization.permission === 'admin' || selectedOrganization.permission === 'write');

  const isReadAccess = selectedOrganization && selectedOrganization.permission === 'read';

  const handleProceedToSubscribe = () => {
    if (!isAdminOrWriteAccess) {
      setError('You do not have permission to access this page. Please contact an admin for access.');
      return;
    }

    setShowDetailsModal(true);
  };

  useEffect(() => {
    if (plan && plan.key !== formik.values.plan) {
      formik.setFieldValue('plan', plan.key);
    }

    if (plan && plan.key === 9) {
      handleProceedToSubscribe();
    }
  }, [plan]);

  const organizationEmail = selectedOrganization?.email || '';
  const selectedInterval = localStorage.getItem('selectedInterval') || 'month'; 

  const formik = useFormik({
    initialValues: {
      plan: plan ? plan.key : null,
      name: '',
      coupon: '',
      interval: selectedInterval,
      email: organizationEmail,
      card_id:
        subscription.card && subscription.card.length > 0
          ? subscription.card.filter((item) => item.is_default_source)[0].id
          : ''
    },
    validationSchema,
    onSubmit: async (values) => {
      setMessage(null);
      setError(null);
      setIsSubmitting(true);

      const createCustomerResponse = await makePayment({
        name: values.name,
        email: values.email,
        plan: plan && plan.value,
        card_id: selectedCard
          ? selectedCard.id
          : subscription.card.find((item) => item.is_default_source)?.id,
        interval: selectedInterval,
        coupon: values.coupon,
        account_id: selectedOrganization && selectedOrganization.account_id
      });
      if (createCustomerResponse && createCustomerResponse.result === 'success') {
        localStorage.removeItem('plan');
            localStorage.removeItem('period');
        const { subscriptionId } = createCustomerResponse.additional;
        if (values.card_id) {
          localStorage.setItem(SUBSCRIPTION_ID_KEY, subscriptionId);
          setTimeout(() => {
            fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
            navigate(routes.SETUP);
          }, 2000);
        } else {
          const addCard = await addCardToSubscription(subscriptionId);
          if (addCard.result === 'success') {
            localStorage.setItem(SUBSCRIPTION_ID_KEY, subscriptionId);
            setTimeout(() => {
              fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
              navigate(routes.SETUP);
            }, 1000);
          }
        }
      } else {
        // setError('Error completing subscription');
        setIsSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (interval && interval.value !== formik.values.interval) {
      formik.setFieldValue('interval', interval.value);
    }
  }, [interval]);

  useEffect(() => {
    if (isNewCardAdded && formik.isValid) {
      formik.submitForm();
    }
  }, [isNewCardAdded]);

  

  return (
    <>
    {loading || isSubmitting ? (
      <div className="py-16 px-4 rounded-md bg-white text-center mb-5">
        <p className="text-[#008160] font-semibold mb-2 capitalize">Processing your subscription</p>
        <p className="text-sm mb-2">Please wait while we set up your account...</p>
        <LoadingSpinner color={'text-[#008160]'} />
        {errorMessage && <Error value={errorMessage} />}
        {error && <Error value={error} />}

      </div>
    ) : (
    <>
      {isAdminOrWriteAccess ? (
        isNewCardAdded ? (
          <div className="py-16 px-4 rounded-md bg-white text-center mb-5">
            <p className="text-[#008160] font-semibold mb-2 capitalize">Processing your subscription</p>
            <p className="text-sm">Please wait while we set up your account...</p>

            {errorMessage && <Error value={errorMessage} />}
                  {error && <Error value={error} />}
          </div>
        ) : (
          <>
            <div className={' border-t border-t-gray-200 p-3 md:p-5'} style={{ display: showDetailsModal ? 'none' : 'block' }}>
              {message ? (
                <div className={'h-[25vh] flex flex-col justify-center items-center text-center'}>
                  <SuccessMessage message={message} />
                  <p className={'text-sm text-gray-400'}>Redirecting to add a website...</p>
                </div>
              ) : (
                <div className={'flex flex-col gap-4 '}>
                  <div>
                    <label className={'capitalize text-sm'}>
                      Email <span className={'text-red-500'}>*</span>{' '}
                    </label>
                    <input
                      type={'email'}
                      className={'p-2 w-full rounded border border-gray-300 mt-1'}
                      name={'email'}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className={'grid md:grid-cols-2 gap-3'}>
                    <div>
                      <label className={'capitalize text-sm'}>
                        Select payment card <span className={'text-red-500'}>*</span>{' '}
                      </label>
                      <div className={'relative w-full mt-1'}>
                        <button
                          type={'button'}
                          onClick={() => setCardOptionsShow(!cardOptionsShow)}
                          className={`border px-3 py-2 flex items-center justify-between ${
                            cardOptionsShow ? 'border-gray-300' : 'border-gray-300'
                          } w-full rounded text-sm`}
                        >
                          <div className={'flex items-center justify-between w-full'}>
                            <div className={'flex items-center space-x-3'}>
                              <img
                                src={`/assets/payments/${
                                  (selectedCard || profile.subscription_details?.card[0])?.card?.brand === 'Visa'
                                    ? 'visa.svg'
                                    : (selectedCard || profile.subscription_details?.card[0])?.card?.brand === 'MasterCard'
                                    ? 'mastercard.svg'
                                    : (selectedCard || profile.subscription_details?.card[0])?.card?.brand === 'Discover'
                                    ? 'discover.svg'
                                    : (selectedCard || profile.subscription_details?.card[0])?.card?.brand === 'American Express'
                                    ? 'amex.svg'
                                    : (selectedCard || profile.subscription_details?.card[0])?.card?.brand === 'Diners Club'
                                    ? 'diners.svg'
                                    : (selectedCard || profile.subscription_details?.card[0])?.card.brand === 'UnionPay'
                                    ? 'unionpay.svg'
                                    : (selectedCard || profile.subscription_details?.card[0])?.card?.brand === 'JCB'
                                    ? 'jcb.svg'
                                    : ''
                                }`}
                                alt={''}
                                className={'w-8 h-5 object-contain'}
                              />
                              <div className={'flex flex-col sm:flex-row sm:items-center sm:space-x-2'}>
                                <span className={'text-xs'}>
                                  {(selectedCard ?? profile.subscription_details?.card[0])?.card?.brand}
                                </span>
                                <span className={'whitespace-nowrap'}>
                                  **** **** **** {(selectedCard || profile.subscription_details?.card[0])?.card?.last4}
                                </span>
                              </div>
                            </div>
                            <span>
                              <i
                                className={`fi ${
                                  cardOptionsShow ? 'fi-sr-caret-up' : 'fi-sr-caret-down'
                                } text-base`}
                              ></i>
                            </span>
                          </div>
                        </button>
                        {cardOptionsShow && (
                          <SubscriberCardPopover
                            hideCardOptions={() => setCardOptionsShow(false)}
                            show={cardOptionsShow}
                            setSelectedCard={setSelectedCard}
                            options={profile.subscription_details?.card as any[]}
                            onClickOutside={() => setCardOptionsShow(false)}
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      <label className={'capitalize text-sm'}>Coupon</label>
                      <input
                        type={'text'}
                        className={'p-2 w-full rounded border border-gray-300 mt-1'}
                        name={'coupon'}
                        value={formik.values.coupon}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className={'flex gap-3 flex-col-reverse md:flex-row justify-between'}>
                    <button
                      onClick={onCancel} 
                      className={`px-4 py-2 flex justify-center items-center rounded text-sm text-gray-700 bg-gray-200 hover:bg-gray-300`}>
                      <span>Cancel</span>
                    </button>
                    <div className={'flex lg:w-fit'}>
                      <PrimaryButton
                        disabled={!formik.values.plan || !formik.values.email || !formik.values.card_id || loading}
                        title={loading ? 'Creating Subscription...' : 'Proceed to subscribe'}
                        handler={() => handleProceedToSubscribe()}
                      />
                    </div>
                  </div>
                  {errorMessage && <Error value={errorMessage} />}
                  {error && <Error value={error} />}
                </div>
              )}
            </div>
            <div className='rounded-md border-t border-gray-200 p-3 md:p-5' style={{ display: showDetailsModal ? 'block' : 'none' }}>
              <DetailsModal
                formik={formik}
                plan={plan}
                interval={interval}
                closeModal={() => setConfirmOpen(false)}
                onProceed={() => {
                  setShowDetailsModal(false);
                }}
              />
            </div>
          </>
        )
      ) : isReadAccess ? (
        <div className="py-16 px-4 rounded-md bg-white text-center mb-5">
          <p className="text-[#008160] font-semibold mb-2 capitalize">Read only access</p>
          <p className="text-sm">You do not have access to purchase a subscription. Please contact your account owner for access.</p>
        </div>
      ) : (
        <div className="py-16 px-4 rounded-md bg-white text-center mb-5">
          <p className="text-[#008160] font-semibold mb-2 capitalize">Access denied</p>
          <p className="text-sm">Please select an account to purchase the subscription</p>
        </div>
      )}
    </>
    )}
    </>
  );
}
