import PrimaryButtonWithIcon from '../../components/global/ui/buttons/PrimaryButtonWithIcon';
import PackageItem from '../Plans/PlanPackageItem';
import { useSignup } from '../../pages/authentication/Register/useSignup';
import { useContext, useState } from 'react';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import Error from '../../components/global/ui/alerts/Error';
import { useAuthUser } from 'react-auth-kit';
import Info from '../../components/global/ui/alerts/Info';
import SuccessMessage from '../../components/global/ui/SuccessMessage';

export const AccountHolderSetup = ({ closeModal }: { closeModal: () => void }) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const { accountHolderRequest, loading, errorMessage } = useSignup();
  const { profile, fetchOrganizationData, switchOrganization } = useContext(OrganizationContext);
  const auth = useAuthUser();

  const requestAccountHolder = async (resend: boolean) => {
    const response = await accountHolderRequest({
      user_id: profile.profile_details?.user_id as number,
      resend: resend ? 1 : null
    });
    if (response?.result == 'success') {
      setSuccessMessage(response.msg || response.message);

      // set organization to the user's organization
      // switchOrganization((profile.organizations[0]?.account_id as string) || auth()?.account_id);
    }
  };

  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"></div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div
          className={`relative inline-block align-bottom bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle lg:w-2/5`}>
          <div
            style={{
              background: `url(/assets/account-setup-bg.png)`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '10vw',
              backgroundPosition: '0% 0%'
              // opacity: '40%',
            }}
            className={'bg-white p-10'}>
            <div className={'flex flex-col items-end text-right gap-2'}>
              <h5 className={'text-2xl font-semibold'}>Set up your own account!</h5>

              <p className={'text-sm w-2/3'}></p>
            </div>
            <div>
              {loading ? (
                <div className={'p-10 flex flex-col gap-5 justify-center items-center'}>
                  <LoadingSpinner color={'text-emerald-600'} />
                  <p>Processing request...please wait</p>
                </div>
              ) : successMessage ? (
                <div className={'flex flex-col justify-center gap-2'}>
                  <SuccessMessage message={successMessage} />
                  <div className={'flex justify-center'}>
                    <button
                      type={'button'}
                      onClick={closeModal}
                      className={`w-full lg:w-1/6 px-4 py-2 rounded text-gray-700 bg-gray-200 hover:bg-gray-300 text-xs`}>
                      Close
                    </button>
                  </div>
                </div>
              ) : profile.profile_details?.holder_request ? (
                <div className={'flex flex-col justify-center gap-2'}>
                  <Info
                    value={
                      'Your account holder request is in progress. We have sent you an activation link to your email. Click the link to activate your account.'
                    }
                  />
                  <div className={'flex justify-end gap-2'}>
                    <button
                      type={'button'}
                      onClick={closeModal}
                      className={`w-full lg:w-1/6 px-4 py-2 rounded text-gray-700 bg-gray-200 hover:bg-gray-300 text-xs`}>
                      Close
                    </button>
                    <PrimaryButtonWithIcon
                      handler={() => requestAccountHolder(true)}
                      title={'Resend verification'}
                      icon={<i className="fi fi-rs-rotate-right text-sm"></i>}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className={'my-5 flex flex-col gap-3'}>
                    <div
                      className={
                        'p-4 bg-white border-l-2 lg:border-l-4 border-emerald-600 rounded drop-shadow flex items-start gap-2 cursor-default'
                      }>
                      <div>
                        <span className="relative flex h-4 w-4">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-400 opacity-75"></span>
                          {/*<span className="relative inline-flex rounded-full h-3 w-3 bg-emerald-600"></span>*/}
                          <span className="relative inline-flex">
                            <i className="fi fi-ss-shield-check text-emerald-600"></i>
                          </span>
                        </span>
                      </div>
                      <div>
                        <p className={'text-sm font-medium text-gray-900'}>
                          You are currently a guest user on this platform
                        </p>
                      </div>
                    </div>
                    <p className={'text-base'}>
                      Once you initiate a request, we will send you an email to validate your
                      request
                    </p>
                    <div className={'bg-emerald-50 rounded p-5'}>
                      {[
                        'Manage your subscriptions',
                        'Manage your teams',
                        'Manage your own websites and heatmaps'
                      ].map((item, index) => (
                        <PackageItem color={'text-[#091226]'} key={index} value={item} />
                      ))}
                    </div>
                  </div>
                  <div className={'flex justify-end gap-2'}>
                    <button
                      type={'button'}
                      onClick={closeModal}
                      className={`px-4 py-2 rounded text-gray-700 bg-gray-200 hover:bg-gray-300 text-xs`}>
                      Cancel
                    </button>
                    <PrimaryButtonWithIcon
                      handler={() => requestAccountHolder(false)}
                      title={'Initiate request'}
                      icon={<i className="fi fi-rr-portrait text-sm"></i>}
                    />
                  </div>
                </>
              )}
              {errorMessage && <Error value={errorMessage} />}
            </div>
            {/*)}*/}
          </div>
        </div>
      </div>
    </div>
  );
};
