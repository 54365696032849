import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useState } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import SuccessMessage from '../../../components/global/ui/SuccessMessage';
import Error from '../../../components/global/ui/alerts/Error';
import Modal from '../../../components/global/ui/Modal';
import { useCoupon } from '../../../pages/main/Coupons/useCoupon';
import { ICoupon } from '../../../interfaces/coupon';

export default function UpdateCoupon({
  coupon,
  closeModal
}: {
  coupon: ICoupon;
  closeModal: () => void;
}) {
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .max(255, 'Title cannot exceed 255 characters')
      .required('Title is required'),
    amount: Yup.string().required('Amount is required'),
    expiry: Yup.string().required('Expiry date is required'),
    description: Yup.string()
      .max(255, 'Description cannot exceed 255 characters')
      .required('Description is required')
  });

  const { editCoupon, loading, errorMessage } = useCoupon();

  const [message, setMessage] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      title: coupon.title || '',
      amount: parseInt(coupon.amount) || '',
      begin: coupon.begin || '',
      expiry: coupon.expiry || '',
      description: coupon.description || ''
    },
    validationSchema,
    onSubmit: async (values) => handleUpdateCoupon(values)
  });

  const handleUpdateCoupon = async (values: FormikValues) => {
    setMessage(null);

    const response = await editCoupon({
      state: values.status,
      couponId: coupon.uuid
    });

    if (response?.result === 'success') {
      setMessage(response.msg);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Update coupon'} width={'lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Updating coupon...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}>
              <div>
                <div className={'grid grid-cols-2 gap-2'}>
                  <div className={'col-span-2'}>
                    <label className={'text-sm'}>
                      Title <span className={'text-red-500'}>*</span>
                    </label>
                    <input
                      type={'text'}
                      name={'title'}
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      autoFocus={true}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1"
                    />
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.title && formik.errors.title}
                    </p>
                  </div>
                  <div className={'col-span-2'}>
                    <label className={'text-sm'}>
                      Amount <span className={'text-red-500'}>*</span>
                    </label>
                    <input
                      type={'text'}
                      name={'amount'}
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      placeholder={'USD or %'}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1"
                    />
                    <small>Without adding % will set it as USD.</small>
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.amount && formik.errors.amount}
                    </p>
                  </div>
                  <div>
                    <label className={'text-sm'}>
                      Begin date <span className={'text-red-500'}>*</span>
                    </label>
                    <input
                      type={'date'}
                      name={'begin'}
                      value={formik.values.begin}
                      onChange={formik.handleChange}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1"
                    />
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.begin && formik.errors.begin}
                    </p>
                  </div>
                  <div>
                    <label className={'text-sm'}>
                      Expiry <span className={'text-red-500'}>*</span>
                    </label>
                    <input
                      type={'date'}
                      name={'expiry'}
                      value={formik.values.expiry}
                      onChange={formik.handleChange}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1"
                    />
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.expiry && formik.errors.expiry}
                    </p>
                  </div>
                  <div className={'col-span-2'}>
                    <label className={'text-sm'}>
                      Description <span className={'text-red-500'}>*</span>
                    </label>
                    <textarea
                      name={'description'}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      rows={2}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                    />
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.description && formik.errors.description}
                    </p>
                  </div>
                </div>
                <div className={'mt-2'}>
                  <PrimaryButton
                    disabled={loading}
                    title={loading ? 'Updating coupon...' : 'Update coupon'}
                    handler={() => formik.handleSubmit()}
                  />
                </div>
                {errorMessage && <Error value={errorMessage} />}
              </div>
            </form>
          )}
        </div>
      )}
    </Modal>
  );
}
