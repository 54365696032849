import { ILog } from '../../interfaces/analytic';
import moment from 'moment';

export default function LogListCard({ data }: { data: ILog }) {
  return (
    <>
      <div
        className={`border rounded-md border-gray-200 hover:border-gray-400 bg-white hover:shadow p-3 md:p-3`}>
        <div className={'flex justify-between gap-5'}>
          <div className={'flex gap-2 items-center'}>
            <span className={''}>
              <i className="fi fi-rr-browser text-sm"></i>
            </span>
            <span className={'text-sm flex gap-1'}>
              <span className={'font-medium'}>{data.config_os}</span>
              <span className={'italic'}>{data.config_browser_name}</span>
              <span className={'italic'}>v{data.config_browser_version}</span>
            </span>
          </div>
          <span className={'italic text-sm'}>{moment(data.log_date).format('lll')}</span>
        </div>
        <div className={'mt-1'}>
          <p className={'text-sm'}>{data.activity}</p>
        </div>
        <div className={'text-sm mt-2 flex gap-5'}>
          <span>
            <span className={'font-medium'}>Subject: </span>
            <span className={'italic'}>{data.subject}</span>
          </span>
          <span>
            <span className={'font-medium'}>Log for: </span>
            <span className={'italic'}>{data.log_for}</span>
          </span>
        </div>
        <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-7 gap-2 lg:gap-5'}></div>
      </div>
    </>
  );
}
