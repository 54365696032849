export default function WebsiteExists({
  message,
  closeModal
}: {
  message: string;
  closeModal: () => void;
}) {
  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"></div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle lg:w-1/4">
          <div className={'bg-white p-5'}>
            <div className={'flex justify-between item-center gap-3'}>
              <div className={'flex gap-2'}>
                <span>
                  <i className="fi fi-ss-info text-yellow-500 text-lg"></i>
                </span>
                <h5 className={'font-semibold text-base'}>Message</h5>
              </div>
              <button type={'button'} onClick={closeModal} className={'p-1'}>
                <i className="fi fi-ss-cross"></i>
              </button>
            </div>
            <div>
              <p className={'text-base'}>{message}</p>
            </div>
            <div className={'grid gap-2'}>
              <div className={'mt-2 flex gap-2'}>
                <button
                  type={'button'}
                  onClick={closeModal}
                  className={`px-4 py-2 rounded-lg text-gray-700 bg-gray-300 w-full`}>
                  Contact team
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
