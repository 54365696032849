import moment from 'moment/moment';
import { useAuthUser } from 'react-auth-kit';
import { isMobile } from 'react-device-detect';
import { IUserTicketIssueWithComment } from '../../interfaces/support-ticket';

export default function SupportTicketIssueListCard({
  data,
  selectedTicket,
  setSelectedTicket,
  openModal
}: {
  data: IUserTicketIssueWithComment;
  selectedTicket: IUserTicketIssueWithComment;
  setSelectedTicket: (item: IUserTicketIssueWithComment) => void;
  openModal: () => void;
}) {
  const auth = useAuthUser();
  return (
    <div
      onClick={() => {
        setSelectedTicket(data);
        if (isMobile) {
          openModal();
        }
      }}
      className={`w-full flex gap-2 cursor-pointer rounded p-3 border ${
        data.ticket.id == selectedTicket.ticket.id
          ? 'lg:bg-emerald-50 lg:border-emerald-300 drop-shadow-md'
          : 'lg:border-gray-200 hover:border-gray-300'
      }`}>
      <div className={''}>
        <span>
          {data.ticket.status == 'active' ? (
            <i className="fi fi-rr-hourglass-end text-lg text-gray-600"></i>
          ) : (
            <i className="fi fi-sr-comment-check text-lg text-[#008160]"></i>
          )}
        </span>
      </div>
      <div className={'md:w-[95%]'}>
        <div className={'lg:w-full lg:flex lg:justify-between grid grid-cols-12 gap-3'}>
          <div className={'w-[90%] lg:w-[85%] col-span-11'}>
            <p className={'font-medium text-sm truncate'}>{data.ticket.title}</p>
          </div>
          <div className={'flex justify-end gap-2 items-start col-span-1'}>
            <span className={'lg:pt-0.5'}>
              <i className="fi fi-rr-comment-alt text-base text-gray-600"></i>
            </span>
            <span className={'text-sm lg:text-base'}>{data.comments.length}</span>
          </div>
        </div>
        <div className={'lg:mt-1 flex justify-between items-center gap-2'}>
          <div className={'flex items-center gap-2'}>
            <span className={'text-sm'}>#{data.ticket.id}</span>
            <span className={'text-sm'}>{moment(data.ticket.created_at).format('ll')}</span>
            <span className={'text-sm italic text-[#008160] font-medium hidden lg:flex'}>
              by {auth()?.user_id == data.ticket.user_id ? 'You' : data.ticket.created_by_name}
            </span>
          </div>
          <div>
            <span
              className={
                'rounded-full lg:px-2 lg:py-1 text-xs text-[#008160] lg:border border-[#008160] capitalize leading-none'
              }>
              {data.ticket.department}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
