import { useState } from 'react';
import { getRequest } from '../../../requests';
import { useAuthHeader } from 'react-auth-kit';

export const useAddWebsite = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const addWebsite = async ({
    name,
    url,
    ecommerce = 0,
    currency,
    platform = 'other',
    subscriptionId
  }: {
    name: string;
    url: string;
    ecommerce?: number;
    currency?: string;
    platform?: string;
    subscriptionId: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);
    setInfoMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.addWebsite',
          name,
          url,
          subscriptionId: subscriptionId,
          ecommerce: ecommerce ? 1 : 0,
          currency,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        if (response.data.result === 'error' && response.data.action === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        if (response.data.result === 'error' && response.data.action === 'info') {
          setInfoMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }

        setLoading(false);
        return response;
      }
    } catch (e) {
      setErrorMessage('Error adding website');
      setLoading(false);
    }
  };

  const getCurrencies = async () => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.currencies',
          array: 1,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error fetching currencies');
      setLoading(false);
    }
  };

  const generateCode = async (siteId: any) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.getTrackingCode',
          siteId: siteId,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response;
      }
    } catch (e) {
      setErrorMessage('Error generating tracking code for verification');
      setLoading(false);
    }
  };

  const shareSnippetByEmail = async ({
    siteId,
    name,
    message,
    email
  }: {
    siteId: any;
    name: string;
    message: string;
    email: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.shareSnippetByEmail',
          siteId: siteId,
          token: token,
          name,
          message,
          email,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response;
      }
    } catch (e) {
      setErrorMessage('Error sharing verification code by email');
      setLoading(false);
    }
  };

  return {
    addWebsite,
    getCurrencies,
    shareSnippetByEmail,
    generateCode,
    loading,
    infoMessage,
    errorMessage
  };
};
