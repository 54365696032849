import React, { useState } from 'react';
import ShareSnippetByEmail from './ShareSnippetByEmail';

export default function InstallationHelp({ color }: { color?: string }) {
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);

  return (
    <div className={'flex justify-center'}>
      {shareModalOpen && <ShareSnippetByEmail closeModal={() => setShareModalOpen(false)} />}
      <div className={'w-3/5 mt-3 flex flex-col items-center text-center'}>
        <p className={'font-bold text-sm mb-1'}>Install with developer help</p>
        <p className={'text-xs'}>
          Share your tracking code and installation instructions with a developer on your team.
        </p>
        <button
          type={'button'}
          onClick={() => setShareModalOpen(true)}
          className={'mt-3 rounded px-3 py-1 border-2 font-medium text-xs'}>
          Share by email
        </button>
      </div>
    </div>
  );
}
