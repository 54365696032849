import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import Layout from '../../../layouts/global/Layout';
import CreateCoupon from '../../../feature/AccountSubscription/Coupons/CreateCoupon';
import CreatePromo from '../../../feature/AccountSubscription/Coupons/CreatePromo';
import CouponListCard from '../../../feature/AccountSubscription/Coupons/CouponListCard';
import SearchCoupons from '../../../components/form/SearchCoupons';
import { useCoupon } from './useCoupon';
import { ICoupon } from '../../../interfaces/coupon';
import PrimaryButtonWithIcon from '../../../components/global/ui/buttons/PrimaryButtonWithIcon';
import { routes } from '../../../constants/routes';
import PageTitle from '../../../components/global/PageTitle';

export default function Coupons() {
  const [createCouponOpen, setCreateCouponOpen] = useState<boolean>(false);
  const [createPromoOpen, setCreatePromoOpen] = useState<boolean>(false);

  const [tab, setTab] = useState<number>(1);

  const [coupons, setCoupons] = useState<ICoupon[] | []>([]);
  const [promos, setPromos] = useState<ICoupon[] | []>([]);
  const [couponSearchResults, setCouponSearchResults] = useState<ICoupon[] | []>([]);
  const [promoSearchResults, setPromoSearchResults] = useState<ICoupon[] | []>([]);

  const [updatedCoupon, setUpdatedCoupon] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const {
    editCoupon,
    editPromo,
    retrieveCoupons,
    retrievePromos,
    promoLoading,
    couponLoading,
    errorMessage
  } = useCoupon();

  const toggleCouponStatus = (data: ICoupon) => {
    setUpdatedCoupon(null);

    editCoupon({
      state: !(data.status == 'active'),
      couponId: data.uuid
    }).then((res) => {
      if (res.result == 'success') {
        setUpdatedCoupon(true);
      }
    });
  };

  const togglePromoStatus = (data: ICoupon) => {
    setUpdatedCoupon(null);

    editPromo({
      state: !(data.status == 'active'),
      promotionId: data.uuid
    }).then((res) => {
      if (res.result == 'success') {
        setUpdatedCoupon(true);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    retrieveCoupons().then((res) => {
      setCoupons(res);
      setCouponSearchResults(res);
    });

    retrievePromos().then((res) => {
      setPromos(res);
      setPromoSearchResults(res);
    });
    setLoading(false);
  }, [updatedCoupon]);

  const tabs = [
    {
      name: 'Coupons',
      id: 1,
      total: coupons ? coupons?.length : 0
    },
    {
      name: 'Promos',
      id: 2,
      total: promos ? promos?.length : 0
    }
  ];

  return (
    <Layout>
      <PageTitle title={'Coupons'} />
      <div>
        {createCouponOpen && <CreateCoupon closeModal={() => setCreateCouponOpen(false)} />}
        {createPromoOpen && (
          <CreatePromo closeModal={() => setCreatePromoOpen(false)} coupons={coupons} />
        )}
        <div className={'flex gap-4 mb-4'}>
          {tabs &&
            tabs.map((item, index) => (
              <button
                type={'button'}
                onClick={() => setTab(item.id)}
                key={index}
                className={`${
                  tab === item.id
                    ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                    : 'border-transparent'
                } py-2 border-b-2 hover:border-gray-300 font-medium text-sm flex gap-3`}>
                <span className={'text-xs md:text-sm'}>{item.name}</span>
                <span className={'bg-emerald-100 text-emerald-500 font-medium px-2 rounded'}>
                  {item.total}
                </span>
              </button>
            ))}
        </div>
        <div>
          {tab == 1 ? (
            <>
              <div className={'flex justify-between gap-5'}>
                <SearchCoupons
                  coupons={coupons}
                  setSearchResults={setCouponSearchResults}
                  placeholder={'Search coupons'}
                />

                <div className={'hidden lg:flex gap-2'}>
                  <PrimaryButtonWithIcon
                    handler={() => setCreateCouponOpen(true)}
                    title={'Create coupon'}
                    icon={<i className="fi fi-rr-plus text-xs"></i>}
                  />
                </div>
              </div>
              <div className={'my-3 grid gap-2'}>
                {couponLoading ? (
                  <div
                    className={
                      'p-5 h-[70vh] flex flex-col justify-center items-center text-center'
                    }>
                    <LoadingSpinner color={'text-[#008160]'} />
                    <p>Loading coupons</p>
                  </div>
                ) : couponSearchResults && couponSearchResults.length > 0 ? (
                  couponSearchResults
                    .sort()
                    .map((item, index) => (
                      <CouponListCard
                        toggle={toggleCouponStatus}
                        data={item as ICoupon}
                        key={index}
                      />
                    ))
                ) : (
                  <div className={'h-[70vh] flex flex-col justify-center items-center text-center'}>
                    <h4 className={'font-medium mt-5'}>No coupons found.</h4>
                    <div className={'mt-3 flex'}>
                      <button
                        type={'button'}
                        onClick={() => setCreateCouponOpen(true)}
                        className={
                          'rounded px-4 py-2 flex items-start gap-2 bg-[#008160] hover:bg-emerald-600 text-white font-medium'
                        }>
                        <span>
                          <i className="fi fi-rr-plus text-sm"></i>
                        </span>
                        <span className={'text-sm'}>Create coupon</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <Link
                to={routes.PRICING}
                className={
                  'fixed lg:hidden bottom-5 pt-1 right-5 w-14 h-14 shadow-md rounded-full bg-[#008160] hover:bg-emerald-600 flex justify-center items-center text-white'
                }>
                <i className="fi fi-rr-plus text-xl"></i>
              </Link>
            </>
          ) : tab == 2 ? (
            <>
              <div className={'flex justify-between gap-5'}>
                <SearchCoupons
                  coupons={promos}
                  setSearchResults={setPromoSearchResults}
                  placeholder={'Search promos'}
                />

                <div className={'hidden lg:flex gap-2'}>
                  <PrimaryButtonWithIcon
                    handler={() => setCreatePromoOpen(true)}
                    title={'Create promo code'}
                    icon={<i className="fi fi-rr-plus text-xs"></i>}
                  />
                </div>
              </div>
              <div className={'my-3 grid gap-2'}>
                {promoLoading ? (
                  <div
                    className={
                      'p-5 h-[70vh] flex flex-col justify-center items-center text-center'
                    }>
                    <LoadingSpinner color={'text-[#008160]'} />
                    <p>Loading promos</p>
                  </div>
                ) : promoSearchResults && promoSearchResults.length > 0 ? (
                  promoSearchResults
                    .sort()
                    .map((item, index) => (
                      <CouponListCard
                        toggle={togglePromoStatus}
                        data={item as ICoupon}
                        key={index}
                      />
                    ))
                ) : (
                  <div className={'h-[70vh] flex flex-col justify-center items-center text-center'}>
                    {/*<img src={'/assets/analytics.png'} width={250} height={250} className={'w-[15vw]'} />*/}
                    <h4 className={'font-medium mt-5'}>No promo found.</h4>
                    <div className={'mt-3 flex'}>
                      <button
                        type={'button'}
                        onClick={() => setCreatePromoOpen(true)}
                        className={
                          'rounded px-4 py-2 flex items-start gap-2 bg-[#008160] hover:bg-emerald-600 text-white font-medium'
                        }>
                        <span>
                          <i className="fi fi-rr-plus text-sm"></i>
                        </span>
                        <span className={'text-sm'}>Create promo</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <Link
                to={routes.PRICING}
                className={
                  'fixed lg:hidden bottom-5 pt-1 right-5 w-14 h-14 shadow-md rounded-full bg-[#008160] hover:bg-emerald-600 flex justify-center items-center text-white'
                }>
                <i className="fi fi-rr-plus text-xl"></i>
              </Link>
            </>
          ) : (
            <>
              <div className={'flex justify-between gap-5'}>
                <SearchCoupons
                  coupons={coupons}
                  setSearchResults={setCouponSearchResults}
                  placeholder={'Search CouponList'}
                />

                <div className={'hidden lg:flex gap-2'}>
                  <button
                    type={'button'}
                    onClick={() => setCreateCouponOpen(true)}
                    className={
                      'rounded px-4 py-2 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                    }>
                    <span>
                      <i className="fi fi-rr-plus text-xs"></i>
                    </span>
                    <span className={'text-sm'}>Create coupon</span>
                  </button>
                </div>
              </div>
              <div className={'my-3 grid gap-2'}>
                {couponLoading ? (
                  <div
                    className={
                      'p-5 h-[70vh] flex flex-col justify-center items-center text-center'
                    }>
                    <LoadingSpinner color={'text-[#008160]'} />
                    <p>Loading coupons</p>
                  </div>
                ) : couponSearchResults && couponSearchResults.length > 0 ? (
                  couponSearchResults
                    .sort()
                    .map((item, index) => (
                      <CouponListCard
                        toggle={togglePromoStatus}
                        data={item as ICoupon}
                        key={index}
                      />
                    ))
                ) : (
                  <div className={'h-[70vh] flex flex-col justify-center items-center text-center'}>
                    {/*<img src={'/assets/analytics.png'} width={250} height={250} className={'w-[15vw]'} />*/}
                    <h4 className={'font-medium mt-5'}>No coupons found.</h4>
                    <div className={'mt-3 flex'}>
                      <button
                        type={'button'}
                        onClick={() => setCreateCouponOpen(true)}
                        className={
                          'rounded px-4 py-2 flex items-start gap-2 bg-[#008160] hover:bg-emerald-600 text-white font-medium'
                        }>
                        <span>
                          <i className="fi fi-rr-plus text-sm"></i>
                        </span>
                        <span className={'text-sm'}>Create coupon</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <Link
                to={routes.PRICING}
                className={
                  'fixed lg:hidden bottom-5 pt-1 right-5 w-14 h-14 shadow-md rounded-full bg-[#008160] hover:bg-emerald-600 flex justify-center items-center text-white'
                }>
                <i className="fi fi-rr-plus text-xl"></i>
              </Link>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}
