import { useEffect, useState } from 'react';
import { IWebsiteSubscription } from '../../interfaces/subscription';
import React from 'react';
import { useUserSites } from '../../hooks/useUserSites';

interface Props {
  websiteDetails: IWebsiteSubscription | null;
  onClose: () => void;
}

const WebsiteDetailsModal: React.FC<Props> = ({ websiteDetails, onClose }) => {
  const { getFeaturesListByIdsite } = useUserSites();
  const [featuresList, setFeaturesList] = useState<string>('');
  const [loadingFeatures, setLoadingFeatures] = useState<boolean>(true);

  useEffect(() => {
    const fetchFeaturesList = async () => {
      if (websiteDetails?.website?.idsite) {
        const idsite = websiteDetails.website.idsite;
        const features = await getFeaturesListByIdsite(idsite);
        setFeaturesList(features);
        setLoadingFeatures(false);
      }
    };

    fetchFeaturesList();
  }, [websiteDetails, getFeaturesListByIdsite]);

  return (
    <div className="inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 absolute z-50">
      <div className="bg-white py-8 px-4 rounded-lg w-full mx-3 lg:w-2/4 xl:w-2/6 overflow-y-auto">
        <div className='flex flex-col justify-between items-center'>
          <div className='bg-slate-100 text-dark p-3 w-full rounded-md text-center border-b'>
            <h2 className="text-lg font-semibold capitalize">{websiteDetails?.website?.name}</h2>
            <p className="text-sm">{websiteDetails?.website?.main_url}</p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 p-3">
          <div className='capitalize'>status :</div>
          <div className='text-end capitalize font-semibold'>{websiteDetails?.website?.status}</div>
        </div>
        <div className="grid grid-cols-2 gap-4 p-3">
          <div className='capitalize'>verified :</div>
          <div className='text-end capitalize font-semibold'>{Number(websiteDetails?.website?.verified) === 1 ? 'Yes' : 'No'}</div>
        </div>
        <div className="grid grid-cols-2 gap-4 p-3">
          <div className='capitalize'>Recording :</div>
          <div className='text-end capitalize font-semibold'>{Number(websiteDetails?.website?.enable_recording) === 1 ? 'Enabled' : 'Disabled'}</div>
        </div>
        <div className="grid grid-cols-2 gap-4 p-3">
          <div className='capitalize'>Platform :</div>
          <div className='text-end capitalize font-semibold'>{websiteDetails?.website?.platform}</div>
        </div>
        {loadingFeatures ? (
          <div className="grid grid-cols-2 gap-4 p-3 items-center">
            <div className='capitalize'>Features List :</div>
            <div className='text-end capitalize font-semibold'><div className="loader"></div></div>
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-4 p-3">
            <div className='capitalize'>Features List :</div>
            <div className='text-end capitalize font-semibold'>{featuresList}</div>
          </div>
        )}
        <div className='flex justify-end'>
          <button className="bg-[#008160] hover:bg-emerald-600 cursor-pointer text-white px-4 py-2 mt-4 rounded-md" onClick={onClose}>Close</button>
        </div>
      </div>

      {/* Loader CSS */}
      <style>
        {`
          .loader {
            width: 60px;
            height: 6px;
            -webkit-mask: linear-gradient(90deg, #000 70%, #0000 0) left/20% 100%;
            background: linear-gradient(#000 0 0) left -25% top 0 /20% 100% no-repeat #ddd;
            animation: l7 1s infinite steps(6);
          }
          @keyframes l7 {
            100% { background-position: right -25% top 0 }
          }
        `}
      </style>
    </div>
  );
};

export default WebsiteDetailsModal;
