import { useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import Layout from '../../../layouts/global/Layout';
import PageTitle from '../../../components/global/PageTitle';
import AIPromptListCard from '../../../feature/Dashboard/AIPromptListCard';
import { useAnalytics } from '../Dashboard/useAnalytics';
import { IAIDocument } from '../../../interfaces/analytic';
import OpenAIPrompt from '../../../feature/Dashboard/OpenAIPrompt';
import SearchAIPrompt from '../../../components/form/SearchAIPrompt';

export default function OpenAIDocuments() {
  const [addDocumentOpen, setAddDocumentOpen] = useState<boolean>(false);
  const [documents, setDocuments] = useState<IAIDocument[] | []>([]);
  const [searchResults, setSearchResults] = useState<IAIDocument[] | []>([]);

  const { loadAllAiInsightDocuments, loading, errorMessage } = useAnalytics();

  const fetchDocuments = async () => {
    const response = await loadAllAiInsightDocuments();

    if (response) {
      setDocuments(response.data.data);
      setSearchResults(response.data.data);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <Layout>
      {addDocumentOpen && (
        <OpenAIPrompt refetch={fetchDocuments} closeModal={() => setAddDocumentOpen(false)} />
      )}
      <PageTitle title={'OpenAI Documents'} />
      <div>
        <div className={'flex justify-between gap-5'}>
          <SearchAIPrompt data={documents} setSearchResults={setSearchResults} />
          <button
            onClick={() => setAddDocumentOpen(true)}
            className={
              'rounded px-4 py-2 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
            }>
            <span>
              <i className="fi fi-rr-plus text-xs"></i>
            </span>
            <span className={'text-xs'}>New OpenAI Document</span>
          </button>
        </div>
        <div className={'my-3 flex flex-col gap-2 h-[70vh] overflow-auto slim-scroll pr-2'}>
          {loading ? (
            <div className={'p-5 h-[70vh] flex flex-col justify-center items-center text-center'}>
              <LoadingSpinner color={'text-[#008160]'} />
              <p>Loading documents...</p>
            </div>
          ) : searchResults.length > 0 ? (
            <div className={'grid grid-cols-3 gap-5'}>
              {searchResults.map((item, index) => (
                <AIPromptListCard refetch={fetchDocuments} data={item} key={index} />
              ))}
            </div>
          ) : documents.length == 0 ? (
            <div className={'h-[60vh] flex flex-col justify-center items-center text-center'}>
              <h4 className={'font-medium mt-5'}>No document found</h4>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  );
}
