import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PackageChart({ data }: { data: any }) {
  const dataStats = {
    labels: Object.keys(data),
    datasets: [
      {
        label: 'No. of Subscriptions',
        data: Object.values(data),
        backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(255, 159, 64, 0.2)'],
        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 159, 64, 1)'],
        borderWidth: 2
      }
    ]
  };
  return (
    <Pie
      options={{
        plugins: {
          legend: {
            position: 'bottom' as const
          },
          title: {
            text: 'Subscription Plans',
            display: true
          }
        }
      }}
      data={dataStats}
    />
  );
}
