import { IAccount } from '../../interfaces/client';

export default function UserAccountListCard({ data }: { data: IAccount }) {
  return (
    <div className={'flex-1'}>
      <div className={'grid lg:grid-cols-10'}>
        <div className={'lg:col-span-6'}>
          <h6 className={'font-semibold'}>{data.name}</h6>
        </div>
        <div className={'lg:col-span-1'}>
          <span>{data.account_id}</span>
        </div>
        <div className={'lg:col-span-1'}>
          <div className={'flex'}>
            <span
              className={`rounded-full px-2 py-1 leading-none text-xs font-medium ${
                data.status == 'active'
                  ? 'bg-emerald-100 text-[#008160]'
                  : data.status == 'paused'
                  ? 'bg-amber-100 text-amber-500'
                  : data.status == 'cancelled'
                  ? 'bg-red-100 text-red-500'
                  : ''
              } uppercase`}>
              {data.status}
            </span>
          </div>
        </div>
        <div className={'lg:col-span-2 flex gap-1 items-start'}>
          <span className={''}>
            {data.permission == 'read' ? (
              <i className="fi fi-rr-book-alt"></i>
            ) : data.permission == 'write' ? (
              <i className="fi fi-rr-pencil"></i>
            ) : (
              <i className="fi fi-rr-lock"></i>
            )}
          </span>
          <span className={''}>{data.access_label}</span>
        </div>
      </div>
      <div className={'mt-2'}>
        <div className={'flex gap-2 text-sm'}>
          <span>Join date: </span>
          <span>{data.joining_date}</span>
        </div>
      </div>
    </div>
  );
}
