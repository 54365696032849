import moment from 'moment/moment';
import { Link, useNavigate } from 'react-router-dom';
import { useNotifications } from '../../../../pages/main/Notifications/useNotifications';
import { useRef, useState } from 'react';
import useClickOutside from '../../../../hooks/useClickOutside';
import ViewNotification from '../../../../feature/Notifications/ViewNotification';
import { IUserNotifications } from '../../../../interfaces/notification';
import { routes } from '../../../../constants/routes';

export default function NotificationPopover({
  notifications,
  onClickOutside,
  show
}: {
  notifications: any;
  onClickOutside: () => void;
  show: boolean;
}) {
  const { markAllNoticesAsRead } = useNotifications();
  const markAllAsComplete = async () => {
    const response = await markAllNoticesAsRead();
    if (response.result === 'success') {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  const ref: any = useRef(null);

  if (!show) return null;

  useClickOutside(ref, onClickOutside);

  const navigate = useNavigate();

  const [selected, setSelected] = useState<IUserNotifications | null>(null);
  const [viewOpen, setViewOpen] = useState<boolean>(false);

  return (
    <>
      {viewOpen && selected && (
        <ViewNotification notification={selected} closeModal={() => setViewOpen(false)} />
      )}
      <div
        ref={ref}
        className={'absolute right-0 top-[7vh] bg-white z-20 drop-shadow-lg rounded p-2 w-[30vw]'}>
        <div className={'flex justify-between gap-2 p-2'}>
          <h5 className={'font-medium text-sm'}>Recent notifications</h5>
          {notifications &&
            notifications.filter((item: any) => item.status !== 'seen').length > 0 && (
              <button
                type={'button'}
                onClick={markAllAsComplete}
                className={`text-xs rounded-sm text-emerald-600 hover:bg-emerald-100 border border-emerald-400 px-2 py-1`}>
                Mark all as read
              </button>
            )}
        </div>
        <div className={'my-2 min-h-[20vh] max-h-[70vh] overflow-y-auto slim-scroll'}>
          {(notifications && notifications.length === 0) || !notifications ? (
            <div className={'min-h-[20vh] flex flex-col justify-center items-center'}>
              <div className={'flex justify-center mb-2'}>
                <span
                  className={
                    'w-14 h-14 rounded-full pt-2 flex justify-center items-center text-gray-400 bg-gray-100'
                  }>
                  <i className="fi fi-sr-bell-ring text-3xl"></i>
                </span>
              </div>
              <h6>No notification</h6>
            </div>
          ) : notifications && notifications.length > 0 ? (
            <div className={'grid gap-2'}>
              {notifications.slice(0, 10).map((item: any, index: number) => (
                <div
                  onClick={() => {
                    navigate(routes.NOTIFICATIONS);
                    // setSelected(item);
                    // setViewOpen(true);
                    // onClickOutside();
                  }}
                  key={index}
                  className={'flex gap-2 items-center hover:bg-gray-100 p-2 cursor-pointer'}>
                  {item.status !== 'seen' && (
                    <div className={'flex items-start'}>
                      <span className={'w-2 h-2 rounded-full bg-emerald-400'}></span>
                    </div>
                  )}
                  <div className={'w-full'}>
                    <div
                      className={`text-sm ${
                        item.status !== 'seen' ? 'font-medium' : 'font-normal'
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: item.message as string
                      }}></div>
                    <div className={'mt-2 w-full flex justify-between items-center gap-2'}>
                      <span className={'text-xs font-medium'}>
                        {item.event_type.replace(/[._]/g, ' ').toUpperCase()}
                      </span>
                      <span className={'text-xs text-gray-600'}>
                        {moment(item.time_added).format('lll')}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div className={'flex justify-center p-2'}>
          <Link to={routes.NOTIFICATIONS} className={'text-sm underline text-center'}>
            See all notifications
          </Link>
        </div>
      </div>
    </>
  );
}
