import { Link } from 'react-router-dom';
import { useContext } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import Layout from '../../../layouts/global/Layout';
import { routes } from '../../../constants/routes';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import OrganizationListCardItem from '../../../feature/Accounts/OrganizationListCardItem';
import PageTitle from '../../../components/global/PageTitle';

export default function Accounts() {
  const { profile, loading } = useContext(OrganizationContext);

  return (
    <Layout>
      <PageTitle title={'Managed Accounts'} />
      <div className={'lg:col-span-10 grid lg:grid-cols-10 gap-5'}>
        <div className={'lg:col-span-7'}>
          {loading ? (
            <div className={'flex h-[60vh] gap-3 flex-col justify-center items-center text-center'}>
              <LoadingSpinner color={'text-[#008160]'} />
              <p>Loading accounts</p>
            </div>
          ) : (
            <div className={'rounded-md border border-gray-200 bg-white p-3 md:p-5 text-gray-800'}>
              <div className={'mb-2'}>
                <h6 className={'font-semibold md:font-medium text-sm lg:text-base'}>Accounts</h6>
                <p className={'text-xs'}>
                  All accounts you belong to and can manage based on permissions
                </p>
              </div>
              <div className={'grid gap-2'}>
                {loading ? (
                  <div
                    className={
                      'p-5 h-[70vh] flex flex-col justify-center items-center text-center'
                    }>
                    <LoadingSpinner color={'text-[#008160]'} />
                    <p>Loading organizations...</p>
                  </div>
                ) : (
                  profile &&
                  profile.organizations
                    .filter((item) => item.status == 'active')
                    .map((item, index) => <OrganizationListCardItem data={item} key={index} />)
                )}
              </div>
            </div>
          )}
        </div>
        <div className={'lg:col-span-3'}>
          <div className={'rounded-2xl border border-gray-200 bg-white text-gray-500'}>
            <div
              style={{
                background: `url(/assets/account-setup-bg.png)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                opacity: '60%'
              }}
              className={'h-[30vh] w-full rounded-tl-2xl'}></div>
            <div className={'p-3 md:p-10'}>
              <h6 className={'font-semibold md:font-semibold text-sm lg:text-2xl'}>
                Build your team
              </h6>
              {/*<hr className={'my-1 md:my-2'} />*/}
              <div className={'my-2'}>
                <p className={'text-sm md:text-sm'}>
                  Invite your team members to manage your account websites and subscriptions.
                </p>
              </div>
              <div className={'flex mt-8'}>
                <Link
                  to={routes.ACCOUNT_TEAM}
                  className={
                    'rounded w-full text-center bg-[#008160] hover:bg-emerald-600 text-white font-medium px-3 md:px-4 py-2 md:py-2 text-xs md:text-sm'
                  }>
                  Manage teams
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
