import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useContext, useState } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import Modal from '../../components/global/ui/Modal';
import { useDeleteWebsite } from '../../pages/main/Websites/useDeleteWebsite';
import { IWebsiteDeleteModal } from '../../interfaces/website';
import { ORGANIZATION_ID } from '../../constants';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import WarningPrompt from '../../components/global/ui/alerts/WarningPrompt';
import { useEditWebsite } from '../../pages/main/Websites/useEditWebsite';
import moment from 'moment';

export default function FlagWebsite({ website, closeModal }: IWebsiteDeleteModal) {
  const { flagWebsite, loading, errorMessage } = useEditWebsite();
  const [message, setMessage] = useState<string | null>(null);

  const { fetchOrganizationData } = useContext(OrganizationContext);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Enter the website name')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      comment: website.flagged_comment || ''
    },
    validationSchema,
    onSubmit: async (values) => handleFlagWebsite(values)
  });

  const handleFlagWebsite = async (values: FormikValues) => {
    setMessage(null);
    const response = await flagWebsite({
      flag: 1,
      comment: values.comment,
      siteId: website.idsite
    });
    if (response?.result == 'success') {
      setMessage(response?.msg);
      // refetch organization data
      fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);

      setTimeout(() => {
        closeModal();
      }, 4000);
    }
  };

  const handleRemoveFlagWebsite = async (values: FormikValues) => {
    setMessage(null);
    const response = await flagWebsite({
      flag: 0,
      // comment: values.comment,
      siteId: website.idsite
    });
    if (response?.result == 'success') {
      setMessage(response?.msg);
      // refetch organization data
      fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);

      setTimeout(() => {
        closeModal();
      }, 4000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Confirm Flagging'} width={'w-full md:w-1/2 lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Processing...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <div>
              {website.flagged == 1 ? (
                <div>
                  <WarningPrompt>
                    <>
                      <p className={'font-medium'}>
                        This website has been flagged to be suspicious
                      </p>
                      <p className={'text-xs'}>
                        Proceeding to flag this website will inform other super admins of your
                        suspicion of this website
                      </p>
                    </>
                  </WarningPrompt>
                  <div className={'mt-3'}>
                    <label htmlFor={'comment'} className={'text-sm'}>
                      Comment
                    </label>
                    <textarea
                      rows={3}
                      name={'comment'}
                      disabled={true}
                      placeholder={'No comment'}
                      value={formik.values.comment}
                      onChange={formik.handleChange}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center text-sm"
                    />
                  </div>
                  {website.flagged == 1 && (
                    <div className={'mt-2'}>
                      <div>
                        <p className={'text-xs italic'}>
                          Flagged on: {moment(website.flagged_date).format('lll')}
                        </p>
                      </div>
                      <div className={'mt-3'}>
                        <button
                          type={'button'}
                          onClick={handleRemoveFlagWebsite}
                          // disabled={formik.values.code != registration.ver_code}
                          className={`rounded font-medium px-4 py-2 text-xs bg-[#008160] hover:bg-emerald-600 text-white
                            `}>
                          {'Remove flag'}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div>
                    <WarningPrompt>
                      <>
                        <p className={'font-medium'}>You are opting to flag this website</p>
                        <p className={'text-xs'}>
                          Proceeding to flag this website will inform other super admins of your
                          suspicion of this website
                        </p>
                      </>
                    </WarningPrompt>
                    <div className={'mt-5'}>
                      <div className={'mb-4'}>
                        <label htmlFor={'comment'} className={'text-sm'}>
                          Comment
                        </label>
                        <textarea
                          rows={3}
                          name={'comment'}
                          placeholder={'State reason for flagging this site'}
                          value={formik.values.comment}
                          onChange={formik.handleChange}
                          className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                        />
                        <p className={'text-sm text-red-500'}>
                          {formik.touched.comment && formik.errors.comment}
                        </p>
                      </div>
                      <p className={'text-sm'}>
                        Type <strong>{website.name}</strong> below to proceed
                      </p>
                      <div className={'my-1'}>
                        <input
                          type={'text'}
                          name={'name'}
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          className="w-full bg-transparent outline-0 px-3 py-2 rounded border border-gray-300 mt-1 flex items-center"
                        />

                        <p className={'text-xs text-red-500'}>
                          {formik.touched.name && formik.errors.name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={'grid gap-2'}>
                    <div className={'mt-2 flex flex-col-reverse lg:flex-row justify-between gap-2'}>
                      <button
                        type={'button'}
                        onClick={closeModal}
                        className={`px-4 py-3 rounded text-gray-700 bg-gray-200 hover:bg-gray-300 text-xs`}>
                        Cancel
                      </button>
                      <button
                        type={'button'}
                        onClick={() => formik.handleSubmit()}
                        disabled={formik.values.name !== website.name}
                        className={`rounded bg-white font-medium px-4 py-3 text-xs  ${
                          formik.values.name === '' || formik.values.name !== website.name
                            ? 'border border-red-500 text-red-500 hover:bg-transparent'
                            : 'bg-red-500 hover:bg-red-600 text-white'
                        }                      `}>
                        {loading ? 'Flagging website..' : 'Proceed'}
                      </button>
                    </div>
                    {errorMessage && <Error value={`${errorMessage}`} />}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}
