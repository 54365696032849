import React, { createContext, useContext, useState, ReactNode } from 'react';

interface NotificationContextType {
  newNotificationFlag: boolean;
  updateNotificationFlag: () => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotificationContext must be used within a NotificationProvider');
  }
  return context;
};

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationContextProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [newNotificationFlag, setNewNotificationFlag] = useState<boolean>(false);

  const updateNotificationFlag = () => {
    setNewNotificationFlag((prevFlag) => !prevFlag);
  };

  return (
    <NotificationContext.Provider value={{ newNotificationFlag, updateNotificationFlag }}>
      {children}
    </NotificationContext.Provider>
  );
};
