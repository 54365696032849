import { useState } from 'react';
import { getRequest } from '../../../requests';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const usePasswordReset = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const getResetCode = async (email: string) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }

      const gReCaptchaToken = await executeRecaptcha('reset');

      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.getResetCode',
          email,
          format: 'json',
          reCaptchaToken: gReCaptchaToken
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
        }
        setLoading(false);
        return response.data;
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMessage('Error requesting password reset');
    }
  };

  const resetPassword = async ({
    password,
    email,
    code
  }: {
    password: string;
    email: string | null;
    code: string | null;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.resetPassword',
          email,
          password,
          ver_code: code,
          format: 'json'
        }
      });

      if (response.data.result === 'error') {
        setErrorMessage('Error resetting the password');
        setLoading(false);
      }
      setLoading(false);
      return response.data;
    } catch (error: any) {
      setLoading(false);
      setErrorMessage('Error connecting to server');
    }
  };

  return { resetPassword, getResetCode, errorMessage, loading };
};
