import { useContext, useState } from 'react';
import { getRequest } from '../../../requests';
import { useAuthHeader } from 'react-auth-kit';
import { useLogoutUser } from '../../../util/global';
import { OrganizationContext } from '../../../contexts/OrganizationContext';

export const useTicketIssues = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [ticketsLoading, setTicketsLoading] = useState<boolean>(false);
  const [ticketLoading, setTicketLoading] = useState<boolean>(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const { logoutUser } = useLogoutUser();
  const { selectedOrganization } = useContext(OrganizationContext);

  const loadUserTicketIssues = async ({ account_id }: { account_id: any }) => {
    setTicketsLoading(true);
    setErrorMessage(null);
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.getTickets',
          token: token && token,
          account_id,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error' && response.data.reason == 'token_expired') {
          await logoutUser();
          setErrorMessage(response.data.msg);
          setTicketsLoading(false);
          return;
        } else if (response.data.result === 'error') {
          setErrorMessage(response.data.msg);
          setTicketsLoading(false);
          return;
        }
        setTicketsLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error loading ticket issues');
      setTicketsLoading(false);
    }
  };
  const loadUserTicketIssue = async ({ ticketId }: { ticketId: number }) => {
    setTicketLoading(true);
    setErrorMessage(null);
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.getTicket',
          token: token && token,
          ticketId,
          account_id: selectedOrganization && selectedOrganization.account_id,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg);
          setTicketLoading(false);
          return;
        }
        setTicketLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage(`Error loading ticket issue #${ticketId}`);
      setTicketLoading(false);
    }
  };
  const createTicketIssue = async ({
    message,
    department,
    title,
    siteId
  }: {
    message: string;
    department: string;
    title: string;
    siteId?: string;
  }) => {
    setTicketsLoading(true);
    setErrorMessage(null);
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.addTicket',
          token: token && token,
          message,
          department,
          account_id: selectedOrganization && selectedOrganization.account_id,
          siteId,
          title,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg);
          setTicketsLoading(false);
          return;
        }
        setTicketsLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error loading ticket issues');
      setTicketsLoading(false);
    }
  };
  const addCommentToTicketIssue = async ({
    ticketId,
    message
  }: {
    ticketId: number;
    message: string;
  }) => {
    setTicketsLoading(true);
    setErrorMessage(null);
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.addComment',
          token: token && token,
          message,
          ticketId,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg);
          setTicketsLoading(false);
          return;
        }
        setTicketsLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error adding comment to ticket issue');
      setTicketsLoading(false);
    }
  };

  const closeTicketIssue = async ({ ticketId }: { ticketId: number }) => {
    setTicketsLoading(true);
    setErrorMessage(null);
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.closeTicket',
          token: token && token,
          ticketId,
          account_id: selectedOrganization && selectedOrganization.account_id,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setTicketsLoading(false);
          return;
        }
        setTicketsLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error closing ticket issue');
      setTicketsLoading(false);
    }
  };

  const reopenTicketIssue = async ({ ticketId }: { ticketId: number }) => {
    setTicketsLoading(true);
    setErrorMessage(null);
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.reopenTicket',
          token: token && token,
          ticketId,
          account_id: selectedOrganization && selectedOrganization.account_id,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setTicketsLoading(false);
          return;
        }
        setTicketsLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error reopening ticket issue');
      setTicketsLoading(false);
    }
  };

  return {
    loadUserTicketIssues,
    loadUserTicketIssue,
    createTicketIssue,
    closeTicketIssue,
    reopenTicketIssue,
    addCommentToTicketIssue,
    ticketLoading,
    ticketsLoading,
    errorMessage
  };
};
