import { useState } from 'react';
import axios from 'axios';
import { getRequest } from '../../../requests';
import { useAuthHeader } from 'react-auth-kit';

export const useVerifyWebsite = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [installationLoading, setInstallationLoading] = useState<boolean>(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const verifyInstallation = async ({
    siteId,
    hmac,
    host,
    code
  }: {
    siteId: string;
    hmac: string;
    host: string;
    code: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.verifySite',
          token,
          siteId,
          hmac,
          host,
          code,
          verify: true,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed verifying installation');
      setLoading(false);
    }
  };

  const verifySite = async ({ websiteUrl, siteId }: { websiteUrl: string; siteId: string }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await axios.get(`${process.env.REACT_APP_VERIFY_URL}?page=${websiteUrl}`);

      if (response.data.status === 'success' && response.data.msg === 'Script is not installed') {
        setErrorMessage(response.data.msg || response.data.message);
        setLoading(false);
        return;
      } else if (
        response.data.status === 'success' &&
        response.data.msg.toLowerCase() == 'script is successfully installed'
      ) {
        const verifyResponse = await getRequest({
          url: 'index.php',
          data: {},
          params: {
            module: 'API',
            method: 'PaymentIntegration.verifySite',
            token,
            siteId,
            format: 'json'
          }
        });

        if (verifyResponse.data) {
          if (response.data.result === 'error') {
            setErrorMessage(response.data.msg || response.data.message);
            setLoading(false);
            return;
          }
          setLoading(false);
          return verifyResponse;
        }
      }
    } catch (e) {
      setErrorMessage('Error verifying website');
      setLoading(false);
    }
  };
  const installShopify = async (websiteUrl: string) => {
    setInstallationLoading(true);
    setErrorMessage(null);

    try {
      const response = await axios.get(`${process.env.REACT_APP_VERIFY_URL}?page=${websiteUrl}`);
      if (response) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setInstallationLoading(false);
          return;
        }
        setInstallationLoading(false);
        return response;
      }
    } catch (e) {
      setErrorMessage('Error verifying website');
      setLoading(false);
    }
  };

  return {
    verifySite,
    verifyInstallation,
    installShopify,
    installationLoading,
    loading,
    errorMessage
  };
};
