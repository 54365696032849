import { useEffect, useState } from 'react';
import { getRequest } from '../../../requests';
import { useAuthHeader } from 'react-auth-kit';
import { useLogoutUser } from '../../../util/global';
import { INotificationResponse } from '../../../interfaces/notification';

export const useNotifications = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [notificationsLoading, setNotificationsLoading] = useState<boolean>(false);
  const [markAllNotificationsReadLoading, setMarkAllNotificationsReadLoading] =
    useState<boolean>(false);
  const [markNotificationAsReadLoading, setMarkNotificationAsReadLoading] =
    useState<boolean>(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const { logoutUser } = useLogoutUser();

  const loadUserNotifications = async () => {
    setNotificationsLoading(true);
    setErrorMessage(null);
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.notifications',
          token: token && token,
          status: 'pending,seen',
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error' && response.data.reason == 'token_expired') {
          await logoutUser();
          setErrorMessage(response.data.msg);
          setNotificationsLoading(false);
          return;
        } else if (response.data.result === 'error') {
          setErrorMessage(response.data.msg);
          setNotificationsLoading(false);
          return;
        }
        setNotificationsLoading(false);
        return response.data as INotificationResponse;
        
      }
    } catch (e) {
      setErrorMessage('Error loading notifications');
      setNotificationsLoading(false);
    }
  };
  const checkTokenStatus = async () => {
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.notifications',
          token: token,
          format: 'json'
        }
      });
  
      if (response.data?.result === 'error' && response.data.reason === 'token_expired') {
        await logoutUser();
      }
    } catch (e) {
      // no error handling needed
    }
  };
  useEffect(() => {
    const intervalId = setInterval(checkTokenStatus, 5 * 60 * 1000); 
    return () => clearInterval(intervalId);
  }, []);
  
  const markAllNoticesAsRead = async () => {
    setMarkAllNotificationsReadLoading(true);
    setErrorMessage(null);
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.markAllNoticesAsRead',
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result == 'error') {
          setErrorMessage(response.data.msg);
          setMarkAllNotificationsReadLoading(false);
          return;
        }
        setMarkAllNotificationsReadLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error marking all notifications as read');
      setMarkAllNotificationsReadLoading(false);
    }
  };
  const markNotificationAsRead = async (notificationId: string) => {
    setMarkNotificationAsReadLoading(true);
    setErrorMessage(null);
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.markNoticeAsRead',
          token: token,
          notificationId,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result == 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setMarkNotificationAsReadLoading(false);
          return;
        }
        setMarkNotificationAsReadLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error marking notifications as read');
      setMarkNotificationAsReadLoading(false);
    }
  };

  const loadPlans = async () => {
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.notifications',
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error' && response.data.reason == 'token_expired') {
          await logoutUser();
          setErrorMessage(response.data.msg);
          return null;
        } else if (response.data.result === 'error') {
          setErrorMessage(response.data.msg);
          return null;
        }
        return response.data.plans;
      }
    } catch (e) {
      setErrorMessage('Error loading plans');
      return null;
    }
  };

  return {
    loadUserNotifications,
    markAllNoticesAsRead,
    markNotificationAsRead,
    markNotificationAsReadLoading,
    markAllNotificationsReadLoading,
    errorMessage,
    notificationsLoading,
    loadPlans
  };
};
