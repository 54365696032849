import PrimaryButton from '../../components/global/ui/buttons/PrimaryButton';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useState } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import { useAddWebsite } from '../../pages/main/Setup/useAddWebsite';
import { useQuery } from '../../util/global';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import Modal from '../../components/global/ui/Modal';

export default function ShareSnippetByEmail({ closeModal }: { closeModal: () => void }) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Recipient name is required'),
    email: Yup.string().email('Enter a valid email').required('Recipient email is required'),
    message: Yup.string().required('Message is required')
  });

  const { shareSnippetByEmail, loading, errorMessage } = useAddWebsite();

  const [message, setMessage] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: ''
    },
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onSubmit: async (values, actions) => handleUpdateWebsite(values, actions)
  });

  const query = useQuery();

  const handleUpdateWebsite = async (values: FormikValues) => {
    setMessage(null);

    const response = await shareSnippetByEmail({
      name: values.name,
      siteId:
        query.get('step') &&
        (query.get('step') === '2' || query.get('step') === '3') &&
        query.get('siteId'),
      email: values.email,
      message: values.message
    });

    if (response?.data.result === 'success') {
      setMessage(response.data.msg);
    }
  };

  return (
    <div className={'text-gray-700'}>
      <Modal closeModal={closeModal} title={'Share tracking code'} width={'lg:w-1/3'}>
        {loading ? (
          <div className={'flex flex-col justify-center items-center p-5'}>
            <LoadingSpinner color={'text-[#008160]'} />
            <h5 className={'mt-2 text-sm'}>Sharing tracking code...Please wait</h5>
          </div>
        ) : (
          <div>
            {message ? (
              <SuccessMessage message={message} />
            ) : (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  formik.handleSubmit();
                }}>
                <p className={'text-sm mb-2'}>
                  We&apos;ll send an email to this person with the snippet for verification of your
                  website
                </p>
                <div className={'grid gap-2'}>
                  <div>
                    <label className={'text-sm'}>
                      Recipient&apos;s name <span className={'text-red-500'}>*</span>
                    </label>
                    <input
                      type={'text'}
                      name={'name'}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      autoFocus={true}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                    />
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.name && formik.errors.name}
                    </p>
                  </div>
                  <div>
                    <label className={'text-sm'}>
                      Recipient&apos;s email address <span className={'text-red-500'}>*</span>
                    </label>
                    <input
                      type={'text'}
                      name={'email'}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                    />
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.email && formik.errors.email}
                    </p>
                  </div>
                  <div>
                    <label className={'text-sm'}>
                      Message <span className={'text-red-500'}>*</span>
                    </label>
                    <textarea
                      name={'message'}
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      rows={4}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                    />
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.message && formik.errors.message}
                    </p>
                  </div>
                  <div className={'mt-2'}>
                    <PrimaryButton
                      disabled={loading}
                      title={loading ? 'sending tracking code...' : 'Send message'}
                      handler={() => formik.handleSubmit()}
                    />
                  </div>
                  {errorMessage && <Error value={errorMessage} />}
                </div>
              </form>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
}
