import { usePayment } from '../../../pages/main/Pricing/usePayment';
import { useContext, useState } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import SuccessMessage from '../../../components/global/ui/SuccessMessage';
import Error from '../../../components/global/ui/alerts/Error';
import Modal from '../../../components/global/ui/Modal';
import { ORGANIZATION_ID } from '../../../constants';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import WarningPrompt from '../../../components/global/ui/alerts/WarningPrompt';

export default function DeleteCard({
  cardId,
  customerId,
  closeModal
}: {
  cardId: string;
  customerId: string;
  closeModal: () => void;
}) {
  const { deleteCard, loading, errorMessage } = usePayment();
  const [message, setMessage] = useState<string | null>(null);
  const { fetchOrganizationData } = useContext(OrganizationContext);

  const handleDeleteCard = async () => {
    setMessage(null);
    const response = await deleteCard({ cardId, customerId });
    if (response?.result === 'success') {
      setMessage(response.msg);
      // refetch organization data
      fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);

      setTimeout(() => {
        // window.location.reload();
        closeModal();
      }, 1000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Delete card'} width={'lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Deleting card...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <div>
              <div>
                <WarningPrompt>
                  <>
                    <p className={'text-sm'}>
                      You are removing this payment card from your account on Heatmap.com
                    </p>
                  </>
                </WarningPrompt>
              </div>
              <div className={'grid gap-2'}>
                <div className={'mt-2 flex flex-col-reverse lg:flex-row justify-end gap-2'}>
                  <button
                    type={'button'}
                    disabled={loading}
                    onClick={closeModal}
                    className={`px-4 py-2 rounded text-sm text-gray-700 bg-gray-200 hover:bg-gray-300`}>
                    Cancel
                  </button>
                  <button
                    type={'button'}
                    onClick={handleDeleteCard}
                    disabled={loading}
                    className={`rounded bg-white hover:bg-red-600 font-medium px-4 py-2 text-sm bg-red-500 text-white`}>
                    {loading ? 'deleting card...' : 'Yes, remove'}
                  </button>
                </div>
              </div>
            </div>
          )}
          {errorMessage && <Error value={errorMessage} />}
        </div>
      )}
    </Modal>
  );
}
