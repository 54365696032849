import { useState } from 'react';
import { getRequest } from '../../../requests';
import { useAuthHeader } from 'react-auth-kit';

export const useTeamInvites = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const retrieveAccountMembers = async ({ account_id }: { account_id: number }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.retrieveAccountMembers',
          account_id,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error retrieving account team members');
      setLoading(false);
    }
  };

  const inviteUser = async ({
    email,
    access,
    account_id,
    site_ids
  }: {
    email: string;
    access: string;
    site_ids: string;
    account_id?: number;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.sendInvite',
          email,
          access,
          account_id,
          site_ids,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error inviting user');
      setLoading(false);
    }
  };

  const removeUser = async ({ user_id, account_id }: { user_id: string; account_id?: number }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.removeUser',
          user_id,
          account_id,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error removing user');
      setLoading(false);
    }
  };

  const updateUser = async ({
    user_id,
    access,
    site_ids,
    account_id
  }: {
    user_id?: string | number;
    access: string;
    site_ids: string;
    account_id?: number;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.updateTeamMember',
          user_id,
          account_id,
          access,
          site_ids,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error updating team member');
      setLoading(false);
    }
  };

  const validateInvitation = async ({
    email,
    invite_token
  }: {
    email: string;
    invite_token: string | null;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      setLoading(true);

      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.validateInvitation',
          invite_token,
          format: 'json',
          email
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
        } else if (response.data.result === 'success') {
          localStorage.setItem('email', email);
          setLoading(false);
          return response.data;
        }
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMessage('Error validating invitation');
    }
  };

  const cancelInvitation = async ({ account_id }: { account_id: string }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      setLoading(true);

      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.cancelInvitation',
          // invite_token,
          token,
          format: 'json',
          account_id
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
        } else if (response.data.result === 'success') {
          setLoading(false);
          return response.data;
        }
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMessage('Error rejecting invitation');
    }
  };

  const leaveOrganization = async ({ account_id }: { account_id: string }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      setLoading(true);

      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.leaveManagedTeam',
          token,
          format: 'json',
          account_id
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
        } else if (response.data.result === 'success') {
          setLoading(false);
          return response.data;
        }
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMessage('Error leaving account');
    }
  };

  return {
    retrieveAccountMembers,
    inviteUser,
    removeUser,
    updateUser,
    validateInvitation,
    cancelInvitation,
    leaveOrganization,
    loading,
    errorMessage
  };
};
