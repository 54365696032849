import { IAuthFormHeader } from '../../interfaces/components';
import heatmapLogo from '../../assets/LogoOnly.svg';

export default function AuthFormHeader({ title, description }: IAuthFormHeader) {
  return (
    <div className={'flex flex-col items-center'}>
      {/* <img className="login-logo mb-2" src="/assets/logo.svg" alt="" /> */}
      <img src={heatmapLogo} alt="" className={'w-[50px] mb-2'} />
      <div className="text-align-center mb-2">
        <p className="text-xl text-black font-bold mb-1">{title}</p>
        <p className="text-sm text-black">{description}</p>
      </div>
    </div>
  );
}
