import { IButton } from '../../../../interfaces/components';

export default function PrimaryButton({ title, handler, disabled = false }: IButton) {
  return (
    <button
      type={'submit'}
      onClick={handler}
      disabled={disabled}
      className={`${
        disabled ? 'bg-emerald-200 hover:bg-emerald-200' : 'hover:bg-emerald-600 bg-[#008160]'
      } px-5 py-3 rounded w-full text-white text-sm`}>
      {title}
    </button>
  );
}
