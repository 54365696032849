import moment from 'moment/moment';
import { useContext, useEffect, useState } from 'react';
import { useTicketIssues } from '../../pages/main/SupportTickets/useTicketIssues';
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import LoadingSpinnerMini from '../../components/global/LoadingSpinnerMini';
import { useAuthUser } from 'react-auth-kit';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import * as Yup from 'yup';
import { IUserTicketIssueWithComment } from '../../interfaces/support-ticket';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import Error from '../../components/global/ui/alerts/Error';

export default function SelectedTicketIssue({
  ticket,
  setSelectedTicket,
  fetchTickets
}: {
  ticket: IUserTicketIssueWithComment;
  fetchTickets: any;
  setSelectedTicket: (item: IUserTicketIssueWithComment) => void;
}) {
  const auth = useAuthUser();

  const [resolveTicketLoading, setResolveTicketLoading] = useState<boolean>(false);
  const [addCommentLoading, setAddCommentLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [ticketDetail, setTicketDetail] = useState<IUserTicketIssueWithComment | null>(null);

  const {
    closeTicketIssue,
    reopenTicketIssue,
    addCommentToTicketIssue,
    ticketLoading,
    errorMessage
  } = useTicketIssues();

  const { selectedOrganization, profile } = useContext(OrganizationContext);

  useEffect(() => {
    if (selectedOrganization && ticket) {
      setTicketDetail(ticket);
    }
  }, [ticket]);

  const markAsResolved = async (ticketId: number) => {
    setResolveTicketLoading(true);
    setMessage(null);
    const response = await closeTicketIssue({ ticketId });
    if (response.result == 'error') {
      setResolveTicketLoading(false);
    } else if (response.result == 'success') {
      setSelectedTicket(response.data);
      setTicketDetail(response.data);
      setResolveTicketLoading(false);
    }
  };

  const reopenTicket = async (ticketId: number) => {
    setResolveTicketLoading(true);
    setMessage(null);
    const response = await reopenTicketIssue({ ticketId });
    if (response.result == 'error') {
      setResolveTicketLoading(false);
    } else if (response.result == 'success') {
      setSelectedTicket(response.data);
      setTicketDetail(response.data);
      setResolveTicketLoading(false);
    }
  };

  const addComment = async (values: FormikValues, actions: FormikHelpers<any>) => {
    setAddCommentLoading(true);
    setMessage(null);
    const response = await addCommentToTicketIssue({
      ticketId: ticket.ticket.id,
      message: values.newComment
    });
    if (response.result == 'success') {
      setSelectedTicket(response.data);
      setTicketDetail(response.data);
      actions.resetForm();
      setAddCommentLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    newComment: Yup.string()
      .max(2000, 'Comment cannot exceed 255 characters')
      .required('Comment is required')
  });

  const formik = useFormik({
    initialValues: { newComment: '' },
    onSubmit: (values: FormikValues, actions: FormikHelpers<any>) => addComment(values, actions),
    validationSchema
  });

  if (ticketLoading)
    return (
      <div
        className={
          'p-5 h-[50vh] lg:h-[80vh] flex flex-col justify-center items-center text-center'
        }>
        <LoadingSpinner color={'text-[#008160]'} />
      </div>
    );

  return (
    <div>
      <div>
        <div className={'flex justify-between gap-3 mb-2'}>
          <h6 className={'font-light italic'}>#{ticketDetail?.ticket.id}</h6>
          <span
            className={
              'rounded-full px-2 py-1 text-xs text-[#008160] border border-[#008160] capitalize'
            }>
            {ticketDetail?.ticket.department}
          </span>
        </div>
        <h6 className={'text-sm font-medium'}>{ticketDetail?.ticket.title}</h6>
        <div className={'mt-1 flex flex-col md:flex-row justify-between lg:items-center'}>
          <div>
            <span className={'text-xs'}>
              Created on {moment(ticketDetail?.ticket.created_at).format('lll')}
            </span>
          </div>
          {ticketDetail?.ticket.status == 'active' ? (
            <button
              type={'button'}
              title={
                profile.profile_details?.permission == 'read'
                  ? 'You do not have the permission to close ticket'
                  : ''
              }
              disabled={profile.profile_details?.permission == 'read' || resolveTicketLoading}
              onClick={() => markAsResolved(ticketDetail.ticket.id)}
              className={`text-sm md:text-xs mt-1 flex gap-2 rounded ${
                profile.profile_details?.permission == 'read'
                  ? 'bg-gray-100 text-gray-400'
                  : 'border border-[#008160] text-[#008160] hover:bg-emerald-100'
              } px-2 py-1 text-center justify-center`}>
              {resolveTicketLoading ? (
                <LoadingSpinnerMini color={'text-[#008160]'} />
              ) : (
                <span>Mark as resolved</span>
              )}
            </button>
          ) : (
            <div className={'flex'}>
              <div
                className={`text-xs mt-1 flex gap-2 rounded-full font-medium text-gray-600 bg-gray-100 px-2 py-1`}>
                <span>
                  <i className="fi fi-sr-comment-check text-sm text-gray-600"></i>
                </span>
                <span>Closed</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {ticketDetail?.ticket.site_url && (
        <div className={'mt-2'}>
          <a
            href={ticketDetail?.ticket.site_url}
            target={'_blank'}
            className={'flex gap-2'}
            rel="noreferrer">
            <span>
              <i className="fi fi-rr-link text-sm items-center"></i>
            </span>
            <span className={'text-sm'}>
              <span>{ticketDetail.ticket.site_name}</span>{' '}
              <span className={'hover:underline text-emerald-600'}>
                ({ticketDetail?.ticket.site_url})
              </span>
            </span>
            {ticketDetail.ticket.site_verified == 1 && (
              <span>
                <i className="fi fi-ss-badge-check text-xl md:text-xl"></i>
              </span>
            )}
          </a>
        </div>
      )}

      <div className={'mt-3'}>
        <div className={'bg-gray-100 rounded p-3 text-sm min-h-[15vh]'}>
          <div
            dangerouslySetInnerHTML={{
              __html: ticketDetail?.ticket.message as string
            }}></div>
        </div>
        <div className={'mt-3'}>
          <h6 className={'text-sm font-medium'}>Comments</h6>
          {ticketDetail?.ticket.status == 'closed' ? (
            <div className={'flex flex-col items-center gap-3 p-5'}>
              <p className={'text-sm italic text-center'}>Ticket has been closed</p>
              <button
                onClick={() => reopenTicket(ticketDetail?.ticket.id)}
                disabled={resolveTicketLoading}
                className={
                  'rounded flex justify-center items-center text-xs px-3 py-2 bg-[#008160] hover:bg-emerald-600 text-white'
                }>
                {resolveTicketLoading ? (
                  <LoadingSpinnerMini color={'text-white'} />
                ) : (
                  <span>Reopen Ticket</span>
                )}
              </button>
            </div>
          ) : (
            <div>
              <textarea
                name={'newComment'}
                id={'newComment'}
                value={formik.values.newComment}
                onChange={formik.handleChange}
                rows={4}
                placeholder={'Leave a comment...'}
                className={
                  'w-full bg-transparent outline-emerald-600 p-2 rounded border border-gray-300 mt-1 flex items-center text-sm'
                }
              />
              <div className={'mt-2 flex justify-end'}>
                <button
                  type={'button'}
                  onClick={() => formik.handleSubmit()}
                  disabled={addCommentLoading}
                  className={`rounded flex justify-center items-center text-xs px-3 py-2  ${
                    addCommentLoading
                      ? 'bg-emerald-300 hover:bg-emerald-300'
                      : 'bg-[#008160] hover:bg-emerald-600'
                  } text-white`}>
                  {addCommentLoading ? (
                    <span className={'flex gap-2 items-center'}>
                      <LoadingSpinnerMini color={'text-white'} />
                      <span>Adding comment...</span>
                    </span>
                  ) : (
                    <span>Add comment</span>
                  )}
                </button>
              </div>
            </div>
          )}
          <div className={'flex flex-col gap-3 mt-3'}>
            {ticketDetail && ticketDetail?.comments.length > 0 ? (
              ticketDetail?.comments.map((item, index) => (
                <div key={index}>
                  {item.user_type == 'admin' ? (
                    <div className={'flex justify-start'}>
                      <div
                        className={
                          'w-[90%] grid gap-2 rounded bg-emerald-50 border border-emerald-400 p-2 lg:p-3'
                        }>
                        <div className={'flex items-center gap-1'}>
                          <span
                            className={
                              'leading-none rounded-full bg-emerald-400 text-white px-2 py-1 text-xs'
                            }>
                            {'Admin'}
                          </span>
                          <span className={'text-sm'}>{item.created_by_name} </span>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.message as string
                          }}
                          className={'text-sm font-medium text-emerald-500'}></div>
                        <p className={'text-xs'}>
                          Answered {moment(item.created_at).format('lll')}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className={'flex justify-end'}>
                      <div
                        className={
                          'w-[90%] grid gap-2 rounded bg-gray-100 border border-transparent p-2 lg:p-3'
                        }>
                        <div>
                          <span
                            className={'leading-none rounded-full bg-gray-300 px-3 py-1 text-xs'}>
                            {auth()?.user_id == item?.user_id ? 'You' : item?.created_by_name}
                          </span>
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.message as string
                          }}
                          className={'text-sm'}></div>
                        <p className={'text-xs'}>{moment(item.created_at).format('lll')}</p>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div>
                <p className={'text-sm'}>No comment added</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {errorMessage && (
        <div className={'fixed right-5 bottom-5'}>
          <Error value={errorMessage} />
        </div>
      )}
    </div>
  );
}
