import React from 'react';
import { isMobile } from 'react-device-detect';

export default function OnboardingProgressBar({ step }: { step: number }) {
  return (
    <div className={'flex justify-center'}>
      <div className="flex items-center mb-2">
        <span className={'w-1 h-4 bg-[#008160]'}></span>
        <span
          className={`align-self-center progress-track ${
            step === 1 || step === 2 || step === 3 || step === 4
              ? 'progress-track-finished'
              : 'progress-track'
          } w-[13vw] lg:w-[5vw]`}
        />
        <span
          className={`w-6 h-6 rounded-full border-2 border-[#008160] ${
            step === 1
              ? 'border-[#008160]'
              : step === 2 || step === 3 || step === 4
              ? 'bg-[#008160] text-white border-[#008160]'
              : 'text-[#008160] border-[#E6E7E8]'
          } flex justify-center items-center`}>
          <span className={'text-sm'}>1</span>
        </span>
        <span
          className={`align-self-center progress-track ${
            step === 2 || step === 3 || step === 4 ? 'progress-track-finished' : 'progress-track'
          } w-[13vw] lg:w-[5vw]`}
        />
        <span
          className={`w-6 h-6 rounded-full border-2 ${
            step === 2
              ? 'border-[#008160]'
              : step === 3 || step === 4
              ? 'bg-[#008160] text-white border-[#008160]'
              : 'text-[#008160] border-[#E6E7E8]'
          } flex justify-center items-center`}>
          <span className={'text-sm'}>2</span>
        </span>
        <span
          className={`align-self-center progress-track ${
            step === 3 || step === 4 ? 'progress-track-finished' : 'progress-track'
          } w-[13vw] lg:w-[5vw]`}
        />
        <span
          className={`w-6 h-6 rounded-full border-2 ${
            step === 3
              ? 'border-[#008160]'
              : step === 4 || step === 5
              ? 'bg-[#008160] text-white border-[#008160]'
              : 'text-[#008160] border-[#E6E7E8]'
          } flex justify-center items-center`}>
          <span className={'text-sm'}>3</span>
        </span>
        {!isMobile && (
          <>
            <span
              className={`align-self-center progress-track ${
                step === 4 || step === 5 ? 'progress-track-finished' : 'progress-track'
              } w-[13vw] lg:w-[5vw]`}
            />

            <span
              className={`w-6 h-6 rounded-full border-2 ${
                step === 4
                  ? 'border-[#008160]'
                  : step === 5
                  ? 'bg-[#008160] text-white border-[#008160]'
                  : 'text-[#008160] border-[#E6E7E8]'
              } flex justify-center items-center`}>
              <span className={'text-sm'}>4</span>
            </span>
          </>
        )}
      </div>
    </div>
  );
}
