import { IInput } from '../../interfaces/components';
import { useState } from 'react';

export default function InputPassword({
  value,
  name,
  changeHandler,
  type = 'password',
  placeholder,
  autoFocus = false
}: IInput) {
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const togglePassword = () => setPasswordShow(!passwordShow);
  // const hidePassword = () => setPasswordShow(false)

  return (
    <div className="w-full rounded border border-gray-300 mt-2 flex items-center">
      <input
        value={value}
        onChange={changeHandler}
        name={name}
        type={passwordShow ? 'text' : 'password'}
        placeholder={placeholder}
        autoFocus={autoFocus}
        className={'bg-transparent w-full outline-none focus:outline-none p-3'}
      />
      <button
        type={'button'}
        onClick={togglePassword}
        className={'text-2xl text-gray-400 flex justify-center items-center pt-2 px-2'}>
        {passwordShow ? <i className="fi fi-ss-eye-crossed"></i> : <i className="fi fi-ss-eye"></i>}
      </button>
    </div>
  );
}
