import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useState } from 'react';
import Modal from '../../components/global/ui/Modal';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import PrimaryButton from '../../components/global/ui/buttons/PrimaryButton';
import Error from '../../components/global/ui/alerts/Error';
import { useFeedback } from '../../pages/main/Feedback/useFeedback';

export default function GiveFeedback({ closeModal }: { closeModal: () => void }) {
  const validationSchema = Yup.object().shape({
    message: Yup.string().required('Type something...')
  });

  const { addFeedback, loading, errorMessage } = useFeedback();

  const [message, setMessage] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      message: '',
      userId: ''
    },
    validationSchema,
    onSubmit: async (values) => handleGiveFeedback(values)
  });

  const handleGiveFeedback = async (values: FormikValues) => {
    setMessage(null);

    const response = await addFeedback({
      message: values.message
    });

    if (response?.result === 'success') {
      setMessage(response.msg || response.message);
    }
  };

  return (
    <Modal closeModal={closeModal} title={''} width={'lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Submitting feedback...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <div className={'p-5'}>
              <SuccessMessage message={'Thank you for your feedback!'} />
              <p className={'text-center text-sm mt-5'}>
                Our mission is to be the most affordable CRO tool in the industry that changes
                businesses & lives, so thanks for being part of our vision!
                <br />
                <br />
                If you have any questions directly related to your account, reach out to{' '}
                <a
                  href={'mailto:support@heatmap.com'}
                  className={'text-emerald-600 font-medium underline'}>
                  Support@Heatmap.com
                </a>
              </p>
            </div>
          ) : (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}>
              <div>
                <div className={'flex flex-col gap-3 items-center text-center mb-5'}>
                  <h4 className={'text-lg font-semibold'}>Have Any Feedback?</h4>
                  <p className={'text-sm'}>
                    We&apos;re currently in Beta, so we love feedback! Any way we can improve your
                    experience or upgrade our software, let us know!
                  </p>
                </div>
                <div className={'grid gap-2'}>
                  <div className={''}>
                    <textarea
                      rows={5}
                      name={'message'}
                      placeholder={'Share your experience with us...'}
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      autoFocus={true}
                      className="w-full bg-transparent outline-0 p-2 text-sm rounded border border-gray-300 mt-1"
                    />
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.message && formik.errors.message}
                    </p>
                  </div>
                </div>
                <div className={'mt-2'}>
                  <PrimaryButton
                    disabled={loading}
                    title={loading ? 'Submitting feedback...' : 'Submit Feedback'}
                    handler={() => formik.handleSubmit()}
                  />
                </div>
                {errorMessage && <Error value={errorMessage} />}
              </div>
            </form>
          )}
        </div>
      )}
    </Modal>
  );
}
