import '../../../styles/styles.css';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import RightSectionPlaceholder from '../../../layouts/authentication/RightSectionPlaceholder';
import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import InputPassword from '../../../components/input/InputPassword';
import AuthFormHeader from '../../../components/form/AuthFormHeader';
import { usePasswordReset } from '../ResetPassword/usePasswordReset';
import TopHeader from '../../../components/global/TopHeader';
import Error from '../../../components/global/ui/alerts/Error';
import Success from '../../../components/global/ui/alerts/Success';
import { useState } from 'react';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { routes } from '../../../constants/routes';

export default function NewPassword() {
  const { resetPassword, loading, errorMessage } = usePasswordReset();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const ver_code = localStorage.getItem('ver_code');

  const navigate = useNavigate();
  const auth = useAuthUser();

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\-\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Re-type password')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: async (values) => handleNewPassword(values)
  });

  const handleNewPassword = async (values: FormikValues) => {
    setSuccessMessage(null);
    const response = await resetPassword({
      password: values.password,
      email: localStorage.getItem('email') || auth()?.email,
      code: ver_code
    });

    if (response?.result === 'success') {
      localStorage.removeItem('ver_code');
      setSuccessMessage(response.msg || response.message);
      setTimeout(() => {
        navigate(routes.LOGIN);
      }, 2000);
    }
  };

  return (
    <div>
      <main className="main-container">
        <TopHeader />

        <div className="grid lg:grid-cols-2 sides-container">
          <section className="pt-[10vh] px-5 flex flex-col items-center">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}
              className="w-full md:w-1/2 flex flex-col">
              <AuthFormHeader
                title={'Reset Password'}
                description={
                  'You will be redirected to the login screen after resetting your password.'
                }
              />
              <InputPassword
                name={'password'}
                value={formik.values.password}
                changeHandler={formik.handleChange}
                placeholder={'Password'}
                autoFocus={true}
              />
              <p className={'text-sm text-red-500'}>
                {formik.touched.password && formik.errors.password}
              </p>
              <InputPassword
                name={'confirmPassword'}
                value={formik.values.confirmPassword}
                changeHandler={formik.handleChange}
                placeholder={'Confirm Password'}
              />
              <p className={'text-sm text-red-500'}>
                {formik.touched.confirmPassword && formik.errors.confirmPassword}
              </p>

              <div className={'mt-2 w-full'}>
                <PrimaryButton
                  disabled={loading}
                  title={loading ? 'Resetting...' : 'Reset password'}
                  handler={() => formik.handleSubmit()}
                />
              </div>

              <Link to={routes.LOGIN} className="text-secondary text-sm text-center mt-2">
                {token ? (
                  <Link to={routes.DASHBOARD} className="text-secondary">
                    Go back to dashboard
                  </Link>
                ) : (
                  <Link to={routes.LOGIN} className="text-secondary">
                    Go back to login
                  </Link>
                )}
              </Link>
              {errorMessage && <Error value={errorMessage} />}
              {successMessage && <Success value={successMessage} />}
            </form>
          </section>
          <RightSectionPlaceholder />
        </div>
      </main>
    </div>
  );
}
