import AppNavbar from '../../components/global/AppNavbar';
import SideBar from '../../components/global/SideBar';
import { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { AccountHolderSetup } from '../../feature/AccountProfile/AccountHolderSetup';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { useAuthUser } from 'react-auth-kit';
import { NotificationContextProvider } from '../../contexts/NotificationContext';

export default function Layout({ children }: { children: any }) {
  const { profile, accountHolderSetupOpen, setAccountHolderSetupOpen } =
    useContext(OrganizationContext);
  const [guestAlertShow, setGuestAlertShow] = useState<boolean>(false);
  // const [accountHolderSetupOpen, setAccountHolderSetupOpen] = useState<boolean>(false);

  useEffect(() => {
    if (profile.profile_details?.account_holder == 0) {
      setGuestAlertShow(true);
    }
  }, []);

  return (
    <main className={'h-[100vh] grid lg:grid-cols-12'}>
      {/*{accountHolderSetupOpen && (*/}
      {/*  <AccountHolderSetup closeModal={() => setAccountHolderSetupOpen(false)} />*/}
      {/*)}*/}
      <div className={'hidden lg:flex w-full lg:col-span-2 bg-white shadow'}>
        <SideBar />
      </div>
      <div className={'col-span-10 lg:col-span-10'}>
        <NotificationContextProvider>
          <AppNavbar />
        </NotificationContextProvider>
        <div className={'bg-white lg:bg-gray-100 lg:p-5 h-[88vh] overflow-auto slim-scroll'}>
          <div className={'bg-white min-h-[85vh] p-5'}>{children}</div>
        </div>
        {guestAlertShow && (
          <div
            onClick={(event: any) => {
              if (!event.target.className.includes('close')) {
                setGuestAlertShow(false);
                setAccountHolderSetupOpen(true);
              }
            }}
            className={'p-5 absolute right-0 bottom-5 w-[25vw]'}>
            <div
              className={
                'p-4 bg-white border-l-2 lg:border-l-4 border-emerald-600 rounded drop-shadow-lg flex justify-between items-start gap-2 cursor-pointer'
              }>
              <div>
                <span className="relative flex h-4 w-4">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-400 opacity-75"></span>
                  {/*<span className="relative inline-flex rounded-full h-3 w-3 bg-emerald-600"></span>*/}
                  <span className="relative inline-flex">
                    <i className="fi fi-ss-shield-check text-emerald-600"></i>
                  </span>
                </span>
              </div>
              <div>
                <p className={'text-sm font-medium text-gray-900'}>
                  You are currently a guest user on this platform
                </p>
                <p className={'text-xs text-gray-500'}>
                  Click here to setup your own account to manage your websites
                </p>
              </div>
              <button
                type={'button'}
                className={'p-2 close'}
                onClick={() => setGuestAlertShow(false)}>
                <i className="close fi fi-ss-cross text-xs"></i>
              </button>
            </div>
          </div>
        )}
      </div>
    </main>
  );
}
