import Snippet from '../Snippet';
import React from 'react';
import { ITracking } from '../../../interfaces/global';

export default function ThirdPartyBuilder({ trackingDetails }: { trackingDetails: ITracking }) {
  return (
    <div>
      <div className="mb-3">
        <p className="text-3 text-black my-8 text-center">
          Add your Snippet to your 3rd party page builder
        </p>
        <div className={'my-5 flex gap-2 justify-center items-center'}>
          <span>
            <img alt={''} src={'/assets/shield-check-filled.svg'} className={'w-8'} />
          </span>
          <span className={'text-base font-medium text-[#008160]'}>Verification complete</span>
        </div>
        <div className={'flex flex-col items-start'}>
          <h6 className={'font-medium text-sm'}>
            Copy and paste this snippet for 3rd party page builder
          </h6>
          <Snippet snippet={trackingDetails.script as string} />
        </div>
      </div>
    </div>
  );
}
