import { useContext, useRef, useState, useEffect } from 'react';
import useClickOutside from '../../../../hooks/useClickOutside';
import { IUserWebsite } from '../../../../interfaces/website';
import { OrganizationContext } from '../../../../contexts/OrganizationContext';
import { useAuthUser } from 'react-auth-kit';
import { useSubscription } from '../../../../pages/main/AccountSubscription/useSubscription';
import { ORGANIZATION_ID } from '../../../../constants';
import Modal from '../Modal';
import SuccessMessage from '../SuccessMessage';
import LoadingSpinner from '../../LoadingSpinner';

export default function WebsiteListCardMenuPopover({
  data,
  onClickOutside,
  setCancelOpen,
  setDeleteOpen,
  setSnippetOpen,
  setUpgradeOpen,
  setFlaggingOpen,
  setUpdateSubscriptionOpen,
  setEditOpen,
  setSelected,
  initialUpgradeOpen,
  show,
  subscriptionId
}: {
  data: IUserWebsite;
  setCancelOpen: (x: boolean) => void;
  setDeleteOpen: (x: boolean) => void;
  setSnippetOpen: (x: boolean) => void;
  setEditOpen: (x: boolean) => void;
  setUpgradeOpen: (x: boolean) => void;
  setFlaggingOpen: (x: boolean) => void;
  setUpdateSubscriptionOpen: (x: boolean) => void;
  setSelected: (x: IUserWebsite) => void;
  onClickOutside: () => void;
  show: boolean;
  subscriptionId?: any;
  initialUpgradeOpen: boolean; 
}) {
  const ref: any = useRef(null);

  const pRef = useRef(false);

  useClickOutside(ref, onClickOutside);

  if (!show) return null;

  const { profile } = useContext(OrganizationContext);
  const auth = useAuthUser();

  const isGuestWithReadAccess = profile.profile_details?.permission == 'read';
  const isGuestWithWriteAccess = profile.profile_details?.permission == 'write';
  const isAccountHolder = profile.profile_details?.permission == 'admin';
  const isSuperUser = auth()?.super_user === 1;
  const [loading, setLoading] = useState<boolean>(false);

  const { cancelSubscription, errorMessage } = useSubscription();
  const [message, setMessage] = useState<string | null>(null);
  const [messageShow, setMessageShow] = useState<boolean>(false);

  const { fetchOrganizationData } = useContext(OrganizationContext);

  const handleCancelSubscription = async (subscriptionId: string) => {
    setMessageShow(true);
    setLoading(true);
    setMessage(null);
    const response = await cancelSubscription({
      subscriptionId: subscriptionId
    });
    if (response.result == 'success') {
      setMessage(response.msg);
      setLoading(false);
      setTimeout(() => {
        // refetch organization data
        fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
      }, 2000);
    }
    setLoading(false);
    // setMessageShow(false);
  };

  useEffect(() => {
    if (initialUpgradeOpen) {
      setUpgradeOpen(true);
    }
  }, [initialUpgradeOpen, setUpgradeOpen]);

  // This is the function called by the "Cancel button"
  function cancel({ subscriptionId }: { subscriptionId: string }) {
    setLoading(false);
    if (process.env.NODE_ENV == 'production' && !pRef.current && typeof window !== 'undefined') {
      window.profitwell('start', {
        user_email: auth()?.email
      });
      pRef.current = true;
    }

    window
      .profitwell('init_cancellation_flow', {
        subscription_id: subscriptionId
      })
      .then((result: any) => {
        // User does not proceed to cancel
        if (result.status === 'retained' || result.status === 'aborted') {
          setLoading(false);
          return;
        }
        // User proceeds to cancel subscription

        handleCancelSubscription(subscriptionId);
      });
  }

  return (
    <>
      {(loading || message) && messageShow && (
        <Modal closeModal={() => setMessageShow(false)} title={''} width={'lg:w-1/3'}>
          {message ? (
            <SuccessMessage message={message as string} />
          ) : (
            <div className={'flex flex-col justify-center items-center p-5'}>
              <LoadingSpinner color={'text-[#008160]'} />
              <h5 className={'mt-2 text-sm'}>Cancelling subscription...Please wait</h5>
            </div>
          )}
        </Modal>
      )}
      <div
        ref={ref}
        className={
          'absolute top-5 lg:top-10 right-0 bg-white z-50 drop-shadow-lg w-[45vw] md:w-[30vw] lg:w-[15vw] '
        }>
        <div className={'flex flex-col'}>
          {isAccountHolder && data.status != 'cancelled' && data.can_upgrade && (
            <button
              type={'button'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelected(data);
                setUpgradeOpen(true);
                onClickOutside();
              }}
              className={
                'edit z-10 w-full px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
              }>
              <span>
                <i className="fi fi-rr-rocket-lunch text-sm md:text-base"></i>
              </span>
              <span>Change Plan</span>
            </button>
          )}

          {(isAccountHolder || isGuestWithWriteAccess || isSuperUser) &&
            data.status != 'cancelled' && (
              <button
                type={'button'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelected(data);
                  setEditOpen(true);
                  onClickOutside();
                }}
                className={
                  'edit z-10 w-full px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
                }>
                <span>
                  <i className="edit fi fi-rr-pencil text-sm md:text-base"></i>
                </span>
                <span>Edit website</span>
              </button>
            )}
          {(isAccountHolder || isGuestWithWriteAccess || isSuperUser) && data.verified == 0 && (
            <button
              type={'button'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelected(data);
                setDeleteOpen(true);
                onClickOutside();
              }}
              className={
                'edit z-10 w-full px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
              }>
              <span>
                <i className="edit fi fi-rr-trash text-sm md:text-lg"></i>
              </span>
              <span>Delete website</span>
            </button>
          )}
          <button
            type={'button'}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSelected(data);
              setSnippetOpen(true);
              onClickOutside();
            }}
            className={
              'edit z-10 px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
            }>
            <span>
              <i className="edit fi fi-rr-square-terminal text-sm md:text-lg"></i>
            </span>
            <span className={'edit'}>Verification snippet</span>
          </button>

          {!(isGuestWithReadAccess || isGuestWithWriteAccess) && profile.profile_details?.has_permission &&
            data.verified == 0 &&
            data.status != 'cancelled' && (
              <button
                disabled={loading}
                type={'button'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelected(data);
                  if (isSuperUser) {
                    setCancelOpen(true);
                  } else cancel({ subscriptionId });
                  onClickOutside();
                }}
                className={
                  'edit z-10 px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
                }>
                <span>
                  <i className="edit fi fi-rr-ban text-sm md:text-lg"></i>
                </span>
                <span className={'edit'}>Cancel subscription</span>
              </button>
            )}
          {isSuperUser && (
            <button
              type={'button'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelected(data);
                setFlaggingOpen(true);
                onClickOutside();
              }}
              className={
                'edit z-10 px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
              }>
              <span>
                <i className="edit fi fi-rr-flag text-sm md:text-lg"></i>
              </span>
              <span className={'edit'}>{data.flagged == 1 ? 'Remove flag' : 'Flag website'}</span>
            </button>
          )}
          {isSuperUser && data.status != 'cancelled' && (
            <button
              type={'button'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelected(data);
                setUpdateSubscriptionOpen(true);
                onClickOutside();
              }}
              className={
                'edit z-10 w-full px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
              }>
              <span>
                <i className="fi fi-rr-edit text-sm md:text-base"></i>
              </span>
              <span>Update subscription</span>
            </button>
          )}

        </div>
      </div>
    </>
  );
}
