import PrimaryButton from '../../components/global/ui/buttons/PrimaryButton';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import { useTeamInvites } from '../../pages/main/TeamManagement/useTeamInvites';
import Modal from '../../components/global/ui/Modal';
import UserAccessPopover from '../../components/global/ui/popovers/UserAccessPopover';
import { IUserAccessOption } from '../../interfaces/user';
import { userAccessOptions } from '../../constants';
import WebsitesMultiSelectListingPopover from '../../components/global/ui/popovers/WebsitesMultiSelectListingPopover';
import { IWebsiteSubscription } from '../../interfaces/subscription';

export default function InviteUser({
  closeModal,
  account_id,
  fetchData,
  websites
}: {
  closeModal: any;
  account_id?: number;
  websites: IWebsiteSubscription[];
  fetchData: () => void;
}) {
  const validationSchema = Yup.object().shape({
    access: Yup.string(),
    // siteIds: Yup.string().required(''),
    email: Yup.string().email('Enter a valid email address').required('Email address is required')
  });

  const { inviteUser, loading, errorMessage } = useTeamInvites();
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [accessOptionsShow, setAccessOptionsShow] = useState<boolean>(false);
  const [websiteSelectionShow, setWebsiteSelectionShow] = useState<boolean>(false);
  const [selectedAccess, setSelectedAccess] = useState<IUserAccessOption | null>(null);
  const [selectedWebsites, setSelectedWebsites] = useState<IWebsiteSubscription[] | []>([]);

  const hideAccessOptions = () => setAccessOptionsShow(false);

  const closeWebsiteSelectionDropdown = () => {
    setWebsiteSelectionShow(false);
  };

  const formik = useFormik({
    initialValues: {
      access: userAccessOptions && userAccessOptions[0].id,
      email: '',
      site_ids: '',
      allWebsitesSelected: false
    },
    validationSchema,
    onSubmit: async (values) => handleInviteUser(values)
  });

  const handleInviteUser = async (values: FormikValues) => {
    setMessage(null);
    if (selectedWebsites.length === 0) {
      return setError('Select at least one website to be assigned');
    }
    const response = await inviteUser({
      access: selectedAccess
        ? (selectedAccess?.id as string)
        : userAccessOptions && userAccessOptions[0].id,
      email: values.email,
      account_id,
      site_ids:
        selectedWebsites.length > 0 ? selectedWebsites.map((item) => item.idsite).toString() : ''
    });
    if (response?.result === 'success') {
      setMessage(response.msg);
      setError(null);
      // refetch team members
      fetchData();

      setTimeout(() => {
        closeModal();
      }, 3000);
    }
  };

  const removeWebsiteFromList = (id: number) => {
    setSelectedWebsites(selectedWebsites.filter((item) => item.idsite !== id));
  };

  const filteredItems = websites.filter(
    (item) => selectedWebsites.findIndex((sel) => sel.idsite === item.idsite) === -1
  );

  useEffect(() => {
    if (formik.values.allWebsitesSelected) {
      setSelectedWebsites([...websites]);
    }
  }, [formik.values.allWebsitesSelected]);

  return (
    <Modal closeModal={closeModal} title={'Invite people'} width={'w-full md:w-1/2 lg:w-1/3'}>
      {loading && selectedWebsites.length > 0 ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Sending user invitation...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}
              className={'grid gap-2'}>
              <div>
                <label className={'text-sm'}>Email address</label>
                <input
                  type={'email'}
                  name={'email'}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  autoFocus={true}
                  className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                />
                <p className={'text-sm text-red-500'}>
                  {formik.touched.email && formik.errors.email}
                </p>
              </div>
              <div>
                <label className={'text-sm'}>Select access level</label>
                <div className={'relative w-full mt-1'}>
                  <button
                    type={'button'}
                    onClick={() => setAccessOptionsShow(!accessOptionsShow)}
                    className={`border px-3 py-2 flex justify-between ${
                      accessOptionsShow ? 'border-gray-300' : 'border-gray-300'
                    } w-full rounded text-sm`}>
                    {selectedAccess ? (
                      <>
                        <span>{selectedAccess.name}</span>
                        <span>
                          <i
                            className={`fi ${
                              accessOptionsShow ? 'fi-sr-caret-up' : 'fi-sr-caret-down'
                            } text-lg`}></i>
                        </span>
                      </>
                    ) : (
                      <>
                        <span>{userAccessOptions[0].name}</span>
                        <span>
                          <i
                            className={`fi ${
                              accessOptionsShow ? 'fi-sr-caret-up' : 'fi-sr-caret-down'
                            } text-lg`}></i>
                        </span>
                      </>
                    )}
                  </button>
                  {accessOptionsShow && (
                    <UserAccessPopover
                      hideAccessOptions={hideAccessOptions}
                      show={accessOptionsShow}
                      setSelectedAccess={setSelectedAccess}
                      options={userAccessOptions}
                      onClickOutside={() => hideAccessOptions()}
                    />
                  )}
                </div>
              </div>
              <div>
                {!formik.values.allWebsitesSelected && (
                  <div className={'relative w-full mt-1'}>
                    <div
                      onClick={() => {
                        if (filteredItems.length > 0)
                          setWebsiteSelectionShow(!websiteSelectionShow);
                      }}>
                      {selectedWebsites && selectedWebsites?.length > 0 ? (
                        <div
                          className={`border p-2 ${
                            websiteSelectionShow ? 'border-gray-300' : 'border-gray-300'
                          } w-full rounded text-sm`}>
                          <h5 className={'text-sm'}>Assigned websites</h5>
                          <div className={'flex flex-wrap gap-2 mt-2'}>
                            {selectedWebsites &&
                              selectedWebsites.length > 0 &&
                              selectedWebsites.map((selected) => (
                                <span
                                  key={selected.idsite}
                                  className={
                                    'rounded-full px-3 py-1 flex items-center gap-2 bg-emerald-100'
                                  }>
                                  <span className={'text-xs'}>{selected.website?.name}</span>
                                  <span
                                    className={'cursor-pointer'}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      removeWebsiteFromList(selected.idsite);
                                    }}>
                                    <i className="fi fi-ss-cross text-xs"></i>
                                  </span>
                                </span>
                              ))}
                          </div>
                        </div>
                      ) : (
                        <div
                          className={
                            'flex gap-2 justify-center items-center p-2 rounded border border-emerald-300 bg-emerald-50 hover:bg-emerald-100 cursor-pointer'
                          }>
                          <span>
                            <i className="fi fi-rr-plus text-xs"></i>
                          </span>
                          <span className={'text-sm font-medium'}>Select Websites</span>
                        </div>
                      )}
                    </div>
                    {filteredItems.length > 0 && filteredItems.length !== websites.length && (
                      <div className={'mt-2'}>
                        <button
                          type={'button'}
                          onClick={() => setWebsiteSelectionShow(!websiteSelectionShow)}
                          className={
                            'flex gap-2 justify-center items-center px-2 py-1 rounded border border-[#008160] text-[#008160] hover:bg-[#008160] hover:text-white'
                          }>
                          <span>
                            <i className="fi fi-rr-plus text-xs"></i>
                          </span>
                          <span className={'text-xs font-medium'}>Add Websites</span>
                        </button>
                      </div>
                    )}
                   {websiteSelectionShow && (
                    <WebsitesMultiSelectListingPopover
                      show={websiteSelectionShow}
                      selectedWebsites={selectedWebsites as IWebsiteSubscription[]}
                      setSelectedWebsites={(websites: IWebsiteSubscription[]) => {
                        setSelectedWebsites(websites);
                        closeWebsiteSelectionDropdown();
                      }}
                      data={filteredItems}
                      removeItem={removeWebsiteFromList}
                      onClickOutside={() => setWebsiteSelectionShow(false)}
                    />
                  )}
                  </div>
                )}
                <div className={'mt-2 '}>
                  <div className={'flex justify-between items-center gap-5'}>
                    <div className={'flex gap-2'}>
                      <input
                        id={'allWebsitesSelected'}
                        type={'checkbox'}
                        name={'allWebsitesSelected'}
                        checked={formik.values.allWebsitesSelected}
                        onChange={formik.handleChange}
                        className={` outline-0 w-5 h-5 rounded border border-gray-300 accent-[#008160]`}
                      />
                      <label htmlFor={'allWebsitesSelected'} className={'text-sm'}>
                        Assign all websites
                      </label>
                    </div>

                    {selectedWebsites.length > 0 && !formik.values.allWebsitesSelected && (
                      <div>
                        <button
                          type={'button'}
                          onClick={() => setSelectedWebsites([])}
                          className={
                            'flex gap-2 justify-center items-center px-2 py-1 rounded border border-[#008160] text-[#008160] hover:bg-[#008160] hover:text-white'
                          }>
                          <span>
                            <i className="fi fi-rr-cross text-xs"></i>
                          </span>
                          <span className={'text-xs font-medium'}>Clear all</span>
                        </button>
                      </div>
                    )}
                  </div>
                  {error && (
                    <small className={''}>Select at least one website to be assigned</small>
                  )}
                </div>
              </div>
              <div className={`${accessOptionsShow || (websiteSelectionShow && 'mt-[15vh]')} mt-5`}>
                <PrimaryButton
                  disabled={loading}
                  title={loading ? 'Sending invite...' : 'Send invite'}
                  handler={() => formik.handleSubmit()}
                />
              </div>

              {errorMessage && <Error value={errorMessage} />}
            </form>
          )}
        </div>
      )}
    </Modal>
  );
}
