import React from 'react';
import { ISettings } from '../../interfaces/global';
import CodeEditor from '@uiw/react-textarea-code-editor';

const DynamicField: React.FC<{ fieldData: ISettings; formik: any }> = ({ fieldData, formik }) => {
  const { type, name, label } = fieldData;

  const renderField = () => {
    switch (type) {
      case 'text':
        return (
          <input
            className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300"
            type={type}
            name={name}
            onChange={formik.handleChange}
            value={formik.values[name]}
          />
        );
      case 'number':
        return (
          <input
            className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300"
            type={type}
            name={name}
            onChange={formik.handleChange}
            value={formik.values[name]}
          />
        );
      case 'textarea':
        return (
          <textarea
            className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300"
            name={name}
            cols={4}
            onChange={formik.handleChange}
            value={formik.values[name]}
          />
        );
      case 'select':
        return (
          <select
            className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300"
            name={name}
            onChange={formik.handleChange}
            value={formik.values[name]}>
            {fieldData.values &&
              fieldData.values.map((option, index) => {
                const optionKey = Object.keys(option)[0];
                const optionValue = option[optionKey];
                return (
                  <option key={index} value={optionValue}>
                    {optionKey}
                  </option>
                );
              })}
          </select>
        );
      case 'code':
        return (
          <CodeEditor
            value={formik.values[name]}
            name={name}
            language="js"
            placeholder="Please enter code."
            onChange={formik.handleChange}
            padding={15}
            className={'min-h-[28vh]'}
            style={{
              backgroundColor: '#f5f5f5',
              fontFamily:
                'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace'
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <label htmlFor={name}>{label}</label>
        {renderField()}
      </div>
    </form>
  );
};

export default DynamicField;
