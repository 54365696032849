import { useState } from 'react';
import { useAuthHeader } from 'react-auth-kit';
import { getRequest } from '../../../requests';
import toast, { Toaster } from 'react-hot-toast';

export const useAppSettings = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const loadAppSettings = async ({ option }: { option?: string }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.getAppSettings',
          token: token,
          option,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        } else if (response.data.result === 'success') {
          setLoading(false);
          return response.data.msg;
        }
      }
      setErrorMessage('No data received');
      return null;
    } catch (error) {
      console.error('Error fetching app settings:', error);
      // setErrorMessage('Failed app settings: ' + error.);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const loadWebsiteSettings = async ({ siteId }: { siteId: string | number }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.loadWebsiteSettings',
          token: token,
          siteId,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        } else if (response.data.result === 'success') {
          setLoading(false);
          return response.data.msg;
        }
      }
      setErrorMessage('No data received');
      return null;
    } catch (error) {
      setErrorMessage('Failed fetching website settings');
      setLoading(false);
    }
  };

  const updateAppSettings = async ({
    id,
    idsite,
    option,
    option_value
  }: {
    id?: number;
    idsite?: number;
    option: string;
    option_value: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.updateAppSettingsList',
          token: token,
          id: id ?? 0,
          idsite: idsite ?? 0,
          option,
          option_value,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          console.error('Failed to update settings:', response.data);
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Failed app settings');
      setLoading(false);
    }
  };

  const updateWebsiteSettings = async ({
    siteId,
    settings
  }: {
    siteId?: number;
    settings: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.updateWebsiteSettings',
          token: token,
          siteId,
          settings,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          console.error('Failed to update settings:', response.data);
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Failed app settings');
      setLoading(false);
    }
  };

  const addExemption = async ({
    option,
    idsite,
    option_value
  }: {
    option?: string;
    idsite?: string;
    option_value: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);
    setInfoMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.addAppSettingsList',
          option,
          option_value,
          idsite,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        if (response.data.result === 'error' && response.data.action === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        if (response.data.result === 'error' && response.data.action === 'info') {
          setInfoMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }

        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error adding URL or IP Exemption');
      setLoading(false);
    }
  };

    const saveWebsiteFeatures = async ({ idsite, features_list, option }: { idsite: string; features_list: string; option: string }) => {

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.saveWebsiteFeatures',
          token: token,
          idsite,
          features_list,
          option,
          format: 'json'
        }
      });
  
      if (response.data) {
        if (response.data.result === 'error') {
          toast.error(`${response.data.msg}`);
          // console.log(response.data.msg || response.data.message);
          
        } else if (response.data.result === 'success') {
          return toast.success(`${response.data.msg}`);
          // return toast.success(response.data.msg || response.data.message);
        }
      }
    } catch (error) {
      console.error('Error calling API:', error);
      return null;
    }
  };

  const updateExemption = async ({
    option,
    option_value
  }: {
    option?: string;
    option_value: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);
    setInfoMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.updateAppSettingsList',
          option,
          option_value,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        if (response.data.result === 'error' && response.data.action === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        if (response.data.result === 'error' && response.data.action === 'info') {
          setInfoMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }

        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error updating URL or IP Exemption');
      setLoading(false);
    }
  };

  const updateRule = async (selectedRule: any) => {
    if (!selectedRule) return;

    const payload = {
      template_id: selectedRule.id,
      rule_id: selectedRule.rule_id,
      title: selectedRule.title,
      description: selectedRule.description,
      placeholders: selectedRule.placeholders || {},
      example_recommendations: selectedRule.example_recommendations || { bad: "", good: "" },
      recommendation: selectedRule.recommendation,
      priority: selectedRule.priority,
      metadata: selectedRule.metadata || {},
    };

    // Log the payload to the console
    // console.log("Updating rule:", payload);

    try {
      const response = await fetch('https://6ukidahmx8.execute-api.us-west-2.amazonaws.com/Prod/insight.updateTemplate', {
        method: 'POST',
       
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (result.status === 'success') {
        toast.success('Rule updated successfully');
      } else {
        toast.error('Failed to update rule');
      }
    } catch (error) {
      // console.error('Error updating rule:', error);
      toast.error('Error updating rule');
    }
  };

  return {
    loadAppSettings,
    loadWebsiteSettings,
    updateAppSettings,
    updateWebsiteSettings,
    addExemption,
    updateExemption,
    saveWebsiteFeatures,
    updateRule,
    loading,
    errorMessage
  };
};

