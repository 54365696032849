import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '../../util/global';
import { routes } from '../../constants/routes';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { useSignIn, useSignOut } from 'react-auth-kit';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import Error from '../../components/global/ui/alerts/Error';
import { useUserSites } from '../../hooks/useUserSites';
import { CLIENT_URL, ORGANIZATION_ID } from '../../constants';

export default function LandingPageAuth() {
  const navigate = useNavigate();
  const query = useQuery();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const { loadUserProfile, loading, errorMessage } = useUserSites();
  const { setProfile, setSelectedOrganization } = useContext(OrganizationContext);

  const signIn = useSignIn();
  const signOut = useSignOut();

  useEffect(() => {
    if (query && query.get('auth_token') && query.get('hash') && query.get('email')) {
      // signOut();
      localStorage.removeItem('email');
      localStorage.removeItem('password');
      localStorage.removeItem(ORGANIZATION_ID);
      localStorage.removeItem(CLIENT_URL);
      localStorage.setItem('_auth', query && (query.get('auth_token') as string));
      loadUserProfile({
        auth_token: query.get('auth_token') as string,
        email: query.get('email') as string
      }).then((res) => {
        if (res) {
          setProfile(res);
          // setSelectedOrganization(res.organizations[0]);
          if (res.profile_details.super_user == 1) {
            signIn({
              token: query.get('auth_token') as string,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: false,
                hash: query.get('hash') as string
              },
              tokenType: 'Bearer'
            });
            setSuccessMessage('Login successful');
            setTimeout(() => {
              navigate(routes.DASHBOARD);
            }, 3000);
            // setLoading(false);
          } else if (
            res.profile_details.account_holder == 1 &&
            res.subscription_details.subscription.length === 0
          ) {
            signIn({
              token: query.get('auth_token') as string,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser:
                  res.subscription_details.card.length === 0 && res.organizations.length === 1,
                hash: query.get('hash') as string
              },
              tokenType: 'Bearer'
            });
            setSuccessMessage('Login successful');
            setTimeout(() => {
              navigate(routes.PLANS);
            }, 3000);
            // setLoading(false);
          } else {
            signIn({
              token: query.get('auth_token') as string,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: false,
                hash: query.get('hash') as string
              },
              tokenType: 'Bearer'
            });
            // setLoading(false);
            setSuccessMessage('Login successful');
            setTimeout(() => {
              navigate(routes.DASHBOARD);
            }, 3000);
          }
        }
      });
    }
  }, []);

  return (
    <div>
      <main
        style={{
          background: 'url(/assets/img/waitlist-bg.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
        className={'h-screen flex gap-3 flex-col justify-center items-center p-5 lg:p-10'}>
        <Link to={routes.DASHBOARD} className={'flex items-center gap-3'}>
          <img src="/assets/heatmap-logo.svg" alt="" />
          {/*<p className="text-secondary text-lg">Heatmap.com</p>*/}
        </Link>
        <div className={'flex flex-col gap-2 justify-center items-center'}>
          {loading ? (
            <div className={'flex justify-center items-center'}>
              <LoadingSpinner color={'text-emerald-600'} />
            </div>
          ) : successMessage ? (
            <div className={'w-full lg:w-2/3 text-center grid gap-5'}>
              <h5 className={'font-bold text-xl lg:text-2xl'}>Logged in successfully!</h5>
              <p className={'text-sm'}>redirecting...</p>
              {/*{successMessage && <SuccessMessage message={successMessage as string} />}*/}
            </div>
          ) : null}
          <div className={'w-full md:w-[20vw]'}>
            {errorMessage && <Error value={errorMessage as string} />}
          </div>
        </div>
      </main>
    </div>
  );
}
