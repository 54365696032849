import { Link } from 'react-router-dom';
import moment from 'moment';
import { useContext, useRef, useState, useEffect } from 'react';
import UpgradeSubscription from '../../AccountSubscription/UpgradeSubscription';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import EditWebsite from '../../Websites/EditWebsite';
import DeleteWebsite from '../../Websites/DeleteWebsite';
import { IUserWebsite } from '../../../interfaces/website';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { routes } from '../../../constants/routes';
import { IWebsiteSubscription } from '../../../interfaces/subscription';
import { ORGANIZATION_ID } from '../../../constants';
import { useSubscription } from '../../../pages/main/AccountSubscription/useSubscription';
import SuccessMessage from '../../../components/global/ui/SuccessMessage';
import Modal from '../../../components/global/ui/Modal';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import CancelSubscription from '../../AccountSubscription/CancelSubscription';
import UpdateSubscription from '../../Websites/UpdateSubscription';

export default function ActiveSubscriptionListCard({ item }: { item: IWebsiteSubscription }) {
  const [selected, setSelected] = useState<IWebsiteSubscription | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [upgradeOpen, setUpgradeOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [cancelOpen, setCancelOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [ updateSubscriptionOpen, setUpdateSubscriptionOpen,] = useState<boolean>(false);
  
  // dropdown const
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const auth = useAuthUser();
  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const { profile } = useContext(OrganizationContext);

  const isGuestWithReadAccess = profile.profile_details?.permission == 'read';
  const isGuestWithWriteAccess = profile.profile_details?.permission == 'write';
  const isAccountHolder = profile.profile_details?.permission == 'admin';
  const isSuperUser = auth()?.super_user === 1;

  const { cancelSubscription, errorMessage } = useSubscription();
  const [message, setMessage] = useState<string | null>(null);
  const [messageShow, setMessageShow] = useState<boolean>(false);

  const { fetchOrganizationData } = useContext(OrganizationContext);

  const ref = useRef(false);
  const handleCancelSubscription = async (subscriptionId: string) => {
    setMessageShow(true);
    setLoading(true);
    setMessage(null);
    const response = await cancelSubscription({
      subscriptionId: subscriptionId
    });
    if (response.result == 'success') {
      setMessage(response.msg);
      setLoading(false);
      setTimeout(() => {
        // refetch organization data
        fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
      }, 2000);
    }
    setLoading(false);
    // setMessageShow(false);
  };

  // This is the function called by the "Cancel button"
  function cancel({ subscriptionId }: { subscriptionId: string }) {
    setLoading(true);
    if (process.env.NODE_ENV === 'production' && !ref.current && typeof window !== 'undefined') {
      window.profitwell('start', {
        user_email: auth()?.email
      });
      ref.current = true;
    }

    window
      .profitwell('init_cancellation_flow', {
        subscription_id: subscriptionId
      })
      .then((result: any) => {
        // User does not proceed to cancel
        if (result.status === 'retained' || result.status === 'aborted') {
          setLoading(false);
          return;
        }
        // User proceeds to cancel subscription

        handleCancelSubscription(subscriptionId);
      });
  }

  return (
    <>
      {editOpen && selected && (
        <EditWebsite
          website={selected.website as IUserWebsite}
          closeModal={() => setEditOpen(false)}
        />
      )}
      {deleteOpen && selected && (
        <DeleteWebsite
          website={selected.website as IUserWebsite}
          closeModal={() => setDeleteOpen(false)}
        />
      )}
      {upgradeOpen && selected && (
        <UpgradeSubscription
          currentPlan={item.plan}
          currentInterval={item.plan_interval}
          subscriptionId={item.subscription_id}
          closeModal={() => setUpgradeOpen(false)}
        />
      )}
      {isSuperUser && cancelOpen && selected && (
        <CancelSubscription
          subscriptionId={selected.subscription_id}
          subscriptionID={selected.id as string}
          website={selected}
          closeModal={() => setCancelOpen(false)}
        />
      )}
      {updateSubscriptionOpen && selected && (
        <UpdateSubscription website={selected} subscriptionId={item.subscription_id} closeModal={() => setUpdateSubscriptionOpen(false)} />
      )}
      {(loading || message) && messageShow && (
        <Modal closeModal={() => setMessageShow(false)} title={''} width={'lg:w-1/3'}>
          {message ? (
            <SuccessMessage message={message as string} />
          ) : (
            <div className={'flex flex-col justify-center items-center p-5'}>
              <LoadingSpinner color={'text-[#008160]'} />
              <h5 className={'mt-2 text-sm'}>Cancelling subscription...Please wait</h5>
            </div>
          )}
        </Modal>
      )}

      {item.status !== 'cancelled' && (
        <div>
          <div className='hidden lg:flex'>
            <table className="min-w-full bg-white border border-gray-200">
              <tbody>
                <tr
                  className={`truncate border border-gray-200 bg-white hover:border-gray-400 ${item.website?.verified && profile ? 'cursor-pointer' : 'cursor-default'
                    } w-full rounded-md p-3 md:p-3`}>
                  <td onClick={(event: any) => {
                    /*
                    * Checks if user clicks edit, upgrade or cancel buttons to avoid redirection to dashboard
                    *  */
                    if (profile && item.website?.verified == 1 && !event.target.className.includes('edit')) {
                      let dashboardUrl = '';
                      if (item.website.early_release) {
                        dashboardUrl = `${process.env.REACT_APP_EARLY_ACCESS_DASHBOARD_URL
                          }/index.php?module=Login&action=auth&hash=${auth()?.hash}&key=${token}&idSite=${item.idsite
                          }&account_id=${item.account_id}`;
                      } else {
                        dashboardUrl = `${process.env.REACT_APP_API_URL
                          }/index.php?module=Login&action=auth&hash=${auth()?.hash}&key=${token}&idSite=${item.idsite
                          }&account_id=${item.account_id}`;
                      }
                      window.location.replace(dashboardUrl);
                    }
                  }}

                    className='p-3'>
                    <div className=''>
                      <div className='flex items-center'>
                        <span
                          className={`rounded-full px-2 py-1 text-xs font-medium border mr-3 ${item.status == 'active'
                              ? 'bg-emerald-100 text-[#008160] border-emerald-100'
                              : item.status == 'cancelled'
                                ? 'bg-red-100 text-red-500 border-red-100'
                                : 'bg-amber-100 text-amber-600 border-amber-100'
                            } uppercase`}>
                          {item.status}
                        </span>
                        <span className={'font-medium text-sm md:text-sm capitalize mr-3'}>
                          {item.website?.name}
                        </span>
                        {item.website?.ecommerce === 1 && (
                          <>
                            <span className={'w-1 h-1 rounded-full bg-gray-400 mr-3'}></span>
                            <span className={'font-normal text-base lg:text-base text-gray-500'}>
                              e-commerce
                            </span>
                          </>
                        )}

                        {item.status !== 'cancelled' && (
                          <div className='ml-3'>
                            {item.website?.verified !== 0 ? (
                              <span className={'pt-2 flex'}>
                                <i className="fi fi-ss-badge-check text-xl text-[#008160]"></i>
                              </span>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={'flex items-center gap-2 my-2'}>
                      <span>
                        <i className="fi fi-rr-link text-sm items-center"></i>
                      </span>
                      <span className={'text-sm text-gray-700'}>{item.website?.main_url}</span>
                      {isSuperUser && (
                        <>
                          <span className={'hidden lg:flex w-1 h-1 rounded-full bg-gray-400'}></span>

                          <div className={'flex gap-2'}>
                            <span>
                              <i className="fi fi-rr-user text-sm items-center"></i>
                            </span>
                            <span className={'flex flex-wrap text-sm text-gray-700'}>
                              <span>{item.created_by && item.created_by?.full_name}</span>{' '}
                              <span>({item.created_by && item.created_by?.email})</span>
                            </span>
                          </div>
                        </>
                      )}
                    </div>

                    <div className={'flex'}>
                      <div className='mr-4'>
                        <h6 className={'text-sm'}>Started: {moment(item.plan_period_start).format('ll')}</h6>
                      </div>
                      <div>
                        <h6 className={'text-sm text-right lg:text-left'}>
                          Ends: {moment(item.plan_period_end).format('ll')}
                        </h6>
                      </div>
                    </div>

                  </td>

                  <td className="align-top p-3">
                    <div className='flex items-center justify-end'>
                      {isAccountHolder && item.status != 'cancelled' && item.can_upgrade && (
                        <button
                          title={'Change Plan'}
                          type={'button'}
                          onClick={() => {
                            setSelected(item);
                            setUpgradeOpen(true);
                          }}
                          className={
                            'rounded shadow text-[13px] px-3 py-2.5 flex items-center gap-2 border border-[#008160] hover:bg-emerald-600 text-[#008160] hover:text-white'
                          }>
                          Change Plan
                        </button>
                      )}
                      {item.status != 'cancelled' && (
                        <div className='ml-2.5'>
                          {item.website?.verified != 0 ? (
                            <span >
                              {/* <i className="fi fi-ss-badge-check text-2xl text-[#008160]"></i> */}
                            </span>
                          ) : !isGuestWithReadAccess ? (
                            <span className={'flex'}>
                              <Link
                                to={`${routes.SETUP}?step=${item.website?.ecommerce == 0 ? '2' : '3'
                                  }&siteId=${item.idsite}&url=${item.website?.main_url}`}
                                className={
                                  'rounded flex justify-center items-center text-xs px-3 py-3 bg-[#008160] hover:bg-emerald-600 text-white'
                                }>
                                <span>Complete installation</span>
                              </Link>
                            </span>
                          ) : null}
                        </div>
                      )}
                      {item.website?.verified == 1 && (
                        <span className={'flex gap-2'}>
                          {item.website.early_release ? (
                            <a
                              href={`${process.env.REACT_APP_EARLY_ACCESS_DASHBOARD_URL
                                }/index.php?module=Login&action=auth&hash=${auth()?.hash
                                }&key=${token}&idSite=${item.idsite}&account_id=${item.account_id}`}
                              className={
                                'rounded shadow text-sm px-6 py-2 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                              }
                            >
                              <span className={'pt-1'}>
                                <i className="fi fi-rr-sparkles text-sm"></i>
                              </span>{' '}
                              <span className={'text-xs'}>View analytics</span>
                            </a>
                          ) : (
                            <a
                              href={`${process.env.REACT_APP_API_URL
                                }/index.php?module=Login&action=auth&hash=${auth()?.hash
                                }&key=${token}&idSite=${item.idsite}&account_id=${item.account_id}`}
                              className={
                                'rounded shadow text-sm px-6 py-2.5 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                              }
                            >
                              <i className="fi fi-ss-chart-histogram text-sm"></i>{' '}
                              <span className={'text-xs'}>View analytics</span>
                            </a>
                          )}
                        </span>
                      )}

                      <div className="relative ml-2.5" ref={dropdownRef}>
                        <button onClick={() => setDropdownOpen(!dropdownOpen)}
                          type={'button'}
                          className={
                            'edit rounded px-2 py-2.5 flex items-start gap-2 border border-transparent hover:border-gray-500 bg-gray-100 text-gray-500 text-sm'
                          }>
                          <span>
                            <i className="edit fi fi-rs-menu-dots-vertical text-sm"></i>
                          </span>
                        </button>
                        {dropdownOpen && (
                          <div className="absolute bg-white border right-0 w-52 border-gray-300 shadow-lg rounded mt-1 z-10">
                            <ul>
                              {(!(isGuestWithReadAccess || isGuestWithWriteAccess) || isSuperUser) && profile.profile_details?.has_permission &&
                                !(item.status === 'paused' || item.status === 'cancelled') && (
                                  <li
                                    onClick={() => {
                                      setSelected(item);
                                      setDropdownOpen(false);
                                      if (isSuperUser) {
                                        setCancelOpen(true);
                                      } else {
                                        cancel({ subscriptionId: item.subscription_id });
                                      }
                                    }}
                                    className="py-2 px-2 hover:bg-gray-200 cursor-pointer"
                                  >
                                    <button
                                      disabled={loading}
                                      type="button"
                                      className="edit rounded px-2 py-1 flex items-start gap-2 text-sm"
                                    >
                                      <span className="fi fi-ss-ban"></span>
                                      <span>{loading ? 'Cancelling' : 'Cancel Account'}</span>
                                    </button>
                                  </li>
                                )}
                              {(isAccountHolder || isGuestWithWriteAccess || isSuperUser) &&
                                item.status !== 'cancelled' && (
                                  <li
                                    onClick={() => {
                                      setSelected(item);
                                      setEditOpen(true);
                                      setDropdownOpen(false);
                                    }}
                                    className="py-2 px-2 hover:bg-gray-200 cursor-pointer"
                                  >
                                    <button
                                      title="Edit Website"
                                      type="button"
                                      className="edit rounded px-2 py-1 flex items-start gap-2 text-sm"
                                    >
                                      <span>
                                        <i className="edit fi fi-rr-pencil text-sm"></i>
                                      </span>
                                      <span>Edit</span>
                                    </button>
                                  </li>
                                )}
                              {(isAccountHolder || isGuestWithWriteAccess || isSuperUser) &&
                                item.website?.verified === 0 &&
                                item.status !== 'cancelled' && (
                                  <li
                                    onClick={() => {
                                      setSelected(item);
                                      setDeleteOpen(true);
                                      setDropdownOpen(false);
                                    }}
                                    className="py-2 px-2 hover:bg-gray-200 cursor-pointer"
                                  >
                                    <button
                                      title="Delete website"
                                      type="button"
                                      className="edit rounded px-2 py-1 flex items-start gap-2 text-sm"
                                    >
                                      <span>
                                        <i className="edit fi fi-rr-trash text-sm"></i>
                                      </span>
                                      <span>Delete</span>
                                    </button>
                                  </li>
                                )}
                                {isSuperUser && item.status != 'cancelled' && (
                                <button
                                  type={'button'}
                                  onClick={(e) => {
                                    setDropdownOpen(false);
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSelected(item);
                                    setUpdateSubscriptionOpen(true);
                                  }}
                                  className={
                                    'edit z-10 w-full px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
                                  }>
                                  <span>
                                    <i className="fi fi-rr-edit text-sm md:text-base"></i>
                                  </span>
                                  <span>Update Subscription</span>
                                </button>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>


                    </div>


                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>


      )}
    </>
  );
}
