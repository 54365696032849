import PrimaryButton from '../../components/global/ui/buttons/PrimaryButton';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useEditUser } from '../../pages/main/AccountProfile/useEditUser';
import { useContext, useState } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import Modal from '../../components/global/ui/Modal';
import { IUserEditModal } from '../../interfaces/user';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { ORGANIZATION_ID } from '../../constants';
// import countriesData from '../../data/countries.json';

export default function EditProfile({ user, closeModal }: IUserEditModal) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Enter your full name'),
    username: Yup.string().required('Enter a username'),
    email: Yup.string().email('Enter a valid email address').required('Email address is required'),
    // nationality: Yup.string(),
    billing_address: Yup.string()
  });

  const { editUser, loading, errorMessage } = useEditUser();
  const { fetchOrganizationData } = useContext(OrganizationContext);

  const [message, setMessage] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      name: user.full_name || '',
      username: user.username || '',
      email: user.email || '',
      phone: user.phone || '',
      billing_address: user.billing_address || ''
      // nationality: user.nationality || ''
    },
    validationSchema,
    onSubmit: async (values) => handleUpdateProfile(values)
  });

  const handleUpdateProfile = async (values: FormikValues) => {
    setMessage(null);

    const response = await editUser({
      name: values.name,
      email: values.email,
      billing_address: values.billing_address,
      phone: values.phone
      // nationality: values.nationality
    });
    if (response?.data.result === 'success') {
      setMessage(response.data.msg);
      // refetch organization data
      fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);

      setTimeout(() => {
        closeModal();
      }, 1000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Edit profile'} width={'lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Updating your profile...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}
              className={'grid grid-cols-2 gap-2'}>
              <div className={'col-span-2'}>
                <label className={'text-sm'}>Full name</label>
                <input
                  type={'text'}
                  name={'name'}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  autoFocus={true}
                  className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                />
                <p className={'text-sm text-red-500'}>
                  {formik.touched.name && formik.errors.name}
                </p>
              </div>
              <div className={'col-span-2'}>
                <label className={'text-sm'}>Username</label>
                <input
                  type={'text'}
                  name={'username'}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  disabled={true}
                  title={'Username cannot be changed'}
                  className="w-full bg-gray-200 outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                />
                <p className={'text-sm text-red-500'}>
                  {formik.touched.username && formik.errors.username}
                </p>
              </div>
              <div>
                <label className={'text-sm'}>Phone number</label>
                <input
                  type={'tel'}
                  name={'phone'}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                />
                <p className={'text-sm text-red-500'}>
                  {formik.touched.phone && formik.errors.phone}
                </p>
              </div>
              <div>
                <label className={'text-sm'}>Email address</label>
                <input
                  type={'email'}
                  name={'email'}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                />
                <p className={'text-sm text-red-500'}>
                  {formik.touched.email && formik.errors.email}
                </p>
              </div>
              <div className={'col-span-2'}>
                <label className={'text-sm'}>Billing address</label>
                <textarea
                  rows={2}
                  name={'billing_address'}
                  value={formik.values.billing_address}
                  onChange={formik.handleChange}
                  className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                />
                <p className={'text-sm text-red-500'}>
                  {formik.touched.billing_address && formik.errors.billing_address}
                </p>
              </div>
              {/*<div className={'col-span-2'}>*/}
              {/*  <label className={'text-sm'}>Country</label>*/}
              {/*  <select*/}
              {/*    className={'p-2 w-full rounded border border-gray-300'}*/}
              {/*    name={'nationality'}*/}
              {/*    value={formik.values.nationality}*/}
              {/*    onChange={formik.handleChange}>*/}
              {/*    {countriesData &&*/}
              {/*      countriesData.map((item, index) => (*/}
              {/*        <option key={index} value={item.name}>*/}
              {/*          {item.name}*/}
              {/*        </option>*/}
              {/*      ))}*/}
              {/*  </select>*/}
              {/*  <p className={'text-sm text-red-500'}>*/}
              {/*    {formik.touched.nationality && formik.errors.nationality}*/}
              {/*  </p>*/}
              {/*</div>*/}
              <div className={'mt-2 col-span-2'}>
                <PrimaryButton
                  disabled={loading}
                  title={loading ? 'Updating profile...' : 'Update profile'}
                  handler={() => formik.handleSubmit()}
                />
                {errorMessage && <Error value={errorMessage} />}
              </div>
            </form>
          )}
        </div>
      )}
    </Modal>
  );
}
