import React from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IWebsitePlatformChart } from '../../../../interfaces/analytic';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function WebsitePlatforms({ data }: { data: IWebsitePlatformChart }) {
  const options = {
    elements: {
      bar: {
        borderWidth: 1.5
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: true,
        text: 'Website Platforms'
      }
    }
  };

  const dataStats = {
    labels: Object.keys(data),
    datasets: [
      {
        label: 'Active',
        data: Object.values(data).map((item) => item.active),
        backgroundColor: 'rgba(0, 129, 96, 0.3)',
        borderColor: 'rgb(0, 129, 96)'
      },
      {
        label: 'Pending',
        data: Object.values(data).map((item) => item.pending),
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        borderColor: 'rgb(255, 206, 86)'
      },
      {
        label: 'Paused',
        data: Object.values(data).map((item) => item.paused),
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgb(255, 99, 132)'
      }
    ]
  };
  return <Bar options={options} data={dataStats} />;
}
