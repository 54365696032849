import React, { useEffect, useState, useMemo } from 'react';
import { IWebsiteSubscription } from '../../interfaces/subscription';

interface SearchFormProps {
  websites: IWebsiteSubscription[];
  setSearchResults: React.Dispatch<React.SetStateAction<IWebsiteSubscription[]>>;
}

const ALL_OPTION = 'All';

export default function SearchWebsites({ websites, setSearchResults }: SearchFormProps) {
  const [filterShow, setFilterShow] = useState<boolean>(false);
  const [filters, setFilters] = useState({
    status: ALL_OPTION,
    type: ALL_OPTION,
    verificationStatus: ALL_OPTION,
    platform: ALL_OPTION,
    plan: ALL_OPTION,
    account: ALL_OPTION
  });

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => e.preventDefault();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) return setSearchResults(websites);
    const results = websites.filter(
      (item) =>
        item.website?.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.website?.main_url?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSearchResults(results);
  };

  const handleFilterChange = (filterName: keyof typeof filters, value: string) => {
    console.log(`Changing filter ${filterName} to ${value}`);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  useEffect(() => {
    const newFilteredData = websites?.filter((item) => {
      return (
        (filters.status === ALL_OPTION || item.status === filters.status) &&
        (filters.platform === ALL_OPTION || item.website?.platform === filters.platform) &&
        (filters.account === ALL_OPTION || item.created_by.full_name === filters.account) &&
        (filters.plan === ALL_OPTION || item.plan_name === filters.plan) &&
        (filters.verificationStatus === ALL_OPTION ||
          (filters.verificationStatus === 'Verified' && item.website?.verified === 1) ||
          (filters.verificationStatus === 'Pending' && item.website?.verified === 0))
      );
    }) || [];

    setSearchResults(newFilteredData);
  }, [filters, websites, setSearchResults]);

  const statusOptions = useMemo(() => [ALL_OPTION, ...new Set(websites?.map((item) => item.status))], [websites]);
  const platformOptions = useMemo(() => [ALL_OPTION, ...new Set(websites?.map((item) => item.website?.platform))], [websites]);
  const accountOptions = useMemo(() => [ALL_OPTION, ...new Set(websites?.map((item) => item.created_by.full_name))], [websites]);
  const verificationOptions = useMemo(() => [ALL_OPTION, ...new Set(websites?.map((item) => (item.website?.verified === 0 ? 'Pending' : 'Verified')))], [websites]);
  const planOptions = useMemo(() => [ALL_OPTION, ...new Set(websites?.map(item => item.plan_name) || [])], [websites]);

  return (
    <div className={'flex flex-col gap-2'}>
      <div className={'flex gap-2'}>
        <form
          onSubmit={handleSearch}
          className={
            'border border-gray-300 bg-gray-100 rounded px-4 py-2 flex gap-3 items-start w-full lg:w-[20vw]'
          }>
          <span>
            <i className="fi fi-rr-search"></i>
          </span>
          <input
            type={'search'}
            id={'search'}
            placeholder={'Search websites'}
            className={'bg-transparent w-full text-sm'}
            onChange={handleSearchChange}
          />
        </form>
        <button
          type={'button'}
          onClick={() => setFilterShow(!filterShow)}
          className={`rounded px-3 py-2 flex items-center gap-2 border border-[#008160] ${
            filterShow ? 'bg-emerald-100' : ''
          } hover:bg-emerald-100 text-[#008160]`}>
          <span>
            <i className="fi fi-rr-filter text-xs"></i>
          </span>
          <span className={'text-xs'}>Filter websites</span>
        </button>
      </div>
      {filterShow && (
        <div>
          <h4 className={'text-sm'}>Filter results</h4>
          <div className={'flex gap-2 mt-1'}>
            <div>
              <label className={'text-sm'}>Platform</label>
              <select
                className={`px-2 py-2 w-full rounded border text-sm border-gray-300 capitalize`}
                name={'platform'}
                value={filters.platform}
                onChange={(event) => handleFilterChange('platform', event.target.value)}>
                {platformOptions &&
                  platformOptions.filter(item => item).map((item: any, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label className={'text-sm'}>Status</label>
              <select
              className={`px-2 py-2 w-full rounded border text-sm border-gray-300 capitalize`}
              name={'status'}
              value={filters.status}
              onChange={(event) => handleFilterChange('status', event.target.value)}>
              {statusOptions &&
                statusOptions.filter(item => item).map((item: any, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
            </select>
            </div>
            <div>
              <label className={'text-sm'}>Verification</label>
              <select
                className={`px-2 py-2 w-full rounded border text-sm border-gray-300`}
                name={'verificationStatus'}
                value={filters.verificationStatus}
                onChange={(event) => handleFilterChange('verificationStatus', event.target.value)}>
                {verificationOptions &&
                  verificationOptions.filter(item => item).map((item: any, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
            <div>
            <label className={'text-sm'}>Plan</label>
            <select
              className={`px-2 py-2 w-full rounded border text-sm border-gray-300`}
              name={'plan'}
              value={filters.plan}
              onChange={(event) => handleFilterChange('plan', event.target.value)}>
              {planOptions.map((item: string, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
            <div>
              <label className={'text-sm'}>Account</label>
              <select
                className={`px-2 py-2 w-full rounded border text-sm border-gray-300`}
                name={'account'}
                value={filters.account}
                onChange={(event) => handleFilterChange('account', event.target.value)}>
                {accountOptions &&
                  accountOptions.filter(item => item).map((item: any, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}