import { IUserAccessOption } from '../interfaces/user';
import shopifyLogo from '../assets/brands/shopify.png';
import woocommerceLogo from '../assets/brands/Woocommerce.png';
import otherPlatformLogo from '../assets/brands/link.png';
import bigCommerceLogo from '../assets/brands/bigcommerce.png';
import webflowLogo from '../assets/brands/Webflow.png';
import wordpressLogo from '../assets/brands/Wordpress.png';
import cartIcon from '../assets/cart.png';
import leadgenIcon from '../assets/envelope.png';

export const RE_DIGIT = new RegExp(/^\d+$/);

export const USER_ROUTE = '/v5637true';

export const userTypes = [
  {
    name: 'User',
    id: 'user',
    description: 'User clients of the platform'
  },
  {
    name: 'Support',
    id: 'support',
    description: 'User who can respond to client issues'
  },
   {
    name: 'Marketing',
    id: 'marketing',
    description: 'Users who belongs to the marketing team'
  },
  {
    name: 'Administrator',
    id: 'admin',
    description: 'User with superuser access'
  },
 
];

export const userAccessOptions: IUserAccessOption[] = [
  {
    name: 'Read access',
    id: 'read',
    description: 'User can view analytics and create tickets'
  },
  {
    name: 'Write access',
    id: 'write',
    description: 'User can view analytics, manage websites and create tickets'
  }
];

export const websiteGoals = [
  {
    id: 1,
    name: 'Ecommerce Website',
    description: 'Customers directly pay for products on your site',
    icon: cartIcon
  },
  {
    id: 2,
    name: 'Lead Generation Website',
    description: 'Collect customer info but no direct purchases on-site',
    icon: leadgenIcon
  }
];

export const brands = [
  {
    id: 1,
    name: 'Shopify',
    key: 'shopify',
    icon: shopifyLogo
  },
  {
    id: 5,
    name: 'BigCommerce',
    key: 'bigcommerce',
    icon: bigCommerceLogo
  },
  {
    id: 3,
    name: 'Woocommerce',
    key: 'woocommerce',
    icon: woocommerceLogo
  },
  {
    id: 4,
    name: 'WordPress',
    key: 'wordpress',
    icon: wordpressLogo
  },
  {
    id: 6,
    name: 'Other Platforms',
    key: 'other',
    icon: otherPlatformLogo
  },
  {
    id: 2,
    name: 'Webflow',
    key: 'webflow',
    icon: webflowLogo
  }
];

export const SUBSCRIPTION_ID_KEY = 'webmtmsubId';
export const ORGANIZATION_ID = 'webmtmactId';
export const CLIENT_URL = 'webmtmurl';

export const LANDING_PAGE_URL = 'https://heatmap.com';

export const freemium = [
  'Revenue-based heatmap & scrollmap for one page',
  '15 standard filters',
  '60 day data storage',
  'Custom filters',
  'Interactive mode',
  'Comparison mode',
  'Site speed tracking',
  'Email support',
  'Works with any CMS'
];

export const pro = [
  'Interactive revenue-based heatmaps and scrollmaps for every page',
  'Revenue-based screen recordings',
  'Unlimited custom data filters',
  'Code quality and site speed tracking',
  '365 day data storage',
  'Weekly AI recommendations',
  'Element naming and data table export (CSV)',
  'Integrations with split testing platforms',
  'Dedicated Slack channel',
  'Works with any CMS or custom build'
  
];

export const agency = [
  'Everything in the Pro plan, plus:',
  'Data stored forever',
  'Private API data exports',
  'Custom, real-time AI modeling',
  'Early access to beta features',
  'Dedicated CRO Manager',
  'Custom data compliance implementations'
];

export const basicPlanItems = [
  'Revenue-based heatmaps & scrollmaps',
  'Revenue-tracked screen recordings',
  'Chrome Extension for Live Interactions',
  'Unlimited Users Per Account',
  'Access to Education Center',
  'Account Manager & CRO Manager'
];
export const businessPlanItems = [
  'Revenue-based heatmaps & scrollmaps',
  'Revenue-tracked screen recordings',
  'Chrome Extension for Live Interactions',
  'Unlimited Users Per Account',
  'Access to Education Center',
  'Account Manager & CRO Manager'
];
export const premiumPlanItems = [
  'Revenue-based heatmaps & scrollmaps',
  'Revenue-tracked screen recordings',
  'Chrome Extension for Live Interactions',
  'Unlimited Users Per Account',
  'Access to Education Center',
  'Dedicated Account Manager'
];
export const freemiumPlanItems = [
  'Everything is free',
  'Revenue-tracked screen recordings',
  'Chrome Extension for Live Interactions',
  'Unlimited Users Per Account',
  'Access to Education Center',
  'Dedicated Account Manager'
];
export const agencyPlanItems = ['Everything in Grow Plan', 'For clients with 5 or more websites'];

export const websiteSettingsTabs = [
  {
    name: 'Basic',
    id: 1
  },
  {
    name: 'Advanced',
    id: 2
  }
];

export const analyticTabs = [
  {
    name: 'Onboarding',
    id: 1
  },
  {
    name: 'Subscriptions',
    id: 2
  },
  {
    name: 'Cancellations',
    id: 3
  }
];

export const openAITabs = [
  {
    name: 'Training',
    id: 1
  },
  {
    name: 'Prompt',
    id: 2
  }
];

export const settingsTabs = [
  {
    name: 'URL & IP Exemptions',
    id: 1
  },
  {
    name: 'Subscription Capping',
    id: 2
  },
  {
    name: 'Website Settings',
    id: 3
  },
  {
    name: 'Feature Management',
    id: 4
  }
];

export const GOOGLE_RECAPTCHA_OPTIONS: any = {
  async: false,
  defer: false,
  appendTo: 'head',
  nonce: undefined
};

export const AGENCY_BOOKING_URL = 'https://calendly.com/heatmap/30min?month=2023-05';

export const CANCELLATION_BOOKING_URL =
  'https://calendly.com/chaseheatmap/heatmap-com-onboarding-1-1';

export const SHOPIFY_INSTALLATION_VIDEO_URL =
  'https://player.vimeo.com/video/922550401?h=7e00bb6cf2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';

export const WORDPRESS_INSTALLATION_VIDEO_URL =
  'https://player.vimeo.com/video/843264545?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';

export const periods = [
  {
    name: 'This Week',
    id: 2
  },
  {
    name: 'This Year',
    id: 3
  }
];

export const cancellationTypes = [
  {
    name: 'Attempted Cancellations',
    id: 1
  },
  {
    name: 'Recovered Cancellations',
    id: 2
  },
  {
    name: 'Lost Cancellations',
    id: 3
  }
];

export const clientDetailsTabs = [
  {
    id: 1,
    name: 'Account Profile'
  },
  {
    id: 2,
    name: 'Security'
  },
  {
    id: 3,
    name: 'Websites'
  },
  {
    id: 4,
    name: 'Team Members'
  },
  {
    id: 5,
    name: 'Accounts'
  }
];

export const cancelledSubscriptionsTabs = [
  {
    id: 1,
    name: 'Today'
  },
  {
    id: 2,
    name: 'This Week'
  },
  {
    id: 3,
    name: 'This Year'
  }
];

export const cancellationReasons = [
  { id: '1', reason: 'It was difficult to set up' },
  { id: '2', reason: 'Inaccurate Screenshots' },
  { id: '3', reason: 'Inaccurate Data' },
  { id: '4', reason: 'New screenshots not working' },
  { id: '5', reason: 'Cannot view Navigation or Cart' },
  { id: '6', reason: 'App is slowing down my website' },
  { id: '7', reason: "Screen recordings aren't accurate" },
  { id: '8', reason: 'Software is expensive' },
  { id: '9', reason: "I don't know how to read the data" },
  { id: '10', reason: "I don't use the app" },
  { id: '11', reason: "There are features I need that aren't offered" },
  { id: '12', reason: 'Other' }
];

export const allMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const allWeekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const OPEN_AI_PROMPT_API_URL =
  'https://8vg6euvpxa.execute-api.us-west-2.amazonaws.com/Prod/ai.context';

export const AMPLITUDE_API_KEY = '1f3e5578bd47c13fc518f8cd190d8dc4';

export const INTERCOM_APP_ID = 'zmls3dse';

export const AMPLITUDE_EVENTS = {
  VISIT_ACCOUNT_CREATION_PAGE: 'VISIT_ACCOUNT_CREATION_PAGE',
  COMPLETED_ACCOUNT_CREATION: 'COMPLETED_ACCOUNT_CREATION',
  CARD_ADDED_TO_ACCOUNT: 'CARD_ADDED_TO_ACCOUNT',
  SITE_VERIFIED: 'SITE_VERIFIED'
};

export const GOOGLE_TAG = 'GTM-MH96WFMJ';
