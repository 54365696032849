import { useState } from 'react';
import { getRequest } from '../../../requests';
import { useAuthHeader } from 'react-auth-kit';

export const useCoupon = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [promoLoading, setPromoLoading] = useState(true);
  const [couponLoading, setCouponLoading] = useState(true);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const retrieveCoupons = async () => {
    setCouponLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.retrieveCoupon',
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setCouponLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setCouponLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed retrieving coupons');
      setCouponLoading(false);
    }
  };
  const retrievePromos = async () => {
    setPromoLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.retrievePromo',
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setPromoLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setPromoLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed retrieving promos');
      setPromoLoading(false);
    }
  };

  const deleteCoupon = async (couponId: string) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.deleteCoupon',
          couponId,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed deleting a coupon');
      setLoading(false);
    }
  };

  const deletePromo = async (promoId: string) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.deletePromo',
          promoId,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed deleting a promo');
      setLoading(false);
    }
  };

  const assignCoupon = async (customerId: string, coupon: string) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.assignCoupon',
          customerId,
          token: token,
          coupon,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed applying a coupon');
      setLoading(false);
    }
  };

  const createCoupon = async ({
    title,
    amount,
    expiry,
    max_usage,
    min_plan,
    description
  }: {
    title: string;
    amount: string;
    expiry: string;
    max_usage : number;
    min_plan:string;
    description: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.createCoupon',
          title,
          amount,
          expiry,
          max_usage,
          min_plan,
          description,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed creating a coupon');
      setLoading(false);
    }
  };

  const editCoupon = async ({ state, couponId }: { state: boolean; couponId: string }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.updateCoupon',
          state,
          couponId,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed updating a coupon');
      setLoading(false);
    }
  };

  const createPromo = async ({
    promoCode,
    couponId,
    expiry
  }: {
    promoCode: string;
    couponId: string;
    expiry: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.createPromo',
          promoCode,
          couponId,
          expiry,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed creating a promo code');
      setLoading(false);
    }
  };

  const editPromo = async ({ state, promotionId }: { state: boolean; promotionId: string }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.updatePromo',
          state,
          promotionId,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed updating a coupon');
      setLoading(false);
    }
  };

  const verifyCoupon = async (coupon: string, amount: string) => {
    setLoading(true);
    setErrorMessage(null);
  
    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.verifyCoupon',
          token: token,
          coupon,
          amount,
          format: 'json'
        }
      });
  
      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed verifying coupon');
      setLoading(false);
    }
  };

  return {
    createPromo,
    deleteCoupon,
    deletePromo,
    retrieveCoupons,
    retrievePromos,
    createCoupon,
    editCoupon,
    editPromo,
    assignCoupon,
    verifyCoupon,
    errorMessage,
    loading,
    promoLoading,
    couponLoading
  };
};
