import { IFormikSubscriptionCap, ISubscriptionCaps } from '../../interfaces/subscription';
import { useAppSettings } from '../../pages/main/PlatformSettings/useAppSettings';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import { useContext, useState } from 'react';
import { ORGANIZATION_ID } from '../../constants';
import { OrganizationContext } from '../../contexts/OrganizationContext';

export default function SubscriptionCappingSettings({
  data,
  loading
}: {
  data: ISubscriptionCaps;
  loading: boolean;
}) {
  const [message, setMessage] = useState<string | null>(null);
  const { updateAppSettings, errorMessage, loading: updateLoading } = useAppSettings();
  const plans = ['Basic', 'Starter', 'Grow', 'Scale', 'ScalePlus'];
  const caps = [{ sessions: 'Session' }, { revenue: 'Revenue' }, { teams: 'Teams' }];
  const { fetchOrganizationData } = useContext(OrganizationContext);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Website name is required'),
    url: Yup.string().required('URL is required'),
    isEcommerce: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      sessionsBasic: (data?.Basic.sessions && data?.Basic.sessions) || 0,
      sessionsStarter: (data?.Starter.sessions && data?.Starter.sessions) || 0,
      sessionsGrow: (data?.Grow.sessions && data?.Grow.sessions) || 0,
      sessionsScale: (data?.Scale.sessions && data?.Scale.sessions) || 0,
      sessionsScalePlus: (data?.ScalePlus.sessions && data?.ScalePlus.sessions) || 0,
      revenueBasic: (data?.Basic.revenue && data?.Basic.revenue) || 0,
      revenueStarter: (data?.Starter.revenue && data?.Starter.revenue) || 0,
      revenueGrow: (data?.Grow.revenue && data?.Grow.revenue) || 0,
      revenueScale: (data?.Scale.revenue && data?.Scale.revenue) || 0,
      revenueScalePlus: (data?.ScalePlus.revenue && data?.ScalePlus.revenue) || 0,
      teamsBasic: (data?.Basic.teams && data?.Basic.teams) || 0,
      teamsStarter: (data?.Starter.teams && data?.Starter.teams) || 0,
      teamsGrow: (data?.Grow.teams && data?.Grow.teams) || 0,
      teamsScale: (data?.Scale.teams && data?.Scale.teams) || 0,
      teamsScalePlus: (data?.ScalePlus.teams && data?.ScalePlus.teams) || 0
    },
    validationSchema,
    onSubmit: async (values) => handleUpdateSessionCaps(values)
  });

  const handleUpdateSessionCaps = async (values: FormikValues) => {
    setMessage(null);
    try {
      let cap_data = plans.reduce((acc: any, plan: string) => {
        acc[plan] = {};
        caps.forEach((cap) => {
          const key = Object.keys(cap)[0];
          const fieldName = `${key}${plan}`;
          acc[plan][key] = values[fieldName];
        });

        return acc;
      }, {});

      cap_data = JSON.stringify([{ ...cap_data }]);
      const response = await updateAppSettings({ option: 'price_gating', option_value: cap_data });
      // if (errorMessage) alert(errorMessage);
      if (response?.result === 'success') {
        setMessage(response.msg);
        // refetch organization data
        fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
        setTimeout(() => {
          setMessage(null);
        }, 3000);
      }
    } catch (e) {
      console.log();
    }
  };

  return (
    <div>
    {loading ? (
      <div className={'p-5 h-[70vh] flex flex-col justify-center items-center text-center'}>
        <LoadingSpinner color={'text-[#008160]'} />
      </div>
    ) : (
      <div>
        {updateLoading ? (
          <div className={'h-[60vh] flex flex-col justify-center items-center p-5'}>
            <LoadingSpinner color={'text-[#008160]'} />
            <h5 className={'mt-2 text-sm text-center'}>Updating subscription cappings...Please wait</h5>
          </div>
        ) : message ? (
          <div className={'h-[60vh] flex flex-col justify-center items-center p-5'}>
          <SuccessMessage message={message} />
        </div>
        ) : (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await handleUpdateSessionCaps(formik.values);
            }}>
            <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'}>
              {caps.map((cap: typeof caps[0]) => {
                const key = Object.keys(cap)[0];
                const capName = cap[key as keyof typeof cap] as string;
                return (
                  <div className={'p-3 sm:p-5 bg-gray-100 rounded-md'} key={key}>
                    <h4 className={'font-medium text-sm sm:text-base'}>{capName}</h4>
                    <div className={'flex flex-col gap-3 mt-2'}>
                      {plans.map((plan) => {
                        return (
                          <div key={plan.toLowerCase()}>
                            <label className={'text-xs sm:text-sm'}>{plan}</label>
                            <input
                              type={'number'}
                              name={`${key}${plan}`}
                              value={
                                formik.values[`${key}${plan}` as keyof IFormikSubscriptionCap]
                              }
                              onChange={formik.handleChange}
                              className="w-full bg-white outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center text-sm"
                            />
                            <p className={'text-xs text-red-500'}>
                              {formik.touched[`${key}${plan}` as keyof IFormikSubscriptionCap] &&
                                formik.errors[`${key}${plan}` as keyof IFormikSubscriptionCap]}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={'mt-5 flex justify-center sm:justify-end'}>
              <button
                type="submit"
                className={`rounded px-3 py-2 flex items-center justify-center  gap-2 text-white text-center transition-all w-full sm:w-auto ${
                  loading
                    ? 'bg-[#559383] cursor-progress'
                    : 'bg-[#008160] hover:bg-emerald-600 cursor-pointer'
                }`}>
                <span className={'text-sm'}>
                  {loading ? 'Saving changes...' : 'Save Changes'}
                </span>
              </button>
            </div>
          </form>
        )}
      </div>
    )}
  </div>
  );
}
