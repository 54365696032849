import { Link, useLocation } from 'react-router-dom';
import { useContext, useState } from 'react';
import { useNotifications } from '../../pages/main/Notifications/useNotifications';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import SideBar from './SideBar';
import NotificationPopover from './ui/popovers/NotificationPopover';
import AccountPopover from './ui/popovers/AccountPopover';
import { IUserNotifications } from '../../interfaces/notification';
import UserOrganizationsPopover from './ui/popovers/UserOrganizationsPopover';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { routes } from '../../constants/routes';
import heatmapLogo from '../../assets/LogoGreen.svg';
// import heatmapLogo from '../../assets/heatmap-logo_beta-03.png';
import { useNotificationContext } from '../../contexts/NotificationContext';
import { useQuery } from 'react-query';
import LoadingSpinnerMini from './LoadingSpinnerMini';

export default function AppNavbar() {
  const { loadUserNotifications } = useNotifications();

  const { profile, selectedOrganization, setSelectedOrganization } =
    useContext(OrganizationContext);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);

  const [organizationsShow, setOrganizationsShow] = useState<boolean>(false);

  const auth = useAuthUser();
  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const { pathname } = useLocation();

  const { newNotificationFlag } = useNotificationContext();

  const {
    data: notifications,
    isLoading,
    isError,
    error
  } = useQuery(['userNotifications', newNotificationFlag], loadUserNotifications, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false
  });

  const openSidebar = () => setSidebarOpen(true);
  const closeSidebar = () => setSidebarOpen(false);

  return (
    <div className={'relative w-full'}>
      {sidebarOpen && (
        <div
          className={
            'bg-gray-500 bg-opacity-75 transition-opacity absolute top-0 left-0 w-full h-screen z-[100] ease-in-out duration-300'
          }>
          <div
            className={`w-[90vw] md:w-[50vw] bg-white h-screen ${
              sidebarOpen ? 'translate-x-0 ' : 'translate-x-full'
            }`}>
            <SideBar closeSidebar={closeSidebar} />
          </div>
        </div>
      )}
      <nav className="bg-white px-5 lg:pl-5 lg:pr-[2vw] py-3 flex justify-between lg:justify-between items-center">
        <div className={'flex items-center gap-4 lg:hidden'}>
          <button
            type={'button'}
            onClick={openSidebar}
            className={'flex justify-center items-center text-black'}>
            <i className="fi fi-rr-menu-burger text-2xl pt-2"></i>
          </button>
          <Link to={routes.DASHBOARD} id="logo-link" className={'flex items-center gap-3'}>
            <img src={heatmapLogo} alt="" className={'w-[200px]'} />
            {/*<img src="/assets/Vector.svg" alt="" className={''} />*/}
            {/*<p className="text-secondary font-medium">Heatmap.com</p>*/}
          </Link>
        </div>
        <div className={'relative hidden lg:flex'}>
          {profile && profile.profile_details?.super_user == 0 && pathname !== routes.SETUP && (
            <>
              <button
                type={'button'}
                onClick={() => setOrganizationsShow(!organizationsShow)}
                className={`border px-3 py-2 flex justify-between w-[20vw] bg-white drop-shadow-sm rounded text-sm`}>
                {selectedOrganization ? (
                  <>
                    <span className={'flex items-start flex-col truncate'}>
                      <span className={'font-normal text-xs'}>Account</span>
                      <span className={'font-medium'}>
                        {selectedOrganization ? selectedOrganization?.name : 'All Accounts'}
                      </span>
                    </span>
                    <span>
                      <i className={`fi fi-sr-caret-down text-base`}></i>
                    </span>
                  </>
                ) : (
                  <>
                    <span className={'flex flex-col items-start'}>
                      <span className={'font-normal text-xs'}>Account</span>
                      <span>
                        {selectedOrganization ? selectedOrganization?.name : 'All Accounts'}
                      </span>
                    </span>
                    <span>
                      <i className={`fi fi-sr-caret-down text-lg`}></i>
                    </span>
                  </>
                )}
              </button>
              {organizationsShow && (
                <UserOrganizationsPopover
                  hideOrganizations={() => setOrganizationsShow(false)}
                  show={organizationsShow}
                  setSelectedOrganization={setSelectedOrganization}
                  options={profile.organizations.filter((item) => item.status == 'active')}
                  onClickOutside={() => setOrganizationsShow(false)}
                />
              )}
            </>
          )}
        </div>
        <div className={'flex lg:hidden'}>
          {token && notifications && notifications.msg.notification ? (
            <Link to={routes.NOTIFICATIONS} className={'relative'}>
              <span
                className={`w-10 h-10 rounded-full pt-1 ${
                  notificationOpen ||
                  (notifications &&
                    notifications.msg.notification.filter(
                      (item: IUserNotifications) => item.status !== 'seen'
                    ).length > 0)
                    ? 'bg-emerald-50 text-emerald-600'
                    : 'bg-gray-50 text-gray-600'
                } text-2xl flex justify-center items-center hover:bg-emerald-50 hover:text-emerald-600`}>
                <i className="fi fi-rr-bell"></i>
              </span>
              {notifications &&
                notifications.msg.notification &&
                notifications.msg.notification.filter(
                  (item: IUserNotifications) => item.status !== 'seen'
                ).length > 0 && (
                  <span
                    className={
                      'w-2 h-2 rounded-full bg-[#008160] shadow-md absolute right-0 top-0'
                    }></span>
                )}
            </Link>
          ) : (
            <Link
              to={'/register'}
              className={'bg-[#00C191] text-white rounded-md px-4 py-2 text-sm'}>
              <span>Get started</span>
            </Link>
          )}
        </div>
        <div className={'hidden lg:flex items-center gap-3'}>
          {token ? (
            <div className={'flex items-center gap-4'}>
              <div>
                {isLoading ? (
                  <div>
                    <LoadingSpinnerMini color={'green'} />
                  </div>
                ) : (
                  notifications &&
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  notifications.msg &&
                  notifications.msg.notification && (
                    <div className={'relative'}>
                      <button
                        onClick={() => setNotificationOpen(!notificationOpen)}
                        className={'relative'}>
                        <span
                          className={`w-10 h-10 rounded-full pt-1 ${
                            notificationOpen ||
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            notifications.msg.notification.filter(
                              (item: IUserNotifications) => item.status !== 'seen'
                            ).length > 0
                              ? 'bg-emerald-50 text-emerald-600'
                              : 'bg-gray-50 text-gray-600'
                          } text-2xl flex justify-center items-center hover:bg-emerald-50 hover:text-emerald-600`}>
                          <i className="fi fi-rr-bell"></i>
                        </span>
                        {notifications &&
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          notifications.msg.notification.filter(
                            (item: IUserNotifications) => item.status !== 'seen'
                          ).length > 0 && (
                            <span
                              className={
                                'w-2 h-2 rounded-full bg-[#008160] shadow-md absolute right-0 top-0'
                              }></span>
                          )}
                      </button>
                      {notificationOpen && (
                        <NotificationPopover
                          notifications={notifications.msg.notification}
                          show={notificationOpen}
                          onClickOutside={() => setNotificationOpen(false)}
                        />
                      )}
                    </div>
                  )
                )}
              </div>
              <div className={'relative'}>
                <button
                  type={'button'}
                  onClick={() => setMenuOpen(!menuOpen)}
                  className={'flex items-center gap-2'}>
                  <span
                    className={
                      'w-10 h-10 rounded-full bg-emerald-100 text-emerald-600 font-medium text-xl flex justify-center items-center uppercase'
                    }>
                    {auth()?.full_name?.charAt(0)}
                  </span>
                </button>
                {menuOpen && (
                  <AccountPopover show={menuOpen} onClickOutside={() => setMenuOpen(false)} />
                )}
              </div>
            </div>
          ) : (
            <>
              <Link
                to={'/login'}
                className={'border border-[#00C191] bg-white text-[#00C191] rounded-lg px-6 py-2'}>
                <span>Log in</span>
              </Link>
              <Link to={'/register'} className={'bg-[#00C191] text-white rounded-lg px-6 py-2'}>
                <span>Get free trial</span>
              </Link>
            </>
          )}
        </div>
      </nav>
    </div>
  );
}
