import { useState } from 'react';
import SubscriptionInvoicesListItemCard from './SubscriptionInvoicesListItemCard';

export default function SubscriptionHistory({
  userSubscription,
  history,
  error
}: {
  userSubscription: any;
  history: any[];
  error: string | null;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust as needed

  // Calculate total pages and current page items
  const totalPages = Math.ceil(history.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = history.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page changes
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Pagination display logic
  const paginationDisplay = () => {
    if (totalPages <= 1) return null;

    const renderPageNumbers = () => {
      const pageNumbers = [];

      // Calculate start and end page numbers
      let startPage = Math.max(1, currentPage - 1);
      const endPage = Math.min(totalPages, startPage + 2);

      // Adjust startPage if we're near the end
      if (endPage - startPage < 2) {
        startPage = Math.max(1, endPage - 2);
      }

      // Add the page numbers to the list
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      // Add ellipses if there are more pages
      if (startPage > 1) {
        pageNumbers.unshift('...');
        pageNumbers.unshift(1);
      }

      if (endPage < totalPages) {
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }

      return pageNumbers.map((pageNumber, index) => {
        if (pageNumber === '...') {
          return (
            <li key={index}>
              <a
                href="#"
                className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border-t border-b border-gray-300 hover:bg-gray-100 hover:text-gray-700"
              >
                {pageNumber}
              </a>
            </li>
          );
        }

        return (
          <li key={index}>
            <a
              href="#"
              onClick={() => handlePageChange(Number((pageNumber)))}
              className={`flex items-center justify-center px-4 h-10 leading-tight ${
                currentPage === pageNumber
                  ? 'text-emerald-600 border border-r-2 border-emerald-300 bg-emerald-50 hover:bg-emerald-100 hover:text-emerald-700 '
                  : 'text-gray-500 bg-white border-t border-b border-gray-300 hover:bg-gray-100 hover:text-gray-700'
              }`}
            >
              {pageNumber}
            </a>
          </li>
        );
      });
    };

    return (
      <div className="flex justify-end -bottom-5 sticky bg-white py-3">
      <nav aria-label="Page navigation example">
        <ul className="flex items-center -space-x-px h-10 text-base">
          <li>
            <a
              href="#"
              onClick={() => handlePageChange(currentPage - 1)}
              className={`flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white rounded-l border border-r-2 border-gray-300 hover:bg-gray-100 hover:text-gray-700  rounded-s-lg  ${
                currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''
              }`}
              aria-disabled={currentPage === 1}
            >
              <svg
                className="w-3 h-3 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
              <span className="ml-2">Previous</span>
            </a>
          </li>
          {renderPageNumbers()}
          <li>
            <a
              href="#"
              onClick={() => handlePageChange(currentPage + 1)}
              className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white rounded-r border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 ${
                currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''
              }`}
              aria-disabled={currentPage === totalPages}
            >
              <span className="mr-2">Next</span>
              <svg
                className="w-3 h-3 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
      </div>
    );
  };

  return (
    <div className={'mt-2 grid gap-2'}>
      {error ? (
        <div className={'flex justify-center items-center p-5 text-center'}>
          <h5>{error}</h5>
        </div>
      ) : currentItems.length > 0 ? (
        currentItems.map((item: any, index: number) => (
          <SubscriptionInvoicesListItemCard
            key={index}
            data={item}
            plan={userSubscription && userSubscription.subscription.plan}
            plan_name={userSubscription && userSubscription.subscription.plan_name}
          />
        ))
      ) : (
        <div>
          <span>No payment history</span>
        </div>
      )}

      {/* Pagination Controls */}
      {paginationDisplay()}
    </div>
  );
}
