import { useState } from 'react';
import { getRequest } from '../../../requests';

export const useResendOTP = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const resendOTP = async ({ email }: { email: string }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      setLoading(true);
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.resendToken',
          format: 'json',
          email
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMessage('Error resending OTP');
    }
  };

  return { resendOTP, errorMessage, loading };
};
