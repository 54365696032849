import React from 'react';

export default function BigCommerceInstruction() {
  return (
    <div>
      <div className={'mb-3'}>
        <h3 className={'font-medium text-xl text-center'}>
          Installation instructions for BigCommerce
        </h3>
      </div>
      <div className={'bg-white px-5 py-5 text-black text-left text-sm'}>
        <div className={'lg:h-[55vh] overflow-auto slim-scroll pr-2'}>
          <div>
            <h6 className={'font-semibold text-sm text-gray-700'}>
              To add the snippet to your BigCommerce website
            </h6>
            <h6 className={'text-sm my-3'}>
              **PLEASE NOTE**: there are two snippets you need to install on your BigCommerce
              website to get revenue tracking included in your heatmap.
            </h6>
          </div>
          <div>
            <h6 className={'text-sm mb-2'}>To add the snippet to your website:</h6>
            <h6 className={'text-sm'}>1. Copy the Header snippet after adding your site</h6>
          </div>
          <div>
            <h6 className={'text-sm'}>2. Go to your BigCommerce dashboard.</h6>
            <h6 className={'text-sm'}>3. Click on Storefront section</h6>
            <img
              width={300}
              src={'/assets/img/bc/bc-storefront.png'}
              alt={''}
              className={'my-3 w-4/5 lg:w-full object-contain'}
            />
          </div>
          <div>
            <h6 className={'text-sm'}>4. Go to Script Manager tab.</h6>
            <img
              width={300}
              src={'/assets/img/bc/bc-scriptmanager.png'}
              alt={''}
              className={'my-3 w-4/5 lg:w-full object-contain'}
            />
          </div>
          <div>
            <h6 className={'text-sm'}>5. Then click on Create a Script</h6>
            <img
              width={300}
              src={'/assets/img/bc/bc-script.png'}
              alt={''}
              className={'my-3 w-4/5 lg:w-full object-contain'}
            />
          </div>
          <div>
            <h6 className={'text-sm'}>
              6. Select the options below and paste the Heatmap code snippet in the Script contents
            </h6>
            <ul className={'list-disc pl-8 font-medium text-sm'}>
              <li>Location on page &gt; Head</li>
              <li>Select pages where script will be added &gt; All pages</li>
              <li>Script Category &gt; Essential</li>
            </ul>
            <img
              width={300}
              src={'/assets/img/bc/bc-loada.png'}
              alt={''}
              className={'my-3 w-4/5 lg:w-full object-contain'}
            />
            <img
              width={300}
              src={'/assets/img/bc/bc-loadb.png'}
              alt={''}
              className={'my-3 w-4/5 lg:w-full object-contain'}
            />
          </div>
          <div>
            <h6 className={'font-medium text-sm'}>7. Save and you are good to go</h6>
          </div>
          <div className={'mt-4'}>
            <h6>Add your conversion snippet (Second Snippet)</h6>
          </div>
          <div>
            <h6 className={'font-medium text-sm'}>1. Create a script</h6>
            <img
              width={300}
              src={'/assets/img/bc/bc-scriptmanager2.png'}
              alt={''}
              className={'my-3 w-4/5 lg:w-full object-contain'}
            />
          </div>
          <div>
            <h6 className={'font-medium text-sm'}>
              2. Select the options below and paste the Heatmap code snippet in the Script contents
            </h6>
            <ul className={'list-disc pl-8 font-medium text-sm'}>
              <li>Location on page &gt; Footer</li>
              <li>Select pages where script will be added &gt; All pages</li>
              <li>Script Category &gt; Essential</li>
            </ul>
            <img
              width={300}
              src={'/assets/img/bc/bc-loadaa.png'}
              alt={''}
              className={'my-3 w-4/5 lg:w-full object-contain'}
            />
            <img
              width={300}
              src={'/assets/img/bc/bc-loadab.png'}
              alt={''}
              className={'my-3 w-4/5 lg:w-full object-contain'}
            />
          </div>
          <div>
            <h6 className={'font-medium text-sm'}>3. Save and you are good to go</h6>
          </div>
        </div>
      </div>
    </div>
  );
}
