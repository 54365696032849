import Snippet from '../Snippet';
import React from 'react';
import { ITracking } from '../../../interfaces/global';

export default function Shopify({ trackingDetails }: { trackingDetails: ITracking }) {
  return (
    <div>
      <div>
        <h6 className={'font-medium text-base mb-2 text-gray-700'}>
          Installation for your Shopify website
        </h6>
      </div>
      <div>
        <h6 className={'font-medium text-sm'}>1. Code snippet for theme.liquid & 3rd Party Page Builders</h6>
        <Snippet snippet={trackingDetails.script as string} />
      </div>

      <div className={'mt-5'}>
        <h6 className={'font-medium text-sm'}>2. Code snippet for Custom Pixel</h6>
        <Snippet snippet={trackingDetails.postPurchaseScript as string} />
      </div>
    </div>
  );
}
