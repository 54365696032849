import React, { useEffect, useState } from 'react';
import Platform from '../../../feature/Websites/Onboarding/Platform';
import AppNavbar from '../../../components/global/AppNavbar';
import { useQuery } from '../../../util/global';
import { useUserSites } from '../../../hooks/useUserSites';
import { Link, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import { ISubscription } from '../../../interfaces/subscription';
import { IUserWebsite } from '../../../interfaces/website';
import PlatformType from '../../../feature/Websites/Onboarding/PlatformType';
import PlatformBrand from '../../../feature/Websites/Onboarding/PlatformBrand';
import SnippetInstallationVerification, {
  PlaceholderForMobile
} from '../../../feature/Websites/Onboarding/SnippetInstallationVerification';
import ReactPlayer from 'react-player';
import { routes } from '../../../constants/routes';
import { SHOPIFY_INSTALLATION_VIDEO_URL } from '../../../constants';
import BigCommerceInstruction from '../../../feature/SnippetInstallation/BigCommerceInstruction';
import placeholderBgImg from '../../../assets/placeholder-img.png';
import WordpressWoocommerceInstruction from '../../../feature/SnippetInstallation/WordpressWoocommerceInstruction';
import InstallationHelp from '../../../feature/SnippetInstallation/InstallationHelp';
import { ITracking } from '../../../interfaces/global';
import { isMobile } from 'react-device-detect';
import TopHeader from '../../../components/global/TopHeader';
import { useIntercom } from 'react-use-intercom';
import { NotificationContextProvider } from '../../../contexts/NotificationContext';

export default function Setup() {
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [websiteError, setWebsiteError] = useState<string | null>(null);
  const [userSubscription, setUserSubscription] = useState<ISubscription | null>(null);
  const [selectedPageBuilderStatus, setSelectedPageBuilderStatus] = useState<string | null>(null);
  const [platformChangeShow, setPlatformShow] = useState<boolean>(false);

  const { loadUserProfile } = useUserSites();
  const { shutdown, boot } = useIntercom();

  // State variables to handle values for snippet verification
  const [tracking, setTracking] = useState<ITracking>({
    script: null,
    orderStatusScript: null,
    postPurchaseScript: null,
    woocommerce: null,
    bigcommerce: null,
    conversion: null,
    platform: null
  });

  const navigate = useNavigate();

  const changeStep = (s: number) => setStep(s);
  const query = useQuery();

  useEffect(() => {
    // Continue from the previous step based on the step set on the url
    if (query.get('step') && query.get('step') === '2') {
      setStep(2);
    } else if (query.get('step') && query.get('step') === '3') {
      setStep(3);
    }

    if (query.get('step') && query.get('step') === '3' && !query.get('url')) {
      setWebsiteError('No URL for verification found!');
    }

    // Fetch the user websites and subscriptions
    loadUserProfile({}).then((res) => {
      setUserSubscription(res.subscription_details);
      setLoading(false);

      /* Redirect a user to the pricing page to buy a new Subscription
        if there is no pending Subscription or unverified website
       */
      if (
        res.subscription_details.subscription.filter((item: any) => item.idsite == 0).length == 0 &&
        res.subscription_details.subscription.filter((item: any) => item.website.verified == 0)
          .length == 0
      ) {
        navigate(routes.PRICING);
      } else if (
        /* Redirect a user to the pricing page to buy a new Subscription
        if there is no pending Subscription and website Onboarding step is deleted from url
       */
        res.subscription_details.subscription.filter((item: any) => item.idsite == 0).length == 0 &&
        !query.get('step')
      ) {
        navigate(routes.PRICING);
      }
    });
  }, []);

  useEffect(() => {
    if (isMobile) {
      setPlatformShow(true);
      shutdown();
    } else boot();
  }, [isMobile]);

  if (loading)
    return (
      <div className={'h-screen bg-white flex justify-center items-center'}>
        <LoadingSpinner color={'text-[#008160]'} />
      </div>
    );

  if (platformChangeShow) {
    return (
      <div>
        <TopHeader />
        <main className={'h-[80vh] flex flex-col justify-center p-5'}>
          <PlaceholderForMobile />
        </main>
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <div className={'h-screen bg-white flex justify-center items-center'}>
          <LoadingSpinner color={'text-[#008160]'} />
        </div>
      ) : websiteError ? (
        <div className={'h-screen bg-white flex flex-col justify-center items-center'}>
          {websiteError}
          <Link
            to={routes.WEBSITES}
            className={
              'rounded px-4 py-2 flex items-start gap-2 bg-[#008160] text-white font-medium mt-5'
            }>
            <span>
              <i className="fi fi-rr-arrow-left text-sm"></i>
            </span>
            <span className={'text-sm'}>Go to websites</span>
          </Link>
        </div>
      ) : (
        <main className={'min-h-screen w-full'}>
          <NotificationContextProvider>
            <AppNavbar />
          </NotificationContextProvider>
          <div className="grid lg:grid-cols-2 min-h-[90vh]">
            <section className="px-10 py-10 flex justify-center items-center">
            {/* <section className="px-10 py-10 flex flex-col items-center"> */}
              {step === 1 && userSubscription ? (
                <Platform
                  websites={userSubscription.subscription}
                  changeStep={changeStep}
                  userSubscription={userSubscription as ISubscription}
                />
              ) : step === 2 ? (
                <PlatformType changeStep={changeStep} />
              ) : step === 3 ? (
                <PlatformBrand
                  changeStep={changeStep}
                  setTracking={setTracking}
                  selectedPageBuilderStatus={selectedPageBuilderStatus}
                  setSelectedPageBuilderStatus={setSelectedPageBuilderStatus}
                />
              ) : step === 4 && tracking ? (
                <SnippetInstallationVerification
                  changeStep={changeStep}
                  trackingDetails={tracking}
                  selectedPageBuilderStatus={selectedPageBuilderStatus}
                  data={name as unknown as IUserWebsite}
                />
              ) : null}
            </section>
            <>
              {(step === 1 || step === 2 || step === 3) && (
                <section className={'bg-[#008160] hidden lg:flex'}>
                  <div
                    style={{
                      backgroundImage: `url(${placeholderBgImg})`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'top'
                    }}
                    className={'w-full min-h-[95vh] object-cover hidden lg:flex'}></div>
                </section>
              )}
              {(step === 4 || step === 5) && (
                <section
                  className={
                    'bg-[#008160] p-10 text-white text-center hidden lg:flex justify-center min-h-[95vh]'
                  }>
                  <div className={'flex justify-center items-center flex-col'}>
                    <div className={''}>
                      {tracking.platform == 'bigcommerce' ? (
                        <BigCommerceInstruction />
                      ) : tracking.platform == 'woocommerce' || tracking.platform == 'wordpress' ? (
                        <WordpressWoocommerceInstruction />
                      ) : (
                        <div className={'flex flex-col justify-center items-center'}>
                          <h3 className={'font-bold text-[28px] w-4/5 text-center'}>
                            {/* REQUIRED: Watch this video for how to install your snippets */}
                            Watch this video on how to install your snippets
                          </h3>
                          <div className={'my-5 w-full h-[55vh]'}>
                            <ReactPlayer
                              width={'lg:100%'}
                              height={'100%'}
                              controls={true}
                              url={SHOPIFY_INSTALLATION_VIDEO_URL}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_4150_39483)">
                            <path d="M11.7256 11.9348L4 7.92512C4.16503 6.5628 5.40278 5.5 6.90872 5.5H21.0913C22.5972 5.5 23.835 6.5628 24 7.92512L16.2847 11.9348C14.8303 12.6884 13.1697 12.6884 11.7256 11.9348Z" fill="#00C191"/>
                            <path d="M24 9.5V16.6515C24 18.2262 22.6927 19.5 21.0767 19.5H6.92331C5.30726 19.5 4 18.2262 4 16.6515V9.5L10.9789 13.2713C11.9362 13.7929 12.9655 14.0537 14.0051 14.0537C15.0345 14.0537 16.0638 13.7929 17.0314 13.2713L24 9.5Z" fill="#A8F7D4"/>
                            </g>
                            <defs>
                            <filter id="filter0_d_4150_39483" x="-2" y="0.5" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4150_39483"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4150_39483" result="shape"/>
                            </filter>
                            </defs>
                          </svg>
                    {!(tracking.platform == 'woocommerce' || tracking.platform == 'wordpress') && (
                      <InstallationHelp />
                    )}
                  </div>
                </section>
              )}
            </>
          </div>
          <footer
            className={
              'bg-[#008160] text-white flex flex-col items-center lg:hidden px-10 py-5 text-center w-full'
            }>
            <img src={'/assets/logo-white.svg'} width={50} alt={''} className={'w-6'} />
            <p className={'text-sm'}>All rights reserved 2024</p>
          </footer>
        </main>
      )}
    </>
  );
}
