import { useState } from 'react';
import { getRequest } from '../../../requests';
import { useAuthHeader } from 'react-auth-kit';

export const useSetupTwoFA = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [verifyLoading, setVerifyLoading] = useState<boolean>(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const setup2FA = async () => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.setup2FA',
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.action === 'error' && response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        } else if (response.data.action === 'info') {
          setInfoMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error setting up Two-Factor Authentication');
      setLoading(false);
    }
  };

  const disable2FA = async () => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.disable2FA',
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.action === 'error' && response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        } else if (response.data.action === 'info') {
          setInfoMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error disabling Two-Factor Authentication');
      setLoading(false);
    }
  };

  const disableClient2FA = async ({ user_id }: { user_id: number }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.disableClient2FA',
          user_id,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.action === 'error' && response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        } else if (response.data.action === 'info') {
          setInfoMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error disabling Two-Factor Authentication');
      setLoading(false);
    }
  };

  const verify2FA = async ({
    code,
    secret,
    userToken
  }: {
    code: string;
    secret: string;
    userToken?: string;
  }) => {
    setVerifyLoading(true);
    setErrorMessage(null);

    if (code === '' || !code) {
      setErrorMessage('Enter the code on your Authenticator app');
      setVerifyLoading(false);
      return;
    }

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.verify2FA',
          token: userToken || token,
          code,
          secret,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.action === 'error' && response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setVerifyLoading(false);
          return;
        } else if (response.data.action === 'info') {
          setInfoMessage(response.data.msg || response.data.message);
          setVerifyLoading(false);
          return;
        }
        setVerifyLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error verifying Two-Factor Authentication');
      setVerifyLoading(false);
    }
  };

  return {
    setup2FA,
    verify2FA,
    disable2FA,
    disableClient2FA,
    loading,
    verifyLoading,
    errorMessage,
    infoMessage
  };
};
