import { useState } from 'react';
import Modal from '../../components/global/ui/Modal';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import WarningPrompt from '../../components/global/ui/alerts/WarningPrompt';
import Error from '../../components/global/ui/alerts/Error';
import { IAIDocument } from '../../interfaces/analytic';
import { useAnalytics } from '../../pages/main/Dashboard/useAnalytics';

export default function ActivateAIPrompt({
  data,
  closeModal,
  refetch
}: {
  data: IAIDocument;
  closeModal: () => void;
  refetch: () => void;
}) {
  const { activateAiInsight, loading, errorMessage } = useAnalytics();
  const [message, setMessage] = useState<string | null>(null);

  const handleActivatingDocument = async () => {
    setMessage(null);
    const response = await activateAiInsight({
      id: data.id,
      status: 1
    });
    if (response?.status == 200) {
      setMessage('Document has been successfully activated!');
      refetch();
      setTimeout(() => {
        closeModal();
      }, 1000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Activate document'} width={'w-full md:w-1/2 lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Activating document...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <div>
              <div>
                <WarningPrompt>
                  <>
                    <p className={'font-medium'}>
                      You are opting to activating this OpenAI document
                    </p>
                    <p className={'text-xs'}>
                      Proceeding to activate will set this OpenAI document as the default prompt.
                    </p>
                  </>
                </WarningPrompt>
              </div>
              <div className={'grid gap-2 mt-5'}>
                <div className={'mt-2 flex flex-col-reverse lg:flex-row justify-end gap-2'}>
                  <button
                    type={'button'}
                    onClick={closeModal}
                    className={`px-4 py-2 rounded text-gray-700 bg-gray-200 hover:bg-gray-300 text-xs`}>
                    Cancel
                  </button>
                  <button
                    type={'button'}
                    onClick={handleActivatingDocument}
                    disabled={loading}
                    className={`rounded font-medium px-4 py-2 text-xs bg-green-500 hover:bg-green-600 text-white`}>
                    {loading ? 'Activating document..' : 'Yes, proceed to activate'}
                  </button>
                </div>
                {errorMessage && <Error value={`${errorMessage}`} />}
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}
