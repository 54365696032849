import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import PrimaryButton from '../../components/global/ui/buttons/PrimaryButton';
import Error from '../../components/global/ui/alerts/Error';
import Modal from '../../components/global/ui/Modal';
import { useState } from 'react';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { useClients } from '../../pages/main/Clients/useClients';
import { IClient, IRegistration } from '../../interfaces/client';

export default function SendEmailToClient({
  client,
  closeModal
}: {
  client: IClient | IRegistration;
  closeModal: () => void;
}) {
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    message: Yup.string().required('Message is required')
  });

  const [message, setMessage] = useState<string | null>(null);
  const [selectedClient, setSelectedClient] = useState();

  const { sendEmailToClient, loading, errorMessage } = useClients();

  const formik = useFormik({
    initialValues: {
      client: client.user_id || '',
      title: '',
      email: '',
      message: ''
    },
    validationSchema,
    onSubmit: async (values) => handleSendEmailToClient(values)
  });

  const handleSendEmailToClient = async (values: FormikValues) => {
    setMessage(null);

    const response = await sendEmailToClient({
      title: values.title,
      message: values.message,
      userId: client.user_id as number
    });

    if (response?.result === 'success') {
      setMessage(response.msg || response.message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <Modal
      closeModal={closeModal}
      title={client.user_id ? `Send Email to ${client.full_name}` : 'Send Email'}
      width={'lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Sending email to client...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}>
              {/*<p className={'text-sm mb-2'}>*/}
              {/*  We&apos;ll send an email to this person with the snippet for verification of your*/}
              {/*  website*/}
              {/*</p>*/}
              <div className={'grid gap-2'}>
                {/*<div>*/}
                {/*  <label className={'text-sm'}>*/}
                {/*    Recipient <span className={'text-red-500'}>*</span>*/}
                {/*  </label>*/}
                {/*  <input*/}
                {/*    type={'text'}*/}
                {/*    name={'title'}*/}
                {/*    value={formik.values.title}*/}
                {/*    onChange={formik.handleChange}*/}
                {/*    autoFocus={true}*/}
                {/*    className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"*/}
                {/*  />*/}
                {/*  <p className={'text-sm text-red-500'}>*/}
                {/*    {formik.touched.title && formik.errors.title}*/}
                {/*  </p>*/}
                {/*</div>*/}
                <div>
                  <label className={'text-sm'}>
                    Title <span className={'text-red-500'}>*</span>
                  </label>
                  <input
                    type={'text'}
                    name={'title'}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    autoFocus={true}
                    className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                  />
                  <p className={'text-sm text-red-500'}>
                    {formik.touched.title && formik.errors.title}
                  </p>
                </div>
                <div>
                  <label className={'text-sm'}>
                    Message <span className={'text-red-500'}>*</span>
                  </label>
                  <textarea
                    name={'message'}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    rows={4}
                    className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                  />
                  <p className={'text-sm text-red-500'}>
                    {formik.touched.message && formik.errors.message}
                  </p>
                </div>
                <div className={'mt-2'}>
                  <PrimaryButton
                    disabled={loading}
                    title={loading ? 'sending tracking code...' : 'Send message'}
                    handler={() => formik.handleSubmit()}
                  />
                </div>
                {errorMessage && <Error value={errorMessage} />}
              </div>
            </form>
          )}
        </div>
      )}
    </Modal>
  );
}
