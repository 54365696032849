import React, { useContext, useState } from 'react';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import Modal from '../../../components/global/ui/Modal';
import { useIP } from './useIP';
import { IpManagementInterface } from '../../../interfaces/coupon';
import Error from '../../../components/global/ui/alerts/Error';

export default function AddNewIPs({
  closeModal,
  setIPs: _setIPs,
  setIPsearchResults
}: {
  closeModal: () => void;
  setIPs: React.Dispatch<React.SetStateAction<[] | IpManagementInterface[]>>;
  setIPsearchResults: React.Dispatch<React.SetStateAction<[] | IpManagementInterface[]>>;
}) {
  const initialData = [{ id: 1, title: '', value: '' }];
  const [ips, setIPs] = useState(initialData);

  const { profile } = useContext(OrganizationContext);
  const myIP = profile.profile_details?.ipaddress;
  const [errors, setErrors] = useState<{ [key: number]: string }>({});

  const [keepModalOpen, setKeepModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { createIPs } = useIP();
  const addNewIpToBlock = (_myIP?: boolean) => {
    const lastItem = ips.at(-1);
    if (_myIP) {
      setIPs((prevIPs) => {
        const filteredIPs = prevIPs.filter((ip) => ip.value !== '');
        const lastItem = prevIPs[prevIPs.length - 1];
        const newItem = {
          id: lastItem ? lastItem.id + 1 : 1,
          title: 'My current IP',
          value: String(myIP)
        };
        return [...filteredIPs, newItem];
      });

      return;
    }
    if (lastItem) setIPs((p) => [...p, { id: lastItem.id + 1, value: '', title: '' }]);
    else setIPs([{ id: 1, title: '', value: '' }]);
  };

  const removeIp = (id: number) => {
    if (ips.length > 1) setIPs((prevIPs) => prevIPs.filter((ip) => ip.id !== id));
  };
  const submitIps = async () => {
    setIsLoading(true);
    setErrorMessage('');
    const data = ips.map((ip) => ({ value: ip.value, title: ip.title }));
    try {
      const response = await createIPs({ data });
      if (response?.additional?.length) {
        _setIPs(response?.additional);
        setIPsearchResults(response?.additional);
        setIsLoading(false);
        setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
          if (!keepModalOpen) {
            closeModal();
          }
        }, 2000);
      } else {
        setIsLoading(false);
        setErrorMessage('IP already added');
        // Set a timeout to clear the error message after 2 seconds
        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const areInputsValid = () => {
    return ips.every(ip => {
      const ipPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      return ip.title.trim() !== '' && ipPattern.test(ip.value);
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const { name, value } = e.target;
    setIPs((prevIPs) => {
      return prevIPs.map((data) => {
        if (data.id === id) {
          return { ...data, [name]: value };
        }
        return data;
      });
    });

    // Validate IP address
    if (name === 'value') {
      const ipPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      if (!ipPattern.test(value) && value !== '') {
        setErrors(prev => ({ ...prev, [id]: 'Invalid IP address format' }));
      } else {
        setErrors(prev => {
          const newErrors = { ...prev };
          delete newErrors[id];
          return newErrors;
        });
      }
    }
  };


  return (
    <Modal closeModal={closeModal} title={'Block IP Address'} width={'xl:w-[600px]'}>
      <div className="flex flex-col gap-4 w-full p-4">
        <div className="flex flex-col gap-4">
          {ips.map((iPData) => (
            <div key={iPData.id} className="flex items-start gap-3">
              <div className=" flex-grow">
                <div className="mb-8">
                  <label htmlFor="title" className='font-semibold'>Nickname</label>
                  <input
                    value={iPData.title}
                    type="text"
                    name={`title`}
                    onChange={(e) => handleInputChange(e, iPData.id)}
                    placeholder="Enter name"
                    className={`border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#3ead91] focus:border-[#3ead91] w-full mt-2`}
                  />
                </div>
                <div className="flex flex-col col-span-2">
                  <label htmlFor="value" className='font-semibold'>IP address</label>
                  <input
                    value={iPData.value}
                    type="text"
                    name={`value`}
                    size={15}
                    onChange={(e) => handleInputChange(e, iPData.id)}
                    placeholder="00.000.000.000"
                    className={`border rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#3ead91] focus:border-[#3ead91] w-full mt-2 ${errors[iPData.id] ? 'border-red-500' : 'border-gray-300'
                      }`}
                  />
                  {errors[iPData.id] && (
                    <p className="text-red-500 text-sm mt-1">{errors[iPData.id]}</p>
                  )}
                </div>
                <button onClick={() => addNewIpToBlock(true)}
                  disabled={!!ips.find((data) => data.value === myIP)}
                  className='mt-2 text-sm text-[#08916F] font-medium underline'>Use my IP address</button>
              </div>
              {ips.length > 1 ? (
                <span
                  onClick={() => removeIp(iPData.id)}
                  className="fi fi-rr-trash text-xl cursor-pointer hover:text-[red] mt-2"></span>
              ) : null}
            </div>
          ))}
        </div>
        <div>
        </div>
        {errorMessage && <Error value={errorMessage} />}
        <div className='flex justify-between items-center'>
          <div>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={keepModalOpen}
                onChange={(e) => setKeepModalOpen(e.target.checked)}
              />
              <div className="relative w-11 h-6 bg-[#8F99A3] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#00C291]"></div>
              <span className="ms-3 text-sm font-medium text-gray-900  ml-3">Add Another IP Address</span>
            </label>
          </div>
          <button
            onClick={submitIps}
            disabled={!areInputsValid() || isLoading}
            className={`rounded-lg shadow text-[13px] px-[1.15rem] py-3 flex items-center gap-2 border w-[50%] justify-center font-bold  ${areInputsValid() && !isLoading
                ? 'hover:bg-emerald-600 text-white hover:text-white bg-[#008160]'
                : 'bg-emerald-200 text-white cursor-not-allowed shadow-none border-none'
              }`}
          >
            {isLoading ? (
              <p>Saving...</p>
            ) : isSaved ? (
              'Saved'
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}

