import { IClient } from '../../interfaces/client';
import moment from 'moment';
import { useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import ClientCardMenuPopover from '../../components/global/ui/popovers/ClientCardMenuPopover';
import ChangeClientStatus from './ChangeClientStatus';
import SendEmailToClient from './SendEmailToClient';
import ClientAccount from './ClientAccount';
import DeleteClient from './DeleteClient';

export default function ClientListCard({ data, triggerRefetch }: { data: IClient, triggerRefetch: () => void  }) {
  const [selected, setSelected] = useState<IClient | null>(null);
  const [menuOptionOpen, setMenuOptionOpen] = useState<boolean>(false);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [activateModalOpen, setActivateModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState<boolean>(false);
  const [changeStatusModalOpen, setChangeStatusModalOpen] = useState<boolean>(false);
  const [clientDetailsOpen, setClientDetailsOpen] = useState<boolean>(false);

  const auth = useAuthUser();

  const openDrawer = (event: any) => {
    if (!event.target.className.includes('option')) {
      setClientDetailsOpen(true);
      setSelected(data);
    }
  };

  return (
    <>
      {changeStatusModalOpen && data && (
        <ChangeClientStatus
          client={data as IClient}
          closeModal={() => setChangeStatusModalOpen(false)}
        />
      )}
      {sendEmailModalOpen && data && (
        <SendEmailToClient
          client={data as IClient}
          closeModal={() => setSendEmailModalOpen(false)}
        />
      )}
      {clientDetailsOpen && data && (
        <ClientAccount data={data} closeModal={() => setClientDetailsOpen(false)} />
      )}

      {deleteModalOpen && data && (
        <DeleteClient client={data} closeModal={() => setDeleteModalOpen(false)} />
      )}

      <div
        className={
          'border rounded-md border-gray-200 hover:border-gray-300 bg-white hover:shadow p-3 md:p-3 cursor-pointer'
        }>
        <div className={'grid grid-cols-1 lg:grid-cols-12 gap-2 lg:gap-5'}>
          <div onClick={openDrawer} className={'col-span-3 flex flex-col'}>
            <span className={'flex gap-2'}>
              <span className={'text-sm'}>{data.full_name}</span>
              {data.account_holder == 1 ? (
                <span title={'Account holder'} className={'text-emerald-600'}>
                  <i className="fi fi-sr-shield-check"></i>
                </span>
              ) : data.account_holder == 0 ? (
                <span title={'Guest user'} className={''}>
                  <i className="fi fi-rr-portrait"></i>
                </span>
              ) : null}
            </span>
            <span className={'text-sm text-gray-500'}>{data.email}</span>
          </div>
          <div onClick={openDrawer} className={'col-span-3 text-sm'}>
            {data.login ? (
              <span className={'text-sm text-[#008160]'}>
                {data.login && '@'}
                {data.login}
              </span>
            ) : (
              <span className={'text-sm italic text-gray-700'}>null</span>
            )}
            {/*<span>{data.login}</span>*/}
          </div>
          <div onClick={openDrawer} className={'col-span-1 text-sm'}>
            <span>{data.user_id}</span>
          </div>
          <div onClick={openDrawer} className={'col-span-1 text-sm'}>
          <span>
            {data.is_agency ? (
              <span className="text-gray-900">agency</span>
            ) : (
              <span className="text-gray-900">user</span>
            )}
          </span>
        </div>
          <div onClick={openDrawer} className={'col-span-1'}>
            <span
              className={`rounded-full px-2 py-1 leading-none text-xs font-medium ${
                data.status == 'active'
                  ? 'bg-emerald-100 text-[#008160]'
                  : data.status == 'inactive'
                  ? 'bg-red-100 text-red-500'
                  : ''
              } uppercase`}>
              {data.status}
            </span>
          </div>
          <div
            onClick={openDrawer}
            className={'col-span-2 flex items-center lg:items-start gap-2 text-sm'}>
            <span>{moment(data.date_registered).format('lll')}</span>
          </div>
          <div className={'col-span-1 flex justify-end gap-2 text-sm'}>
            {auth()?.super_user == 1 && (
              <div className={'option relative'}>
                <button
                  title={'Options'}
                  type={'button'}
                  onClick={() => {
                    setSelected(data);
                    setMenuOptionOpen(true);
                  }}
                  className={
                    'option rounded px-2 py-1 flex items-start gap-2 border border-transparent hover:border-gray-500 bg-gray-100 text-gray-500 text-sm'
                  }>
                  <span>
                    <i className="option fi fi-rs-menu-dots-vertical text-sm"></i>
                  </span>
                </button>
                {menuOptionOpen && (
                  <ClientCardMenuPopover
                  triggerRefetch={triggerRefetch}
                    setCancelModalOpen={setCancelModalOpen}
                    setDeleteModalOpen={setDeleteModalOpen}
                    setActivateModalOpen={setActivateModalOpen}
                    setEditModalOpen={setEditModalOpen}
                    setSendEmailModalOpen={setSendEmailModalOpen}
                    setChangeStatusModalOpen={setChangeStatusModalOpen}
                    data={data as IClient}
                    onClickOutside={() => setMenuOptionOpen(false)}
                    show={menuOptionOpen}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
