import { useContext, useState } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import Error from '../../components/global/ui/alerts/Error';
import { useTeamInvites } from '../../pages/main/TeamManagement/useTeamInvites';
import Modal from '../../components/global/ui/Modal';
import { ORGANIZATION_ID } from '../../constants';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { useAuthUser } from 'react-auth-kit';
import LoadingSpinnerMini from '../../components/global/LoadingSpinnerMini';
import Success from '../../components/global/ui/alerts/Success';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import { IOrganization } from '../../interfaces/organization';

export default function PendingOrganizationInvite({ closeModal }: { closeModal: any }) {
  const [selected, setSelected] = useState<IOrganization | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const auth = useAuthUser();
  const { profile, loading, fetchOrganizationData, selectedOrganization } =
    useContext(OrganizationContext);
  const {
    validateInvitation,
    cancelInvitation,
    loading: validateInvitationLoading,
    errorMessage: validationInvitationError
  } = useTeamInvites();

  const acceptInvitation = async (invite_token: string) => {
    setMessage(null);
    const response = await validateInvitation({ email: auth()?.email, invite_token });
    if (response.result == 'success') {
      setMessage(response.msg || response.message);

      // refetch organization data
      fetchOrganizationData(
        (localStorage.getItem(ORGANIZATION_ID) as string) || auth()?.account_id
      );
    }
  };

  const rejectInvitation = async ({ account_id }: { account_id: string }) => {
    setMessage(null);
    const response = await cancelInvitation({ account_id });
    if (response.result == 'success') {
      setMessage(response.msg || response.message);

      // refetch organization data
      fetchOrganizationData(
        (localStorage.getItem(ORGANIZATION_ID) as string) || auth()?.account_id
      );
    }
  };

  return (
    <Modal
      closeModal={closeModal}
      title={'Pending Account Invitations'}
      width={'w-full md:w-1/2 lg:w-1/3'}>
      {
        //   validateInvitationLoading ? (
        //   <div className={'flex flex-col justify-center items-center p-5'}>
        //     <LoadingSpinner color={'text-[#008160]'} />
        //     <h5 className={'mt-2 text-sm'}>Processing request...Please wait</h5>
        //   </div>
        // ) :
        profile && profile.organizations.filter((item) => item.status == 'pending').length == 0 ? (
          <div className={'mb-2'}>
            <SuccessMessage message={'Invitations processed successfully!'} />
          </div>
        ) : profile &&
          profile.organizations.filter((item) => item.status == 'pending').length > 0 ? (
          <div className={'p-3'}>
            <div className={'mb-2'}>
              <p className={'text-sm'}>
                You have received an invitation to join the following organization(s). <br />
                Click <strong>Accept</strong> to join or <strong>Reject</strong> to cancel
                invitation
              </p>
            </div>
            <div className={'flex flex-col gap-2'}>
              {profile.organizations
                .filter((item) => item.status == 'pending')
                .map((invite, index) => (
                  <div
                    key={index}
                    className={
                      'border border-gray-300 hover:border-gray-400 p-4 rounded grid grid-cols-4 gap-2'
                    }>
                    <div className={'col-span-3 flex flex-col'}>
                      <h6 className={'font-medium'}>{invite.name}</h6>
                      <div className={'mt-1 flex justify-between items-center'}>
                        <span className={'text-xs rounded-full bg-emerald-100 px-2 py-1'}>
                          {invite.access_label}
                        </span>
                      </div>
                    </div>
                    {selected === invite && validateInvitationLoading ? (
                      <LoadingSpinnerMini color={'text-white'} />
                    ) : (
                      <div className={'flex gap-2 justify-end items-start'}>
                        <button
                          type={'button'}
                          disabled={selected === invite && validateInvitationLoading}
                          onClick={() => {
                            setSelected(invite);
                            acceptInvitation(invite.invite_token as string);
                          }}
                          className={`px-4 py-2 text-xs ${
                            validateInvitationLoading
                              ? 'bg-emerald-200 hover:bg-emerald-200'
                              : 'bg-[#008160] border border-[#008160] hover:bg-emerald-600'
                          } text-white rounded`}>
                          {validateInvitationLoading ? (
                            <LoadingSpinnerMini color={'text-white'} />
                          ) : (
                            'Accept'
                          )}
                        </button>
                        <button
                          type={'button'}
                          disabled={selected === invite && validateInvitationLoading}
                          onClick={() => {
                            setSelected(invite);
                            rejectInvitation({
                              account_id: invite.account_id as string
                            });
                          }}
                          className={`rounded bg-white font-medium px-4 py-2 text-xs  ${
                            loading
                              ? 'text-red-500 hover:bg-transparent'
                              : 'border-red-500 border bg-transparent hover:bg-red-100 text-red-500'
                          }`}>
                          {validateInvitationLoading ? (
                            <LoadingSpinnerMini color={'text-white'} />
                          ) : (
                            'Reject'
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              {validationInvitationError && <Error value={validationInvitationError} />}
            </div>
            {message && <Success value={message} />}
          </div>
        ) : null
      }
    </Modal>
  );
}
