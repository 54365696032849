import { useEffect, useRef, useState } from 'react';
import useClickOutside from '../../../../hooks/useClickOutside';
import SearchIndustry from '../../../form/SearchIndustry';

export default function IndustriesPopover({
  data,
  setSelected,
  hideData,
  onClickOutside,
  show
}: {
  data: string[];
  setSelected: (item: any) => void;
  hideData: () => void;
  onClickOutside?: any;
  show: boolean;
}) {
  const [searchResults, setSearchResults] = useState<string[] | []>([]);

  useEffect(() => {
    setSearchResults(data);
  }, []);

  if (!show) return null;

  const ref: any = useRef(null);
  useClickOutside(ref, onClickOutside);

  return (
    <div ref={ref} className={'absolute top-0 left-0 bg-white w-full drop-shadow-md'}>
      <div className={'p-2'}>
        <SearchIndustry data={data} setSearchResults={setSearchResults} />
      </div>

      <hr className={'m-1'} />
      <div className={'max-h-[25vh] overflow-y-auto slim-scroll'}>
        {searchResults.length > 0 ? (
          searchResults.map((item, index) => (
            <button
              key={index}
              type={'button'}
              onClick={() => {
                setSelected(item);
                hideData();
              }}
              className={'px-3 py-2 flex justify-between gap-2 w-full hover:bg-gray-100'}>
              <div className={''}>
                <span className={'text-gray-700 text-sm'}>{item}</span>
              </div>
            </button>
          ))
        ) : (
          <div className={'p-2 flex justify-center'}>
            <span className={'italic text-sm text-center'}>No record found</span>
          </div>
        )}
      </div>
    </div>
  );
}
