import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useState } from 'react';
import Modal from '../../components/global/ui/Modal';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import PrimaryButton from '../../components/global/ui/buttons/PrimaryButton';
import Error from '../../components/global/ui/alerts/Error';
import { openAITabs } from '../../constants';
import { useAnalytics } from '../../pages/main/Dashboard/useAnalytics';
import moment from 'moment';
import { IAIDocument } from '../../interfaces/analytic';

export default function OpenAIPrompt({
  closeModal,
  data,
  refetch
}: {
  closeModal: () => void;
  data?: IAIDocument;
  refetch: () => void;
}) {
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .max(255, 'Title cannot exceed 255 characters')
      .required('Document title is required'),
    prompt: Yup.string().required('Please enter the AI prompt.'),
    context: Yup.string().required('Please specify the context of AI prompt.')
  });

  const [message, setMessage] = useState<string | null>(null);
  const [tab, setTab] = useState<number>(1);
  const { addAiInsight, updateAiInsight, loading, errorMessage } = useAnalytics();

  const formik = useFormik({
    initialValues: {
      title: data?.title || '',
      context: data?.context || '',
      prompt: data?.prompt || '',
      id: '',
      updated_at: data?.updated_at || ''
    },
    validationSchema,
    onSubmit: async (values) => handleOpenAIPrompt(values)
  });

  const handleOpenAIPrompt = async (values: FormikValues) => {
    setMessage(null);

    let response;

    if (data) {
      response = await updateAiInsight({
        title: values.title,
        context: values.context,
        prompt: values.prompt,
        id: data.id
      });
    } else {
      response = await addAiInsight({
        title: values.title,
        context: values.context,
        prompt: values.prompt
      });
    }

    if (response?.status === 200) {
      setMessage('Data submitted successfully!');
      refetch();
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
  };

  return (
    <Modal
      closeModal={closeModal}
      title={'OpenAI inputs'}
      width={`${message ? 'lg:w-1/3' : 'lg:w-[50%]'}`}>
      <div className={'px-5 pb-5'}>
        {loading ? (
          <div className={'flex flex-col justify-center items-center p-5'}>
            <LoadingSpinner color={'text-[#008160]'} />
            <h5 className={'mt-2 text-sm'}>Processing request...Please wait</h5>
          </div>
        ) : (
          <div>
            {message ? (
              <div className={'p-5'}>
                <SuccessMessage message={message} />
              </div>
            ) : (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  formik.handleSubmit();
                }}>
                <div>
                  <div className={'mb-5'}>
                    <input
                      type={'text'}
                      name={'title'}
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      placeholder={'Title of Context Document'}
                      autoFocus={true}
                      className="w-full bg-gray-50 border border-gray-300 rounded outline-0 px-2 py-2 text-lg font-medium flex items-center"
                    />
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.title && formik.errors.title}
                    </p>
                  </div>
                  <div className={'flex justify-between gap-5'}>
                    <div className={'flex gap-4 mb-3'}>
                      {openAITabs &&
                        openAITabs.map((item, index) => (
                          <button
                            type={'button'}
                            onClick={() => setTab(item.id)}
                            key={index}
                            className={`${
                              tab === item.id
                                ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                                : 'border-transparent hover:border-gray-300'
                            } py-2 border-b-2 font-medium text-sm flex gap-3`}>
                            <span className={'text-sm'}>{item.name}</span>
                          </button>
                        ))}
                    </div>
                    {data && <p>Last update: {moment(formik.values.updated_at).format('lll')}</p>}
                  </div>

                  <div>
                    {tab === 2 ? (
                      <div className={''}>
                        <textarea
                          rows={20}
                          name={'prompt'}
                          placeholder={'Enter the AI prompt'}
                          value={formik.values.prompt}
                          onChange={formik.handleChange}
                          className="w-full bg-transparent outline-0 p-2 text-sm rounded border border-gray-300 mt-1"
                        />
                      </div>
                    ) : (
                      <div className={''}>
                        <textarea
                          rows={20}
                          name={'context'}
                          placeholder={'Enter the AI training context'}
                          value={formik.values.context}
                          onChange={formik.handleChange}
                          className="w-full bg-transparent outline-0 p-2 text-sm rounded border border-gray-300 mt-1"
                        />
                      </div>
                    )}
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.context && formik.errors.context}
                    </p>
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.prompt && formik.errors.prompt}
                    </p>
                  </div>
                  <div className={'mt-2 flex justify-center'}>
                    <div className={'w-1/3'}>
                      <PrimaryButton
                        disabled={loading}
                        title={loading ? 'Submitting data...' : 'Submit'}
                        handler={() => formik.handleSubmit()}
                      />
                    </div>
                  </div>
                  {errorMessage && <Error value={errorMessage} />}
                </div>
              </form>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
