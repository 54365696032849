import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE_API_KEY } from '../constants';

export const initAmplitude = () => amplitude.init(AMPLITUDE_API_KEY);

export const trackAmplitudeEvent = (customEvent: string) => {
  amplitude.track(customEvent);
};

export const updateAmplitudeUserId = (userId: any) => {
  amplitude.setUserId(`heatmap-user-${userId}`);
};
