import PendingSubscriptionListCard from '../../feature/Dashboard/Subscription/PendingSubscriptionListCard';
import { ISubscription } from '../../interfaces/subscription';

export default function PendingUnusedSubscription({
  userSubscription
}: {
  userSubscription: ISubscription;
}) {
  return (
    <div className={'rounded-md shadow-sm mb-3 text-gray-500'}>
      <div className={'grid gap-2'}>
        {userSubscription.subscription
          .filter((item) => !item.website)
          .map((item, index) => (
            <PendingSubscriptionListCard data={item} key={index} />
          ))}
      </div>
    </div>
  );
}
