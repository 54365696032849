import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import Modal from '../../components/global/ui/Modal';
import { useDeleteWebsite } from '../../pages/main/Websites/useDeleteWebsite';
import { IWebsiteDeleteModal } from '../../interfaces/website';
import { ORGANIZATION_ID } from '../../constants';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import WarningPrompt from '../../components/global/ui/alerts/WarningPrompt';

export default function DeleteWebsite({ website, closeModal }: IWebsiteDeleteModal) {
  const { deleteWebsite, loading, errorMessage } = useDeleteWebsite();
  const [message, setMessage] = useState<string | null>(null);

  const { fetchOrganizationData } = useContext(OrganizationContext);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Enter the website name')
  });

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema,
    onSubmit: () => handleDeleteWebsite()
  });

  const handleDeleteWebsite = async () => {
    setMessage(null);
    const response = await deleteWebsite({
      name: website.name,
      url: website.main_url,
      siteId: website.idsite
    });
    if (response?.result == 'success') {
      setMessage(response?.msg);
      // refetch organization data
      fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);

      setTimeout(() => {
        closeModal();
        // window.location.reload();
      }, 1000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Delete website'} width={'w-full md:w-1/2 lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Deleting website...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <div>
              <div>
                <WarningPrompt>
                  <>
                    <p className={'font-medium'}>You are opting to delete this website</p>
                    <p className={'text-xs'}>
                      Proceeding to delete will remove this website from your account
                    </p>
                  </>
                </WarningPrompt>
                <div className={'mt-5'}>
                  <p className={'text-sm'}>
                    Type <strong>{website.name}</strong> below to proceed
                  </p>
                  <div className={'my-1'}>
                    <input
                      type={'text'}
                      name={'name'}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      className="w-full bg-transparent outline-0 px-3 py-2 rounded border border-gray-300 mt-1 flex items-center"
                    />

                    <p className={'text-xs text-red-500'}>
                      {formik.touched.name && formik.errors.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className={'grid gap-2'}>
                <div className={'mt-2 flex flex-col-reverse lg:flex-row justify-end gap-2'}>
                  <button
                    type={'button'}
                    onClick={closeModal}
                    className={`px-4 py-2 rounded text-gray-700 bg-gray-200 hover:bg-gray-300 text-xs`}>
                    No, do not delete
                  </button>
                  <button
                    type={'button'}
                    onClick={handleDeleteWebsite}
                    disabled={formik.values.name !== website.name}
                    className={`rounded bg-white font-medium px-4 py-2 text-xs  ${
                      formik.values.name === '' || formik.values.name !== website.name
                        ? 'border border-red-500 text-red-500 hover:bg-transparent'
                        : 'bg-red-500 hover:bg-red-600 text-white'
                    }                      `}>
                    {loading ? 'Deleting website..' : 'Yes, proceed to delete'}
                  </button>
                </div>
                {errorMessage && <Error value={`${errorMessage}`} />}
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}
