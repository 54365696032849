import { useState } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import SuccessMessage from '../../../components/global/ui/SuccessMessage';
import Error from '../../../components/global/ui/alerts/Error';
import Modal from '../../../components/global/ui/Modal';
import { useCoupon } from '../../../pages/main/Coupons/useCoupon';

export default function DeleteCoupon({
  couponId,
  closeModal
}: {
  couponId: string;
  closeModal: () => void;
}) {
  const { deleteCoupon, loading, errorMessage } = useCoupon();
  const [message, setMessage] = useState<string | null>(null);

  const handleDeleteCoupon = async () => {
    setMessage(null);
    const response = await deleteCoupon(couponId);
    if (response?.result === 'success') {
      setMessage(response.msg);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Delete coupon'} width={'lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Deleting coupon...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <div>
              <div>
                <p className={'text-sm'}>You are deleting this coupon</p>
              </div>
              <div className={'grid gap-2'}>
                <div className={'mt-2 flex flex-col-reverse lg:flex-row justify-end gap-2'}>
                  <button
                    type={'button'}
                    disabled={loading}
                    onClick={closeModal}
                    className={`px-4 py-2 rounded text-sm text-gray-700 bg-gray-200 hover:bg-gray-300`}>
                    Cancel
                  </button>
                  <button
                    type={'button'}
                    onClick={handleDeleteCoupon}
                    disabled={loading}
                    className={`rounded bg-white hover:bg-red-600 font-medium px-4 py-2 text-sm bg-red-500 text-white`}>
                    {loading ? 'deleting card...' : 'Yes, remove'}
                  </button>
                </div>
              </div>
            </div>
          )}
          {errorMessage && <Error value={errorMessage} />}
        </div>
      )}
    </Modal>
  );
}
