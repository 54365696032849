import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '../../util/global';
import { routes } from '../../constants/routes';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import Error from '../../components/global/ui/alerts/Error';
import { LANDING_PAGE_URL } from '../../constants';
import { useSubscription } from '../main/AccountSubscription/useSubscription';

export default function UnsubscribeEmail() {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const query = useQuery();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const { unsubscribeToEmails, errorMessage } = useSubscription();

  useEffect(() => {
    setLoading(true);
    if (query && query.get('token') && query.get('email')) {
      unsubscribeToEmails({
        email: query.get('email') as string,
        token: query.get('token') as string
      }).then((response) => {
        if (response?.result == 'success') {
          setLoading(false);
          setSuccessMessage(response.msg || response.message);
        }
      });
    } else {
      setLoading(false);
      navigate(routes.DASHBOARD);
    }
  }, []);

  return (
    <div>
      <main
        style={{
          background: 'url(/assets/img/waitlist-bg.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
        className={'h-screen flex gap-3 flex-col justify-center items-center p-5 lg:p-10'}>
        <Link to={routes.DASHBOARD} className={'flex items-center gap-3'}>
          <img src="/assets/heatmap-logo.svg" alt="" />
          {/*<p className="text-secondary text-lg">Heatmap.com</p>*/}
        </Link>
        <div className={'flex flex-col gap-2 justify-center items-center mt-5'}>
          {loading ? (
            <div className={'flex justify-center items-center'}>
              <LoadingSpinner color={'text-emerald-600'} />
            </div>
          ) : successMessage ? (
            <div className={'w-full lg:w-[50vw] text-center flex flex-col items-center gap-5'}>
              <span
                className={
                  'rounded-full bg-gradient-to-r from-[#008160] to-emerald-300 pt-2 flex justify-center items-center w-11 h-11'
                }>
                <i className="fi fi-rr-check text-lg text-white"></i>
              </span>
              <h5 className={'font-semibold text-xl lg:text-2xl'}>{successMessage}</h5>
              <div className={'flex gap-2 mt-5'}>
                <a
                  href={LANDING_PAGE_URL}
                  className={
                    'rounded px-3 lg:px-4 py-1 lg:py-2 flex justify-center items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                  }>
                  <span className={'pt-1'}>
                    <i className="fi fi-rr-house-chimney text-base"></i>
                  </span>
                  <span className={'text-base lg:text-base'}>Heatmap.com</span>
                </a>
                <Link
                  to={routes.LOGIN}
                  className={
                    'rounded px-3 lg:px-4 py-1 lg:py-2 flex justify-center items-center gap-2 border border-[#008160] bg-white hover:bg-emerald-50 text-[#008160]'
                  }>
                  Go to dashboard
                </Link>
              </div>
            </div>
          ) : null}
          <div className={'w-full md:w-[20vw]'}>
            {errorMessage && <Error value={errorMessage as string} />}
          </div>
        </div>
      </main>
    </div>
  );
}
