import { useState } from 'react';
import { ICoupon, IpManagementInterface } from '../../../interfaces/coupon';
import UpdatePromo from '../../../feature/AccountSubscription/Coupons/UpdatePromo';
import UpdateCoupon from '../../../feature/AccountSubscription/Coupons/UpdateCoupon';
import DeletePromo from '../../../feature/AccountSubscription/Coupons/DeletePromo';
import DeleteCoupon from '../../../feature/AccountSubscription/Coupons/DeleteCoupon';

export default function IpList({
  data,
  toggle,
  deleteIP,
  setIPs,
  setIPsearchResults
}: {
  data: IpManagementInterface;
  toggle: (data: IpManagementInterface) => void;
  deleteIP: (ip: string) => Promise<any>;
  setIPs: React.Dispatch<React.SetStateAction<[] | IpManagementInterface[]>>;
  setIPsearchResults: React.Dispatch<React.SetStateAction<[] | IpManagementInterface[]>>;
}) {
  const [selected, setSelected] = useState<ICoupon | null>(null);
  const [activeStatus, setActiveStatus] = useState<boolean>(Boolean(data.status === 'active'));

  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [cancelOpen, setCancelOpen] = useState<boolean>(false);
  const closeEditModal = () => setEditOpen(false);
  const closeCancelModal = () => setCancelOpen(false);

  return (
    <>
      {editOpen && selected && <UpdatePromo coupon={selected} closeModal={closeEditModal} />}
      {editOpen && selected && <UpdateCoupon coupon={selected} closeModal={closeEditModal} />}
      {cancelOpen && selected && <DeleteCoupon couponId={data.id} closeModal={closeCancelModal} />}
      <tr className='align-middle'>
        <td className="p-3 align-middle w-1/4">
          <h6 className="font-medium text-sm capitalize">{data.title}</h6>
        </td>
        <td className="p-3 align-middle w-1/4">
          <h5 className="font-medium text-sm capitalize">{data?.ipaddress}</h5>
        </td>
        <td className="p-3 align-middle w-1/4">
          <span className="rounded-full px-2 py-1 leading-none text-sm font-medium capitalize">
            {data.status === 'active'
              ? 'Blocking'
              : data.status === 'inactive'
                ? 'Not Blocking'
                : data.status}
          </span>
        </td>
        <td className="p-3 align-middle w-1/4 pr-15">
          <div className="flex gap-2 items-center justify-end">
            <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in min-w-max">
              <input
                type="checkbox"
                name="toggle"
                id={`toggle-${data.id}`}
                checked={data.status === 'active'}
                onChange={() => toggle(data)}
                className="toggle-checkbox absolute block w-6 h-[24px] rounded-full bg-white border-4 appearance-none cursor-pointer"
              />
              <label
                htmlFor={`toggle-${data.id}`}
                className="toggle-label block overflow-hidden h-[24px] rounded-full bg-gray-300 cursor-pointer"></label>
            </div>
            <button
              onClick={async () => {
                const response = await deleteIP(data.id);
                setIPs(response?.additional);
                setIPsearchResults(response?.additional);
              }}>
              <i className="fi fi-rs-trash text-lg text-[#ef4444]/80 hover:text-[#ef4444] mr-5"></i>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
}