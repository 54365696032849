import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '../../../util/global';
import { useVerifyWebsite } from '../../../pages/main/Setup/useVerifyWebsite';
import OnboardingProgressBar from './OnboardingProgressBar';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import { isMobile } from 'react-device-detect';
import { routes } from '../../../constants/routes';
import { AMPLITUDE_EVENTS, ORGANIZATION_ID } from '../../../constants';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import InstallationHelp from '../../SnippetInstallation/InstallationHelp';
import WordpressWoocommerce from '../../SnippetInstallation/Installation/WordpressWoocommerce';
import BigCommerce from '../../SnippetInstallation/Installation/BigCommerce';
import OtherPlatforms from '../../SnippetInstallation/Installation/OtherPlatforms';
import Shopify from '../../SnippetInstallation/Installation/Shopify';
import WebflowSquarespace from '../../SnippetInstallation/Installation/WebflowSquarespace';
import ThirdPartyBuilder from '../../SnippetInstallation/Installation/ThirdPartyBuilder';
import { trackAmplitudeEvent } from '../../../util/amplitudeService';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import { IUserWebsite } from '../../../interfaces/website';
import { useAppSettings } from '../../../pages/main/PlatformSettings/useAppSettings';
import axios from 'axios'; 

export default function SnippetInstallationVerification({
  changeStep,
  trackingDetails,
  selectedPageBuilderStatus,
  data
}: {
  data: IUserWebsite,
  changeStep: (s: number) => void;
  trackingDetails: any;
  selectedPageBuilderStatus: string | null;
}) {
  const [message, setMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [platformChangeShow, setPlatformShow] = useState<boolean>(false);
  const [snippetShow, setSnippetShow] = useState<number>(1);
  const { updateWebsiteSettings } = useAppSettings();

  const { fetchOrganizationData, profile } = useContext(OrganizationContext);

  const auth = useAuthUser();
  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const navigate = useNavigate();

  const {
    verifySite,
    loading: verifySiteLoading,
    errorMessage: verifySiteError
  } = useVerifyWebsite();

  const query = useQuery();

  const handleVerifySite = async () => {
    setErrorMessage(null);
    setSnippetShow(2);
    try {
      const siteId = query.get('siteId');
      const url = query.get('url');
  
      if (siteId && url) {
        // Original verification logic
        const res = await verifySite({
          websiteUrl: url,
          siteId: siteId
        });
  
        // New functionality: Send data to pageinsight endpoint
        try {
          await axios.post('https://dashboard.heatmap.com/pageinsight/v1/', {
            url: url,
            idsite: parseInt(siteId, 10)
          });
          console.log('Data sent to pageinsight endpoint successfully');
        } catch (pageinsightError) {
          console.error('Error sending data to pageinsight:', pageinsightError);
        }
  
        if (res?.data.result === 'success') {
          setMessage(res?.data.msg);
          fetchOrganizationData(
            (localStorage.getItem(ORGANIZATION_ID) as string) || auth()?.account_id
          );
        } else if (res?.data.msg === 'Error! Snippet is not inserted Correctly') {
          setErrorMessage(res?.data.msg);
        }
      } else {
        setErrorMessage('Invalid site ID or URL');
      }
    } catch (e) {
      setErrorMessage('Snippet has not been inserted');
    }
  };

  useEffect(() => {
    if (isMobile) setPlatformShow(true);
  }, [isMobile]);

  const handleAnalyticsClick = () => {
    const siteId = query.get('siteId');
    const authData = localStorage.getItem('_auth');

    if (!siteId) {
      console.log('No siteId found in URL');
      return;
    }
    const subscription = profile.subscription_details?.subscription?.find(
      sub => sub.idsite === parseInt(siteId, 10)
    );

    if (subscription) {
      const { plan_name, plan, length } = subscription;
      const url = `${process.env.REACT_APP_API_URL}/index.php?module=Login&action=auth&hash=${auth()?.hash
        }&key=${authData}&idSite=${siteId}&account_id=${subscription.account_id}`;
      window.location.replace(url);
      return { plan_name, plan, length }; // Return these values for use in rendering
    } else {
      console.log('No matching subscription found for siteId:', siteId);
      return null;
    }
  };

  return (
    <>
      <div className="lg:w-[70%] w-full">
        <OnboardingProgressBar step={4} />
        {platformChangeShow ? (
          <PlaceholderForMobile />
        ) : (
          <>
            {verifySiteLoading ? (
              <div className="mb-3">
                <div className={'my-8 text-center'}>
                  <p className="text-3 text-black text-align-center">Verification in Progress</p>
                  <div className={'flex flex-col justify-center items-center'}>
                    <p className={'text-sm text-gray-400 w-1/2'}>
                      We&rsquo;re checking to make sure the snippets were installed correctly.
                    </p>
                    <div className={'my-14'}>
                      <LoadingSpinner color={'text-[#008160]'} />
                    </div>
                  </div>
                </div>
              </div>
            ) : snippetShow == 1 ? (
              <div className="mb-3">
                <p className="text-3 text-black my-8 text-center">
                  {trackingDetails.platform == 'woocommerce' ||
                    trackingDetails.platform == 'wordpress'
                    ? 'Add the WordPress/WooCommerce Plugin'
                    : 'Add Your Snippets'}
                </p>

                <div>
                  <div className={'flex flex-col gap-3'}>
                    {(trackingDetails.platform == 'woocommerce' ||
                      trackingDetails.platform == 'wordpress') && (
                        <WordpressWoocommerce trackingDetails={trackingDetails} />
                      )}
                    {(trackingDetails.platform == 'webflow' ||
                      trackingDetails.platform == 'squarespace') && (
                        <WebflowSquarespace trackingDetails={trackingDetails} />
                      )}
                    {trackingDetails.platform == 'bigcommerce' && (
                      <BigCommerce trackingDetails={trackingDetails} />
                    )}
                    {trackingDetails.platform == 'other' && (
                      <OtherPlatforms trackingDetails={trackingDetails} />
                    )}
                    {trackingDetails.platform == 'shopify' && (
                      <Shopify trackingDetails={trackingDetails} />
                    )}
                  </div>
                </div>
              </div>
            ) : message || verifySiteError || snippetShow == 2 ? (
              <div className={'flex flex-col justify-center items-center text-center'}>
                <div className={'mt-8 text-center'}>
                  <div className={'flex flex-col justify-center items-center'}>
                    {message && selectedPageBuilderStatus == 'No' && (
                      <>
                        <div className={'mt-8 text-center'}>
                          <p className="text-3 text-black text-align-center">Verification Successful</p>
                        </div>

                        <div className='flex flex-col justify-center'>
                          <div className={'mt-10 mb-5 flex gap-2 justify-start items-center'}>
                            <span className=''>
                            <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="path-1-inside-1_4226_42739" fill="white">
                            <path d="M22.7714 8.66262C23.4017 8.45782 24.085 8.80215 24.2259 9.44975C24.7302 11.7684 24.5374 14.1946 23.6573 16.4175C22.6293 19.0138 20.7281 21.1704 18.281 22.5157C15.834 23.861 12.9944 24.3107 10.2514 23.7874C7.50844 23.2642 5.03381 21.8007 3.25384 19.6491C1.47387 17.4975 0.5 14.7924 0.5 12C0.5 9.20755 1.47387 6.50253 3.25384 4.35091C5.03381 2.1993 7.50844 0.735806 10.2514 0.212553C12.5999 -0.235442 15.0192 0.0298047 17.2023 0.959669C17.812 1.21938 18.0222 1.95512 17.7029 2.53589C17.3837 3.11665 16.6559 3.32084 16.0399 3.07646C14.3522 2.40696 12.5007 2.22676 10.7011 2.57004C8.50675 2.98864 6.52705 4.15944 5.10307 5.88073C3.67909 7.60202 2.9 9.76604 2.9 12C2.9 14.234 3.67909 16.398 5.10307 18.1193C6.52705 19.8406 8.50675 21.0114 10.7011 21.43C12.8955 21.8486 15.1672 21.4888 17.1248 20.4125C19.0825 19.3363 20.6035 17.6111 21.4259 15.534C22.1003 13.8307 22.2702 11.9782 21.9288 10.1949C21.8042 9.544 22.1411 8.86742 22.7714 8.66262Z"/>
                            </mask>
                            <path d="M22.7714 8.66262C23.4017 8.45782 24.085 8.80215 24.2259 9.44975C24.7302 11.7684 24.5374 14.1946 23.6573 16.4175C22.6293 19.0138 20.7281 21.1704 18.281 22.5157C15.834 23.861 12.9944 24.3107 10.2514 23.7874C7.50844 23.2642 5.03381 21.8007 3.25384 19.6491C1.47387 17.4975 0.5 14.7924 0.5 12C0.5 9.20755 1.47387 6.50253 3.25384 4.35091C5.03381 2.1993 7.50844 0.735806 10.2514 0.212553C12.5999 -0.235442 15.0192 0.0298047 17.2023 0.959669C17.812 1.21938 18.0222 1.95512 17.7029 2.53589C17.3837 3.11665 16.6559 3.32084 16.0399 3.07646C14.3522 2.40696 12.5007 2.22676 10.7011 2.57004C8.50675 2.98864 6.52705 4.15944 5.10307 5.88073C3.67909 7.60202 2.9 9.76604 2.9 12C2.9 14.234 3.67909 16.398 5.10307 18.1193C6.52705 19.8406 8.50675 21.0114 10.7011 21.43C12.8955 21.8486 15.1672 21.4888 17.1248 20.4125C19.0825 19.3363 20.6035 17.6111 21.4259 15.534C22.1003 13.8307 22.2702 11.9782 21.9288 10.1949C21.8042 9.544 22.1411 8.86742 22.7714 8.66262Z" fill="#00C291" stroke="#00C291" strokeWidth="2" mask="url(#path-1-inside-1_4226_42739)"/>
                            <path d="M22.5 4L11.5 15L6.5 10" stroke="#00C291" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            </span>
                            <span className={'text-base font-medium text-[#1A2128]'}>
                              Header Snippet
                            </span>
                          </div>

                          <div className={'mb-10 flex gap-2  justify-start items-center'}>
                            <span className=''>
                            <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="path-1-inside-1_4226_42739" fill="white">
                            <path d="M22.7714 8.66262C23.4017 8.45782 24.085 8.80215 24.2259 9.44975C24.7302 11.7684 24.5374 14.1946 23.6573 16.4175C22.6293 19.0138 20.7281 21.1704 18.281 22.5157C15.834 23.861 12.9944 24.3107 10.2514 23.7874C7.50844 23.2642 5.03381 21.8007 3.25384 19.6491C1.47387 17.4975 0.5 14.7924 0.5 12C0.5 9.20755 1.47387 6.50253 3.25384 4.35091C5.03381 2.1993 7.50844 0.735806 10.2514 0.212553C12.5999 -0.235442 15.0192 0.0298047 17.2023 0.959669C17.812 1.21938 18.0222 1.95512 17.7029 2.53589C17.3837 3.11665 16.6559 3.32084 16.0399 3.07646C14.3522 2.40696 12.5007 2.22676 10.7011 2.57004C8.50675 2.98864 6.52705 4.15944 5.10307 5.88073C3.67909 7.60202 2.9 9.76604 2.9 12C2.9 14.234 3.67909 16.398 5.10307 18.1193C6.52705 19.8406 8.50675 21.0114 10.7011 21.43C12.8955 21.8486 15.1672 21.4888 17.1248 20.4125C19.0825 19.3363 20.6035 17.6111 21.4259 15.534C22.1003 13.8307 22.2702 11.9782 21.9288 10.1949C21.8042 9.544 22.1411 8.86742 22.7714 8.66262Z"/>
                            </mask>
                            <path d="M22.7714 8.66262C23.4017 8.45782 24.085 8.80215 24.2259 9.44975C24.7302 11.7684 24.5374 14.1946 23.6573 16.4175C22.6293 19.0138 20.7281 21.1704 18.281 22.5157C15.834 23.861 12.9944 24.3107 10.2514 23.7874C7.50844 23.2642 5.03381 21.8007 3.25384 19.6491C1.47387 17.4975 0.5 14.7924 0.5 12C0.5 9.20755 1.47387 6.50253 3.25384 4.35091C5.03381 2.1993 7.50844 0.735806 10.2514 0.212553C12.5999 -0.235442 15.0192 0.0298047 17.2023 0.959669C17.812 1.21938 18.0222 1.95512 17.7029 2.53589C17.3837 3.11665 16.6559 3.32084 16.0399 3.07646C14.3522 2.40696 12.5007 2.22676 10.7011 2.57004C8.50675 2.98864 6.52705 4.15944 5.10307 5.88073C3.67909 7.60202 2.9 9.76604 2.9 12C2.9 14.234 3.67909 16.398 5.10307 18.1193C6.52705 19.8406 8.50675 21.0114 10.7011 21.43C12.8955 21.8486 15.1672 21.4888 17.1248 20.4125C19.0825 19.3363 20.6035 17.6111 21.4259 15.534C22.1003 13.8307 22.2702 11.9782 21.9288 10.1949C21.8042 9.544 22.1411 8.86742 22.7714 8.66262Z" fill="#00C291" stroke="#00C291" strokeWidth="2" mask="url(#path-1-inside-1_4226_42739)"/>
                            <path d="M22.5 4L11.5 15L6.5 10" stroke="#00C291" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            </span>
                            <span className={'text-base font-medium text-[#1A2128]'}>
                              Checkout Snippet
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    {message && selectedPageBuilderStatus == 'Yes' && (
                      <ThirdPartyBuilder trackingDetails={trackingDetails} />
                    )}
                    {verifySiteError && (
                      <>
                        <div className={'mt-8 text-center'}>
                          <p className="text-3 text-black text-align-center">Verification Unsuccessful</p>
                        </div>

                        <div className='flex flex-col justify-center'>
                          <div className={'mt-10 mb-5 flex gap-2 justify-start items-center'}>
                            <span className='border border-[#FF4A3D] rounded-full p-1'>
                              <img src={'/assets/x-02-filled.svg'} className={'w-4'} alt={''} />
                            </span>
                            <span className={'text-base font-medium text-[#FF4A3D]'}>
                              Header Snippet
                            </span>
                          </div>

                          <div className={'mb-10 flex gap-2  justify-start items-center'}>
                            <span className='border border-[#FF4A3D] rounded-full p-1'>
                              <img src={'/assets/x-02-filled.svg'} className={'w-4'} alt={''} />
                            </span>
                            <span className={'text-base font-medium text-[#FF4A3D]'}>
                              Checkout Snippet
                            </span>
                          </div>
                        </div>

                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {!verifySiteLoading && !message ? (
              <div className={'flex flex-col items-center mt-5'}>
                <div className={'w-full md:w-[60%] grid gap-3'}>
                  {snippetShow === 1 ? (
                    <>
                      <button
                        type={'button'}
                        disabled={verifySiteLoading}
                        onClick={handleVerifySite}
                        className={`rounded-md px-3 py-3 ${verifySiteLoading
                            ? 'bg-[#F6F6F6] text-gray-600'
                            : ' bg-[#008160] hover:bg-emerald-600 text-white '
                          } w-full font-medium text-sm`}>
                        {message ? 'Next' : 'Verify Website'}
                      </button>
                      {!message && (
                        <button
                          type={'button'}
                          onClick={() => {
                            changeStep(3);
                            setSnippetShow(1);
                          }}
                          className={
                            'px-3 py-2 bg-transparent text-[#008160] w-full font-medium text-sm'
                          }>
                          Go Back
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      <button
                        type={'button'}
                        onClick={() => setSnippetShow(1)}
                        className={'px-3 py-3 bg-[#008160] hover:bg-emerald-600 text-white w-full font-medium text-sm rounded-md'}>
                        Go Back
                      </button>
                      <Link to={routes.DASHBOARD} className="text-[#008160] hover:text-emerald-600 w-full font-medium text-center mt-3">
                        Skip for now
                      </Link>

                    </>
                  )}
                </div>

                {(trackingDetails.platform == 'woocommerce' ||
                  trackingDetails.platform == 'wordpress') && <InstallationHelp />}
              </div>
            ) : !verifySiteLoading ? (
              <div className={'flex justify-center'}>
                {(() => {
                  const siteId = query.get('siteId');
                  const subscription = profile.subscription_details?.subscription?.find(
                    sub => siteId !== null && sub.idsite === parseInt(siteId, 10)
                  );

                  if (subscription) {
                    const { plan_name } = subscription;

                    if (plan_name === "Freemium") {
                      return (
                        <button
                          onClick={handleAnalyticsClick}
                          className={'px-10 py-3 bg-[#008160] hover:bg-emerald-600 text-white w-[50%] font-medium text-sm rounded-md'}
                        >
                          <span> Done </span>
                        </button>
                      );
                    } else {
                      return (
                        <div className='flex justify-center'>
                          <Link
                            to={routes.WEBSITES}
                            className={' hover:bg-emerald-600 bg-[#008160] px-10 py-3 rounded w-full text-white text-sm flex items-center gap-2'}
                          >
                            <span>Done </span>
                          </Link>
                        </div>
                      );
                    }
                  }

                  // Default case if no subscription found
                  return null;
                })()}
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  );
}

export function PlaceholderForMobile() {
  return (
    <div className="mb-3">
      <div className={'my-8 text-center'}>
        <p className="text-3 text-black text-align-center">Continue on Desktop</p>
        <div className={'flex flex-col justify-center items-center'}>
          <p className={'text-sm text-gray-700 w-full'}>
            Use a desktop computer to place the heatmap.com code snippet onto your site.
          </p>
          <div className={'mt-14 flex justify-center'}>
            <img alt={''} src={'/assets/desktop-placeholder.png'} className={'w-4/5'} />
          </div>
        </div>
      </div>
    </div>
  );
}