import { FC, useContext, useEffect, useState } from 'react';
import Layout from '../../../layouts/global/Layout';
import PageTitle from '../../../components/global/PageTitle';
import { ORGANIZATION_ID, settingsTabs } from '../../../constants';
import { useAppSettings } from './useAppSettings';
import { ISubscriptionCaps } from '../../../interfaces/subscription';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import PlatformWebsiteSettings from '../../../feature/Settings/PlatformWebsiteSettings';
import FeatureManagement from '../../../feature/Settings/FeatureManagement';
import SubscriptionCappingSettings from '../../../feature/Settings/SubscriptionCappingSettings';
import { FormikValues, useFormik } from 'formik';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import * as Yup from 'yup';
import SuccessMessage from '../../../components/global/ui/SuccessMessage';

interface IAppSettingsResponse {
  exempted_urls: string;
  exempted_ips: string;
  price_gating: ISubscriptionCaps;
}

interface ExemptionManagerProps {
  loading: boolean;
  exemptedURLs: string;
  exemptedIPs: string;
  loadExemptions: () => Promise<void>;
}

const ExemptionSettings: FC<ExemptionManagerProps> = ({ loading, exemptedURLs, exemptedIPs }) => {
  const { updateExemption, loading: updateLoading, errorMessage } = useAppSettings();
  const { fetchOrganizationData } = useContext(OrganizationContext);

  const [message, setMessage] = useState<string | null>(null);

  const validationSchema = Yup.object().shape({
    option: Yup.string(),
    value: Yup.string()
  });

  const ipFormik = useFormik({
    initialValues: {
      value: exemptedIPs || '',
      option: 'exempted_ips'
    },
    validationSchema,
    onSubmit: async (values) => handleUpdateExemption(values)
  });

  const urlFormik = useFormik({
    initialValues: {
      value: exemptedURLs || '',
      option: 'exempted_urls'
    },
    validationSchema,
    onSubmit: async (values) => handleUpdateExemption(values)
  });

  const handleUpdateExemption = async (values: FormikValues) => {
    setMessage(null);
    const response = await updateExemption({
      option_value: values.value,
      option: values.option
    });

    if (response?.result === 'success') {
      setMessage(response.msg);
      // refetch organization data
      fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
  };

  return (
    <div>
      <div>
        {loading ? (
          <div className={'p-5 h-[70vh] flex flex-col justify-center items-center text-center'}>
            <LoadingSpinner color={'text-[#008160]'} />
          </div>
        ) : exemptedURLs || exemptedIPs ? (
          <div>
            {updateLoading ? (
              <div className={'lg:h-[60vh] flex flex-col justify-center items-center p-5'}>
                <LoadingSpinner color={'text-[#008160]'} />
                <h5 className={'mt-2 text-sm'}>Updating exemptions...Please wait</h5>
              </div>
            ) : message ? (
              <SuccessMessage message={message} />
            ) : (
              <div className={'mt-5 grid lg:grid-cols-2 gap-5'}>
                <form onSubmit={urlFormik.handleSubmit}>
                  <div>
                    <label className={'text-lg'}>Exempted URLs</label>
                    <textarea
                      rows={6}
                      id={'exempted_url'}
                      name={'value'}
                      value={urlFormik.values.value}
                      placeholder={'Exempted URLs'}
                      onChange={urlFormik.handleChange}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                    />
                  </div>
                  <div className={'flex justify-between gap-5 mt-3'}>
                    <button
                      type={'button'}
                      onClick={() => urlFormik.handleSubmit()}
                      className={
                        'rounded px-3 py-2 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                      }>
                      <span>
                        <i className="fi fi-rr-pencil text-xs"></i>
                      </span>
                      <span className={'text-xs'}>Update URL Exemption</span>
                    </button>
                  </div>
                </form>
                <form onSubmit={ipFormik.handleSubmit}>
                  <div>
                    <label className={'text-lg'}>Exempted IPs</label>
                    <textarea
                      rows={6}
                      id={'exempted_ip'}
                      name={'value'}
                      value={ipFormik.values.value}
                      placeholder={'Exempted IPs'}
                      onChange={ipFormik.handleChange}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                    />
                  </div>
                  <div className={'flex justify-between gap-5 mt-3'}>
                    <button
                      type={'button'}
                      onClick={() => ipFormik.handleSubmit()}
                      className={
                        'rounded px-3 py-2 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                      }>
                      <span>
                        <i className="fi fi-rr-pencil text-xs"></i>
                      </span>
                      <span className={'text-xs'}>Update IP Exemption</span>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default function PlatformSettings() {
  const [tab, setTab] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [exemptedURLs, setExemptedURLs] = useState<string>('');
  const [exemptedIPs, setExemptedIPs] = useState<string>('');
  const [subscriptionCaps, setSubscriptionCaps] = useState<ISubscriptionCaps | null>(null);
  const { loadAppSettings } = useAppSettings();

  const loadExemptions = async () => {
    setLoading(true);
    try {
      const response = (await loadAppSettings({})) as IAppSettingsResponse;
      if (response) {
        setSubscriptionCaps(response.price_gating);
        setExemptedURLs(response.exempted_urls);
        setExemptedIPs(response.exempted_ips);
        // setSearchResults(response.exempted_urls);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error loading exemptions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadExemptions();
    // loadAppSettings({ option: 'subscription_caps' }).then((response) => {
    //   if (response.subscription_caps) setSubscriptionCaps(response.subscription_caps[0]);
    // });
  }, []);

  return (
    <>
      <Layout>
        <PageTitle title={'Platform Settings'} />
        <div>
          {loading ? (
            <div className={'p-5 h-[68vh] flex flex-col justify-center items-center text-center'}>
              <LoadingSpinner color={'text-[#008160]'} />
              <p>Loading settings...</p>
            </div>
          ) : (
            <div>
              <div className={'flex gap-4'}>
                {settingsTabs &&
                  settingsTabs.map((item, index) => (
                    <button
                      type={'button'}
                      onClick={() => setTab(item.id)}
                      key={index}
                      className={`${
                        tab === item.id
                          ? 'border-[#008160] text-[#008160] hover:border-[#008160]'
                          : 'border-transparent hover:border-gray-300'
                      } py-2 border-b-2 font-medium text-sm flex gap-3`}>
                      <span className={'text-sm'}>{item.name}</span>
                    </button>
                  ))}
              </div>
              <div className={'mt-2'}>
                <div>
                  {tab == 1 ? (
                    <ExemptionSettings
                      loading={loading}
                      exemptedURLs={exemptedURLs}
                      exemptedIPs={exemptedIPs}
                      loadExemptions={loadExemptions}
                    />
                  ) : tab == 2 ? (
                    <SubscriptionCappingSettings
                      loading={loading}
                      data={subscriptionCaps as ISubscriptionCaps}
                    />
                  ) : tab == 3 ? (
                    <PlatformWebsiteSettings />
                  ) :tab == 4 ? (
                    <FeatureManagement/>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
}
