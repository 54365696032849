import { useRef } from 'react';
import useClickOutside from '../../../../hooks/useClickOutside';
import { IAIDocument } from '../../../../interfaces/analytic';

export default function AIDocumentListCardMenuPopover({
  data,
  onClickOutside,
  setDeleteOpen,
  setActivateOpen,
  setEditOpen,
  setSelected,
  show
}: {
  data: IAIDocument;
  setDeleteOpen: (x: boolean) => void;
  setEditOpen: (x: boolean) => void;
  setActivateOpen: (x: boolean) => void;
  setSelected: (x: IAIDocument) => void;
  onClickOutside: () => void;
  show: boolean;
}) {
  const ref: any = useRef(null);
  useClickOutside(ref, onClickOutside);

  if (!show) return null;

  return (
    <>
      <div
        ref={ref}
        className={
          'absolute top-5 lg:top-10 right-0 bg-white z-50 drop-shadow-lg w-[45vw] md:w-[30vw] lg:w-[15vw] '
        }>
        <div className={'flex flex-col'}>
          {data && data.status === 0 && (
            <button
              type={'button'}
              onClick={() => {
                setSelected(data);
                setActivateOpen(true);
                onClickOutside();
              }}
              className={
                'edit z-10 w-full px-3 py-2 hover:bg-emerald-100 text-emerald-600 text-xs md:text-sm flex gap-2 md:gap-3'
              }>
              <span>
                <i className="fi fi-rr-rocket-lunch text-sm md:text-base"></i>
              </span>
              <span>Activate</span>
            </button>
          )}

          <button
            type={'button'}
            onClick={() => {
              setSelected(data);
              setEditOpen(true);
              onClickOutside();
            }}
            className={
              'edit z-10 w-full px-3 py-2 hover:bg-gray-100 text-xs md:text-sm flex gap-2 md:gap-3'
            }>
            <span>
              <i className="edit fi fi-rr-pencil text-sm md:text-base"></i>
            </span>
            <span>Edit document</span>
          </button>

          {data && data.status === 0 && (
            <button
              type={'button'}
              onClick={() => {
                setSelected(data);
                setDeleteOpen(true);
                onClickOutside();
              }}
              className={
                'edit z-10 w-full px-3 py-2 hover:bg-red-100 text-red-500 text-xs md:text-sm flex gap-2 md:gap-3'
              }>
              <span>
                <i className="edit fi fi-rr-trash text-sm md:text-lg"></i>
              </span>
              <span>Delete document</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
}
