export default function WarningPrompt({ children }: { children: any }) {
  return (
    <div className={'flex gap-2 text-sm bg-amber-50 p-3 rounded border-l-2 border-amber-500'}>
      <div className={'w-[10%] flex justify-center'}>
        <span>
          <i className="fi fi-ss-triangle-warning text-2xl text-amber-500"></i>
        </span>
      </div>
      <div className={'grid gap-2 text-sm w-[90%]'}>{children}</div>
    </div>
  );
}
