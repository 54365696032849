import { IClient } from '../../interfaces/client';

interface SearchFormProps {
  clients: any;
  setSearchResults: any;
}

export default function SearchClients({ onSearchChange }: { onSearchChange: any }) {
  const handleInputChange = (e: any) => {
    // This calls the debounced handleSearchChange function in Clients component
    onSearchChange(e.target.value);
  };

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className={
        'border border-gray-300 bg-gray-100 rounded px-4 py-2 flex gap-3 items-start w-full'
      }>
      <span>
        <i className="fi fi-rr-search"></i>
      </span>
      <input
        type={'search'}
        id={'search'}
        placeholder={'Search client'}
        className={'bg-transparent w-full text-sm'}
        onChange={handleInputChange}
      />
    </form>
  );
}
