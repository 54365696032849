import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Error from '../../components/global/ui/alerts/Error';
import Modal from '../../components/global/ui/Modal';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { IOrganization } from '../../interfaces/organization';
import { useTeamInvites } from '../../pages/main/TeamManagement/useTeamInvites';
import { ORGANIZATION_ID } from '../../constants';
import WarningPrompt from '../../components/global/ui/alerts/WarningPrompt';

export default function LeaveOrganization({
  data,
  closeModal
}: {
  data: IOrganization;
  closeModal: () => void;
}) {
  const { leaveOrganization, loading, errorMessage } = useTeamInvites();
  const [message, setMessage] = useState<string | null>(null);

  const { fetchOrganizationData, profile } = useContext(OrganizationContext);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Enter the account name')
  });

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema,
    onSubmit: () => handleLeaveOrganization(data.account_id as string)
  });

  const handleLeaveOrganization = async (account_id: string) => {
    setMessage(null);
    const response = await leaveOrganization({
      account_id
    });
    if (response?.result == 'success') {
      setMessage(response?.msg);
      // refetch organization data
      fetchOrganizationData(profile && (profile.organizations[0].account_id as string));
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Leave organization'} width={'w-full md:w-1/2 lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Leaving organization...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <div>
              <div>
                <WarningPrompt>
                  <>
                    <p className={'font-medium'}>You are opting to leave this organization</p>
                    <p className={'text-xs'}>
                      Proceeding to leave this organization will remove your access to the websites
                      and subscriptions of this account
                    </p>
                  </>
                </WarningPrompt>
                <div className={'mt-5'}>
                  <p className={'text-sm'}>
                    Type <strong>{data.name}</strong> below to proceed
                  </p>
                  <div className={'my-1'}>
                    <input
                      type={'text'}
                      name={'name'}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      className="w-full bg-transparent outline-0 px-3 py-2 rounded border border-gray-300 mt-1 flex items-center"
                    />

                    <p className={'text-xs text-red-500'}>
                      {formik.touched.name && formik.errors.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className={'grid gap-2'}>
                <div className={'mt-2 flex flex-col-reverse lg:flex-row justify-end gap-2'}>
                  <button
                    type={'button'}
                    onClick={closeModal}
                    className={`px-4 py-2 rounded text-gray-700 bg-gray-200 hover:bg-gray-300 text-xs`}>
                    No, do not leave
                  </button>
                  <button
                    type={'button'}
                    onClick={() => handleLeaveOrganization(data.account_id as string)}
                    disabled={formik.values.name !== data.name}
                    className={`rounded bg-white font-medium px-4 py-2 text-xs ${
                      formik.values.name === '' || formik.values.name !== data.name
                        ? 'border border-red-500 text-red-500 hover:bg-transparent'
                        : 'bg-red-500 hover:bg-red-600 text-white'
                    }                      `}>
                    {loading ? 'Leaving organization..' : 'Yes, proceed to leave'}
                  </button>
                </div>
                {errorMessage && <Error value={`${errorMessage}`} />}
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}
