import { Link } from 'react-router-dom';
import { useAuthUser } from 'react-auth-kit';
import { useLogoutUser } from '../../../../util/global';
import { useRef } from 'react';
import useClickOutside from '../../../../hooks/useClickOutside';
import { routes } from '../../../../constants/routes';

export default function AccountPopover({
  onClickOutside,
  show
}: {
  onClickOutside?: any;
  show: boolean;
}) {
  const auth = useAuthUser();
  const { logoutUser } = useLogoutUser();

  const ref: any = useRef(null);
  useClickOutside(ref, onClickOutside);

  if (!show) return null;

  return (
    <div
      ref={ref}
      className={'absolute right-0 top-[7vh] bg-white drop-shadow-lg rounded w-[22vw] z-10'}>
      <div className={'h-[10vh] bg-emerald-300'}>
        <span
          className={
            'absolute top-3 right-3 rounded-full text-[#008160] bg-white px-2 py-1 text-xs'
          }>
          {auth()?.account_holder == 1 && auth()?.super_user == 1
            ? 'Administrator'
            : auth()?.account_holder == 1 && auth()?.super_user == 0
            ? 'Account Owner'
            : 'Guest'}
        </span>
      </div>
      <div>
        <div className={'flex px-5 -mt-8'}>
          <span
            className={
              'w-14 h-14 rounded-full bg-gray-200 text-gray-600 font-medium text-xl flex justify-center items-center uppercase'
            }>
            {auth()?.full_name?.charAt(0)}
          </span>
        </div>
        <div className={'px-5 pt-3 pb-5'}>
          <div>
            <h5 className={'text-base font-medium capitalize'}>{auth()?.full_name}</h5>
            <p className={'text-sm'}>{auth()?.email}</p>
          </div>
          <div>
            <Link to={routes.ACCOUNT_PROFILE} className={'text-sm underline text-[#008160]'}>
              See Profile
            </Link>
          </div>
          <div>
            <Link to={routes.ACCOUNTS} className={'text-sm underline text-[#008160]'}>
              Accounts
            </Link>
          </div>
          <div className={'mt-5'}>
            <button
              type={'button'}
              onClick={() => logoutUser()}
              className={'w-full bg-red-500 hover:bg-red-600 rounded py-2 text-sm text-white'}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
