import { useState } from 'react';
import { getRequest } from '../../../requests';
import { useAuthHeader } from 'react-auth-kit';

export const useClients = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [promoLoading, setPromoLoading] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const listClients = async ({ page, search }: { page?: number; search?: string }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.listClients',
          token: token,
          page,
          search,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed retrieving clients');
      setLoading(false);
    }
  };

  const listRegistrations = async () => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.clientRegistrations',
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed retrieving new client registrations');
      setLoading(false);
    }
  };
  const addClient = async ({
    email,
    fullname,
    password,
    user_type
  }: {
    email: string;
    fullname: string;
    password: string;
    user_type: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.addClient',
          email,
          fullname,
          password,
          user_type,
          token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed adding a new client');
      setLoading(false);
    }
  };

  const deleteClient = async ({ userId }: { userId: number }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.deleteClient',
          user_id: userId,
          token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed deleting client');
      setLoading(false);
    }
  };

  const cancelRegistration = async (userId: number) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.deleteRegisteredClient',
          user_id: userId,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed cancelling registration');
      setLoading(false);
    }
  };

  const activateRegistration = async (email: string) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.activateClient',
          email,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed activating account');
      setLoading(false);
    }
  };

  const editRegistration = async ({
    email,
    userId,
    fullname,
    password
  }: {
    email: string;
    userId: number;
    fullname: string;
    password: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.updateRegisteredClient',
          email,
          user_id: userId,
          token: token,
          fullname,
          password,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed applying a coupon');
      setLoading(false);
    }
  };

  const UpdateAgency = async ({
    userId,
    is_agency
  }: {
    userId: number;
    is_agency : boolean
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.setAsAgency',
          user_id: userId,
          token: token,
          is_agency,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed applying a coupon');
      setLoading(false);
    }
  };

  const sendEmailToClient = async ({
    title,
    userId,
    message
  }: {
    title: string;
    message: string;
    userId: number;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.sendClientEmail',
          title,
          message,
          user_id: userId,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed sending email to client(s)');
      setLoading(false);
    }
  };

  const changeClientStatus = async ({ state, userId }: { state: boolean; userId: number }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.changeClientStatus',
          state,
          user_id: userId,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed changing client status');
      setLoading(false);
    }
  };

  const listClientAccounts = async ({ user_id }: { user_id: string | number }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.loadOrganizations',
          user_id,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Failed retrieving client accounts');
      setLoading(false);
    }
  };

  return {
    listClients,
    listRegistrations,
    addClient,
    deleteClient,
    editRegistration,
    cancelRegistration,
    activateRegistration,
    sendEmailToClient,
    changeClientStatus,
    listClientAccounts,
    UpdateAgency,
    errorMessage,
    loading,
    promoLoading,
    couponLoading
  };
};
