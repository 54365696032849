import SearchWebsites from '../../components/form/SearchWebsites';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { IWebsiteSubscription } from '../../interfaces/subscription';
import SelectedWebsiteSettingsDetails from './SelectedWebsiteSettingsDetails';

export default function PlatformWebsiteSettings() {
  const { profile, loading: websiteLoading } = useContext(OrganizationContext);
  const [searchWebResults, setSearchWebResults] = useState<IWebsiteSubscription[] | []>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedWebsite, setSelectedWebsite] = useState(
    profile && profile.subscription_details?.subscription[0]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
  }


  useEffect(() => {
    setLoading(true);
    if (profile) {
      setSearchWebResults(profile.subscription_details?.subscription as IWebsiteSubscription[]);
      setLoading(false);
    }
  }, [profile.subscription_details?.subscription]);

  const openModal = (website:any) => {
    setSelectedWebsite(website);
    if (window.innerWidth < 1024) {
      setIsModalOpen(true);
    }
  };

  // Custom Modal component
  const Modal = ({ isOpen, onClose, children }: ModalProps) => {
    if (!isOpen) return null;


    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4">
        <div className="bg-white rounded-lg w-full max-w-md max-h-[90vh] overflow-y-auto">
          <div className="flex justify-end p-2">
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="p-4">
            {children}
          </div>
        </div>
      </div>
    );
  };

 return (
  <div className="lg:grid lg:grid-cols-4 gap-5">
    <div className="lg:col-span-2 bg-gray-50 border-2 border-gray-200 rounded-lg p-3 lg:p-5">
      <SearchWebsites
        websites={profile.subscription_details?.subscription as IWebsiteSubscription[]}
        setSearchResults={setSearchWebResults}
      />
      <div className="h-[60vh] lg:h-[60vh] slim-scroll overflow-y-auto flex flex-col gap-3 mt-5">
        {loading || websiteLoading ? (
          <div className="p-5 h-[60vh] flex flex-col justify-center items-center text-center">
            <LoadingSpinner color="text-[#008160]" />
          </div>
        ) : profile &&
          profile.subscription_details &&
          profile.subscription_details?.subscription &&
          profile.subscription_details?.subscription.filter((web) => web.website).length > 0 &&
          searchWebResults &&
          searchWebResults.length > 0 ? (
          searchWebResults
            .filter((web) => web.website)
            .map((item, index) => (
              <div
                onClick={() => {
                  setSelectedWebsite(item);
                  openModal(item);
                }}
                className={`${
                  selectedWebsite?.website?.idsite === item.idsite
                    ? 'lg:bg-emerald-50 lg:border-emerald-300 drop-shadow-md'
                    : 'lg:border-gray-200 hover:border-gray-300'
                } bg-white rounded p-3 border border-gray-300 hover:border-emerald-500 flex justify-between gap-3 cursor-pointer`}
                key={index}
              >
                <div>
                  <h4 className="font-medium text-xs sm:text-sm lg:text-base">{item.website?.name}</h4>
                  <div className="flex gap-1 my-1">
                    <span>
                      <i className="fi fi-rr-link text-xs"></i>
                    </span>
                    <span className="text-xs lg:text-sm truncate">
                      {item.website?.main_url}
                    </span>
                  </div>
                </div>
                <div>
                  <span>
                    <i className="fi fi-rr-angle-right text-xs"></i>
                  </span>
                </div>
              </div>
            ))
        ) : null}
      </div>
    </div>

    {/* Desktop version */}
    <div className="hidden lg:block lg:col-span-2">
      {loading || websiteLoading ? (
        <div className="p-5 h-[60vh] flex flex-col justify-center items-center text-center">
          <LoadingSpinner color="text-[#008160]" />
          <p>Loading...</p>
        </div>
      ) : (
        profile &&
        profile.subscription_details &&
        profile.subscription_details?.subscription.filter((web) => web.website).length > 0 &&
        selectedWebsite && (
          <SelectedWebsiteSettingsDetails
            data={{
              siteId: selectedWebsite.idsite,
              name: selectedWebsite.website?.name as string,
              main_url: selectedWebsite.website?.main_url as string
            }}
          />
        )
      )}
    </div>

    {/* Mobile and Tablet Modal */}
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      {loading || websiteLoading ? (
        <div className="p-5 h-[60vh] flex flex-col justify-center items-center text-center">
          <LoadingSpinner color="text-[#008160]" />
          <p className="text-sm">Loading...</p>
        </div>
      ) : (
        profile &&
        profile.subscription_details &&
        profile.subscription_details?.subscription.filter((web) => web.website).length > 0 &&
        selectedWebsite && (
          <SelectedWebsiteSettingsDetails
            data={{
              siteId: selectedWebsite.idsite,
              name: selectedWebsite.website?.name as string,
              main_url: selectedWebsite.website?.main_url as string
            }}
          />
        )
      )}
    </Modal>
  </div>
);
}