import Snippet from '../Snippet';
import React from 'react';
import { ITracking } from '../../../interfaces/global';

export default function WebflowSquarespace({ trackingDetails }: { trackingDetails: ITracking }) {
  return (
    <div>
      <div>
        <h6 className={'font-medium text-base text-gray-700'}>
          Installation for your {trackingDetails.platform} website
        </h6>
      </div>
      <div>
        <h6 className={'font-medium text-sm'}>
          Copy and paste this snippet inside the header of your pages
        </h6>
        <Snippet snippet={trackingDetails.script as string} />
      </div>
    </div>
  );
}
