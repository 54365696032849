import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '../../util/global';
import { routes } from '../../constants/routes';
import { ORGANIZATION_ID } from '../../constants';
import { useSignup } from './Register/useSignup';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { useAuthUser } from 'react-auth-kit';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import Error from '../../components/global/ui/alerts/Error';

export default function AccountHolderActivation() {
  const navigate = useNavigate();
  const query = useQuery();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const { validateAccountHolderRequest, loading, errorMessage } = useSignup();
  const { profile, fetchOrganizationData, switchOrganization } = useContext(OrganizationContext);

  const auth = useAuthUser();

  useEffect(() => {
    if (query.get('token') || query.get('email')) {
      validateAccountHolderRequest({
        email: query.get('email') as string,
        token: query.get('token') as string
      }).then((response) => {
        if (response?.result == 'success') {
          setSuccessMessage(response.msg || response.message);

          // set organization to the user's organization
          switchOrganization(
            (profile.organizations[0]?.account_id as string) || auth()?.account_id
          );
        }
      });
    } else {
      navigate(routes.DASHBOARD);
    }
  }, []);

  const goToDashboard = async () => {
    if (profile && profile.subscription_details?.subscription.length === 0) {
      navigate(routes.PLANS);
    } else navigate(routes.DASHBOARD);
  };

  return (
    <div>
      <main
        style={{
          background: 'url(/assets/img/waitlist-bg.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
        className={'h-screen flex gap-3 flex-col justify-center items-center p-5 lg:p-10'}>
        <Link to={routes.DASHBOARD} className={'flex items-center gap-3'}>
          <img src="/assets/Vector.svg" alt="" className={'w-8'} />
          <p className="text-secondary text-lg">Heatmap.com</p>
        </Link>
        <div className={'flex flex-col gap-2 justify-center items-center'}>
          {loading ? (
            <div className={'h-screen flex justify-center items-center'}>
              <LoadingSpinner color={'text-emerald-600'} />
            </div>
          ) : successMessage ? (
            <div className={'w-full lg:w-2/3 text-center grid gap-5'}>
              <h5 className={'font-bold text-xl lg:text-2xl'}>
                You are officially an Account Holder!
              </h5>
              {successMessage && <SuccessMessage message={successMessage as string} />}
            </div>
          ) : errorMessage ? (
            <Error value={errorMessage as string} />
          ) : null}
          <div className={'flex justify-center mt-5'}>
            <button
              // to={routes.LOGIN}
              type={'button'}
              onClick={goToDashboard}
              className={
                'rounded px-3 lg:px-4 py-1 lg:py-2 flex justify-center items-center gap-2 border border-[#008160] bg-white hover:bg-emerald-50 text-[#008160]'
              }>
              Go to dashboard
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}
