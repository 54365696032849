import { CardElement } from '@stripe/react-stripe-js';
import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { intervals, plans } from '../../../pages/main/Pricing';
import { IPlan, IPlanInterval } from '../../../interfaces/components';
import { usePayment } from '../../../pages/main/Pricing/usePayment';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Error from '../../../components/global/ui/alerts/Error';
import { useAuthUser } from 'react-auth-kit';
import { ISubscription } from '../../../interfaces/subscription';
import { ORGANIZATION_ID } from '../../../constants';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { routes } from '../../../constants/routes';

export default function CheckoutForm({
  plan,
  interval,
  subscription
}: {
  plan: IPlan;
  interval: IPlanInterval;
  subscription: ISubscription;
}) {
  const { makePayment, confirmPayment, addCardToSubscription, errorMessage, loading } =
    usePayment();
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null | undefined>(null);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    plan: Yup.string().required('Select your plan'),
    email:
      subscription && subscription.card?.length > 0
        ? Yup.string().email('Enter a valid email')
        : Yup.string().email('Enter a valid email').required('Enter your email'),
    name:
      subscription.card?.length > 0
        ? Yup.string()
        : Yup.string().required('Enter the name on card'),
    interval: Yup.string().required('Specify the interval'),
    coupon: Yup.string()
  });

  const auth = useAuthUser();
  const { fetchOrganizationData } = useContext(OrganizationContext);

  const formik = useFormik({
    initialValues: {
      plan: plan && plan.value,
      name: '',
      coupon: '',
      interval: interval && interval.value,
      email: auth() && auth()?.email,
      card_id:
        subscription.card && subscription.card.length > 0
          ? subscription.card.filter((item) => item.is_default_source)[0].id
          : ''
    },
    validationSchema,
    onSubmit: async (values) => {
      setMessage(null);
      setError(null);

      const response = await makePayment({
        name: values.name,
        email: values.email,
        plan: values.plan,
        card_id: values.card_id,
        interval: values.interval,
        coupon: values.coupon
      });
      if (response.msg) {
        const { subscriptionId } = response.msg;

        if (values.card_id) {
            // setMessage(confirmation.msg);
            // refetch organization data
            fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);

            setTimeout(() => {
              navigate(routes.SETUP);
            }, 1000);
        } else {
            const addCard = await addCardToSubscription(subscriptionId);
            if (addCard.result === 'success') {
              // setMessage(confirmation.msg);
              // refetch organization data
              fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);

              setTimeout(() => {
                navigate(routes.SETUP);
              }, 1000);
            }
        }
      } else {
        // setError('Error completing subscription');
      }
    }
  });

  return (
    <>
      {message ? (
        <div className={'h-[25vh] flex flex-col justify-center items-center text-center'}>
          <h4 className={'text-[#008160] text-lg'}>{message}</h4>
          <p className={'text-sm text-gray-400'}>Redirecting to dashboard...</p>
        </div>
      ) : (
        <div className={'grid gap-2'}>
          <div className={'grid grid-cols-2 gap-2'}>
            <div className={'col-span-2'}>
              <label className={'capitalize text-sm'}>
                Select Plan <span className={'text-red-500'}>*</span>{' '}
              </label>
              <select
                className={'p-2 w-full rounded-md border border-gray-300'}
                name={'plan'}
                value={formik.values.plan}
                onChange={formik.handleChange}>
                {plans &&
                  plans
                    // .filter((plan) => plan.value != 4)
                    .map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.name} - $
                        {formik.values.interval === 'month'
                          ? `${item.monthlyPrice}/month`
                          : `${item.yearlyPrice * 12}/year`}
                      </option>
                    ))}
              </select>
            </div>
          </div>

          <div>
            <label className={'capitalize text-sm'}>
              Email <span className={'text-red-500'}>*</span>{' '}
            </label>
            <input
              type={'email'}
              className={'p-2 w-full rounded-md border border-gray-300'}
              name={'email'}
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {/*<p className={'text-sm text-red-500'}>{formik.touched.email && formik.errors.email}</p>*/}
          </div>
          {subscription && subscription.card.length > 0 ? (
            <div>
              <div>
                <label className={'capitalize text-sm'}>
                  Select payment card <span className={'text-red-500'}>*</span>{' '}
                </label>
                <select
                  className={'p-2 w-full rounded-md border border-gray-300 capitalize'}
                  name={'card_id'}
                  value={formik.values.card_id}
                  onChange={formik.handleChange}>
                  {subscription &&
                    subscription.card.map((item: any, index: number) => (
                      <option value={item.id} key={index}>
                        <span>
                          {item.card.brand} **** **** **** {item.card.last4}
                        </span>
                      </option>
                    ))}
                </select>
              </div>
            </div>
          ) : (
            <div className={'grid gap-2'}>
              <div>
                <label className={'capitalize text-sm'}>
                  Name on Card <span className={'text-red-500'}>*</span>
                </label>
                <input
                  type={'text'}
                  className={'p-2 w-full rounded-md border border-gray-300'}
                  name={'name'}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  placeholder={'Enter full name on the card'}
                />
                <p className={'text-sm text-red-500'}>
                  {formik.touched.name && formik.errors.name}
                </p>
              </div>
              <div>
                <label className={'capitalize text-sm'}>
                  Card Info <span className={'text-red-500'}>*</span>
                </label>
                <CardElement className={'p-3 w-full rounded-md border border-gray-300'} />
              </div>
            </div>
          )}

          <div>
            <label className={'capitalize text-sm'}>Coupon</label>
            <input
              type={'text'}
              className={'p-2 w-full rounded-md border border-gray-300'}
              name={'coupon'}
              value={formik.values.coupon}
              onChange={formik.handleChange}
            />
          </div>
          <div className={''}>
            <PrimaryButton
              disabled={loading }
              title={
                loading || formik.isSubmitting ? 'Creating subscription...' : 'Proceed to subscribe'
              }
              handler={() => formik.handleSubmit()}
            />
          </div>
          {errorMessage && <Error value={errorMessage} />}
          {error && <Error value={error} />}
        </div>
      )}
    </>
  );
}
