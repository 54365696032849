export interface IAnalytic {
  chart: IChart;
  onboarding: {
    completed: string;
    pending: string;
    statuses: {
      active: number;
      deleted: number;
      inactive: number;
      is_removed: number;
      pending: number;
    };
  };
  subscriptions: ISubscriptionsAnalytics;
  today: {
    invitations: number;
    registrations: number;
    unpaid_registrations: number; // signup users but no payment made today
    cancelled_subscriptions: number; // users initiated cancellation today
    subscriptions: number;
  };
  websites?: {
    platforms?: IWebsitePlatformChart;
    verifications?: {
      not_verified: {
        active: number;
        pending: number;
        paused: number;
      };
      verified: {
        active: number;
        pending: number;
        paused: number;
      };
    };
    industry?: any;
  };
}

export interface ISubscriptionsAnalytics {
  active: {
    month: {
      grow: number;
      basic: number;
      starter: number;
      pro: number;
      scale: number;
      'scale plus': number;
    };
    year: {
      grow: number;
      basic: number;
      starter: number;
      pro: number;
      scale: number;
      'scale plus': number;
    };
  };
  basic: {
    incomplete: number;
    cancelled: number;
    active: number;
    active_trial: number;
    active_not_cancelled: number;
    paused: number;
    pending_cancellation: number;
  };
  grow: {
    cancelled: number;
    paused: number;
    active: number;
    incomplete: number;
    active_trial: number;
    active_not_cancelled: number;
    pending_cancellation: number;
  };
  starter: {
    cancelled: number;
    paused: number;
    active: number;
    incomplete: number;
    active_trial: number;
    active_not_cancelled: number;
    pending_cancellation: number;
  };
  pro: {
    cancelled: number;
    paused: number;
    active: number;
    incomplete: number;
    active_trial: number;
    active_not_cancelled: number;
    pending_cancellation: number;
  };
  scale: {
    cancelled: number;
    paused: number;
    active: number;
    incomplete: number;
    active_trial: number;
    active_not_cancelled: number;
    pending_cancellation: number;
  };
  'scale plus': {
    cancelled: number;
    paused: number;
    active: number;
    incomplete: number;
    active_trial: number;
    active_not_cancelled: number;
    pending_cancellation: number;
  };
  statuses: {
    active: number;
    active_trial: number;
    cancelled: number;
    paused: number;
    incomplete: number;
    active_not_cancelled: number; // users who have their subscription active and not cancelled
    pending_cancellation: number; // users who have initiated cancellation but currently have subscription active
    attempted_cancellation: number;
    resolved_cancellation: number;
  };
  plans: {
    basic: number;
    starter: number;
    pro: number;
    grow: number;
    scale: number;
    'scale plus': number;
  };
  platforms?: any;
  interval: {
    1?: {
      cancelled: number; // users who have their subscription active and not cancelled based on intervals
      pending_cancellation: number; // users initiated cancellation based on subscription intervals
    };
    month: {
      active: number;
      active_not_cancelled: number;
      active_trial: number;
      paused: number;
      cancelled: number;
      pending_cancellation: number;
      incomplete: number;
    };
    year: {
      active: number;
      active_not_cancelled: number;
      active_trial: number;
      paused: number;
      cancelled: number;
      pending_cancellation: number;
      incomplete: number;
    };
  };
  clients_pending_cancellation?: IClientsPendingCancellation;
}

export interface IClientsPendingCancellation {
  today: ICancelledSubscriptionItem[];
  weekly: {
    Monday: ICancelledSubscriptionItem[];
    Tuesday: ICancelledSubscriptionItem[];
    Wednesday: ICancelledSubscriptionItem[];
    Thursday: ICancelledSubscriptionItem[];
    Friday: ICancelledSubscriptionItem[];
    Saturday: ICancelledSubscriptionItem[];
    Sunday: ICancelledSubscriptionItem[];
  };
  annual: {
    January: ICancelledSubscriptionItem[];
    February: ICancelledSubscriptionItem[];
    March: ICancelledSubscriptionItem[];
    April: ICancelledSubscriptionItem[];
    May: ICancelledSubscriptionItem[];
    June: ICancelledSubscriptionItem[];
    July: ICancelledSubscriptionItem[];
    August: ICancelledSubscriptionItem[];
    September: ICancelledSubscriptionItem[];
    October: ICancelledSubscriptionItem[];
    November: ICancelledSubscriptionItem[];
    December: ICancelledSubscriptionItem[];
  };
}

export interface ICancelledSubscriptionItem {
  plan: number;
  plan_interval: string;
  paid_amount: string;
  plan_period_start: string;
  plan_period_end: string;
  created: string;
  is_cancelled: number;
  is_cancelled_date: string;
  user_id: string;
  idsite: number;
  full_name: string;
  login: string;
}

export interface ICancellationReasonItem {
  name: string;
  email: string;
  plan: string;
  reason: string;
}

export interface IWebsitePlatformChart {
  shopify?: {
    active: number;
    pending: number;
    paused: number;
  };
  other?: {
    active: number;
    pending: number;
    paused: number;
  };
  bigcommerce?: {
    active: number;
    pending: number;
    paused: number;
  };
  wordpress?: {
    active: number;
    pending: number;
    paused: number;
  };
  woocommerce?: {
    active: number;
    pending: number;
    paused: number;
  };
  webflow?: {
    active: number;
    pending: number;
    paused: number;
  };
}

export enum ChartType {
  user = 1,
  subscription = 2,
  waitlist = 3,
  cancelled_subscriptions = 4
}

export enum CancellationChartType {
  attempted = 1,
  recovered = 2,
  cancelled = 3
}

export enum ChartPeriod {
  week = 1,
  annual = 2
}

export interface IChart {
  annual: {
    users: IMonths;
    subscriptions: IMonths;
    waitlist: IMonths;
    cancelled_subscriptions: IMonths; // cancelled_subscriptions response for users initiated cancellation annual
    cancelled_reason: any;
    cancelled_reason_other: any;
    cancelled_type: any;
  };
  weekly: {
    users: IWeeks;
    subscriptions: IWeeks;
    waitlist: IWeeks;
    cancelled_subscriptions: IMonths; // cancelled_subscriptions response for users initiated cancellation weekly
    cancelled_reason: any;
    cancelled_type: any;
  };
}

export interface IMonths {
  January: number;
  February: number;
  March: number;
  April: number;
  May: number;
  June: number;
  July: number;
  August: number;
  September: number;
  October: number;
  November: number;
  December: number;
}

export interface IWeeks {
  Monday: number;
  Tuesday: number;
  Wednesday: number;
  Thursday: number;
  Friday: number;
  Saturday: number;
  Sunday: number;
}

export interface ILog {
  userID: string;
  log_for: string;
  subject: string;
  activity: string;
  rawip: string;
  log_date: string;
  config_os: string;
  config_browser_name: string;
  config_browser_version: string;
  account_id: string;
}

export interface IAIDocument {
  context: string;
  created_at: string;
  id: number;
  updated_at: string;
  prompt: string;
  title: string;
  status: number;
}
