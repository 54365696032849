import TopHeader from '../../components/global/TopHeader';
import { Link } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { LANDING_PAGE_URL } from '../../constants';

export default function NotFound() {
  return (
    <>
      <TopHeader />
      <main>
        <section
          style={{
            background: 'url(/assets/img/waitlist-bg.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
          className={'p-10 flex justify-center items-center h-[92vh] text-center'}>
          <div className={'flex flex-col justify-center items-center'}>
            <p className={'text-[#008160] text-lg'}>Page not found</p>
            <p className={'font-semibold text-2xl lg:text-3xl text-black'}>
              You missed your way. We know!
            </p>
            <div className={'flex gap-2 mt-5'}>
              <a
                href={LANDING_PAGE_URL}
                className={
                  'rounded px-3 lg:px-4 py-1 lg:py-2 flex justify-center items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                }>
                <span className={'pt-1'}>
                  <i className="fi fi-rr-house-chimney text-base"></i>
                </span>
                <span className={'text-base lg:text-base'}>Heatmap.com</span>
              </a>
              <Link
                to={routes.LOGIN}
                className={
                  'rounded px-3 lg:px-4 py-1 lg:py-2 flex justify-center items-center gap-2 border border-[#008160] bg-white hover:bg-emerald-50 text-[#008160]'
                }>
                Go to dashboard
              </Link>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
