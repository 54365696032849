import CheckoutForm from '../Checkout/CheckoutForm';
import { IPaymentModal } from '../../../interfaces/components';
import FirstTimeCheckoutForm from '../Checkout/FirstTimeCheckoutForm';
import Modal from '../../../components/global/ui/Modal';

export default function Payment({ plan, interval, subscription, closeModal }: IPaymentModal) {
  return (
    <Modal closeModal={closeModal} title={'Subscribe to package'} width={'lg:w-1/3'}>
      {subscription.card.length > 0 ? (
        <CheckoutForm plan={plan} interval={interval} subscription={subscription} />
      ) : (
        <FirstTimeCheckoutForm plan={plan} interval={interval} />
      )}
      <div className={'flex justify-center mt-2'}>
        <img src={'/assets/stripe.png'} alt={''} className={'w-1/2'} />
      </div>
    </Modal>
  );
}
