import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import PaymentMethods from '../../../feature/AccountSubscription/Payments/PaymentMethods';
import SubscriptionHistory from '../../../feature/AccountSubscription/Invoices/SubscriptionHistory';
import { useSubscription } from './useSubscription';
import Layout from '../../../layouts/global/Layout';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { routes } from '../../../constants/routes';

export default function AccountSubscription() {
  const { profile, selectedOrganization } = useContext(OrganizationContext);

  const { getSubscriptionHistory, errorMessage: historyErrorMessage } = useSubscription();

  const [history, setHistory] = useState<[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const loadData = async () => {
    try {
      if (profile) {
        const paymentHistoryResponse = await getSubscriptionHistory(
          selectedOrganization && (selectedOrganization.account_id as number)
        );
        if (paymentHistoryResponse) {
          setHistory(paymentHistoryResponse.msg);
        }
        /* if (profile.profile_details) {
          if (profile.profile_details.days_left === 0 && !profile.profile_details.is_card_added)
            navigate(routes.PRICING);
        } */
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadData().then((r) => console.log(r));
  }, [selectedOrganization]);

  return (
    <Layout>
      <div>
        {loading ? (
          <div className={'flex h-[80vh] gap-5 flex-col justify-center items-center'}>
            <LoadingSpinner color={'text-[#008160]'} />
            <p>Loading payment details...</p>
          </div>
        ) : (
          <div className={'grid lg:grid-cols-6 gap-5 mb-5'}>
            <div className={'lg:col-span-4'}>
            <div
            className={
              'rounded-md border border-gray-200 p-3 md:p-5 bg-white text-gray-500 mb-5'
            }>
            <h6 className={'font-semibold md:font-medium text-sm lg:text-base'}>
              Subscription Invoice History
            </h6>
            <hr className={'my-2 md:my-4'} />
            {profile.subscription_details && history && history?.length > 0 ? (
              <SubscriptionHistory
                error={historyErrorMessage}
                history={history}
                userSubscription={profile.subscription_details}
              />
            ) : (
              <div className="text-center py-6">
                <p className={'font-medium text-sm text-amber-500'}>
                  No invoice history available at this time.
                </p>
              </div>
            )}
          </div>
            </div>
            <div className={'lg:col-span-2'}>
              {profile.subscription_details && profile.subscription_details.card !== undefined && (
                <PaymentMethods />
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}
