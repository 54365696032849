import { useRef } from 'react';
import useClickOutside from '../../../../hooks/useClickOutside';
import { IUserWebsite } from '../../../../interfaces/website';
import { IWebsiteSubscription } from '../../../../interfaces/subscription';
import { useState } from 'react';


export default function WebsitesMultiSelectListingPopover({
  data,
  onClickOutside,
  selectedWebsites,
  setSelectedWebsites,
  removeItem,
  show
}: {
  data: IWebsiteSubscription[];
  selectedWebsites?: IWebsiteSubscription[];
  setSelectedWebsites: any;
  removeItem: any;
  onClickOutside: () => void;
  show: boolean;
}) {
  const ref: any = useRef(null);
  useClickOutside(ref, onClickOutside);

  if (!show) return null;

  const onWebsiteSelected = (newItem: IWebsiteSubscription) => {
    setSelectedWebsites([...(selectedWebsites as IWebsiteSubscription[]), newItem]);
  };

  const [searchTerm, setSearchTerm] = useState('');

const filteredData = data.filter((site) => 
  site.website && 
  (site.website.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
   site.website.main_url.toLowerCase().includes(searchTerm.toLowerCase()))
);

  return (
    <>
      <div
        ref={ref}
        className={
          'absolute top-5 lg:top-0 right-0 bg-white z-50 drop-shadow-lg w-[45vw] md:w-[30vw] lg:w-full '
        }>
        <p className={'font-medium text-sm px-3 pt-3'}>Select websites</p>
        <div className={'px-2 pb-2 flex flex-wrap gap-2'}>
          {selectedWebsites &&
            selectedWebsites.length > 0 &&
            selectedWebsites.map((selected) => (
              <span
                key={selected.idsite}
                className={'rounded-full px-3 py-1 flex items-center gap-2 bg-emerald-100'}>
                <span className={'text-xs'}>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/*@ts-ignore*/}
                  {(selected.website && selected.website.name) || selected.name}
                </span>
                <span onClick={() => removeItem(selected.idsite)}>
                  <i className="fi fi-ss-cross text-xs"></i>
                </span>
              </span>
            ))}
        </div>
        <div className={`flex flex-col h-[23vh] overflow-auto slim-scroll`}>
      <hr className={'my-1'} />
      <div>
        {/* Bind the input element to the searchTerm state */}
        <input 
          type="text" 
          placeholder='search website' 
          className='w-[100%] px-3 py-2 text-sm border-b mb-3' 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)} 
        />
      </div>
      {filteredData.length > 0 ? (
        filteredData
          .map((site, index) => (
            <div
              onClick={(e) => {
                e.stopPropagation();
                onWebsiteSelected(site);
              }}
              // Combine idsite with index to ensure the key is unique
              key={`${site.idsite}-${index}`}
              className={'p-2 flex gap-2 hover:bg-gray-100 cursor-pointer'}
            >
              <div>
                <img
                  className={'rounded-sm w-8 h-8 object-contain drop-shadow-sm bg-white'}
                  src={`https://www.google.com/s2/favicons?domain=${site.website?.main_url}&sz=128`}
                  alt={''}
                />
              </div>
              <div className={'flex flex-col'}>
                <span className={'text-xs font-medium'}>{site.website?.name}</span>
                <span className={'text-xs font-light'}>{site.website?.main_url}</span>
              </div>
            </div>
          ))
      ) : (
        <div className={'p-5 flex justify-center items-center'}>
          <p className={'text-center text-sm italic'}>No websites</p>
        </div>
      )}
    </div>
      </div>
    </>
  );
}
