import { useState } from 'react';
import { getRequest } from '../../../requests';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { useLogoutUser } from '../../../util/global';

export const useSubscription = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const auth = useAuthUser();

  const { logoutUser } = useLogoutUser();

  const cancelSubscription = async ({ subscriptionId }: { subscriptionId: string }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.cancelSubscription',
          subscriptionId,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        // Check if Subscription is canceled on Stripe but not in our database
        if (
          response.data.result === 'error' &&
          response.data.msg.startsWith('Error: No such Subscription:')
        ) {
          // Force cancel in database
          const forceCancelResponse = await getRequest({
            url: 'index.php',
            data: {},
            params: {
              module: 'API',
              method: 'PaymentIntegration.cancelSubscription',
              subscriptionId,
              force: true,
              token: token,
              format: 'json'
            }
          });
          if (forceCancelResponse.data.result === 'success') {
            setLoading(false);
            return forceCancelResponse.data;
          } else {
            setErrorMessage(forceCancelResponse.data.message || forceCancelResponse.data.msg);
            setLoading(false);
            return;
          }
        } else if (response.data.result === 'error') {
          setErrorMessage(response.data.message || response.data.msg);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error canceling Subscription');
      setLoading(false);
    }
  };

  const getSubscriptionCancellationReasons = async (account_id?: string | number | null) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.cancelled_reason_other',
          token: token,
          account_id,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error' && response.data.reason == 'token_expired') {
          await logoutUser();
          setErrorMessage(response.data.msg);
          setLoading(false);
          return;
        } else if (response.data.result === 'error') {
          setErrorMessage('Error');
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error retrieving cancellation reasons');
      setLoading(false);
    }
  };

  const upgradeSubscription = async ({
    currentPlan,
    upgradePlan,
    interval,
    subscriptionId
  }: {
    currentPlan: number;
    upgradePlan: number;
    interval: string;
    subscriptionId: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.upgradeSubscription',
          currentPlan,
          upgradePlan,
          interval,
          trial: localStorage.getItem('trial') || 0,
          subscriptionId,
          token: token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg);
          setLoading(false);
          return;
        }
        localStorage.removeItem('trial');
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error upgrading Subscription');
      setLoading(false);
    }
  };

  const unsubscribeToEmails = async ({ email, token }: { email: string; token: string }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.unsubscribe',
          email,
          token,
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error unsubscribing to emails');
      setLoading(false);
    }
  };
  const getSubscriptionHistory = async (account_id: string | number | null) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.paymentHistory',
          email: auth()?.email,
          token: token,
          account_id,
          status: 'all',
          format: 'json'
        }
      });
      if (response.data) {
        if (response.data.result === 'error' && response.data.reason == 'token_expired') {
          await logoutUser();
          setErrorMessage(response.data.msg);
          setLoading(false);
          return;
        } else if (response.data.result === 'error') {
          setErrorMessage('Error');
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setErrorMessage('Error retrieving payment history');
      setLoading(false);
    }
  };

  return {
    cancelSubscription,
    upgradeSubscription,
    getSubscriptionHistory,
    unsubscribeToEmails,
    loading,
    errorMessage
  };
};
