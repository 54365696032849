import moment from 'moment/moment';
import { useState } from 'react';
import OpenAIPrompt from './OpenAIPrompt';
import { IAIDocument } from '../../interfaces/analytic';
import AIDocumentListCardMenuPopover from '../../components/global/ui/popovers/AIDocumentListCardMenuPopover';
import DeleteAIPrompt from './DeleteAIPrompt';
import ActivateAIPrompt from './ActivateAIPrompt';

export default function AIPromptListCard({
  data,
  refetch
}: {
  data: IAIDocument;
  refetch?: () => void;
}) {
  const [selected, setSelected] = useState<IAIDocument | null>(null);
  const [menuOptionOpen, setMenuOptionOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [activateOpen, setActivateOpen] = useState<boolean>(false);

  return (
    <>
      {editOpen && selected && (
        <OpenAIPrompt
          refetch={refetch as () => void}
          data={selected}
          closeModal={() => setEditOpen(false)}
        />
      )}
      {deleteOpen && selected && (
        <DeleteAIPrompt
          refetch={refetch as () => void}
          data={selected}
          closeModal={() => setDeleteOpen(false)}
        />
      )}
      {activateOpen && selected && (
        <ActivateAIPrompt
          refetch={refetch as () => void}
          data={selected}
          closeModal={() => setActivateOpen(false)}
        />
      )}
      <div
        className={`border rounded-md border-gray-200 cursor-pointer hover:border-gray-400 bg-white hover:shadow p-3 md:p-3`}>
        <div className={'flex justify-between'}>
          <div className={''}>
            {data && data.status == 1 && (
              <span
                className={`rounded-full px-2 py-1 leading-none text-xs font-medium bg-emerald-100 text-[#008160] uppercase`}>
                Active
              </span>
            )}
          </div>
          <div className={'relative'}>
            <button
              title={'Options'}
              type={'button'}
              onClick={() => {
                setSelected(data);
                setMenuOptionOpen(true);
              }}
              className={
                'option rounded px-2 py-1 flex items-start gap-2 border border-transparent hover:border-gray-500 bg-gray-100 text-gray-500 text-sm'
              }>
              <span>
                <i className="option fi fi-rs-menu-dots-vertical text-sm"></i>
              </span>
            </button>
            {menuOptionOpen && (
              <AIDocumentListCardMenuPopover
                data={data}
                setDeleteOpen={setDeleteOpen}
                setEditOpen={setEditOpen}
                setActivateOpen={setActivateOpen}
                setSelected={setSelected}
                onClickOutside={() => setMenuOptionOpen(false)}
                show={menuOptionOpen}
              />
            )}
          </div>
        </div>
        <div
          onClick={() => {
            setSelected(data);
            setEditOpen(true);
          }}>
          <div>
            <h5 className={'font-medium text-base mt-2'}>{data.title}</h5>
          </div>
          <div>
            <span className={'text-sm italic'}>
              Updated: {moment(data.updated_at).format('lll')}
            </span>
          </div>
          <div className={'mt-2 bg-gray-100 rounded p-3'}>
            <h6 className={'font-medium mb-2'}>Training</h6>
            <div
              dangerouslySetInnerHTML={{
                __html: data.context as string
              }}
              className={'text-sm truncate'}></div>
          </div>
          <div className={'mt-2 bg-gray-100 rounded p-3'}>
            <h6 className={'font-medium mb-2'}>Prompt</h6>
            <div
              dangerouslySetInnerHTML={{
                __html: data.prompt as string
              }}
              className={'text-sm truncate'}></div>
          </div>
        </div>
      </div>
    </>
  );
}
