import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useState } from 'react';
import { useClients } from '../../pages/main/Clients/useClients';
import Modal from '../../components/global/ui/Modal';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import PrimaryButton from '../../components/global/ui/buttons/PrimaryButton';
import Error from '../../components/global/ui/alerts/Error';
import { userAccessOptions, userTypes } from '../../constants';
import UserAccessPopover from '../../components/global/ui/popovers/UserAccessPopover';
import { IUserAccessOption, IUserType } from '../../interfaces/user';
import UserTypesPopover from '../../components/global/ui/popovers/UserTypesPopover';

export default function AddNewClient({ closeModal }: { closeModal: () => void }) {
  /* const validationSchema = Yup.object().shape({
    fullname: Yup.string().required('Enter your full name'),
    email: Yup.string().email('Enter a valid email address').required('Email address is required'),
    password: Yup.string()
      .min(8, 'Password character should be longer than 8')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
  }); */

  const validationSchema = Yup.object().shape({
    fullname: Yup.string().required('Enter your full name'),
    email: Yup.string().email('Enter a valid email address').required('Email address is required'),
    password: Yup.string()
      .min(8, 'Password character should be longer than 8')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)\-\_\=\+\[\]\{\}\;\:\'\"\,\<\>\.\/\?\|\\~`])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
  });

  const { addClient, loading, errorMessage } = useClients();

  const [message, setMessage] = useState<string | null>(null);
  const [userTypesShow, setUserTypesShow] = useState<boolean>(false);
  const [selectedUserType, setSelectedUserType] = useState<IUserType | null>(
    userTypes && userTypes[0]
  );

  const hideAccessOptions = () => setUserTypesShow(false);

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      password: ''
    },
    validationSchema,
    onSubmit: async (values) => handleAddNewClient(values)
  });

  const handleAddNewClient = async (values: FormikValues) => {
    setMessage(null);

    const response = await addClient({
      email: values.email,
      fullname: values.fullname,
      password: values.password,
      user_type: selectedUserType?.id as string
    });

    if (response?.result === 'success') {
      setMessage(response.msg || response.message);
      setTimeout(() => {
        closeModal();
      }, 5000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Set up user'} width={'lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Creating a client account...Please wait</h5>
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                formik.handleSubmit();
              }}>
              <div>
                <div className={'grid gap-2'}>
                  <div className={''}>
                    <label className={'text-sm'}>
                      Full name <span className={'text-red-500'}>*</span>
                    </label>
                    <input
                      type={'text'}
                      name={'fullname'}
                      value={formik.values.fullname}
                      onChange={formik.handleChange}
                      autoFocus={true}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1"
                    />
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.fullname && formik.errors.fullname}
                    </p>
                  </div>
                  <div>
                    <label className={'text-sm'}>
                      Email address <span className={'text-red-500'}>*</span>
                    </label>
                    <input
                      type={'email'}
                      name={'email'}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1"
                    />
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.email && formik.errors.email}
                    </p>
                  </div>
                  <div>
                    <label className={'text-sm'}>
                      User type <span className={'text-red-500'}>*</span>
                    </label>
                    <div className={'relative w-full mt-1'}>
                      <button
                        type={'button'}
                        onClick={() => setUserTypesShow(!userTypesShow)}
                        className={`border px-3 py-2 flex justify-between ${
                          userTypesShow ? 'border-gray-300' : 'border-gray-300'
                        } w-full rounded text-sm`}>
                        {selectedUserType ? (
                          <>
                            <span className={'flex flex-col items-start'}>
                              <span>{selectedUserType.name}</span>
                              <span className={'text-xs'}>{selectedUserType.description}</span>
                            </span>
                            <span>
                              <i
                                className={`fi ${
                                  userTypesShow ? 'fi-sr-caret-up' : 'fi-sr-caret-down'
                                } text-lg`}></i>
                            </span>
                          </>
                        ) : (
                          <>
                            <span className={'flex flex-col items-start'}>
                              <span>{userTypes[0].name}</span>
                              <span className={'text-xs'}>{userTypes[0].description}</span>
                            </span>
                            <span>
                              <i
                                className={`fi ${
                                  userTypesShow ? 'fi-sr-caret-up' : 'fi-sr-caret-down'
                                } text-lg`}></i>
                            </span>
                          </>
                        )}
                      </button>
                      {userTypesShow && (
                        <UserTypesPopover
                          hideUserTypes={hideAccessOptions}
                          show={userTypesShow}
                          setSelectedUserType={setSelectedUserType}
                          options={userTypes}
                          onClickOutside={() => hideAccessOptions()}
                        />
                      )}
                    </div>
                  </div>
                  <div className={`${userTypesShow && 'mt-[15vh]'}`}>
                    <label className={'text-sm'}>
                      Password <span className={'text-red-500'}>*</span>
                    </label>
                    <input
                      type={'password'}
                      name={'password'}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1"
                    />
                    <p className={'text-sm text-red-500'}>
                      {formik.touched.password && formik.errors.password}
                    </p>
                  </div>
                </div>
                <div className={'mt-2'}>
                  <PrimaryButton
                    disabled={loading}
                    title={loading ? 'Creating client account...' : 'Create Client Account'}
                    handler={() => formik.handleSubmit()}
                  />
                </div>
                {errorMessage && <Error value={errorMessage} />}
              </div>
            </form>
          )}
        </div>
      )}
    </Modal>
  );
}
